import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Select,
  Empty,
  Layout,
  Result,
  DatePicker,
  Tooltip,
} from "antd";
import {
  LeftOutlined,
  RightOutlined,
  SmileOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { useMediaQuery } from "react-responsive";
import {
  fetchChatbotHistory,
  saveChatbotHistory,
  getEvents,
} from "../../utils/commonApis";
import { ApiCall } from "../../utils/api";
import { isEmpty, isEqual, uniqWith } from "lodash";
import "./eventCalender.css";
import {
  getAllEventDates,
  getAllSessions,
  getAllSubDivisionsData,
  getAVLTimes,
  getCurrentTimeAndPercentage,
  getHourIntervals,
  getLeftTitles,
  getNewDateIndexToNavigate,
  getRightTitles,
  getSessionlabels,
  getSponserTimingsAndHeader,
  isPastDate,
} from "./eventCalender.utils";
import moment from "moment";
import ExpoHallOpen from "./Popups/ExpoHallOpen";
import Sponser from "./Popups/Sponser";
import AudiSession from "./Popups/AudiSession";
import NetworkingSession from "./Popups/NetworkingSession";
import TickerConfiguration from "./Popups/TickerConfiguration";
import { toast } from "react-toastify";
import {
  DEFAULT_CHATBOT_HISTORY,
  TIME_FORMAT,
} from "../../components/constsnts/common.constants";
import Text from "antd/lib/typography/Text";
import {
  getNearestFutureDate,
  getComentChatUser,
  isAdminLevelUser,
  isNonAdminLevelUser,
  isAdminCustomer,
  isRouteExistInMenu,
  isSuperAdminOrCustomer,
} from "../../utils/commonFunctions";
import CustomChatbot from "../../components/Widgets/CustomChatbot";
import { useStateIfMounted } from "use-state-if-mounted";
import { currentTimeLineDiv, getCellText } from "./Components/CommonComponents";
import EventCalenderHeader from "./Components/EventCalenderHeader";
import { dialogs } from "../../components/constsnts/string.constants";
import { useSelector } from "react-redux";
import AudiViewDelegates from "../commonPopups/AudiViewDelegates";
import NetViewDelegates from "../commonPopups/NetViewDelegates";
import PastDateDelegate from "../commonPopups/PastDateDelegate";
import EventCalenderSider from "./Components/EventCalenderSider";

const EventCalendar = (eventCalProps) => {
  const vistaEventId = useSelector((state) => state?.vistaBrand?.eventId); //from Vista redux
  const scollToRef = useRef();
  const [disableEvent, setDisableEvent] = useStateIfMounted(false);
  const Tablet = useMediaQuery({ minWidth: 1000, maxWidth: 1025 });
  const Tablet2 = useMediaQuery({ maxWidth: 900 });

  const { Option } = Select;
  const { Header } = Layout;
  var CryptoJS = require("crypto-js");
  const [liveTime, setLiveTime] = useState(getCurrentTimeAndPercentage());
  const [isCollapsedSider, setIsCollapsedSider] = useState(true);
  const [eventCalendarData, setEventCalendarData] = useStateIfMounted({});
  const [eventList, setEventList] = useStateIfMounted([]);
  const [currentEventID, setCurrentEventID] = useStateIfMounted("empty"); //106
  const [currentEventData, setCurrentEventData] = useStateIfMounted({});
  const [userType, setUserType] = useState("");
  const [userBrandId, setUserBrandId] = useState(null);
  const [loader, setLoader] = useState(false);
  const today = moment().format("MMMM DD, yyyy");
  const [allSessions, setAllSessions] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [brandsOfEvent, setBrandsOfEvent] = useState([]);
  const [datePickerModal, setDatePickerModal] = useStateIfMounted(false);
  const [dateToDisplay, setDateToDisplay] = useStateIfMounted(today);
  const [currentDateForCalender, setCurrentDateForCalender] = useStateIfMounted(
    moment().format("YYYY-MM-DD")
  );
  const [dateForDatePicker, setDateForDatePicker] = useStateIfMounted(moment());
  const [isNoEventDataFound, setIsNoEventDataFound] = useStateIfMounted(false);
  const [isNoDataForTheDay, setIsNoDataForTheDay] = useStateIfMounted(false);
  const [event_dates, set_event_dates] = useStateIfMounted([]);
  const [current_date_to_navigate, set_current_date_to_navigate] =
    useStateIfMounted({
      currentIndex: 0,
      dateString: moment().format("YYYY-MM-DD"),
      length: 0,
    });
  const [event_dates_to_navigate, set_event_dates_to_navigate] =
    useStateIfMounted([current_date_to_navigate]);
  const [expoAvlTimings, setExpoAvlTimings] = useState({ sponsers: [] });
  const [mainAudiAvlTimings, setMainAudiAvlTimings] = useState({
    sessions: [],
    sponsers: [],
  });
  const [satAudiAvlTimings, setSatAudiAvlTimings] = useState({
    sessions: [],
    sponsers: [],
  });
  const [netLoungeAvlTimings, setNetLoungeAvlTimings] = useState({
    sessions: [],
    sponsers: [],
  });
  const [tickerAvlTimings, setTickerAvlTimings] = useState({ sponsers: [] });
  const [socialAvlTimings, setSocialAvlTimings] = useState({ sponsers: [] });
  const [concrsAvlTimings, setConcrsAvlTimings] = useState({ sponsers: [] });

  const defaultHeaderData = {
    boxData: {},
    currentIndex: 0,
    count: 0,
    allDivisions: [],
  };
  const defaultSponserData = {
    boxData: [],
    allDivisions: [],
    currentIndex: 0,
    count: 0,
  };
  const [default_sponsor_details, set_default_sponsor_details] = useState({});

  //expo hall
  const [expoHallData, setExpoHallData] = useState(defaultHeaderData);
  const [expoHallSponsers, setExpoHallSponsers] = useState(defaultSponserData);
  const [expoSponserHeader, setExpoSponserHeader] = useState({});
  const [expoHallCurrentIndex, setExpoHallCurrentIndex] = useState(0);
  const [expoHallOpenModal, setExpoHallOpenModal] = useState({
    isOpen: false,
    expoHallData: null,
    currentBlock: null,
  });
  const [sponsorModal, setSponsorModal] = useState({
    isOpen: false,
    sponserData: null,
    title: "",
    existingTimings: [],
    hourItem: null,
  });
  //main audi
  const [mainAudiData, setMainAudiData] = useState(defaultHeaderData);
  const [mainAudiSessions, setMainAudiSessions] = useState(defaultSponserData);
  const [mainAudiSponsers, setMainAudiSponsers] = useState(defaultSponserData);
  const [mainAudiSponserHeader, setMainAudiSponserHeader] = useState({});

  //sat audi
  const [satAudiData, setSatAudiData] = useState(defaultHeaderData);
  const [satAudiSessions, setSatAudiSessions] = useState(defaultSponserData);
  const [satAudiSponsers, setSatAudiSponsers] = useState(defaultSponserData);
  const [satAudiSponserHeader, setSatAudiSponserHeader] = useState({});
  const [satAudiCurrentIndex, setSatAudiCurrentIndex] = useState(0);
  //audi session modal
  const [audiSessionModal, setAudiSessionModal] = useState({
    isOpen: false,
    audiSessionData: null,
    title: "",
    existingTimings: [],
    hourItem: null,
  });

  //audi session for view
  const [audiSessionView, setAudiSessionView] = useState({
    isOpen: false,
    audiSessionData: null,
    title: "",
    existingTimings: [],
  });

  //net lounges
  const [netLoungeData, setNetLoungeData] = useState(defaultHeaderData);
  const [netLoungeSessions, setNetLoungeSessions] =
    useState(defaultSponserData);
  const [netLoungeSponsers, setNetLoungeSponsers] =
    useState(defaultSponserData);
  const [netSponserHeader, setNetSponserHeader] = useState({});
  const [netLoungeCurrentIndex, setNetLoungeCurrentIndex] = useState(0);
  const [netwrkSessionModal, setNetwrkSessionModal] = useState({
    isOpen: false,
    networkSessionData: null,
    title: "",
    existingTimings: [],
  });

  const [networkModalForView, setNetworkModalForView] = useState({
    isOpen: false,
    networkSessionData: null,
    title: "",
    existingTimings: [],
  });

  const [tickerData, setTickerData] = useState(defaultSponserData);
  const [socialData, setSocialData] = useState(defaultSponserData);
  const [concourseData, setConcourseData] = useState(defaultSponserData);
  const [tickerConfigModal, setTickerConfigModal] = useState({
    isOpen: false,
    tickerData: null,
    existingTimings: [],
  });
  const [browser, setBrowser] = useState("");

  const fullHours = getHourIntervals();

  const [cometChatUser, setCometChatUser] = useState({
    isValidUser: false,
    data: {},
    uid: "",
  });
  const [event_settings, set_event_settings] = useState({
    is_social_wall_active: true,
    is_concourse_active: true,
    is_ticker_tape_active: true,
    is_expo_hall_active: true,
    is_main_hall_active: true,
    is_sat_auditorium_active: true,
    is_networking_active: true,
  });
  const [isChatBot, setIsChatBot] = useState(false);
  const [chatbot_json, set_chatbot_json] = useState([]);
  const [chatbot_history, set_chatbot_history] = useState(
    DEFAULT_CHATBOT_HISTORY
  );

  const [errorModal, setErrorModal] = useState({
    isOpen: false,
    message: "",
  });

  const [classNameForTicker, setClassNameForTicker] = useState(
    "event_table_wrapper1"
  );

  const setChatBotLocalJSON = (response) => {
    set_chatbot_json(response);
    setIsChatBot(true);
  };

  useEffect(async () => {
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(decryptedData);
    let is_route_present = await isRouteExistInMenu(CryptoJS);

    if (localStorage.getItem("brand_id")) {
      setUserBrandId(parseInt(localStorage.getItem("brand_id")));
    }

    if (is_route_present === false) {
      window.location.href = "/pagenotfound";
    } else {
      const cometRes = await getComentChatUser();
      setCometChatUser(cometRes);
      const eventsData = await getEvents();

      let user_type = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      if (
        user_type === "DELEGATE" ||
        user_type === "SPEAKER" ||
        user_type === "BRAND ADMIN" ||
        user_type === "AGENT"
      ) {
        const localData_event_id = parseInt(localStorage.getItem("event_id"));
        const events = eventsData.filter((item) => {
          return item.event_id === localData_event_id;
        });

        setEventList(events);
        let id = events[0].event_id;
        if (id) {
          setLoader(true);
          setCurrentEventID(id);
          setDisableEvent(true);
        }
      } else {
        if (eventsData.length === 1) {
          setLoader(true);
          setCurrentEventID(eventsData[0].event_id);
        }
        setEventList(eventsData);
      }
    }
  }, [CryptoJS.AES]);

  useEffect(() => {
    if (localStorage.getItem("tickerToDisplay")) {
      setClassNameForTicker("event_table_wrapper2");
    } else {
      setClassNameForTicker("event_table_wrapper1");
    }
  }, [localStorage.getItem("tickerToDisplay")]);

  useEffect(async () => {
    if (currentEventID && currentEventID !== "empty") {
      setIsNoEventDataFound(false);
      // setIsNoDataForTheDay(false);
      const chatHistory = await fetchChatbotHistory({
        event_id: currentEventID,
      });
      if (chatHistory && !isEmpty(chatHistory))
        set_chatbot_history(chatHistory);

      const responseEvent = await ApiCall(
        "GET",
        "",
        `/admin/events/details/get?event_id=${currentEventID ?? ""}`,
        ""
      );

      if (responseEvent?.data?.data)
        setCurrentEventData(responseEvent?.data?.data);

      if (responseEvent?.data?.data?.editor_details) {
        const show_details =
          responseEvent.data.data.editor_details.event_shows_details;

        if (show_details && !isEmpty(show_details)) {
          const event_dates_here = await getAllEventDates(show_details);

          if (event_dates_here) {
            set_event_dates(event_dates_here);
            let nearestDate = getNearestFutureDate(event_dates_here);
            let datesToPush = [];
            event_dates_here.forEach((ele, index) => {
              if (ele === nearestDate)
                set_current_date_to_navigate({
                  currentIndex: index,
                  dateString: nearestDate,
                  length: event_dates_here.length,
                });

              datesToPush.push({
                currentIndex: index,
                dateString: ele,
                length: event_dates_here.length,
              });
            });
            set_event_dates_to_navigate(datesToPush);
            if (!nearestDate || isEmpty(nearestDate)) {
              nearestDate = moment().format("YYYY-MM-DD");
            }
            setCurrentDateForCalender(nearestDate);
            setDateToDisplay(moment(nearestDate).format("MMMM DD, yyyy"));
            setDateForDatePicker(moment(nearestDate));
            callFetchEventCalenderData(true, nearestDate);
          }
        } else dataNotFound();
      } else dataNotFound();
    }
  }, [currentEventID]);

  useEffect(() => {
    setCurrentEventID(vistaEventId);
  }, [vistaEventId]);

  function dataNotFound() {
    toast.error("Event data not found");
    setIsNoEventDataFound(true);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentEventID && currentEventID !== "empty") {
        if (
          expoHallOpenModal.isOpen ||
          audiSessionModal.isOpen ||
          netwrkSessionModal.isOpen ||
          tickerConfigModal.isOpen ||
          sponsorModal.isOpen ||
          audiSessionView.isOpen ||
          networkModalForView.isOpen ||
          errorModal.isOpen
        ) {
          //do nothing
        } else callFetchEventCalenderData(false, currentDateForCalender);
      }
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, [
    dateForDatePicker,
    expoHallOpenModal,
    audiSessionModal,
    netwrkSessionModal,
    tickerConfigModal,
    sponsorModal,
    netLoungeCurrentIndex,
    satAudiCurrentIndex,
    expoHallCurrentIndex,
  ]);

  const callFetchEventCalenderData = (
    displayLoader = true,
    dateSelected = ""
  ) => {
    if (displayLoader) setLoader(true);
    setTimeout(async () => {
      setLiveTime(getCurrentTimeAndPercentage());
      if (dateSelected) fetchEventCalenderData(dateSelected);
      else fetchEventCalenderData(currentDateForCalender);
    }, 1000);
  };

  function setAllDatesAndIndexes(d1, d2, d3) {
    setCurrentDateForCalender(d1);
    setDateToDisplay(d2);
    setDateForDatePicker(d3);
    setExpoHallCurrentIndex(0);
    setSatAudiCurrentIndex(0);
    setNetLoungeCurrentIndex(0);
    callFetchEventCalenderData(true, d1);
  }

  const updateExpoHallStates = async (data, currentIndex) => {
    if (
      data.expo_hall_configurations &&
      !isEmpty(data.expo_hall_configurations)
    ) {
      const sessionDataHere = {
        boxData: data.expo_hall_configurations?.[currentIndex],
        currentIndex,
        count: data.expo_hall_configurations
          ? data.expo_hall_configurations.length
          : 0,
      };
      const sponserDataHere = {
        boxData:
          data.expo_hall_configurations?.[currentIndex]
            ?.expo_hall_brand_sponsor,
        currentIndex,
        count:
          data.expo_hall_configurations?.[currentIndex]?.expo_hall_brand_sponsor
            .length,
      };

      let allSessionsDivisions = [];
      let allSponsersDivisions = [];
      await fullHours.forEach((hourItem) => {
        let hrStart = moment(hourItem.start_time, TIME_FORMAT);
        let hrEnd = moment(hourItem.end_time, TIME_FORMAT);
        const divObj = { hrStart, hrEnd, columnName: "expo" };
        allSessionsDivisions.push(
          getAllSubDivisionsData({
            ...divObj,
            data: sessionDataHere,
            isSession: true,
          })
        );

        allSponsersDivisions.push(
          getAllSubDivisionsData({
            ...divObj,
            data: sponserDataHere,
          })
        );
      });

      setExpoHallData({
        ...sessionDataHere,
        allDivisions: allSessionsDivisions,
      });
      setExpoHallSponsers({
        ...sponserDataHere,
        allDivisions: allSponsersDivisions,
      });

      const ele = data.expo_hall_configurations[currentIndex];
      if (ele && ele.expo_hall_brand_sponsor) {
        const res = getSponserTimingsAndHeader(
          ele,
          "expo_hall_brand_sponsor",
          liveTime
        );
        setExpoAvlTimings({ sponsers: res.timings });
        setExpoSponserHeader(res.newHeader);
      }
    }
  };

  const updateMainAudiStates = async (data) => {
    const sessionDataHere = {
      boxData: data.main_auditorium_configurations
        ? data.main_auditorium_configurations.auditorium_sessions
        : [],
      currentIndex: 0,
      count:
        data.main_auditorium_configurations &&
        data.main_auditorium_configurations.auditorium_sessions
          ? data.main_auditorium_configurations.auditorium_sessions.length
          : 0,
    };
    const sponserDataHere = {
      boxData: data.main_auditorium_configurations
        ? data.main_auditorium_configurations.main_auditorium_hall_brand_sponsor
        : [],
      currentIndex: 0,
      count:
        data.main_auditorium_configurations &&
        data.main_auditorium_configurations.main_auditorium_hall_brand_sponsor
          ? data.main_auditorium_configurations
              .main_auditorium_hall_brand_sponsor.length
          : 0,
    };

    let allSessionsDivisions = [];
    let allSponsersDivisions = [];
    await fullHours.forEach((hourItem) => {
      let hrStart = moment(hourItem.start_time, TIME_FORMAT);
      let hrEnd = moment(hourItem.end_time, TIME_FORMAT);
      const divObj = { hrStart, hrEnd, columnName: "mainAudi" };
      allSessionsDivisions.push(
        getAllSubDivisionsData({
          ...divObj,
          data: sessionDataHere,
        })
      );
      allSponsersDivisions.push(
        getAllSubDivisionsData({
          ...divObj,
          data: sponserDataHere,
        })
      );
    });

    setMainAudiData({
      boxData: data.main_auditorium_configurations,
      currentIndex: 0,
      count: 1,
    });

    setMainAudiSessions({
      ...sessionDataHere,
      allDivisions: allSessionsDivisions,
    });
    setMainAudiSponsers({
      ...sponserDataHere,
      allDivisions: allSponsersDivisions,
    });

    if (data.main_auditorium_configurations) {
      let newHeader = {};
      let sponsersTimings = [];
      let sessionsTimings = [];
      if (
        data.main_auditorium_configurations.main_auditorium_hall_brand_sponsor
      ) {
        const res = getSponserTimingsAndHeader(
          data.main_auditorium_configurations,
          "main_auditorium_hall_brand_sponsor",
          liveTime
        );
        sponsersTimings = res.timings;
        newHeader = res.newHeader;
      }

      if (data.main_auditorium_configurations.auditorium_sessions) {
        data.main_auditorium_configurations.auditorium_sessions.forEach(
          (item) => {
            if (item && item.start_time && item.end_time)
              sessionsTimings.push({
                start_time: item.start_time,
                end_time: item.end_time,
                data: item,
              });
          }
        );
      }
      sessionsTimings = uniqWith(sessionsTimings, isEqual);
      if (newHeader) setMainAudiSponserHeader(newHeader);
      setMainAudiAvlTimings({
        sessions: sessionsTimings,
        sponsers: sponsersTimings,
      });
    }
  };

  const updateSatAudiStates = async (data, currentIndex) => {
    const sessionDataHere = {
      boxData:
        data.satellite_auditorium_configurations?.[currentIndex]
          ?.auditorium_sessions,
      currentIndex,
      count:
        data.satellite_auditorium_configurations?.[currentIndex]
          ?.auditorium_sessions.length,
    };
    const sponserDataHere = {
      boxData:
        data.satellite_auditorium_configurations?.[currentIndex]
          ?.satellite_auditorium_brand_sponsor,
      currentIndex,
      count:
        data.satellite_auditorium_configurations?.[currentIndex]
          ?.satellite_auditorium_brand_sponsor.length,
    };

    let allSessionsDivisions = [];
    let allSponsersDivisions = [];
    await fullHours.forEach((hourItem) => {
      let hrStart = moment(hourItem.start_time, TIME_FORMAT);
      let hrEnd = moment(hourItem.end_time, TIME_FORMAT);

      const divObj = { hrStart, hrEnd, columnName: "satAudi" };
      allSessionsDivisions.push(
        getAllSubDivisionsData({
          ...divObj,
          data: sessionDataHere,
        })
      );
      allSponsersDivisions.push(
        getAllSubDivisionsData({
          ...divObj,
          data: sponserDataHere,
        })
      );
    });

    setSatAudiData({
      boxData: data.satellite_auditorium_configurations?.[currentIndex],
      currentIndex,
      count: data.satellite_auditorium_configurations
        ? data.satellite_auditorium_configurations.length
        : 0,
    });

    setSatAudiSessions({
      ...sessionDataHere,
      allDivisions: allSessionsDivisions,
    });

    setSatAudiSponsers({
      ...sponserDataHere,
      allDivisions: allSponsersDivisions,
    });
    if (data.satellite_auditorium_configurations) {
      const ele = data.satellite_auditorium_configurations[currentIndex];
      let newHeader = {};
      let sessionsTimings = [];
      let sponsersTimings = [];
      if (ele && ele.satellite_auditorium_brand_sponsor) {
        const res = getSponserTimingsAndHeader(
          ele,
          "satellite_auditorium_brand_sponsor",
          liveTime
        );
        sponsersTimings = res.timings;
        newHeader = res.newHeader;
      }

      if (ele && ele.auditorium_sessions) {
        ele.auditorium_sessions.forEach((item) => {
          if (item && item.start_time && item.end_time)
            sessionsTimings.push({
              start_time: item.start_time,
              end_time: item.end_time,
              data: item,
            });
        });
      }
      sessionsTimings = uniqWith(sessionsTimings, isEqual);
      if (newHeader) setSatAudiSponserHeader(newHeader);
      setSatAudiAvlTimings({
        sessions: sessionsTimings,
        sponsers: sponsersTimings,
      });
    }
  };

  const updateNetLounges = async (data, currentIndex) => {
    const sessionDataHere = {
      boxData:
        data.networking_lounge_configurations?.[currentIndex]
          ?.networking_lounge_sessions,
      currentIndex,
      count:
        data.networking_lounge_configurations?.[currentIndex]
          ?.networking_lounge_sessions.length,
    };
    const sponserDataHere = {
      boxData:
        data.networking_lounge_configurations?.[currentIndex]
          ?.networking_lounge_brand_sponsor,
      currentIndex,
      count:
        data.networking_lounge_configurations?.[currentIndex]
          ?.networking_lounge_brand_sponsor.length,
    };

    let allSessionsDivisions = [];
    let allSponsersDivisions = [];
    await fullHours.forEach((hourItem) => {
      let hrStart = moment(hourItem.start_time, TIME_FORMAT);
      let hrEnd = moment(hourItem.end_time, TIME_FORMAT);
      const divObj = { hrStart, hrEnd, columnName: "netLounge" };
      allSessionsDivisions.push(
        getAllSubDivisionsData({
          ...divObj,
          data: sessionDataHere,
        })
      );
      allSponsersDivisions.push(
        getAllSubDivisionsData({
          ...divObj,
          data: sponserDataHere,
        })
      );
    });

    setNetLoungeData({
      boxData: data.networking_lounge_configurations?.[currentIndex],
      currentIndex,
      count: data.networking_lounge_configurations
        ? data.networking_lounge_configurations.length
        : 0,
    });
    setNetLoungeSessions({
      ...sessionDataHere,
      allDivisions: allSessionsDivisions,
    });
    setNetLoungeSponsers({
      ...sponserDataHere,
      allDivisions: allSponsersDivisions,
    });
    if (data.networking_lounge_configurations) {
      let newHeader = {};
      let sessionsTimings = [];
      let sponsersTimings = [];
      const ele = data.networking_lounge_configurations[currentIndex];
      if (ele && ele.networking_lounge_brand_sponsor) {
        const res = getSponserTimingsAndHeader(
          ele,
          "networking_lounge_brand_sponsor",
          liveTime
        );
        sponsersTimings = res.timings;
        newHeader = res.newHeader;
      }
      if (ele && ele.networking_lounge_sessions) {
        ele.networking_lounge_sessions.forEach((item) => {
          sessionsTimings.push({
            start_time: item.start_time,
            end_time: item.end_time,
            data: item,
          });
        });
      }
      sessionsTimings = uniqWith(sessionsTimings, isEqual);

      if (newHeader) setNetSponserHeader(newHeader);
      setNetLoungeAvlTimings({
        sessions: sessionsTimings,
        sponsers: sponsersTimings,
      });
    }
  };

  const updateTickerStates = async (data) => {
    const sponserDataHere = {
      boxData: data.ticker_details,
      currentIndex: 0,
      count: 1,
    };
    let allSponsersDivisions = [];
    await fullHours.forEach((hourItem) => {
      let hrStart = moment(hourItem.start_time, TIME_FORMAT);
      let hrEnd = moment(hourItem.end_time, TIME_FORMAT);
      const divObj = { hrStart, hrEnd, columnName: "ticker" };
      allSponsersDivisions.push(
        getAllSubDivisionsData({
          ...divObj,
          data: sponserDataHere,
        })
      );
    });
    setTickerData({
      ...sponserDataHere,
      allDivisions: allSponsersDivisions,
    });
    if (data.ticker_details) {
      const key = "ticker_details";
      const res = getSponserTimingsAndHeader(data, key, null);
      setTickerAvlTimings({ sponsers: res.timings });
    }
  };

  const updateSocialStates = async (data) => {
    const sponserDataHere = {
      boxData: data.social_wall_sponsorships,
      currentIndex: 0,
      count: 1,
    };
    let allSponsersDivisions = [];
    await fullHours.forEach((hourItem) => {
      let hrStart = moment(hourItem.start_time, TIME_FORMAT);
      let hrEnd = moment(hourItem.end_time, TIME_FORMAT);
      const divObj = { hrStart, hrEnd, columnName: "social wall" };
      allSponsersDivisions.push(
        getAllSubDivisionsData({
          ...divObj,
          data: sponserDataHere,
        })
      );
    });
    setSocialData({
      ...sponserDataHere,
      allDivisions: allSponsersDivisions,
    });
    if (data.social_wall_sponsorships) {
      const key = "social_wall_sponsorships";
      const res = getSponserTimingsAndHeader(data, key, null);
      setSocialAvlTimings({ sponsers: res.timings });
    }
  };

  const updateConcourseStates = async (data) => {
    const sponserDataHere = {
      boxData: data.concourse_sponsorship,
      currentIndex: 0,
      count: 1,
    };
    let allSponsersDivisions = [];
    await fullHours.forEach((hourItem) => {
      let hrStart = moment(hourItem.start_time, TIME_FORMAT);
      let hrEnd = moment(hourItem.end_time, TIME_FORMAT);
      const divObj = { hrStart, hrEnd, columnName: "concourse" };
      allSponsersDivisions.push(
        getAllSubDivisionsData({
          ...divObj,
          data: sponserDataHere,
        })
      );
    });
    setConcourseData({
      ...sponserDataHere,
      allDivisions: allSponsersDivisions,
    });
    if (data.concourse_sponsorship) {
      const key = "concourse_sponsorship";
      const res = getSponserTimingsAndHeader(data, key, null);
      setConcrsAvlTimings({ sponsers: res.timings });
    }
  };

  const fetchEventCalenderData = async (dateSelected = "") => {
    setIsNoDataForTheDay(false);

    let apiParams = {
      event_id: currentEventID,
      date:
        dateSelected && !isEmpty(dateSelected)
          ? dateSelected
          : currentDateForCalender,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    ApiCall("get", null, "/admin/eventcalendar/details/get", apiParams)
      .then((response) => {
        if (response?.data?.message === "No Data Found") {
          setIsNoDataForTheDay(true);
          setLoader(false);
        } else if (response && response.message === "Network Error") {
          toast.error("Please check the internet connection");
          setLoader(false);
        } else if (response?.data?.data?.details) {
          const result = response?.data?.data?.details;

          if (result.brand_details) setBrandsOfEvent(result.brand_details);
          let allSessionsToSearch = getAllSessions(result);
          setAllSessions(allSessionsToSearch);

          if (result.default_sponsor_details)
            set_default_sponsor_details(result.default_sponsor_details);

          updateExpoHallStates(result, expoHallCurrentIndex);
          updateMainAudiStates(result);
          updateSatAudiStates(result, satAudiCurrentIndex);
          updateNetLounges(result, netLoungeCurrentIndex);
          updateTickerStates(result);
          updateSocialStates(result);
          updateConcourseStates(result);

          if (result.event_settings) set_event_settings(result.event_settings);
          setEventCalendarData(result);

          setLoader(false);
        }
      })
      .catch((err) => {
        console.error("err fetchEventCalenderData", err);
        setLoader(false);
      });
  };

  function callExpoUpdate(currentIndex) {
    setLoader(true);
    setTimeout(() => {
      updateExpoHallStates(eventCalendarData, currentIndex);
      setLoader(false);
    }, 1000);
  }

  function callSatAudiUpdate(currentIndex) {
    setLoader(true);
    setTimeout(() => {
      updateSatAudiStates(eventCalendarData, currentIndex);
      setLoader(false);
    }, 1000);
  }

  function callNetLoungesUpdate(currentIndex) {
    setLoader(true);
    setTimeout(() => {
      updateNetLounges(eventCalendarData, currentIndex);
      setLoader(false);
    }, 1000);
  }

  const onShuffleRightLeft = (props, leftOrRight) => {
    const { parentTitle, currentIndex, length } = props;
    if (parentTitle === "Expo Hall") {
      if (leftOrRight === "right" && currentIndex < length - 1) {
        setExpoHallCurrentIndex(currentIndex + 1);
        callExpoUpdate(currentIndex + 1);
      } else if (leftOrRight === "left" && currentIndex !== 0) {
        setExpoHallCurrentIndex(currentIndex - 1);
        callExpoUpdate(currentIndex - 1);
      }
    }
    if (parentTitle === "Satellite Auditorium") {
      if (leftOrRight === "right" && currentIndex < length - 1) {
        setSatAudiCurrentIndex(currentIndex + 1);
        callSatAudiUpdate(currentIndex + 1);
      } else if (leftOrRight === "left" && currentIndex !== 0) {
        setSatAudiCurrentIndex(currentIndex - 1);
        callSatAudiUpdate(currentIndex - 1);
      }
    }
    if (parentTitle === "Networking Lounges") {
      if (leftOrRight === "right" && currentIndex < length - 1) {
        setNetLoungeCurrentIndex(currentIndex + 1);
        callNetLoungesUpdate(currentIndex + 1);
      } else if (leftOrRight === "left" && currentIndex !== 0) {
        setNetLoungeCurrentIndex(currentIndex - 1);
        callNetLoungesUpdate(currentIndex - 1);
      }
    }
  };

  function onDateNavigate(leftOrRight) {
    const res = getNewDateIndexToNavigate({
      leftOrRight,
      current_date_to_navigate,
      event_dates_to_navigate,
    });
    const newDate = res.newDate;
    const newIndexForNavigate = res.newIndexForNavigate;

    if (newDate) {
      set_current_date_to_navigate({
        currentIndex: newIndexForNavigate,
        dateString: event_dates_to_navigate[newIndexForNavigate].dateString,
        length: event_dates_to_navigate.length,
      });
      const cDate = newDate.format("YYYY-MM-DD");
      const cDate1 = newDate.format("MMMM DD, yyyy");
      setAllDatesAndIndexes(cDate, cDate1, newDate);
    }
  }

  function checkIfHostingBrandIdAndBrandAdminIdSame(data, sessionType) {
    let hostingBrandIds = [];
    if (sessionType === "audi") {
      hostingBrandIds = data?.audiSessionData?.auditorium_hosting_brands?.map(
        (item) => {
          return item?.brand_id;
        }
      );
    } else if (sessionType === "netLounge") {
      hostingBrandIds =
        data?.networkSessionData?.networking_lounge_sponsoring_brands?.map(
          (item) => {
            return item?.brand_id;
          }
        );
    }

    let isBrandExistSameAsOfBrandAdmin = false;

    hostingBrandIds?.forEach((id) => {
      if (id === userBrandId) {
        isBrandExistSameAsOfBrandAdmin = true;
      }
    });

    return isBrandExistSameAsOfBrandAdmin;
  }

  const onClickLeftCells = (hourItem, isFilled, ele, columnName) => {
    let existingTimings = [];
    let isOldDate = isPastDate(currentDateForCalender);

    if (columnName === "expo") {
      if (isFilled) {
        if (isAdminLevelUser(userType)) {
          if (isOldDate === "yes")
            setErrorModal({
              isOpen: true,
              message: dialogs.eventCalender.validation.currentFutureSession,
            });
          else
            setExpoHallOpenModal({
              expoHallData: expoHallData.boxData,
              isOpen: true,
              currentBlock: ele,
              hourItem,
            });
        }
      }
    } else if (
      columnName === "mainAudi" ||
      columnName === "satAudi" ||
      columnName === "netLounge"
    ) {
      let avlTimings, mainData;
      if (columnName === "mainAudi") avlTimings = mainAudiAvlTimings;
      if (columnName === "satAudi") avlTimings = satAudiAvlTimings;
      if (columnName === "netLounge") avlTimings = netLoungeAvlTimings;
      if (columnName === "mainAudi") mainData = mainAudiData;
      if (columnName === "satAudi") mainData = satAudiData;
      if (columnName === "netLounge") mainData = netLoungeData;
      existingTimings = getAVLTimes(
        !isFilled,
        ele,
        avlTimings,
        "sessions",
        columnName === "netLounge"
          ? "networking_session_id"
          : "auditorium_session_id"
      );
      let labelRes = getSessionlabels(columnName);
      if (isFilled) {
        let objForModal = {
          title: labelRes.leftModalTitle,
          isOpen: true,
          existingTimings,
          hourItem,
        };

        if (columnName === "mainAudi" || columnName === "satAudi") {
          let audiSessionModalData = {
            audiSessionData: {
              ...ele,
              auditorium_configuration_id: mainData.boxData
                ? mainData.boxData.auditorium_configuration_id
                : "",
            },
            ...objForModal,
          };
          if (isAdminLevelUser(userType)) {
            if (isOldDate === "yes")
              setErrorModal({
                isOpen: true,
                message: dialogs.eventCalender.validation.currentFutureSession,
              });
            else {
              if (userType === "BRAND ADMIN") {
                let isAudiSessionPopup =
                  checkIfHostingBrandIdAndBrandAdminIdSame(
                    audiSessionModalData,
                    "audi"
                  ); //This check if the hosting brands id and the brand admin id is same
                //If its same then load audisession popup otherwise audiview popup

                if (isAudiSessionPopup) {
                  setAudiSessionModal(audiSessionModalData);
                } else {
                  setAudiSessionView(audiSessionModalData);
                }
              } else {
                setAudiSessionModal(audiSessionModalData);
              }
            }
          } else if (isNonAdminLevelUser(userType)) {
            if (isOldDate === "yes")
              setErrorModal({
                isOpen: true,
                message: dialogs.eventCalender.validation.currentFutureSession,
              });
            else {
              setAudiSessionView(audiSessionModalData);
            }
          }
        } else if (columnName === "netLounge") {
          let netLoungeModalData = {
            networkSessionData: {
              ...ele,
              networking_configuration_id: mainData.boxData
                ? mainData.boxData.networking_configuration_id
                : "",
            },
            ...objForModal,
          };
          if (isAdminLevelUser(userType)) {
            if (isOldDate === "yes")
              setErrorModal({
                isOpen: true,
                message: dialogs.eventCalender.validation.currentFutureSession,
              });
            else {
              if (userType === "BRAND ADMIN") {
                let isNetLoungePopup = checkIfHostingBrandIdAndBrandAdminIdSame(
                  netLoungeModalData,
                  "netLounge"
                ); //This check if the hosting brands id and the brand admin id is same
                //If its same then load netLounge session popup otherwise Net View popup

                if (isNetLoungePopup) {
                  setNetwrkSessionModal(netLoungeModalData);
                } else {
                  setNetworkModalForView(netLoungeModalData);
                }
              } else {
                setNetwrkSessionModal(netLoungeModalData);
              }
            }
          } else if (isNonAdminLevelUser(userType)) {
            if (isOldDate === "yes")
              setErrorModal({
                isOpen: true,
                message: dialogs.eventCalender.validation.currentFutureSession,
              });
            else {
              setNetworkModalForView(netLoungeModalData);
            }
          }
        }
      } else {
        if (isAdminCustomer(userType)) {
          let objForModal = {
            title: labelRes.leftModalTitle,
            isOpen: true,
            existingTimings,
            hourItem,
          };
          if (columnName === "mainAudi" || columnName === "satAudi") {
            if (isOldDate === "yes")
              setErrorModal({
                isOpen: true,
                message: dialogs.eventCalender.validation.currentFutureSession,
              });
            else {
              setAudiSessionModal({
                audiSessionData: {
                  auditorium_configuration_id: mainData.boxData
                    ? mainData.boxData.auditorium_configuration_id
                    : "",
                },
                ...objForModal,
              });
            }
          } else if (columnName === "netLounge") {
            if (isOldDate === "yes")
              setErrorModal({
                isOpen: true,
                message: dialogs.eventCalender.validation.currentFutureSession,
              });
            else
              setNetwrkSessionModal({
                networkSessionData: {
                  networking_configuration_id: mainData.boxData
                    ? mainData.boxData.networking_configuration_id
                    : "",
                },
                ...objForModal,
              });
          }
        }
      }
    }
  };

  const onClickRightCells = (hourItem, isFilled, ele, columnName) => {
    let existingTimings = [];
    let isOldDate = isPastDate(currentDateForCalender);
    if (columnName === "expo") {
      existingTimings = getAVLTimes(
        !isFilled,
        ele,
        expoAvlTimings,
        "sponsers",
        "brand_configuration_id"
      );

      if (isFilled) {
        if (isAdminLevelUser(userType)) {
          if (isOldDate === "yes")
            setErrorModal({
              isOpen: true,
              message: dialogs.eventCalender.validation.currentFutureSponser,
            });
          else
            setSponsorModal({
              sponserData: ele,
              title: "Expo Hall Sponsorship",
              isOpen: true,
              existingTimings,
              hourItem,
            });
        }
      } else {
        if (isAdminCustomer(userType)) {
          if (isOldDate === "yes")
            toast.error(
              "Please select current date or future date to create/update sponser"
            );
          else
            setSponsorModal({
              sponserData: {
                expo_hall_configuration_id: expoHallData.boxData
                  ? expoHallData.boxData.expo_hall_configuration_id
                  : "",
              },
              title: "Expo Hall Sponsorship",
              isOpen: true,
              existingTimings,
              hourItem,
            });
        }
      }
    } else if (
      columnName === "mainAudi" ||
      columnName === "satAudi" ||
      columnName === "netLounge"
    ) {
      let avlTimings, mainData;
      if (columnName === "mainAudi") avlTimings = mainAudiAvlTimings;
      if (columnName === "satAudi") avlTimings = satAudiAvlTimings;
      if (columnName === "netLounge") avlTimings = netLoungeAvlTimings;
      if (columnName === "mainAudi") mainData = mainAudiData;
      if (columnName === "satAudi") mainData = satAudiData;
      if (columnName === "netLounge") mainData = netLoungeData;
      existingTimings = getAVLTimes(
        !isFilled,
        ele,
        avlTimings,
        "sponsers",
        "brand_configuration_id"
      );

      let modalTitle = "";
      if (columnName === "mainAudi") modalTitle = "Main Auditorium Sponsorship";
      if (columnName === "satAudi")
        modalTitle = "Satellite Auditorium Sponsorship";
      if (columnName === "netLounge") modalTitle = "Network Lounge Sponsorship";

      if (isFilled) {
        if (isAdminLevelUser(userType)) {
          let objForModal = {
            title: modalTitle,
            isOpen: true,
            existingTimings,
            hourItem,
          };

          if (isOldDate === "yes")
            setErrorModal({
              isOpen: true,
              message: dialogs.eventCalender.validation.currentFutureSponser,
            });
          else
            setSponsorModal({
              sponserData: ele,
              ...objForModal,
            });
        }
      } else {
        if (isAdminCustomer(userType)) {
          let objForModal = {
            title: modalTitle,
            isOpen: true,
            existingTimings,
            hourItem,
          };
          if (isOldDate === "yes") {
            setErrorModal({
              isOpen: true,
              message: dialogs.eventCalender.validation.currentFutureSponser,
            });
          } else {
            if (columnName === "mainAudi" || columnName === "satAudi") {
              setSponsorModal({
                sponserData: {
                  auditorium_configuration_id: mainData.boxData
                    ? mainData.boxData.auditorium_configuration_id
                    : "",
                },
                ...objForModal,
              });
            } else {
              setSponsorModal({
                sponserData: {
                  networking_configuration_id: mainData.boxData
                    ? mainData.boxData.networking_configuration_id
                    : "",
                },
                ...objForModal,
              });
            }
          }
        }
      }
    } else if (
      columnName === "ticker" ||
      columnName === "social wall" ||
      columnName === "concourse"
    ) {
      let avlTimings;
      if (columnName === "ticker") avlTimings = tickerAvlTimings;
      if (columnName === "social wall") avlTimings = socialAvlTimings;
      if (columnName === "concourse") avlTimings = concrsAvlTimings;
      existingTimings = getAVLTimes(
        !isFilled,
        ele,
        avlTimings,
        "sponsers",
        "brand_configuration_id"
      );

      if (isFilled) {
        if (isAdminLevelUser(userType)) {
          if (isOldDate === "yes")
            toast.error(
              "Please select current date or future date to create/update " +
                columnName +
                " sponsership"
            );
          else {
            if (columnName === "ticker")
              setTickerConfigModal({
                isOpen: true,
                tickerData: ele,
                existingTimings,
                hourItem,
              });
            else
              setSponsorModal({
                isOpen: true,
                sponserData: ele,
                title:
                  columnName === "social wall"
                    ? "Social Wall Sponsorship"
                    : "Concourse Sponsors",
                existingTimings,
                hourItem,
              });
          }
        }
      } else {
        if (isAdminCustomer(userType)) {
          if (isOldDate === "yes")
            toast.error(
              "Please select current date or future date to create/update " +
                columnName +
                " sponsership"
            );
          else {
            if (columnName === "ticker")
              setTickerConfigModal({
                isOpen: true,
                tickerData: null,
                existingTimings,
                hourItem,
              });
            else
              setSponsorModal({
                isOpen: true,
                sponserData: null,
                title:
                  columnName === "social wall"
                    ? "Social Wall Sponsorship"
                    : "Concourse Sponsors",
                existingTimings,
                hourItem,
              });
          }
        }
      }
    }
  };

  const getLeftCells = (hourItem, hourIndex, sessionsPresent, columnName) => {
    if (
      sessionsPresent &&
      sessionsPresent.allDivisions &&
      sessionsPresent.allDivisions[hourIndex] &&
      !isEmpty(sessionsPresent.allDivisions[hourIndex])
    ) {
      return sessionsPresent.allDivisions[hourIndex].map((ele, index) => {
        if (columnName === "mainAudi" || columnName === "satAudi") {
          ele.color = ele?.auditorium_hosting_brands?.[0]?.brand_color;
        }

        if (ele.isFilled) {
          let filledStyle = {
            height: ele.height,
            backgroundColor: ele.color
              ? ele.color
              : default_sponsor_details.event_color_theme,
            transition: "height 0.5s",
            WebkitTransition: "height 0.5s",
          };
          const startTime = moment(ele.start_time, TIME_FORMAT);
          const endTime = moment(ele.end_time, TIME_FORMAT);
          const filledClass = ele.isAlreadyUp
            ? "event-calender-sponser"
            : ele.isNextAvailable
            ? "event-calender-sponser rounded-top"
            : "event-calender-sponser rounded-top rounded-bottom";
          const title = getLeftTitles(columnName, ele);
          const bgColor = filledStyle.backgroundColor;
          const cellTextProps = { title, startTime, endTime, bgColor };
          return (
            <div
              key={columnName + "_filled_" + index.toString()}
              style={filledStyle}
              onClick={() => onClickLeftCells(hourItem, true, ele, columnName)}
              className={filledClass} //
            >
              {ele.isAlreadyUp && !ele.isFirst
                ? null
                : getCellText(cellTextProps)}
            </div>
          );
        } else
          return (
            <div
              key={columnName + "_" + index.toString()}
              style={{ height: ele.height }}
              onClick={() => onClickLeftCells(hourItem, false, ele, columnName)}
              className="cell_bgColor"
            />
          );
      });
    } else
      return (
        <div
          className="cell_bgColor h-60"
          onClick={() => onClickLeftCells(hourItem, false, null, columnName)}
        />
      );
  };

  const getRightCells = (hourItem, hourIndex, sponsersPresent, columnName) => {
    if (
      sponsersPresent &&
      sponsersPresent.allDivisions &&
      sponsersPresent.allDivisions[hourIndex] &&
      !isEmpty(sponsersPresent.allDivisions[hourIndex])
    ) {
      return sponsersPresent.allDivisions[hourIndex].map((ele) => {
        if (ele.isFilled) {
          let filledStyle = {
            height: ele.height,
            backgroundColor: ele.color
              ? ele.color
              : default_sponsor_details.event_color_theme,
            transition: "height 0.5s",
            WebkitTransition: "height 0.5s",
          };
          const startTime = moment(ele.start_time, TIME_FORMAT);
          const endTime = moment(ele.end_time, TIME_FORMAT);
          const filledClass = ele.isAlreadyUp
            ? "event-calender-sponser"
            : ele.isNextAvailable
            ? "event-calender-sponser rounded-top"
            : "event-calender-sponser rounded-top rounded-bottom";
          const title = getRightTitles(columnName, ele);
          const bgColor = filledStyle.backgroundColor;
          const cellTextProps = { title, startTime, endTime, bgColor };

          return (
            <div
              style={filledStyle}
              onClick={() => onClickRightCells(hourItem, true, ele, columnName)}
              className={filledClass}
            >
              {ele.isAlreadyUp ? null : getCellText(cellTextProps)}
            </div>
          );
        } else
          return (
            <div
              style={{ height: ele.height }}
              onClick={() =>
                onClickRightCells(hourItem, false, ele, columnName)
              }
              className="cell_bgColor"
            />
          );
      });
    } else
      return (
        <div
          className="cell_bgColor h-60"
          onClick={() => onClickRightCells(hourItem, false, null, columnName)}
        />
      );
  };

  const getCellsData = (params, sessionsPresent, sponsersPresent) => {
    const { hourItem, hourIndex, columnName } = params;

    let hrStart = moment(hourItem.start_time, TIME_FORMAT);
    let cellClass = "w-50 d-flex flex-column";
    if (
      columnName === "ticker" ||
      columnName === "social wall" ||
      columnName === "concourse"
    )
      cellClass = "w-100 d-flex flex-column";
    let liveTimeCondition =
      liveTime &&
      liveTime.timeSlot &&
      liveTime.timeSlot === hrStart.format("hh:mm A");

    return (
      <td className="event_calender_td cell_bgColor">
        {columnName === "ticker" ||
        columnName === "social wall" ||
        columnName === "concourse" ? (
          <div
            className="d-flex w-100 h-60 flex-column"
            style={{ cursor: "pointer", position: "relative" }}
          >
            {getRightCells(hourItem, hourIndex, sponsersPresent, columnName)}
            {liveTimeCondition && liveTime && liveTime.minuteDifference
              ? currentTimeLineDiv(liveTime.minuteDifference, scollToRef)
              : null}
          </div>
        ) : (
          <div className="d-flex w-100 h-60">
            <div
              className={cellClass}
              style={{ cursor: "pointer", position: "relative" }}
            >
              {getLeftCells(hourItem, hourIndex, sessionsPresent, columnName)}
              {liveTimeCondition && liveTime && liveTime.minuteDifference
                ? currentTimeLineDiv(liveTime.minuteDifference, scollToRef)
                : null}
            </div>
            <div
              className={cellClass}
              style={{ cursor: "pointer", position: "relative" }}
            >
              {getRightCells(hourItem, hourIndex, sponsersPresent, columnName)}
              {liveTimeCondition && liveTime && liveTime.minuteDifference
                ? currentTimeLineDiv(liveTime.minuteDifference, scollToRef)
                : null}
            </div>
          </div>
        )}
      </td>
    );
  };

  const getCells = (columnName, hourItem, hourIndex) => {
    switch (columnName) {
      case "expo":
        return getCellsData(
          { hourItem, hourIndex, columnName },
          expoHallData,
          expoHallSponsers
        );
      case "mainAudi":
        return getCellsData(
          { hourItem, hourIndex, columnName },
          mainAudiSessions,
          mainAudiSponsers
        );
      case "satAudi":
        return getCellsData(
          { hourItem, hourIndex, columnName },
          satAudiSessions,
          satAudiSponsers
        );
      case "netLounge":
        return getCellsData(
          { hourItem, hourIndex, columnName },
          netLoungeSessions,
          netLoungeSponsers
        );
      case "ticker":
        return getCellsData(
          { hourItem, hourIndex, columnName },
          null,
          tickerData
        );
      case "social wall":
        return getCellsData(
          { hourItem, hourIndex, columnName },
          null,
          socialData
        );
      case "concourse":
        return getCellsData(
          { hourItem, hourIndex, columnName },
          null,
          concourseData
        );
      default:
        return null;
    }
  };

  function getLast3Headers(title) {
    return (
      <th className="border_except_top border-left align-bottom event_header_single">
        <div width="10%" className="text-center" style={{ padding: "5%" }}>
          <span className="text-wrap" style={{ fontSize: "1rem" }}>
            {title}
          </span>
        </div>
      </th>
    );
  }

  const getRows = () => {
    return fullHours.map((hourItem, hourIndex) => {
      return (
        <tr
          id={
            liveTime &&
            liveTime.timeSlotOfCurrentTime &&
            hourItem.start_time === liveTime.timeSlotOfCurrentTime.start_time
              ? "timeline_div"
              : "hr_div"
          }
          key={"hr_div_" + hourItem.start_time}
          className="border-left border-right h-60 border1"
          style={{ width: "100%" }}
        >
          <td
            className="border-right timeslots sticky-col timeslot_sticky"
            style={{ height: "inherit" }}
          >
            <div
              className="d-flex flex-column justify-content-between text-center"
              style={{ height: "100%", marginTop: "-10px" }}
            >
              <Text
                className="align-top align-items-start"
                style={{ fontSize: "1rem" }}
              >
                {hourItem.start_time}
              </Text>
            </div>
          </td>
          {event_settings.is_expo_hall_active &&
          eventCalendarData &&
          expoHallData &&
          expoHallData.boxData &&
          !isEmpty(expoHallData.boxData)
            ? getCells("expo", hourItem, hourIndex)
            : null}
          {event_settings.is_main_hall_active &&
          eventCalendarData &&
          mainAudiData &&
          mainAudiData.boxData &&
          !isEmpty(mainAudiData.boxData)
            ? getCells("mainAudi", hourItem, hourIndex)
            : null}
          {event_settings.is_sat_auditorium_active &&
          eventCalendarData &&
          satAudiData &&
          satAudiData.boxData &&
          satAudiData.boxData !== {}
            ? getCells("satAudi", hourItem, hourIndex)
            : null}
          {event_settings.is_networking_active &&
          eventCalendarData &&
          netLoungeData &&
          netLoungeData.boxData &&
          netLoungeData.boxData !== {}
            ? getCells("netLounge", hourItem, hourIndex)
            : null}
          {event_settings.is_ticker_tape_active &&
          eventCalendarData &&
          eventCalendarData.show_ticker
            ? getCells("ticker", hourItem, hourIndex)
            : null}
          {eventCalendarData && event_settings.is_social_wall_active
            ? getCells("social wall", hourItem, hourIndex)
            : null}
          {eventCalendarData && event_settings.is_concourse_active
            ? getCells("concourse", hourItem, hourIndex)
            : null}
        </tr>
      );
    });
  };

  // event_settings.is_main_hall_active &&
  // eventCalendarData &&
  // mainAudiData &&
  // mainAudiData.boxData &&
  // mainAudiData.boxData !== {}

  if (loader) return <CommonLoader />;
  else {
    const commonHeaderProps = {
      default_sponsor_details,
      userType,
      currentEventID,
      eventCalProps,
      cometChatUser,
      callFetchEventCalenderData,
      onShuffleRightLeft,
      setChatBotLocalJSON,
    };
    const commonSiderProps = {
      allSessions,
      isCollapsedSider,
      setIsCollapsedSider,
    };
    return (
      <div
        style={{ height: "100% !important" }}
        onClick={() => {
          if (datePickerModal) setDatePickerModal(false);
        }}
      >
        <div
          className={`d-flex my-2 h-6p  ${
            !eventCalProps.isFromVista
              ? "justify-content-between"
              : "justify-content-end"
          }  `}
        >
          {eventCalProps && !eventCalProps.isFromVista ? (
            <div className="">
              <h4 className="fs-24 p-0 mb-1 mt-1">Event Calendar</h4>
            </div>
          ) : null}

          {(userType && userType === "SUPER ADMIN") ||
          userType === "CUSTOMER" ? (
            <div
              style={{
                width: Tablet2 ? "50%" : "20%",
                marginRight: eventCalProps?.isFromVista ? "26px" : "0",
              }}
            >
              <div
                className={Tablet ? "col-4" : "col-9 mt-1"}
                style={{ display: "flex" }}
              >
                <div className={Tablet ? "col-4" : "col-6"}>
                  <span style={{ fontSize: "0.9rem" }}>Event Name</span>
                </div>
                <div className={Tablet ? "col-4" : "col-9"}>
                  <Select
                    showSearch
                    placeholder="Select Event"
                    className="w-100 fs-bold-14"
                    // value={currentEventID}
                    value={
                      currentEventID
                        ? currentEventID !== "empty"
                          ? currentEventID
                          : null
                        : null
                    }
                    disabled={disableEvent}
                    onChange={(val) => setCurrentEventID(val)}
                    optionFilterProp="children"
                    listHeight={120}
                    listItemHeight={4}
                  >
                    {!isEmpty(eventList) &&
                      eventList.map((data, index) => {
                        return (
                          <Option key={"event_" + index} value={data.event_id}>
                            {data.event_name}
                          </Option>
                        );
                      })}
                  </Select>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {!currentEventID &&
        currentEventID !== "empty" &&
        isSuperAdminOrCustomer(userType) ? (
          <div className="empty_event">
            <Result
              icon={<SmileOutlined />}
              title={
                <h4 className="fs-pink">
                  Please choose your event in the top right corner
                </h4>
              }
            />
          </div>
        ) : isNoEventDataFound ? (
          <Result
            icon={<SmileOutlined />}
            title="Data not found, please try again later"
          />
        ) : null}
        {currentEventID && currentEventID !== "empty" && !isNoEventDataFound ? (
          <Layout className="h-94p">
            {isChatBot && chatbot_json ? (
              <CustomChatbot
                onClose={async (conversation_details) => {
                  await saveChatbotHistory({
                    event_id: currentEventID,
                    conversation_details,
                  });
                  setIsChatBot(false);
                }}
                responseJson={chatbot_json}
                chatbot_history={chatbot_history}
                onSetHistory={(newHistory) => set_chatbot_history(newHistory)}
                extraProps={{ event_id: currentEventID }}
              />
            ) : null}
            {expoHallOpenModal.isOpen ? (
              <ExpoHallOpen
                onSuccess={(response) => {
                  if (response) {
                    setExpoHallOpenModal({
                      isOpen: false,
                      expoHallData: null,
                      currentBlock: null,
                    });
                    callFetchEventCalenderData();
                  }
                }}
                onCloseModal={() =>
                  setExpoHallOpenModal({
                    isOpen: false,
                    expoHallData: null,
                    currentBlock: null,
                  })
                }
                expoHallData={{
                  ...expoHallOpenModal.expoHallData,
                  hall_title: expoHallData.boxData.hall_title,
                  event_id: currentEventID,
                  currentDateForCalender,
                  userType,
                  hourItem: expoHallOpenModal.hourItem,
                }}
              />
            ) : null}
            {sponsorModal.isOpen ? (
              <Sponser
                onSuccess={(response) => {
                  if (response) {
                    setSponsorModal({
                      isOpen: false,
                      sponserData: null,
                      title: "",
                      existingTimings: [],
                    });
                    callFetchEventCalenderData();
                  }
                }}
                onCloseModal={() =>
                  setSponsorModal({
                    isOpen: false,
                    sponserData: null,
                    title: "",
                    existingTimings: [],
                  })
                }
                sponserData={{
                  ...sponsorModal.sponserData,
                  event_id: currentEventID,
                  currentDateForCalender,
                  title: sponsorModal.title,
                  existingTimings: sponsorModal.existingTimings,
                  userType,
                  hourItem: sponsorModal.hourItem,
                }}
              />
            ) : null}
            {audiSessionModal.isOpen ? (
              <AudiSession
                onSuccess={(response) => {
                  if (response) {
                    setAudiSessionModal({
                      isOpen: false,
                      audiSessionData: null,
                      title: "",
                      existingTimings: [],
                      hourItem: null,
                    });
                    callFetchEventCalenderData();
                  }
                }}
                onCloseModal={() =>
                  setAudiSessionModal({
                    isOpen: false,
                    audiSessionData: null,
                    title: "",
                    existingTimings: [],
                    hourItem: null,
                  })
                }
                sessionData={{
                  ...audiSessionModal.audiSessionData,
                  event_id: currentEventID,
                  currentDateForCalender,
                  existingTimings: audiSessionModal.existingTimings,
                  brandsOfEvent,
                  currentEventData,
                  userType,
                  hourItem: audiSessionModal.hourItem,
                }}
                title={audiSessionModal.title}
              />
            ) : null}
            {audiSessionView.isOpen ? (
              <AudiViewDelegates
                isFromCalender="event"
                onSuccess={(response) => {
                  if (response) {
                    setAudiSessionView({
                      isOpen: false,
                      audiSessionData: null,
                      title: "",
                      existingTimings: [],
                    });
                    callFetchEventCalenderData();
                  }
                }}
                onCloseModal={() =>
                  setAudiSessionView({
                    isOpen: false,
                    audiSessionData: null,
                    title: "",
                    existingTimings: [],
                  })
                }
                data={{
                  ...audiSessionView.audiSessionData,
                  event_id: currentEventID,
                  currentDateForCalender,
                  existingTimings: audiSessionView.existingTimings,
                  brandsOfEvent,
                  userType,
                }}
                title={audiSessionView.title}
              />
            ) : null}
            {netwrkSessionModal.isOpen ? (
              <NetworkingSession
                onSuccess={(response) => {
                  if (response) {
                    setNetwrkSessionModal({
                      isOpen: false,
                      networkSessionData: null,
                      title: "",
                      existingTimings: [],
                    });
                    callFetchEventCalenderData();
                  }
                }}
                onCloseModal={() =>
                  setNetwrkSessionModal({
                    isOpen: false,
                    networkSessionData: null,
                    title: "",
                    existingTimings: [],
                  })
                }
                sessionData={{
                  ...netwrkSessionModal.networkSessionData,
                  event_id: currentEventID,
                  currentDateForCalender,
                  existingTimings: netwrkSessionModal.existingTimings,
                  currentEventData,
                  userType,
                  hourItem: netwrkSessionModal.hourItem,
                }}
                title={netwrkSessionModal.title}
              />
            ) : null}
            {networkModalForView.isOpen ? (
              <NetViewDelegates
                isFromCalender="event"
                onCloseModal={() =>
                  setNetworkModalForView({
                    isOpen: false,
                    networkSessionData: null,
                    title: "",
                    existingTimings: [],
                  })
                }
                data={{
                  ...networkModalForView.networkSessionData,
                  event_id: currentEventID,
                  currentDateForCalender,
                  existingTimings: netwrkSessionModal.existingTimings,
                  userType,
                }}
                title={networkModalForView.title}
              />
            ) : null}
            {tickerConfigModal.isOpen ? (
              <TickerConfiguration
                onSuccess={(response) => {
                  if (response) {
                    setTickerConfigModal({
                      isOpen: false,
                      tickerData: null,
                      existingTimings: [],
                    });
                    callFetchEventCalenderData();
                  }
                }}
                onCloseModal={() =>
                  setTickerConfigModal({
                    isOpen: false,
                    tickerData: null,
                    existingTimings: [],
                  })
                }
                tickerConfigData={{
                  ...tickerConfigModal.tickerData,
                  event_id: currentEventID,
                  currentDateForCalender,
                  existingTimings: tickerConfigModal.existingTimings,
                  userType,
                  hourItem: tickerConfigModal.hourItem,
                }}
                title={"Ticker Configuration"}
              />
            ) : null}
            {errorModal.isOpen ? (
              <PastDateDelegate
                isFromCalender="event"
                message={errorModal.message}
                onCloseModal={() =>
                  setErrorModal({ isOpen: false, message: "" })
                }
              />
            ) : null}

            {!isCollapsedSider ? (
              <EventCalenderSider {...commonSiderProps} />
            ) : null}
            <Layout style={{ height: "inherit !important" }}>
              <Header
                className={
                  isCollapsedSider
                    ? `border-bottom bg-white ${
                        eventCalProps?.isFromVista
                          ? "event_calender_header_vista"
                          : "event_calender_header"
                      }`
                    : "border-bottom bg-white"
                }
              >
                <div className="d-flex align-middle align-items-center">
                  {isCollapsedSider ? (
                    <Tooltip title={"Search Session"}>
                      <SearchOutlined
                        className="px-3"
                        onClick={() => setIsCollapsedSider(!isCollapsedSider)}
                      />
                    </Tooltip>
                  ) : null}
                  <Button
                    className="px-3 button-pink rounded"
                    style={{ height: "43px" }}
                    onClick={() => {
                      let todayString = moment().format("YYYY-MM-DD");
                      let indexNew = event_dates_to_navigate.findIndex(
                        (ele) => ele.dateString === todayString
                      );
                      if (indexNew === -1)
                        toast.error(
                          `Today's date is not included in event shows`
                        );
                      else if (indexNew >= 0) {
                        set_current_date_to_navigate({
                          currentIndex: indexNew,
                          dateString:
                            event_dates_to_navigate[indexNew].dateString,
                          length: event_dates_to_navigate.length,
                        });
                        setAllDatesAndIndexes(todayString, today, moment());
                        setDatePickerModal(false);
                      }
                    }}
                  >
                    TODAY
                  </Button>
                  {datePickerModal && (
                    <div
                      className="px-2 align-items-center"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <DatePicker
                        open={true}
                        defaultOpen={true}
                        name="adminSatellite_prev_pending_date_time1"
                        format={"D/M/YYYY HH:mm"}
                        className="adminSatellite_date_time"
                        disabledDate={(current) => {
                          let fo = current.format("YYYY-MM-DD");
                          if (event_dates.includes(fo)) return false;
                          else return true;
                        }}
                        value={dateForDatePicker}
                        onChange={(value) => {
                          const cDate = value.format("YYYY-MM-DD");
                          const cDate1 = value.format("MMMM DD, yyyy");
                          setAllDatesAndIndexes(cDate, cDate1, value);
                          setDatePickerModal(false);
                        }}
                        style={{ width: "80%" }}
                      />
                    </div>
                  )}
                  {!datePickerModal && (
                    <div className="px-2 d-flex align-items-center cursor-pointer">
                      <LeftOutlined
                        className="px-2 arrow_righ_left"
                        onClick={() => onDateNavigate("left")}
                      />
                      <RightOutlined
                        className="px-2 arrow_righ_left"
                        onClick={() => onDateNavigate("right")}
                      />
                      <span
                        className="date-to-display"
                        onClick={() => setDatePickerModal(true)}
                      >
                        {dateToDisplay}
                      </span>
                    </div>
                  )}
                </div>
              </Header>
              {isNoDataForTheDay ? (
                <Result
                  icon={<SmileOutlined />}
                  title="No event for the day!"
                />
              ) : (
                <div className={classNameForTicker}>
                  <table className="event_table table-responsive">
                    <thead
                      className="event_table_header 
                       event_table_header_bg_white"
                    >
                      <tr className="event_table_header_row">
                        <th className="border_except_top sticky-col event_header_left timeslot_sticky" />
                        {event_settings.is_expo_hall_active &&
                        !isEmpty(expoHallData) &&
                        !isEmpty(expoHallData.boxData) ? (
                          <th className="border_except_top justify-content-center event_header_double">
                            {!isEmpty(expoHallData) &&
                            !isEmpty(expoHallData.boxData) ? (
                              <EventCalenderHeader
                                {...{
                                  ...commonHeaderProps,
                                  parentTitle: "Expo Hall",
                                  currentIndex: expoHallData.currentIndex,
                                  title:
                                    expoHallData.boxData &&
                                    expoHallData.boxData.hall_title
                                      ? expoHallData.boxData.hall_title
                                      : "",
                                  boxData: expoHallData.boxData,
                                  headerData: expoSponserHeader,
                                  length: expoHallData.count,
                                  isFromVista: eventCalProps?.isFromVista,
                                }}
                              />
                            ) : (
                              <Empty description="No Hall Found" />
                            )}
                          </th>
                        ) : null}
                        {event_settings.is_main_hall_active &&
                        !isEmpty(mainAudiData) &&
                        !isEmpty(mainAudiData.boxData) ? (
                          <th className="border_except_top justify-content-center event_header_double">
                            {!isEmpty(mainAudiData) &&
                            !isEmpty(mainAudiData.boxData) ? (
                              <div className="py-3">
                                <EventCalenderHeader
                                  {...{
                                    ...commonHeaderProps,
                                    parentTitle: "Main Auditorium",
                                    currentIndex: mainAudiData.currentIndex,
                                    title: mainAudiData.boxData
                                      ? mainAudiData.boxData.auditorium_title
                                      : "",
                                    boxData: mainAudiData.boxData,
                                    headerData: mainAudiSponserHeader,
                                    length: mainAudiData.count,
                                    isFromVista: eventCalProps?.isFromVista,
                                  }}
                                />
                              </div>
                            ) : (
                              <Empty description="No Audi Found" />
                            )}
                          </th>
                        ) : null}
                        {event_settings.is_sat_auditorium_active &&
                        !isEmpty(satAudiData) &&
                        !isEmpty(satAudiData.boxData) ? (
                          <th className="border_except_top justify-content-center event_header_double">
                            {!isEmpty(satAudiData) &&
                            !isEmpty(satAudiData.boxData) ? (
                              <div className="py-3">
                                <EventCalenderHeader
                                  {...{
                                    ...commonHeaderProps,
                                    parentTitle: "Satellite Auditorium",
                                    currentIndex: satAudiData.currentIndex,
                                    title: satAudiData.boxData
                                      ? satAudiData.boxData.auditorium_title
                                      : "",
                                    boxData: satAudiData.boxData,
                                    headerData: satAudiSponserHeader,
                                    length: satAudiData.count,
                                    isFromVista: eventCalProps?.isFromVista,
                                  }}
                                />
                              </div>
                            ) : (
                              <Empty description="No Audi Found" />
                            )}
                          </th>
                        ) : null}
                        {event_settings.is_networking_active &&
                        !isEmpty(netLoungeData) &&
                        !isEmpty(netLoungeData.boxData) ? (
                          <th className="border_except_top justify-content-center event_header_double">
                            {!isEmpty(netLoungeData) &&
                            !isEmpty(netLoungeData.boxData) ? (
                              <div className="py-3">
                                <EventCalenderHeader
                                  {...{
                                    ...commonHeaderProps,
                                    parentTitle: "Networking Lounges",
                                    currentIndex: netLoungeData.currentIndex,
                                    title: netLoungeData.boxData
                                      ? netLoungeData.boxData.lounge_title
                                      : "",
                                    boxData: netLoungeData.boxData,
                                    headerData: netSponserHeader,
                                    length: netLoungeData.count,
                                    isFromVista: eventCalProps?.isFromVista,
                                  }}
                                />
                              </div>
                            ) : (
                              <Empty description="No Audi Found" />
                            )}
                          </th>
                        ) : null}
                        {event_settings.is_ticker_tape_active &&
                        eventCalendarData &&
                        eventCalendarData.show_ticker
                          ? getLast3Headers("Ticker Tape Sponsors")
                          : null}
                        {eventCalendarData &&
                        event_settings.is_social_wall_active
                          ? getLast3Headers("Social Wall Sponsors")
                          : null}
                        {eventCalendarData && event_settings.is_concourse_active
                          ? getLast3Headers("Concourse Sponsors")
                          : null}
                      </tr>
                    </thead>
                    <tbody className="event_table_body">
                      <tr
                        className="border-left border-right cell_bgColor h-20"
                        style={{ width: "100%" }}
                      >
                        <td className="w-50 h-20" />
                        {event_settings.is_expo_hall_active &&
                        eventCalendarData &&
                        expoHallData &&
                        expoHallData.boxData &&
                        !isEmpty(expoHallData.boxData) ? (
                          <td className="w-100 h-20">
                            <div className="d-flex h-20">
                              <div className="w-50 event_data_header_child_div">
                                Event
                              </div>
                              <div className="w-50 event_data_header_child_div">
                                Sponsor
                              </div>
                            </div>
                          </td>
                        ) : null}
                        {event_settings.is_main_hall_active &&
                        eventCalendarData &&
                        mainAudiData &&
                        mainAudiData.boxData &&
                        !isEmpty(mainAudiData.boxData) ? (
                          <td className="w-100 h-20">
                            <div className="d-flex h-20">
                              <div className="w-50 event_data_header_child_div">
                                Event
                              </div>
                              <div className="w-50 event_data_header_child_div">
                                Sponsor
                              </div>
                            </div>
                          </td>
                        ) : null}
                        {event_settings.is_sat_auditorium_active &&
                        eventCalendarData &&
                        satAudiData &&
                        satAudiData.boxData &&
                        !isEmpty(satAudiData.boxData) ? (
                          <td className="w-100 h-20">
                            <div className="d-flex h-20">
                              <div className="w-50 event_data_header_child_div">
                                Event
                              </div>
                              <div className="w-50 event_data_header_child_div">
                                Sponsor
                              </div>
                            </div>
                          </td>
                        ) : null}
                        {event_settings.is_networking_active &&
                        eventCalendarData &&
                        netLoungeData &&
                        netLoungeData.boxData &&
                        !isEmpty(netLoungeData.boxData) ? (
                          <td className="w-100 h-20">
                            <div className="d-flex h-20">
                              <div className="w-50 event_data_header_child_div">
                                Event
                              </div>
                              <div className="w-50 event_data_header_child_div">
                                Sponsor
                              </div>
                            </div>
                          </td>
                        ) : null}
                        {event_settings.is_ticker_tape_active &&
                        eventCalendarData &&
                        eventCalendarData.show_ticker ? (
                          <td className="w-100 h-20">
                            <div className="d-flex h-20">
                              <div className="w-100 event_data_header_child_div">
                                Ticker
                              </div>
                            </div>
                          </td>
                        ) : null}
                        {eventCalendarData &&
                        event_settings.is_social_wall_active ? (
                          <td className="w-100 h-20">
                            <div className="d-flex h-20">
                              <div className="w-100 event_data_header_child_div">
                                Sponsor
                              </div>
                            </div>
                          </td>
                        ) : null}
                        {eventCalendarData &&
                        event_settings.is_concourse_active ? (
                          <td className="w-100 h-20">
                            <div className="d-flex h-20">
                              <div className="w-100 event_data_header_child_div">
                                Sponsor
                              </div>
                            </div>
                          </td>
                        ) : null}
                      </tr>
                      {getRows()}
                    </tbody>
                  </table>
                </div>
              )}
            </Layout>
          </Layout>
        ) : null}
      </div>
    );
  }
};

export default EventCalendar;
