import React, { useEffect, useState } from "react";
import {
  Layout,
  Badge,
  Avatar,
  Menu,
  Dropdown,
  Breadcrumb,
  Select,
  Popover,
  Tooltip,
  notification,
} from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  BellOutlined,
  DownOutlined,
  DoubleRightOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import "./Header.css";
import { useDispatch, useSelector } from "react-redux";
import {
  reloadChannelsData,
  reloadSupportData,
  setCalendarNotificationData,
  setHeaderNewEventId,
  setHelpAndSupportData,
  setPeopleEventData,
} from "../../redux/slice/headerData";
import { setVistaEventId } from "../../redux/slice/vista";
import {
  acceptMessageHelpSupportRequest,
  acceptMessageRequest,
  acceptVideoRequest,
  clearLocationApi,
  getEvents,
  logout,
  oneToOneRespond,
  updateNotificationStatus,
} from "../../utils/commonApis";
import { getNotifications } from "../../redux/slice/notification";
import {
  convertTimeStamp,
  isNotificationType,
} from "../../utils/commonFunctions";
import { cloneDeep, isEmpty } from "lodash";
import "./notification.less";
import MeetContainer from "../VideoConference/MeetContainer";
import { useMediaQuery } from "react-responsive";
import { setVideoConferenceData } from "../../redux/slice/videoConference";
import {
  allTypesAlert,
  chatAgentNotification,
  chatDelegateNotification,
  eventHelpSupportAgentNotification,
  eventHelpSupportDelegateNotification,
  oneToOneChatNotification,
  oneToOneVideoNotification,
  videoAgentNotification,
  videoDelegateNotification,
} from "./AllNotifications";
import GoogleAuthModal from "./Modal/GoogleAuthModal";
import placeholder_image from "../../assets/images/profile_placeholder.png";
import { toast } from "react-toastify";

const Header = (props) => {
  const socket = props.socket;
  const isMobile = useMediaQuery({ minWidth: 480 });
  var CryptoJS = require("crypto-js");
  const logoFromRedux = useSelector((state) => state?.header?.logo_url);
  const headerData = useSelector((state) => state?.header?.headerObj);
  const vistaEventId = useSelector((state) => state?.vistaBrand?.eventId);
  const eventList = useSelector((state) => state?.header?.events);
  const peopleEventId = useSelector(
    (state) => state?.header?.peopleEventData?.value
  );

  const videoConferenceFlag = useSelector(
    (state) => state?.videoConference?.videoConferenceFlag
  );

  const is_call_accepted = useSelector(
    (state) => state?.videoConference?.is_call_accepted
  );

  const googleAuthFlag = useSelector(
    (state) => state?.videoConference?.googleAuthFlag
  );
  const [headerEventList, setHeaderEventList] = useState([]);

  const notificationSelector = useSelector((state) => state.notifications);
  const { Option } = Select;
  const dispatch = useDispatch();
  const history = useHistory();
  let vistaLocationInterval = useSelector(
    (state) => state?.vistaBrand?.vistaLocationInterval
  ); //from Vista redux
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [userType, setUserType] = useState("");
  const [userName, setUserName] = useState("");
  const [notificationStates, setNotificationStates] = useState();
  const [notificationsCount, setNotificationsCount] = useState(
    notificationSelector && notificationSelector.newNotificationsCount
      ? notificationSelector.newNotificationsCount
      : 0
  );
  const [profileAccess, setProfileAccess] = useState("");
  const [socialAccess, setSocialAccess] = useState("");
  const [showVideoConferencePopup, setShowVideoConferencePopup] =
    useState(false);
  const [showGoogleAuthPopup, setShowGoogleAuthPopup] = useState(false);
  const [loginUserId, setLoginUserId] = useState(null);
  const [chatId, setChatId] = useState(null);
  let [eventId, setEventId] = useState(null);

  useEffect(async () => {
    const ciphertext = localStorage.getItem("user_type");
    if (ciphertext) {
      var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUserType(decryptedData);
    }
    const ciphertextName = localStorage.getItem("user_name");
    if (ciphertextName) {
      var bytes2 = CryptoJS.AES.decrypt(ciphertextName, "user_name");
      var decryptedDataName = JSON.parse(bytes2.toString(CryptoJS.enc.Utf8));
      setUserName(decryptedDataName);
      dispatch(getNotifications());
    }

    let is_profile_access = "";
    let is_social_link_access = "";

    if (localStorage.getItem("is_profile_access"))
      is_profile_access = JSON.parse(localStorage.getItem("is_profile_access"));

    if (localStorage.getItem("is_social_link_access"))
      is_social_link_access = JSON.parse(
        localStorage.getItem("is_social_link_access")
      );

    if (localStorage.getItem("user_id")) {
      let cipher_login_user_id = localStorage.getItem("user_id");
      let bytes_login_user_id = CryptoJS.AES.decrypt(
        cipher_login_user_id,
        "user_id"
      );
      let jsonparse_user_id = JSON.parse(
        bytes_login_user_id.toString(CryptoJS.enc.Utf8)
      );

      setLoginUserId(jsonparse_user_id); //need it for socket notification so converted to string
    }

    if (localStorage.getItem("chat_uid"))
      setChatId(localStorage.getItem("chat_uid"));

    if (localStorage.getItem("event_id"))
      setEventId(parseInt(localStorage.getItem("event_id")));

    setProfileAccess(is_profile_access);
    setSocialAccess(is_social_link_access);
    const events = await getEvents();

    setHeaderEventList(events);
  }, [CryptoJS.AES]);

  useEffect(() => {
    const messageListener = (newNotification) => {
      console.log("newNotification test33 = ", newNotification);

      console.log(
        "newNotification compare ids ",
        newNotification?.receiver_chat_uid,
        localStorage.getItem("chat_uid"),
        newNotification?.receiver_chat_uid === localStorage.getItem("chat_uid"),
        newNotification?.receiver_user_type,
        newNotification?.receiver_user_type === userType
      );

      if (newNotification) {
        dispatch(getNotifications());
        if (
          newNotification?.receiver_chat_uid &&
          newNotification?.receiver_chat_uid ===
            localStorage.getItem("chat_uid") &&
          (newNotification?.notification_type === "ONE_TO_ONE_CHAT" ||
            newNotification?.notification_type === "ONE_TO_ONE_VIDEO")
        ) {
          if (newNotification?.notification_type === "ONE_TO_ONE_CHAT") {
            oneToOneChatNotification({ newNotification, dispatch });
          } else if (
            newNotification?.notification_type === "ONE_TO_ONE_VIDEO" &&
            newNotification?.receiver_user_type === userType
          ) {
            oneToOneVideoNotification({ newNotification, dispatch, socket });
          }
        } else if (
          newNotification?.notification_type === "CHAT_AGENT" &&
          newNotification?.agents_ids?.includes(loginUserId)
        ) {
          chatAgentNotification({ newNotification, dispatch, socket });
          dispatch(
            reloadChannelsData({
              flag: true,
            })
          );
        } else if (
          newNotification?.notification_type === "VIDEO_AGENT" &&
          newNotification?.agents_ids?.includes(loginUserId) &&
          loginUserId !== newNotification?.data?.user_id //Agent should not be able to send video call request to himself
        ) {
          videoAgentNotification({
            newNotification,
            dispatch,
            socket,
            loginUserId,
            userType,
          });
          dispatch(
            reloadChannelsData({
              flag: true,
            })
          );
          dispatch(
            reloadSupportData({
              flag: true,
            })
          );
        } else if (
          newNotification?.notification_type === "CHAT_DELEGATE" &&
          newNotification?.notification_for_user_id === loginUserId
        ) {
          chatDelegateNotification({ newNotification, dispatch });
        } else if (
          newNotification?.notification_type === "VIDEO_DELEGATE" &&
          newNotification?.notification_for_user_id === loginUserId
        ) {
          videoDelegateNotification({ newNotification, dispatch, socket });
        } else if (
          (newNotification?.notification_type === "EVENT_HELP_SUPPORT_AGENT" ||
            newNotification?.notification_type ===
              "TECHNICAL_HELP_SUPPORT_AGENT") &&
          newNotification?.agents_ids?.includes(loginUserId)
        ) {
          eventHelpSupportAgentNotification({
            newNotification,
            dispatch,
            history,
            userType,
          });
          dispatch(
            reloadSupportData({
              flag: true,
            })
          );
        } else if (
          (newNotification?.notification_type ===
            "EVENT_HELP_SUPPORT_DELEGATE" ||
            newNotification?.notification_type ===
              "TECHNICAL_HELP_SUPPORT_DELEGATE") &&
          newNotification?.notification_for_user_id === loginUserId
        ) {
          eventHelpSupportDelegateNotification({ newNotification, dispatch });
        } else if (
          (newNotification?.agents_ids?.includes(loginUserId) &&
            newNotification?.notification_type === "MEETING") ||
          (checkForSameEvent(newNotification?.event_id, eventId) &&
            newNotification?.notification_type ===
              "MY_CALENDAR_ALERTS_MAIN_AUDITORIUM" &&
            checkIfUserIdMatchesLoginUserId(
              newNotification?.agents_ids,
              loginUserId
            )) ||
          (checkForSameEvent(newNotification?.event_id, eventId) &&
            newNotification?.notification_type ===
              "MY_CALENDAR_ALERTS_SATELLITE_AUDITORIUM" &&
            checkIfUserIdMatchesLoginUserId(
              newNotification?.agents_ids,
              loginUserId
            )) ||
          (checkForSameEvent(newNotification?.event_id, eventId) &&
            newNotification?.notification_type ===
              "MY_CALENDAR_ALERTS_NETWORKING_LOUNGE" &&
            checkIfUserIdMatchesLoginUserId(
              newNotification?.agents_ids,
              loginUserId
            )) ||
          (checkForSameEvent(newNotification?.event_id, eventId) &&
            newNotification?.notification_type ===
              "MY_CALENDAR_ALERTS_PRODUCT_ZONE" &&
            checkIfUserIdMatchesLoginUserId(
              newNotification?.agents_ids,
              loginUserId
            ))
        ) {
          allTypesAlert({ newNotification, dispatch, history, userType });
        }
        setNotificationStates((previousData) => {
          let newNotificationStates = cloneDeep(previousData);
          newNotificationStates.unshift(newNotification);

          let newCount = 0;
          if (newNotificationStates) {
            newNotificationStates.forEach((ele) => {
              if (!ele.is_read && isNotificationType(ele.notification_type))
                newCount += 1;
            });
          } else newCount = 1;

          setNotificationsCount(newCount);
          return newNotificationStates;
        });
      }
    };

    const closeNotificationPopup = (obj) => {
      console.log("closeNotificationPopup", obj);
      console.log(is_call_accepted, "is_call_accepted");
      if (obj.chat_id === chatId && !is_call_accepted) {
        notification.destroy();
        toast.error("Call Declined");
      }
    };

    const rejectCallListener = (obj) => {
      console.log("rejectCallListener", obj);

      console.log(loginUserId, obj?.user_id);

      if (
        obj?.notificationFrom === "helpandsupport" &&
        obj?.user_id === loginUserId &&
        obj?.isAgent === true
      ) {
        toast.error("Video Call Request Rejected");
      }
    };

    socket?.on("newNotification", messageListener);
    socket?.on("closeVideoCallNotificationListener", closeNotificationPopup);
    socket?.on("rejectCallListener", rejectCallListener);

    return () => {
      socket?.off("newNotification", messageListener);
      socket?.off("closeVideoCallNotificationListener", closeNotificationPopup);
    };
  }, [socket, loginUserId, is_call_accepted, userType, eventId]);

  useEffect(() => {
    if (notificationSelector && notificationSelector.notificationsData) {
      setNotificationsCount(notificationSelector.newNotificationsCount);
      setNotificationStates(notificationSelector.notificationsData);
    }
  }, [notificationSelector]);

  useEffect(() => {
    setShowVideoConferencePopup(videoConferenceFlag);
  }, [videoConferenceFlag]);

  useEffect(() => {
    setShowGoogleAuthPopup(googleAuthFlag);
  }, [googleAuthFlag]);

  const updateNetwork = () => {
    setIsOnline(window.navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener("offline", updateNetwork);
    window.addEventListener("online", updateNetwork);
    window.addEventListener("navigator offline", function (e) {});
    window.addEventListener("navigator online", function (e) {});

    return () => {
      window.removeEventListener("offline", updateNetwork);
      window.removeEventListener("online", updateNetwork);
    };
  });

  const checkForSameEvent = (eventId1, eventId2) => {
    let checkForSameEventFlag = false;

    if (eventId1 === eventId2) {
      checkForSameEventFlag = true;
    }

    return checkForSameEventFlag;
  };

  const checkIfUserIdMatchesLoginUserId = (ids, userIdToCompare) => {
    return ids?.includes(userIdToCompare);
  };

  const handleNotifications = async (ele, requestAPIData) => {
    if (
      ele.notification_type === "CHAT_AGENT" ||
      ele.notification_type === "VIDEO_AGENT" ||
      ele.notification_type === "EVENT_HELP_SUPPORT_AGENT" ||
      ele.notification_type === "TECHNICAL_HELP_SUPPORT_AGENT"
    ) {
      if (
        ele.notification_type === "EVENT_HELP_SUPPORT_AGENT" ||
        ele.notification_type === "TECHNICAL_HELP_SUPPORT_AGENT"
      ) {
        console.log("check ele", ele);
        let chatRes = await acceptMessageHelpSupportRequest({
          message_id: ele?.data?.message_id,
          user_id: ele?.data?.user_id,
          support_type:
            ele?.notification_type === "EVENT_HELP_SUPPORT_AGENT"
              ? "EVENT SUPPORT"
              : ele?.notification_type === "TECHNICAL_HELP_SUPPORT_AGENT"
              ? "EVENT TECHNICAL SUPPORT"
              : "",

          user_type: ele?.data?.notification_from_user_type,
        });
        console.log("chatRes = ", chatRes);
        if (chatRes?.data?.code === 200) {
          dispatch(
            setHelpAndSupportData({
              supportType: ele?.notification_type,
              userId: ele?.data?.user_id,
            })
          );

          history.push("/helpAndSupport");
        }
      } else if (ele.notification_type === "CHAT_AGENT") {
        let chatRes = await acceptMessageRequest(requestAPIData);

        if (requestAPIData && requestAPIData.chat_id)
          localStorage.setItem(
            "isMessageScreenWithChatUID",
            requestAPIData.chat_id
          );
        window.open("/messages", "_self");
      } else if (ele.notification_type === "VIDEO_AGENT") {
        let videoRes = await acceptVideoRequest(requestAPIData);

        if (videoRes?.data?.code === 200) {
          dispatch(
            setVideoConferenceData({
              videoConferenceFlag: true,
              videoConferenceUrl: ele?.notification_links,
              is_video_call: true,
            })
          );
        }
      }
    } else if (
      ele.notification_type === "CHAT_DELEGATE" ||
      ele.notification_type === "ONE_TO_ONE_CHAT"
    ) {
      if (requestAPIData && requestAPIData.chat_id)
        localStorage.setItem(
          "isMessageScreenWithChatUID",
          requestAPIData.chat_id
        );
      window.open("/messages", "_self");
    } else if (
      ele.notification_type === "ONE_TO_ONE_CHAT_RESPONSE" ||
      ele.notification_type === "ONE_TO_ONE_VIDEO_RESPONSE"
    ) {
      let is_video = true;
      if (ele.notification_type === "ONE_TO_ONE_CHAT_RESPONSE")
        is_video = false;

      const oneToOneRes = await oneToOneRespond({
        ...requestAPIData,
        is_video,
      });

      if (ele.notification_type === "ONE_TO_ONE_CHAT_RESPONSE") {
        if (requestAPIData && requestAPIData.chat_id)
          localStorage.setItem(
            "isMessageScreenWithChatUID",
            requestAPIData.chat_id
          );
        window.open("/messages", "_self");
      }
    } else if (
      ele.notification_type === "EVENT_HELP_SUPPORT_DELEGATE" ||
      ele.notification_type === "TECHNICAL_HELP_SUPPORT_DELEGATE"
    ) {
      dispatch(
        setHelpAndSupportData({
          supportType: ele?.notification_type,
          userId: ele?.data?.user_id,
        })
      );

      history.push("/helpAndSupport");
    } else if (
      ele.notification_type === "CALENDAR INVITES" ||
      ele.notification_type === "MEETING"
    ) {
      dispatch(
        setCalendarNotificationData({
          hallType: ele?.data?.hall_type,
          sessionId: ele?.data?.session_id,
        })
      );

      history.push("/mycalendar");
    }
    // window.open("/mycalendar", "_self");
    else if (
      ele.notification_type === "VIDEO_DELEGATE" ||
      ele.notification_type === "ONE_TO_ONE_VIDEO" ||
      ele.notification_type === "MY_CALENDAR_ALERTS_MAIN_AUDITORIUM" ||
      ele.notification_type === "MY_CALENDAR_ALERTS_SATELLITE_AUDITORIUM" ||
      ele.notification_type === "MY_CALENDAR_ALERTS_PRODUCT_ZONE"
    ) {
      dispatch(
        setVideoConferenceData({
          videoConferenceFlag: true,
          videoConferenceUrl: ele.notification_links,
          is_demo_room: true,
        })
      );
      // window.open(ele.notification_links, "_blank");
    } else if (ele.notification_type === "MY_CALENDAR_ALERTS_NETWORKING_LOUNGE")
      window.open("/networking", "_self");
  };

  const handleLogout = async () => {
    logout();
    socket?.emit(
      "updateOnlineStatus",
      JSON.stringify({
        user_id: loginUserId,
        online: false,
      })
    );

    clearLocationApi("userLeft", vistaLocationInterval);
    localStorage.removeItem("Authorization");
    localStorage.removeItem("is_profile_access");
    localStorage.removeItem("is_social_link_access");
    localStorage.clear();
    history.push("/login");
  };

  const menu = (
    <Menu className="gravit8_header_menu">
      {profileAccess ||
      userType === "SUPER ADMIN" ||
      userType === "CUSTOMER" ||
      userType === "SPEAKER" ? (
        <Menu.Item
          className="gravit8_header_menu_item"
          onClick={() => {
            history.push("/myprofile");
          }}
        >
          <span className="fs-14"> My Profile</span>
        </Menu.Item>
      ) : null}

      <Menu.Item onClick={handleLogout}>
        <span className="fs-14">Log Out</span>
      </Menu.Item>
    </Menu>
  );

  // console.log("notificattion states", notificationStates);

  const renderNotificationList = () => {
    if (notificationStates && !isEmpty(notificationStates))
      return (
        <div className="notification_list_parent">
          {notificationStates.map((ele, index) => {
            let requestAPIData;

            if (
              ele.notification_type === "CHAT_AGENT" ||
              ele.notification_type === "VIDEO_AGENT" ||
              ele.notification_type === "EVENT_HELP_SUPPORT_AGENT" ||
              ele.notification_type === "TECHNICAL_HELP_SUPPORT_AGENT" ||
              ele.notification_type === "ONE_TO_ONE_CHAT_RESPONSE" ||
              ele.notification_type === "ONE_TO_ONE_VIDEO_RESPONSE" ||
              ele.notification_type === "ONE_TO_ONE_CHAT"
            ) {
              requestAPIData = ele.data;
            }

            if (
              isNotificationType(ele.notification_type) &&
              ele.notification_id
            )
              return (
                <div
                  key={"notification_id_" + ele.notification_id}
                  className={
                    ele.is_read
                      ? "notification_list_child notification_list_child_read cursor-pointer"
                      : "notification_list_child cursor-pointer"
                  }
                  onClick={async () => {
                    if (!ele.is_read) {
                      const res = await updateNotificationStatus({
                        notification_id: ele.notification_id,
                        is_read: true,
                      });

                      dispatch(getNotifications());
                      // let clonedNotifData = cloneDeep(notificationStates);
                      // // clonedNotifData[index].is_read = true;
                      // // setNotificationStates(clonedNotifData);
                    }
                    handleNotifications(ele, requestAPIData);
                  }}
                >
                  <div className="notification_list_child_labels ">
                    <label
                      className="notification_list_child_text fs-07rem cursor-pointer"
                      style={{ fontWeight: "bold" }}
                    >
                      {ele.notification_text}
                    </label>
                    <label className="notification_list_child_text fs-06rem">
                      {convertTimeStamp(ele.created_at, "DD-MM-YYYY HH:mm")}
                    </label>
                  </div>
                  <div>
                    <label
                      className="notification_list_button_text"
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        handleNotifications(ele, requestAPIData);
                      }}
                    >
                      {ele.notification_type === "CHAT_DELEGATE" ||
                      ele.notification_type === "EVENT_HELP_SUPPORT_DELEGATE" ||
                      ele.notification_type ===
                        "TECHNICAL_HELP_SUPPORT_DELEGATE" ||
                      ele.notification_type === "ONE_TO_ONE_CHAT" ||
                      ele.notification_type === "ONE_TO_ONE_CHAT_RESPONSE" ||
                      ele.notification_type === "ONE_TO_ONE_VIDEO_RESPONSE" ? (
                        "Respond"
                      ) : ele.notification_type === "CHAT_AGENT" ||
                        ele.notification_type === "EVENT_HELP_SUPPORT_AGENT" ||
                        ele.notification_type ===
                          "TECHNICAL_HELP_SUPPORT_AGENT" ? (
                        <span className="ms-2">Respond</span>
                      ) : ele.notification_type === "MEETING" ||
                        ele.notification_type === "CALENDAR INVITES" ? (
                        "My Calender"
                      ) : ele.notification_type === "VIDEO_DELEGATE" ||
                        ele.notification_type === "VIDEO_AGENT" ||
                        ele.notification_type === "ONE_TO_ONE_VIDEO" ? (
                        <div className="d-flex">
                          <span>Accept</span>
                          <span
                            onClick={async (e) => {
                              e.stopPropagation();
                              const res = await updateNotificationStatus({
                                notification_id: ele.notification_id,
                                is_read: true,
                              });

                              socket.emit(
                                "rejectVideoCall",
                                JSON.stringify({
                                  user_id: ele?.data?.user_id,
                                  notificationFrom: "helpandsupport",
                                })
                              );

                              dispatch(getNotifications());
                            }}
                            className="ms-2"
                          >
                            Reject
                          </span>
                        </div>
                      ) : ele.notification_type ===
                          "MY_CALENDAR_ALERTS_MAIN_AUDITORIUM" ||
                        ele.notification_type ===
                          "MY_CALENDAR_ALERTS_SATELLITE_AUDITORIUM" ||
                        ele.notification_type ===
                          "MY_CALENDAR_ALERTS_NETWORKING_LOUNGE" ||
                        ele.notification_type ===
                          "MY_CALENDAR_ALERTS_PRODUCT_ZONE" ? (
                        "Join Now"
                      ) : null}
                    </label>
                  </div>
                </div>
              );
            else return null;
          })}
        </div>
      );
    return (
      <div className="notification_list_parent">No notifications found.</div>
    );
  };

  // console.log("notification states", notificationStates);
  return (
    <Layout.Header
      className={
        props?.isSidebarCollapsed
          ? "site-layout-background-minimized"
          : "site-layout-background"
      }
      style={{ padding: 0 }}
    >
      <div className={isOnline ? "netUp" : "netDown"}>You are offline</div>
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          {isMobile
            ? React.createElement(
                props?.isSidebarCollapsed
                  ? MenuUnfoldOutlined
                  : MenuFoldOutlined,
                {
                  className: "trigger header-minimize-logo",
                  onClick: props?.toggleSidebar,
                }
              )
            : React.createElement(DoubleRightOutlined, {
                className:
                  "trigger header-minimize-logo header-minimize-logo-mobile",
                onClick: props?.toggleMobileDrawer,
                style: { marginTop: "10px" },
              })}

          {window.location.pathname === "/expomap" ? (
            <div className="d-flex justify-content-start">
              <div className="mt-1">
                <h5 className="header-expomap-title">{headerData?.title}</h5>
                <Breadcrumb separator=">">
                  {headerData?.breadcrumbs?.map((item, i) => {
                    return (
                      <Breadcrumb.Item
                        className="header-expomap-sub-title"
                        key={"headertitle_" + i}
                      >
                        {item}
                      </Breadcrumb.Item>
                    );
                  })}
                </Breadcrumb>
              </div>
            </div>
          ) : null}
        </div>

        <div className="d-flex">
          {(window.location.pathname === "/expomap" ||
            window.location.pathname === "/concourse") &&
          (userType === "SUPER ADMIN" || userType === "CUSTOMER") ? (
            <div className="header_events_list mt-2">
              <Select
                showSearch
                placeholder="Select Event"
                name="event_id"
                allowClear
                className="brands_event_search"
                value={vistaEventId}
                onChange={(val) => {
                  dispatch(setHeaderNewEventId(val));
                  dispatch(setVistaEventId(val));
                }}
                optionFilterProp="children"
                listHeight={120}
                listItemHeight={4}
                bordered={false}
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
              >
                {headerEventList &&
                  headerEventList?.map((data, i) => {
                    return (
                      <Option key={"event_" + i} value={data.event_id}>
                        {data.event_name}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          ) : null}
          {window.location.pathname === "/people" &&
          (userType === "SUPER ADMIN" || userType === "CUSTOMER") ? (
            <div className="header_events_list mt-2">
              <Select
                showSearch
                placeholder="Select Event"
                name="event_id"
                allowClear
                className="brands_event_search"
                value={peopleEventId}
                onChange={(val) => {
                  dispatch(setPeopleEventData({ key: "event_id", value: val }));
                }}
                optionFilterProp="children"
                listHeight={120}
                listItemHeight={4}
                bordered={false}
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
              >
                {headerEventList &&
                  headerEventList?.map((data, i) => {
                    return (
                      <Option key={"event_" + i} value={data.event_id}>
                        {data.event_name}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          ) : null}

          <span className="mx-2 d-flex">
            <span className="mx-2">
              <Badge count={notificationsCount ?? 0}>
                <Popover
                  placement="bottomRight"
                  className=""
                  title={null}
                  content={renderNotificationList}
                  trigger="click"
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                >
                  <BellOutlined style={{ fontSize: "22px" }} />
                </Popover>
              </Badge>
            </span>
            <span
              className="mx-2"
              onClick={() => {
                if (
                  profileAccess ||
                  userType === "SUPER ADMIN" ||
                  userType === "CUSTOMER"
                ) {
                  history.push("/myprofile");
                }
              }}
            >
              <Avatar
                src={
                  logoFromRedux?.includes(null)
                    ? placeholder_image
                    : logoFromRedux
                }
              >
                {userName.split(" ").shift().slice(0, 1)}
                {userName.split(" ").pop().slice(0, 1)}
              </Avatar>
            </span>
            <span
              className="my-2 mx-2 d-flex flex-column"
              style={{ lineHeight: "20px" }}
            >
              {isMobile ? (
                <small className="d-inline-block fs-12 text-secondary">
                  {userType}
                </small>
              ) : null}

              <Tooltip title={userName}>
                <span className="d-inline-block header-user-name">
                  {userName}
                </span>
              </Tooltip>
            </span>
            <span className="mx-2">
              <Dropdown
                overlayStyle={{ width: "200px" }}
                overlay={menu}
                trigger={["click"]}
              >
                <div>
                  <DownOutlined style={{ fontSize: "15px" }} />
                </div>
              </Dropdown>
            </span>
          </span>
        </div>
      </div>
      {showVideoConferencePopup ? (
        <MeetContainer
          loginUserId={loginUserId}
          chat_id={chatId}
          socket={socket}
        />
      ) : null}

      {/* {showGoogleAuthPopup ? <GoogleAuthModal /> : null} */}
    </Layout.Header>
  );
};

export default Header;
