import React, { useState, useEffect, useRef } from "react";
import "../EventCalender/eventCalender.css";
import { Modal, Row, Col, Button, Carousel, Input, Tooltip } from "antd";
import { Chat } from "../../components/svgJS/Chat";
import { Video } from "../../components/svgJS/Video";
import { CalenderRed } from "../../components/svgJS/CalenderRed";
import { BoothRed } from "../../components/svgJS/BoothRed";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import RedAsterick from "../../components/Widgets/RedAsterick";
import {
  getNetworkLoungeSession,
  sendMessageAPI,
  updateAssetDownloadCount,
} from "../../utils/commonApis";
import { DEFAULT_CHATBOT_HISTORY } from "../../components/constsnts/common.constants";
import {
  videoRequestAPI,
  fetchChatbotHistory,
  getChatbotJson,
  saveChatbotHistory,
} from "../../utils/commonApis";
import {
  checkIfChatbotTimeSlot,
  createTZString,
  getComentChatUser,
  vistaRedirectToBooth,
} from "../../utils/commonFunctions";
import CustomChatbot from "../../components/Widgets/CustomChatbot";
import { isEmpty } from "lodash";
import { Booth } from "../../components/svgJS/Booth";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Download } from "../../components/svgJS/Download";
import { User } from "../../components/svgJS/User";
import ICalendarLink from "react-icalendar-link";
import { addToMyCalender } from "../../utils/commonApis";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { onNetworkingJoinSessClickFlag } from "../../redux/slice/networking";
import moment from "moment";
import "./commonPopup.css";
import { setVideoConferenceData } from "../../redux/slice/videoConference";
import { ApiCall } from "../../utils/api";

const NetViewDelegates = (props) => {
  const { data } = props;
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const history = useHistory();
  const messageValidator = useRef(new SimpleReactValidator());
  const [localData, setLocalData] = useState({});
  const [messageModal, setMessageModal] = useState({ isOpen: false, data: {} });
  const [forceUpdate, setForceUpdate] = useState(false);
  const [message_text, set_message_text] = useState("");
  const [cometChatUser, setCometChatUser] = useState({
    isValidUser: false,
    data: {},
    uid: "",
  });
  const [isChatBot, setIsChatBot] = useState(false);
  const [chatbot_json, set_chatbot_json] = useState([]);
  const [chatbot_history, set_chatbot_history] = useState(
    DEFAULT_CHATBOT_HISTORY
  );
  const [loading, setLoading] = useState(false);
  const [canJoinSession, setCanJoinSession] = useState(false);

  useEffect(async () => {
    setLoading(true);
    const cometRes = await getComentChatUser();
    setCometChatUser(cometRes);
    if (data && data.event_id) {
      const chatHistory = await fetchChatbotHistory({
        event_id: data.event_id,
      });
      if (chatHistory && !isEmpty(chatHistory))
        set_chatbot_history(chatHistory);
    }

    if (data && data?.networking_configuration_id) {
      let sessionAPIData = await getNetworkLoungeSession({
        networking_configuration_id: data.networking_configuration_id,
        networking_session_id: data.networking_session_id,
      });

      const d1 = new Date(
        sessionAPIData.networking_lounge_session_details.start_date_time
      );
      const st = d1.toISOString().replace("Z", "");
      const d2 = new Date(
        sessionAPIData.networking_lounge_session_details.end_date_time
      );
      const et = d2.toISOString().replace("Z", "");

      const currentDate = moment().format("YYYY-MM-DD");
      const currenTime = moment().format("HH:mm:ss");
      const start_date_time = createTZString(
        sessionAPIData.networking_lounge_session_details.start_date_time
      );

      const end_date_time = createTZString(
        sessionAPIData.networking_lounge_session_details.end_date_time
      );
      // const eleStartDate = moment.tz(
      //   start_date_time.format("YYYY-MM-DD"),
      //   "YYYY-MM-DD"
      // );
      // const sessStartTime = moment(
      //   start_date_time.format("HH:mm"),
      //   TIME_FORMAT
      // );

      // const sessEndTime = moment(end_date_time.format("HH:mm"), TIME_FORMAT);

      const eleStartDate = start_date_time.format("YYYY-MM-DD");

      const sessStartTime = start_date_time.format("HH:mm");

      const sessEndTime = end_date_time.format("HH:mm");

      if (
        currentDate === eleStartDate &&
        currenTime >= sessStartTime &&
        currenTime <= sessEndTime
      )
        setCanJoinSession(true);
      setLocalData({
        ...sessionAPIData,
        startTime: st,
        endTime: et,
        title: sessionAPIData.networking_lounge_session_details.session_title,
        description:
          sessionAPIData.networking_lounge_session_details.session_description,
        start_date_time: sessStartTime,
        end_date_time: sessEndTime,
      });
      setLoading(false);
    } else setLoading(false);
  }, []);

  function clearMessageAndModal() {
    set_message_text("");
    setMessageModal({ isOpen: false, data: {} });
  }

  async function callSendMessageAPI(channel_id) {
    if (messageValidator.current.allValid()) {
      if (cometChatUser.uid) {
        const response = await sendMessageAPI(channel_id, message_text);
        if (response.data.code === 200) {
          toast.success(response.data.message);
          clearMessageAndModal();
        } else toast.error(response.data.message);
      } else setForceUpdate(!forceUpdate);
    } else {
      messageValidator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
  }

  const saveResponseData = async (bool) => {
    let objToPass = {};
    if (bool) objToPass = { ...data, rsvp_type: "YES" };
    else objToPass = { ...data, rsvp_type: "NO" };
    data !== null &&
      (await ApiCall("POST", objToPass, "/admin/mycalendarrsvp/save", null)
        .then((response) => {
          if (response.data.code === 200) {
            toast.success(response.data.message);
            window.location.reload();
            // getMyCalendarAppointmentDetails()
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((err) => {
          console.error(err);
        }));
  };

  function renderUI() {
    if (loading) return <CommonLoader />;
    else
      return (
        <div
          className={
            props.isFromCalender && props.isFromCalender === "zone"
              ? "w-100 p-4"
              : "w-100"
          }
        >
          {isChatBot && chatbot_json ? (
            <CustomChatbot
              onClose={async (conversation_details) => {
                await saveChatbotHistory({
                  event_id: localData.event_id,
                  conversation_details,
                });
                setIsChatBot(false);
              }}
              responseJson={chatbot_json}
              chatbot_history={chatbot_history}
              onSetHistory={(newHistory) => set_chatbot_history(newHistory)}
              extraProps={{ event_id: localData.event_id }}
            />
          ) : null}
          <div className="demoroom_container w-100 pb-0">
            <Row>
              <span className="popup_label">Title :</span>
            </Row>
            <Row>
              <b>
                {localData?.networking_lounge_session_details?.session_title}
              </b>
            </Row>
            <Row>
              <span className="popup_label mt-2">Time :</span>
            </Row>
            <Row>
              <b>
                {localData?.start_date_time + " - " + localData?.end_date_time}
              </b>
            </Row>
            <Row>
              <span className="popup_label mt-2">Description :</span>
            </Row>
            <Row>
              <b>
                {
                  localData?.networking_lounge_session_details
                    ?.session_description
                }
              </b>
            </Row>
            <Row className="mb-3">
              <Col span={12} className="networkView_column_left">
                <Row>
                  <span className="popup_label mt-2">Seats per Table :</span>
                </Row>
                <Row>
                  <b>
                    {localData?.networking_lounge_session_details
                      ?.seats_per_table ?? "NA"}
                  </b>
                </Row>
                <Row>
                  <span className="popup_label mt-2">Shuffle Time :</span>
                </Row>
                <Row>
                  <b>
                    {localData?.networking_lounge_session_details
                      ?.mins_per_shuffle + " minutes" ?? "NA"}
                  </b>
                </Row>
                <Row>
                  <span className="popup_label mt-2">
                    Waterfall (Intro) Time :
                  </span>
                </Row>
                <Row>
                  <b>
                    {localData?.networking_lounge_session_details
                      ?.seconds_per_intro
                      ? localData?.networking_lounge_session_details
                          ?.seconds_per_intro + " seconds"
                      : "NA"}
                  </b>
                </Row>
                <Row>
                  <span className="popup_label mt-2">
                    Attending Live Currently :
                  </span>
                </Row>
                <Row>
                  <b>1,432</b>
                </Row>
              </Col>
              <Col span={12} className="networkView_column_right">
                <Row>
                  <span className="popup_label mt-2">
                    Speed Dating Enabled? :
                  </span>
                </Row>
                <Row>
                  <b>
                    {localData?.networking_lounge_session_details
                      ?.is_speed_dating_enabled
                      ? "Yes"
                      : "No"}
                  </b>
                </Row>
                <Row>
                  <span className="popup_label mt-2">
                    Waterfall Intros Enabled :
                  </span>
                </Row>
                <Row>
                  <b>
                    {localData?.networking_lounge_session_details
                      ?.is_waterfall_intros_enabled
                      ? "Yes"
                      : "No"}
                  </b>
                </Row>
                <Row>
                  <span className="popup_label mt-2">
                    Provisionally Attending :
                  </span>
                </Row>
                <Row>
                  <b>1,732</b>
                </Row>
              </Col>
              <Col span={12}>
                {localData?.networking_lounge_session_details
                  ?.is_iCal_download_enabled === true &&
                window.navigator.platform.toLowerCase().includes("mac") ? (
                  <ICalendarLink
                    className="networking_downloadBtn"
                    event={localData}
                  >
                    <div>
                      <span>Download iCal</span>
                    </div>
                  </ICalendarLink>
                ) : null}
              </Col>
              <Col span={12}>
                {window.location.pathname !== "/mycalendar" ? (
                  <Button
                    className="add_to_mycal_btn mt-2"
                    onClick={async () => {
                      await addToMyCalender({
                        event_id: localData.event_id,
                        hall_type: "NETWORKING",
                        hall_id: localData?.networking_configuration_id,
                        session_id: localData?.networking_session_id,
                      });
                    }}
                  >
                    <CalenderRed />
                    <span className="ms-2">Add to My Calendar </span>
                  </Button>
                ) : null}
              </Col>
            </Row>
            <Row>
              <span className="popup_label mt-2">Sponsored by</span>
            </Row>
            {localData?.networking_lounge_sponsoring_brands?.length > 0 ? (
              <div style={{ padding: "3%", paddingTop: "1%" }}>
                <Carousel
                  style={{ width: "800px !important" }}
                  className="audiEvent_brand"
                  arrows
                  nextArrow={
                    <div>
                      <i class="arrow right"></i>
                    </div>
                  }
                  prevArrow={
                    <div>
                      <i class="arrow left"></i>
                    </div>
                  }
                >
                  {localData?.networking_lounge_sponsoring_brands &&
                    localData?.networking_lounge_sponsoring_brands?.map(
                      (ele) => {
                        let chatbotRes = checkIfChatbotTimeSlot(ele);
                        return (
                          <div className="d-flex my-1 py-3 px-2">
                            <div className="all_sposnor_images_container">
                              <img
                                className="all_sposnor_images border border-grey"
                                src={
                                  ele.logo_url
                                    ? ele.logo_url
                                    : "https://www.emotivebrand.com/wp-content/uploads/2016/09/tumblr_o05v3eZmyT1ugn1wu_og_1280-1080x675.png"
                                }
                              />
                            </div>

                            <div className="ms-3">
                              <h6>{ele?.brand_title}</h6>
                              <p>{ele?.brand_tier}</p>
                              <div className="d-flex flex-wrap mt-3">
                                {ele?.is_assets_enabled === true &&
                                ele?.asset_file_url &&
                                ele?.asset_name &&
                                ele?.asset_file_url !== null &&
                                ele?.asset_name !== null ? (
                                  <Tooltip
                                    placement="right"
                                    title={ele?.original_file_name}
                                    className="mx-2"
                                  >
                                    <Button
                                      className="border p-1"
                                      onClick={async () => {
                                        if (ele?.can_download_asset) {
                                          window.location.href =
                                            ele?.asset_file_url;

                                          updateAssetDownloadCount(
                                            ele?.zone_asset_id
                                          );
                                        } else {
                                          toast.error(
                                            "Max Number of Downloads Reached"
                                          );
                                        }
                                      }}
                                      download={ele?.original_file_name}
                                    >
                                      <Download />
                                    </Button>
                                  </Tooltip>
                                ) : null}
                                {ele?.is_booth_link_enabled ? (
                                  <Tooltip
                                    placement="right"
                                    title={"Visit Booth"}
                                    className="mx-2"
                                  >
                                    <Button
                                      className="border p-1"
                                      onClick={() => {
                                        let tier = ele?.brand?.brand_tier;
                                        let zones = ele?.brand?.zones;
                                        let sequence = null;
                                        let brand_id = ele?.brand_id;
                                        let final_booth_url =
                                          ele?.brand?.final_booth_url;

                                        vistaRedirectToBooth(
                                          tier,
                                          zones,
                                          sequence,
                                          null,
                                          brand_id,
                                          null,
                                          history,
                                          dispatch,
                                          final_booth_url
                                        );
                                      }}
                                    >
                                      <BoothRed />
                                    </Button>
                                  </Tooltip>
                                ) : null}
                                {(ele?.is_chat_enabled && ele.channel_id) ||
                                (chatbotRes && ele.chat_bot_start_date_time) ? (
                                  <Tooltip
                                    placement="right"
                                    title="Chat"
                                    className="mx-2"
                                  >
                                    <Button
                                      className="border p-1"
                                      onClick={async () => {
                                        if (
                                          chatbotRes &&
                                          ele.chat_bot_start_date_time
                                        ) {
                                          let chat_bot_res =
                                            await getChatbotJson({
                                              zone_id: ele.zone_id,
                                            });
                                          if (
                                            chat_bot_res &&
                                            !isEmpty(chat_bot_res)
                                          ) {
                                            set_chatbot_json(chat_bot_res);
                                            setIsChatBot(true);
                                          } else
                                            toast.error(
                                              "Chatbot details doesn't exist"
                                            );
                                        } else {
                                          setMessageModal({
                                            isOpen: true,
                                            channel_id: ele.channel_id,
                                          });
                                        }
                                      }}
                                    >
                                      <Chat />
                                    </Button>
                                  </Tooltip>
                                ) : null}
                                {ele?.is_demo_room_enabled ? (
                                  <Tooltip
                                    placement="right"
                                    title={"Demo Room"}
                                    className="mx-2"
                                  >
                                    <Button
                                      className="border p-1"
                                      onClick={() => {
                                        dispatch(
                                          setVideoConferenceData({
                                            videoConferenceFlag: true,
                                            videoConferenceUrl:
                                              ele?.zone_demo_room_link,
                                            is_demo_room: true,
                                          })
                                        );
                                      }}
                                    >
                                      <Video />
                                    </Button>
                                  </Tooltip>
                                ) : null}
                                {ele?.is_video_call_enabled ? (
                                  <Tooltip
                                    placement="right"
                                    title={"Next Available Agent"}
                                    className="mx-2"
                                  >
                                    <Button
                                      className="border p-1"
                                      onClick={async () => {
                                        const response = await videoRequestAPI(
                                          ele.channel_id
                                        );
                                        if (response.data.code === 200)
                                          toast.success(response.data.message);
                                        else toast.error(response.data.message);
                                      }}
                                    >
                                      <User />
                                    </Button>
                                  </Tooltip>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                </Carousel>
              </div>
            ) : null}
            {localData?.networking_lounge_sponsoring_brands?.length === 0 ? (
              <Row>
                <b>NA</b>
              </Row>
            ) : null}
            {messageModal?.isOpen &&
            window.location.pathname === "/mycalendar" ? (
              <>
                <Row className="ticker_configuration_row mt-2">
                  <Col span={6} className="ticker_configuration_label">
                    <p>
                      Message
                      <RedAsterick />
                    </p>
                  </Col>
                  <Col span={12}>
                    <TextArea
                      name="message_text"
                      id="message_text"
                      className="ticker_configuration_inputDescription"
                      placeholder="Enter a message"
                      rows={4}
                      value={message_text}
                      onChange={(e) => set_message_text(e.target.value)}
                      onBlur={() =>
                        messageValidator.current.showMessageFor("message_text")
                      }
                    />
                    {messageValidator.current.message(
                      "Message",
                      message_text,
                      "required",
                      { className: "text-danger py-1" }
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={14} className="text-end">
                    <Button
                      className="px-3 rounded"
                      key="save"
                      type="primary"
                      onClick={() =>
                        callSendMessageAPI(messageModal.channel_id)
                      }
                    >
                      Send
                    </Button>
                  </Col>
                  <Col span={6}>
                    <Button
                      className="px-3 button-pink rounded ms-3"
                      key="cancel"
                      onClick={() => clearMessageAndModal()}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </>
            ) : null}

            {messageModal.isOpen &&
            window.location.pathname !== "/mycalendar" ? (
              <Modal
                title={<b>Send Message</b>}
                visible={messageModal.isOpen}
                onOk={() => clearMessageAndModal()}
                onCancel={() => clearMessageAndModal()}
                footer={[
                  <Button
                    className="px-3 button-pink rounded"
                    key="cancel"
                    onClick={() => clearMessageAndModal()}
                  >
                    Cancel
                  </Button>,
                  <Button
                    className="px-3 rounded"
                    key="save"
                    type="primary"
                    onClick={() => callSendMessageAPI(messageModal.channel_id)}
                  >
                    Send
                  </Button>,
                ]}
                className="message_modal"
              >
                <Row className="ticker_configuration_row mt-2">
                  <Col span={6} className="ticker_configuration_label">
                    <p>
                      Message
                      <RedAsterick />
                    </p>
                  </Col>
                  <Col span={12}>
                    <TextArea
                      name="message_text"
                      id="message_text"
                      className="ticker_configuration_inputDescription"
                      placeholder="Enter a message"
                      rows={4}
                      value={message_text}
                      onChange={(e) => set_message_text(e.target.value)}
                      onBlur={() =>
                        messageValidator.current.showMessageFor("message_text")
                      }
                    />
                    {messageValidator.current.message(
                      "Message",
                      message_text,
                      "required",
                      { className: "text-danger py-1" }
                    )}
                  </Col>
                </Row>
              </Modal>
            ) : null}
            <div className="w-100 d-flex mt-4 ">
              <div className="w-75" />
              {props?.data?.rsvp_type === "PENDING" ? (
                <>
                  <div className="d-flex justify-content-end px-3">
                    <Button
                      type="primary"
                      className="button-pink py-1 rounded"
                      onClick={() => saveResponseData(true)}
                    >
                      Accept
                    </Button>
                    <Button
                      type="primary"
                      className="button-pink py-1 mx-3 rounded"
                      onClick={() => saveResponseData(false)}
                    >
                      Reject
                    </Button>
                  </div>
                </>
              ) : canJoinSession ? (
                <Button
                  className="networkingsess_audiBtn fs-16"
                  onClick={() => {
                    dispatch(
                      onNetworkingJoinSessClickFlag({
                        flag: true,
                        networking_session_id: localData?.networking_session_id,
                        networking_configuration_id:
                          localData?.networking_configuration_id,
                      })
                    );
                    history.push("/networking");
                  }}
                >
                  <Booth />
                  <span className="ms-2">Join Session</span>
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      );
  }

  if (props.isFromCalender && props.isFromCalender === "zone")
    return renderUI();
  else
    return (
      <Modal
        title={"Session Details"}
        visible={true}
        onOk={() => {
          if (!loading) {
            if (props.isFromCalender === "event") props.onCloseModal(false);
            else props.onCancel();
          }
        }}
        onCancel={() => {
          if (!loading) {
            if (props.isFromCalender === "event") props.onCloseModal(false);
            else props.onCancel();
          }
        }}
        className="networking-view-modal"
        width={950}
        footer={null}
      >
        {renderUI()}
      </Modal>
    );
};

export default NetViewDelegates;
