import React, { useState, useEffect, useRef } from "react";
import { Button, Switch, Input, Modal } from "antd";
import { Tooltip, Spin } from "antd";
import {
  DeleteOutlined,
  LoadingOutlined,
  InfoCircleOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { CalenderGrey } from "../../../components/svgJS/CalenderGrey";
import FileUpload from "../../common/FileUpload";

import SimpleReactValidator from "simple-react-validator";
import { dialogs } from "../../../components/constsnts/string.constants";
import { toast } from "react-toastify";
import { isImage } from "../../../utils/commonFunctions";
import { uploadVistaFile } from "../../../utils/commonApis";
import "./index.css";
import CommonLoader from "../../../components/Widgets/CommonLoader";
import { DeleteSponsorMedia } from "../../../utils/commonApis";
import { cloneDeep } from "lodash-es";

const EventSettings = ({
  data,
  eventEligibility,
  onChangeData,
  onNavigateToRegistration,
  onNavigateToReception,
  onNavigateToTier,
  onNavigateToSocialWall,
  onValid,
  initiateValidations,
}) => {
  const validator = useRef(new SimpleReactValidator());
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const Tablet = useMediaQuery({ maxWidth: 1200 });
  const [sponsorBanner, setSponsorBanner] = useState(null);
  const [sponsorBoothLeft, setSponsorBoothLeft] = useState(null);
  const [sponsorBoothCenter, setSponsorBoothCenter] = useState(null);
  const [sponsorBoothRight, setSponsorBoothRight] = useState(null);
  const [expochecked, setExpoChecked] = useState(
    data &&
      data.event_settings_details &&
      data.event_settings_details.is_expo_hall_active
      ? true
      : false
  );
  const [satAuditoriumChecked, setSatAuditoriumChecked] = useState(
    data &&
      data.event_settings_details &&
      data.event_settings_details.is_sat_auditorium_active
      ? true
      : false
  );
  const [networkingChecked, setNetworkingChecked] = useState(
    data &&
      data.event_settings_details &&
      data.event_settings_details.is_networking_active
      ? true
      : false
  );
  const [expoHallFileState, setExpoHallFileState] = useState(
    data?.event_settings_details?.event_settings_expo_hall_details ?? []
  );
  const [mainAudiFileState, setMainAudiFileState] = useState(null);
  const [satAudiFileState, setSatAudiFileState] = useState([]);
  const [netLoungeFileState, setNetLoungeFileState] = useState([]);
  const [concourseFileState, setConcourseFileState] = useState(null);
  const [exteriorFileState, setExteriorFileState] = useState(null);
  const [sponsorBannerThumbNail, setSponsorBannerThumbNail] = useState(null);
  const [multipleVistaModalVisible, setMultipleVistaModalVisible] = useState({
    title: "",
    flag: false,
  });
  const [sponsorBoothLeftThumbNail, setSponsorBoothLeftThumbNail] =
    useState(null);
  const [sponsorBoothCenterThumbNail, setSponsorBoothCenterThumbNail] =
    useState(null);
  const [sponsorBoothRightThumbNail, setSponsorBoothRightThumbNail] =
    useState(null);
  const [loader, setLoader] = useState(true);
  const [vistaUploadLoader, setVistaUploadLoader] = useState(false);

  useEffect(async () => {
    if (
      data &&
      data?.event_settings_details?.event_settings_expo_hall_details
    ) {
      setExpoHallFileState(
        data?.event_settings_details?.event_settings_expo_hall_details
      );
    }

    if (data && data.event_settings_details.main_hall_original_filename) {
      setMainAudiFileState({
        name: data.event_settings_details.main_hall_original_filename,
      });
    }

    if (
      data &&
      data?.event_settings_details?.event_settings_satellite_auditorium_details
    ) {
      setSatAudiFileState(
        data?.event_settings_details
          ?.event_settings_satellite_auditorium_details
      );
    }

    if (
      data &&
      data?.event_settings_details?.event_settings_networking_lounge_details
    ) {
      setNetLoungeFileState(
        data?.event_settings_details?.event_settings_networking_lounge_details
      );
    }
    if (data && data.event_settings_details.exterior_original_filename) {
      setExteriorFileState({
        name: data.event_settings_details.exterior_original_filename,
      });
    }
    if (data && data.event_settings_details.concourse_original_filename) {
      setConcourseFileState({
        name: data.event_settings_details.concourse_original_filename,
      });
    }

    if (data && data.event_settings_details.sponsor_banner_url) {
      let logoFileName = data.event_settings_details.sponsor_banner_url
        .split("\\")
        .pop()
        .split("/")
        .pop();

      if (data && data.event_settings_details.sponsor_banner_original_filename)
        logoFileName =
          data.event_settings_details.sponsor_banner_original_filename;

      setSponsorBanner({
        name: logoFileName,
        sponsor_banner_url: data.event_settings_details.sponsor_banner_url,
      });
    }
    if (data && data.event_settings_details.sponsor_booth_left_url) {
      let logoFileName = data.event_settings_details.sponsor_booth_left_url
        .split("\\")
        .pop()
        .split("/")
        .pop();

      if (
        data &&
        data.event_settings_details.sponsor_booth_left_original_filename
      )
        logoFileName =
          data.event_settings_details.sponsor_booth_left_original_filename;

      setSponsorBoothLeft({
        name: logoFileName,
        sponsor_booth_left_url:
          data.event_settings_details.sponsor_booth_left_url,
      });
    }

    if (data && data.event_settings_details.sponsor_booth_center_url) {
      let logoFileName = data.event_settings_details.sponsor_booth_center_url
        .split("\\")
        .pop()
        .split("/")
        .pop();

      if (
        data &&
        data.event_settings_details.sponsor_booth_center_original_filename
      )
        logoFileName =
          data.event_settings_details.sponsor_booth_center_original_filename;

      setSponsorBoothCenter({
        name: logoFileName,
        sponsor_booth_center_url:
          data.event_settings_details.sponsor_booth_center_url,
      });
    }

    if (data && data.event_settings_details.sponsor_booth_right_url) {
      let logoFileName = data.event_settings_details.sponsor_booth_right_url
        .split("\\")
        .pop()
        .split("/")
        .pop();

      if (
        data &&
        data.event_settings_details.sponsor_booth_right_original_filename
      )
        logoFileName =
          data.event_settings_details.sponsor_booth_right_original_filename;

      setSponsorBoothRight({
        name: logoFileName,
        sponsor_booth_right_url:
          data.event_settings_details.sponsor_booth_right_url,
      });
    }
    setLoader(false);
  }, []);

  useEffect(() => {
    if (initiateValidations) {
      handleFormSubmit();
    }
  }, [initiateValidations]);

  const handleFormSubmit = () => {
    if (validator.current.allValid()) {
      if (vistaUploadLoader)
        toast.error(
          "Please wait file is uploading. Please do not close the window."
        );
      else if (
        data.event_settings_details &&
        data.event_settings_details.is_expo_hall_active &&
        data.event_settings_details.no_of_expo_halls === 0
      ) {
        toast.error("Please enter number of halls more than 0");
        onValid(false);
      } else if (
        data.event_settings_details &&
        data.event_settings_details.is_sat_auditorium_active &&
        data.event_settings_details.no_of_sat_auditorium === 0
      ) {
        toast.error("Please enter number of satellite auditoriums more than 0");
        onValid(false);
      } else if (
        data.event_settings_details &&
        data.event_settings_details.is_networking_active &&
        data.event_settings_details.no_of_networking === 0
      ) {
        toast.error("Please enter number of network lounges more than 0");
        onValid(false);
      } else if (
        data?.event_settings_details?.is_sponsor_banner_active &&
        data?.event_settings_details &&
        data?.event_settings_details?.sponsor_banner_original_filename === ""
      ) {
        toast.error("Please upload sponsor banner file");
        onValid(false);
      } else if (
        data?.event_settings_details?.is_sponsor_booth_active &&
        data?.event_settings_details &&
        (data?.event_settings_details?.sponsor_booth_right_original_filename ===
          "" ||
          data?.event_settings_details
            ?.sponsor_booth_center_original_filename === "" ||
          data?.event_settings_details?.sponsor_booth_left_original_filename ===
            "")
      ) {
        toast.error("Please upload all booth files");
        onValid(false);
      } else onValid(true);
    } else {
      onValid(false);
      validator.current.showMessages();
      toast.error(dialogs.missingFields);
    }
  };

  const onHandleChange = (key1, value, parentKey = "") => {
    onChangeData({ [key1]: value }, parentKey);
  };

  const handleSponsorBannerChange = (event) => {
    onChangeData(
      {
        sponsor_banner_file: event.target.files[0],
        sponsor_banner_original_filename: event.target.files[0].name,
      },
      "event_settings_details"
    );
    setSponsorBanner(event.target.files[0]);
    setSponsorBannerThumbNail(URL.createObjectURL(event.target.files[0]));
  };

  const handleBoothLeftChange = (event) => {
    onChangeData(
      {
        sponsor_booth_left_file: event.target.files[0],
        sponsor_booth_left_original_filename: event.target.files[0].name,
      },
      "event_settings_details"
    );
    setSponsorBoothLeft(event.target.files[0]);
    setSponsorBoothLeftThumbNail(URL.createObjectURL(event.target.files[0]));
  };

  const handleBoothCenterChange = (event) => {
    onChangeData(
      {
        sponsor_booth_center_file: event.target.files[0],
        sponsor_booth_center_original_filename: event.target.files[0].name,
      },
      "event_settings_details"
    );
    setSponsorBoothCenter(event.target.files[0]);
    setSponsorBoothCenterThumbNail(URL.createObjectURL(event.target.files[0]));
  };
  const handleBoothRightChange = (event) => {
    onChangeData(
      {
        sponsor_booth_right_file: event.target.files[0],
        sponsor_booth_right_original_filename: event.target.files[0].name,
      },
      "event_settings_details"
    );
    setSponsorBoothRight(event.target.files[0]);
    setSponsorBoothRightThumbNail(URL.createObjectURL(event.target.files[0]));
  };

  const handle360 = async (event, type, multipleIndex = "") => {
    setVistaUploadLoader(true);
    const selectedFile = event.target.files[0];
    let fileKeyForUpload;
    if (type === "expoHall") fileKeyForUpload = "expo_hall_file";
    else if (type === "mainAudi") fileKeyForUpload = "main_auditorium_file";
    else if (type === "satAudi") fileKeyForUpload = "satellite_auditorium_file";
    else if (type === "netLounge") fileKeyForUpload = "networking_lounge_file";
    else if (type === "concourse") fileKeyForUpload = "concourse_file";
    else if (type === "exterior") fileKeyForUpload = "exterior_file";

    const formData = new FormData();
    formData.append(fileKeyForUpload, selectedFile);
    formData.append(
      "data",
      JSON.stringify({ event_id: data?.event_id, sequence: multipleIndex + 1 })
    );
    if (
      process.env.REACT_APP_BASE_URL === "https://api-grv8.dev.brainvire.net"
    ) {
      toast.error(
        "Please do not upload vista file on dev server, server will crash"
      );
      setVistaUploadLoader(false);
    } else {
      let resVista = await uploadVistaFile(formData);
      let res = JSON.parse(JSON.stringify(resVista));
      if (res && res.link) {
        let newDataToAdd = {};
        if (type === "expoHall") {
          let cloneData = cloneDeep(expoHallFileState);
          cloneData[multipleIndex]["sequence"] = multipleIndex + 1;
          cloneData[multipleIndex]["expo_hall_url"] = res.link;
          cloneData[multipleIndex]["expo_hall_original_filename"] =
            selectedFile?.name;

          setExpoHallFileState(cloneData);
          setVistaUploadLoader(false);
          onHandleChange(
            "event_settings_expo_hall_details",
            cloneData,
            "event_settings_details"
          );
        } else if (type === "mainAudi") {
          setMainAudiFileState(selectedFile);
          newDataToAdd["main_hall_url"] = res.link;
          newDataToAdd["main_hall_original_filename"] = selectedFile?.name;
          setVistaUploadLoader(false);
          onChangeData(newDataToAdd, "event_settings_details");
        } else if (type === "satAudi") {
          let cloneData = cloneDeep(satAudiFileState);
          cloneData[multipleIndex]["sequence"] = multipleIndex + 1;
          cloneData[multipleIndex]["sat_audi_hall_url"] = res.link;
          cloneData[multipleIndex]["sat_audi_original_filename"] =
            selectedFile?.name;

          setSatAudiFileState(cloneData);
          setVistaUploadLoader(false);
          onHandleChange(
            "event_settings_satellite_auditorium_details",
            cloneData,
            "event_settings_details"
          );
        } else if (type === "netLounge") {
          let cloneData = cloneDeep(netLoungeFileState);
          cloneData[multipleIndex]["sequence"] = multipleIndex + 1;
          cloneData[multipleIndex]["networking_url"] = res.link;
          cloneData[multipleIndex]["networking_original_filename"] =
            selectedFile?.name;

          setNetLoungeFileState(cloneData);
          setVistaUploadLoader(false);
          onHandleChange(
            "event_settings_networking_lounge_details",
            cloneData,
            "event_settings_details"
          );
        } else if (type === "concourse") {
          setConcourseFileState(selectedFile);
          newDataToAdd["concourse_url"] = res.link;
          newDataToAdd["concourse_original_filename"] = selectedFile?.name;
          setVistaUploadLoader(false);
          onChangeData(newDataToAdd, "event_settings_details");
        } else if (type === "exterior") {
          setExteriorFileState(selectedFile);
          newDataToAdd["exterior_url"] = res.link;
          newDataToAdd["exterior_original_filename"] = selectedFile?.name;
          setVistaUploadLoader(false);
          onChangeData(newDataToAdd, "event_settings_details");
        }
      } else setVistaUploadLoader(false);
    }
  };

  const handleBoothFilesDelete = async (type) => {
    if (type === "sponsorBoothLeft") {
      setSponsorBoothLeft(null);
      setSponsorBoothLeftThumbNail(null);

      let dataToRemove = {
        event_settings_id: data?.event_settings_details?.event_settings_id,
        key: "sponsor_booth_left_file",
      };

      const myResponse = await DeleteSponsorMedia(JSON.stringify(dataToRemove));
      if (myResponse.data.code === 200) {
        toast.success(myResponse.data.message);
      } else {
        toast.error(myResponse.data.message);
      }
    } else if (type === "sponsorBoothCenter") {
      setSponsorBoothCenter(null);
      setSponsorBoothCenterThumbNail(null);

      let dataToRemove = {
        event_settings_id: data?.event_settings_details?.event_settings_id,
        key: "sponsor_booth_center_file",
      };
      const myResponse = await DeleteSponsorMedia(JSON.stringify(dataToRemove));
      if (myResponse.data.code === 200) {
        toast.success(myResponse.data.message);
      } else {
        toast.error(myResponse.data.message);
      }
    } else if (type === "sponsorBoothRight") {
      setSponsorBoothRight(null);
      setSponsorBoothRightThumbNail(null);

      let dataToRemove = {
        event_settings_id: data?.event_settings_details?.event_settings_id,
        key: "sponsor_booth_right_file",
      };
      const myResponse = await DeleteSponsorMedia(JSON.stringify(dataToRemove));
      if (myResponse.data.code === 200) {
        toast.success(myResponse.data.message);
      } else {
        toast.error(myResponse.data.message);
      }
    } else if (type === "sponsorBanner") {
      setSponsorBanner(null);
      setSponsorBannerThumbNail(null);

      let dataToRemove = {
        event_settings_id: data?.event_settings_details?.event_settings_id,
        key: "sponsor_banner_file",
      };
      const myResponse = await DeleteSponsorMedia(JSON.stringify(dataToRemove));
      if (myResponse.data.code === 200) {
        toast.success(myResponse.data.message);
      } else {
        toast.error(myResponse.data.message);
      }
    }
  };

  const handleFileStateDelete = (type, index) => {
    let newDataToAdd = {};
    if (type === "expoHall") {
      let cloneData = cloneDeep(expoHallFileState);

      cloneData[index] = {};
      cloneData[index]["sequence"] = null;
      cloneData[index]["expo_hall_url"] = "";
      cloneData[index]["expo_hall_original_filename"] = "";

      setExpoHallFileState(cloneData);

      onHandleChange(
        "event_settings_expo_hall_details",
        cloneData,
        "event_settings_details"
      );
    } else if (type === "mainAudi") {
      setMainAudiFileState(null);
      document.getElementById("mainaudi-upload").value = null;
      newDataToAdd["main_hall_url"] = null;
      newDataToAdd["main_hall_original_filename"] = "";
      onChangeData(newDataToAdd, "event_settings_details");
    } else if (type === "satAudi") {
      let cloneData = cloneDeep(satAudiFileState);
      cloneData[index] = {};
      cloneData[index]["sequence"] = null;
      cloneData[index]["sat_audi_hall_url"] = "";
      cloneData[index]["sat_audi_original_filename"] = "";

      setSatAudiFileState(cloneData);

      onHandleChange(
        "event_settings_satellite_auditorium_details",
        cloneData,
        "event_settings_details"
      );
    } else if (type === "netLounge") {
      let cloneData = cloneDeep(netLoungeFileState);

      cloneData[index] = {};
      cloneData[index]["sequence"] = null;
      cloneData[index]["networking_url"] = "";
      cloneData[index]["networking_original_filename"] = "";

      setNetLoungeFileState(cloneData);

      onHandleChange(
        "event_settings_networking_lounge_details",
        cloneData,
        "event_settings_details"
      );
    } else if (type === "concourse") {
      setConcourseFileState(null);
      document.getElementById("concourse-upload").value = null;
      newDataToAdd["concourse_url"] = null;
      newDataToAdd["concourse_original_filename"] = "";
      onChangeData(newDataToAdd, "event_settings_details");
    } else if (type === "exterior") {
      setExteriorFileState(null);
      document.getElementById("exterior-upload").value = null;
      newDataToAdd["exterior_url"] = null;
      newDataToAdd["exterior_original_filename"] = "";
      onChangeData(newDataToAdd, "event_settings_details");
    }
  };

  const MultipleVistaUI = (props) => {
    const dataToDisplayForExpoHall = expoHallFileState?.map((data, index) => {
      return (
        <div className="my-2 row">
          <div className="col-3 text-end">
            <span className="mt-1">
              {props?.title} {index + 1}
            </span>
          </div>
          <div className="col-9 concourse_vista_video_upload">
            <FileUpload
              labelClassName="rounded border-pink btn-svg px-4 py-2"
              labelTitle="File Upload"
              name={props?.type}
              inputType="file"
              inputOnChange={(e) => handle360(e, props?.type, index)}
              inputId={parseInt(index)}
              inputName={props?.type}
              value=""
            />

            {expoHallFileState[index] ? (
              <>
                <Tooltip
                  title={
                    expoHallFileState[index]?.name ??
                    expoHallFileState[index]?.expo_hall_original_filename
                  }
                  align="top"
                >
                  <span className="mx-3 uploaded_file_name_event_setting">
                    {expoHallFileState[index]?.name ??
                      expoHallFileState[index]?.expo_hall_original_filename}
                  </span>
                </Tooltip>
                {(expoHallFileState[index]?.name?.trim().length !== 0 &&
                  expoHallFileState[index]?.name?.trim().length !==
                    undefined) ||
                  (expoHallFileState[index]?.expo_hall_original_filename?.trim()
                    .length !== 0 &&
                    expoHallFileState[
                      index
                    ]?.expo_hall_original_filename?.trim().length !==
                      undefined && (
                      <span
                        onClick={() => handleFileStateDelete("expoHall", index)}
                      >
                        <DeleteOutlined />
                      </span>
                    ))}
              </>
            ) : null}
          </div>
        </div>
      );
    });

    const dataToDisplayForSatAudi = satAudiFileState?.map((data, index) => {
      return (
        <div className="my-2 row">
          <div className="col-3 text-end">
            <span className="mt-1">
              {props?.title} {index + 1}
            </span>
          </div>
          <div className="col-9 concourse_vista_video_upload">
            <FileUpload
              labelClassName="rounded border-pink btn-svg px-4 py-2"
              labelTitle="File Upload"
              name={props?.type}
              inputType="file"
              inputOnChange={(e) => handle360(e, props?.type, index)}
              inputId={parseInt(index)}
              inputName={props?.type}
              value=""
            />

            {satAudiFileState[index] ? (
              <>
                <Tooltip
                  title={
                    satAudiFileState[index]?.name ??
                    satAudiFileState[index]?.sat_audi_original_filename
                  }
                  align="top"
                >
                  <span className="mx-3 uploaded_file_name_event_setting">
                    {satAudiFileState[index]?.name ??
                      satAudiFileState[index]?.sat_audi_original_filename}
                  </span>
                </Tooltip>
                {(satAudiFileState[index]?.name?.trim().length !== 0 &&
                  satAudiFileState[index]?.name?.trim().length !== undefined) ||
                  (satAudiFileState[index]?.sat_audi_original_filename?.trim()
                    .length !== 0 &&
                    satAudiFileState[index]?.sat_audi_original_filename?.trim()
                      .length !== undefined && (
                      <span
                        onClick={() => handleFileStateDelete("satAudi", index)}
                      >
                        <DeleteOutlined />
                      </span>
                    ))}
              </>
            ) : null}
          </div>
        </div>
      );
    });

    const dataToDisplayForNetLounge = netLoungeFileState?.map((data, index) => {
      return (
        <div className="my-2 row">
          <div className="col-3 text-end">
            <span className="mt-1">
              {props?.title} {index + 1}
            </span>
          </div>
          <div className="col-9 concourse_vista_video_upload">
            <FileUpload
              labelClassName="rounded border-pink btn-svg px-4 py-2"
              labelTitle="File Upload"
              name={props?.type}
              inputType="file"
              inputOnChange={(e) => handle360(e, props?.type, index)}
              inputId={parseInt(index)}
              inputName={props?.type}
              value=""
            />

            {netLoungeFileState[index] ? (
              <>
                <Tooltip
                  title={
                    netLoungeFileState[index]?.name ??
                    netLoungeFileState[index]?.networking_original_filename
                  }
                  align="top"
                >
                  <span className="mx-3 uploaded_file_name_event_setting">
                    {netLoungeFileState[index]?.name ??
                      netLoungeFileState[index]?.networking_original_filename}
                  </span>
                </Tooltip>
                {(netLoungeFileState[index]?.name?.trim().length !== 0 &&
                  netLoungeFileState[index]?.name?.trim().length !==
                    undefined) ||
                  (netLoungeFileState[
                    index
                  ]?.networking_original_filename?.trim().length !== 0 &&
                    netLoungeFileState[
                      index
                    ]?.networking_original_filename?.trim().length !==
                      undefined && (
                      <span
                        onClick={() =>
                          handleFileStateDelete("netLounge", index)
                        }
                      >
                        <DeleteOutlined />
                      </span>
                    ))}
              </>
            ) : null}
          </div>
        </div>
      );
    });

    return (
      <div>
        <div className="row">
          <div className="col-10"></div>
          <div className="col-2 text-start">
            <span className="text-end ">
              <Tooltip
                placement="left"
                title={"If vista is not uploaded, default vista will display"}
              >
                <InfoCircleOutlined style={{ fontSize: "25px" }} />
              </Tooltip>
            </span>
          </div>
        </div>
        <div className="row my-1">
          {props?.type === "expoHall" && dataToDisplayForExpoHall}
          {props?.type === "satAudi" && dataToDisplayForSatAudi}
          {props?.type === "netLounge" && dataToDisplayForNetLounge}
        </div>
      </div>
    );
  };

  const renderMultipleVistaModal = (title, numberOfUpload, type) => {
    return (
      <Modal
        title={title + " Vista" + " Upload"}
        visible={multipleVistaModalVisible.flag}
        onOk={() => setMultipleVistaModalVisible({ flag: false, title: "" })}
        onCancel={() =>
          setMultipleVistaModalVisible({ flag: false, title: "" })
        }
        footer={null}
        width={700}
      >
        <div className="multiple_vista_parent_div">
          <MultipleVistaUI
            title={title}
            numberOfUpload={numberOfUpload}
            type={type}
          />
        </div>
      </Modal>
    );
  };

  async function popVistaFiles(inputValue, vistaFileStateArray) {
    let dataToModify = [];
    dataToModify = cloneDeep(vistaFileStateArray);
    let finalArr = [];
    if (parseInt(inputValue) === 0) {
      await dataToModify.forEach((ele, index) => {
        if (index <= parseInt(inputValue) + 1) finalArr.push(ele);
      });
    } else {
      await dataToModify.forEach((ele, index) => {
        if (index < parseInt(inputValue)) finalArr.push(ele);
      });
    }
    return finalArr;
  }

  if (loader) return <CommonLoader />;
  else if (data) {
    return (
      <div className="container-fluid">
        <section>
          {vistaUploadLoader ? (
            <Modal
              visible
              centered
              footer={null}
              width={500}
              closeIcon={<></>}
              bodyStyle={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Spin
                className="spinner mt-2"
                style={{ color: "#ef0855" }}
                indicator={loadingIcon}
              />
              <span className="my-2">
                Please wait, do not close this window until finished uploading
                !!!
              </span>
            </Modal>
          ) : null}
          <div className="row my-4">
            <div className="col-10 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Event Settings</h6>
            </div>
            <div className="col-2 mt-4 text-end">
              <Tooltip
                placement="right"
                title={"If vista is not uploaded, default vista will display"}
              >
                <InfoCircleOutlined style={{ fontSize: "30px" }} />
              </Tooltip>
            </div>
          </div>

          <div className="row my-4 row_background">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary">Registration</label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                onChange={(value) => {
                  onHandleChange(
                    "is_registration_active",
                    value,
                    "event_settings_details"
                  );
                }}
                checked={
                  data.event_settings_details &&
                  data.event_settings_details.is_registration_active
                    ? data.event_settings_details.is_registration_active
                    : false
                }
                // className="mx-2"
              />
            </div>
            <div className={Tablet ? "col-4" : "col-2"}>
              <Button
                onClick={() => {
                  if (data && data.event_id) onNavigateToRegistration();
                  else toast.error("Please complete the first step");
                }}
                className="px-3 button-pink rounded border-gray"
              >
                Settings
              </Button>
            </div>
            <div className={Tablet ? "col-2" : "col-1"}></div>
            <div className={Tablet ? "col-4 text-end my-3" : "col-2"}></div>
            <div
              className={Tablet ? "col-4 text-end my-3" : "col-2 text-end"}
            ></div>
            <div
              className={Tablet ? "col-4 text-start my-3" : "col-2 text-start"}
            ></div>
          </div>

          <div className="row my-4">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary">Ticker Tape</label>
            </div>
            <div className={Tablet ? "col-1 " : "col-1"}>
              <Switch
                onChange={(value) => {
                  onHandleChange(
                    "is_ticker_tape_active",
                    value,
                    "event_settings_details"
                  );
                }}
                checked={
                  data.event_settings_details &&
                  data.event_settings_details.is_ticker_tape_active
                    ? data.event_settings_details.is_ticker_tape_active
                    : false
                }
                className="invisible"
              />
            </div>
            <div className={Tablet ? "col-1" : "col-2"}></div>
            <div className={Tablet ? "col-2" : "col-1"}></div>
            <div className={Tablet ? "col-4 text-end my-3" : "col-2"}>
              <Button
                type="primary"
                className=" button-pink rounded btn-svg border-gray"
                icon={<CalenderGrey />}
                onClick={() => window.open("/eventCalendar", "_blank")}
              >
                <span className="mx-2"> Sponsor Calendar</span>
              </Button>
            </div>
            <div className={Tablet ? "" : "col-2 text-end"}></div>
            <div className={Tablet ? "" : "col-2 text-start"}></div>
          </div>

          <div className="row my-4 row_background">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary mx-2">Brand Tier</label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                onChange={(value) => {
                  onHandleChange(
                    "is_brand_tier_active",
                    value,
                    "event_settings_details"
                  );
                }}
                checked={
                  data.event_settings_details &&
                  data.event_settings_details.is_brand_tier_active
                    ? data.event_settings_details.is_brand_tier_active
                    : false
                }
                className="invisible"
              />
            </div>
            <div className={Tablet ? "col-4" : "col-2"}>
              <Button
                onClick={() => {
                  if (data && data.event_id) onNavigateToTier();
                  else toast.error("Please complete the first step");
                }}
                className="px-3 button-pink rounded border-gray"
              >
                Settings
              </Button>
            </div>
            <div className={Tablet ? "col-2" : "col-1"}></div>
            <div className={Tablet ? "col-4 text-end my-3" : "col-2"}></div>
            <div
              className={Tablet ? "col-4 text-end my-3" : "col-2 text-end"}
            ></div>
            <div
              className={Tablet ? "col-4 text-start my-3" : "col-2 text-start"}
            ></div>
          </div>

          <div className="row">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary">Expo Halls</label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                defaultChecked={expochecked}
                onChange={(value) => {
                  if (
                    !value &&
                    data &&
                    data.event_id &&
                    eventEligibility &&
                    eventEligibility.expo_hall_number &&
                    eventEligibility.expo_hall_number.hall_reduce_till > 0
                  ) {
                    toast.error(
                      "You cannot disable expo halls as it contains sponsors"
                    );
                  } else {
                    onChangeData(
                      { is_expo_hall_active: value, no_of_expo_halls: 0 },
                      "event_settings_details"
                    );
                    setExpoChecked(value);
                  }
                }}
                // className="mx-2"
                checked={expochecked}
              />
            </div>
            <div className={Tablet ? "col-3" : "col-2"}>
              <div
                className={`${
                  Tablet ? "event_settings_increment_width" : ""
                } btn-group mx-auto border-gray event-editor-increment-btn rounded`}
                role="group"
                aria-label="Second group"
              >
                <button
                  onClick={() => {
                    if (expochecked) {
                      if (
                        data &&
                        data.event_id &&
                        eventEligibility &&
                        eventEligibility.expo_hall_number
                      ) {
                        if (
                          data?.event_settings_details?.no_of_expo_halls >
                          eventEligibility?.expo_hall_number?.hall_reduce_till
                        ) {
                          let cloneData = cloneDeep(expoHallFileState);

                          cloneData?.pop();
                          setExpoHallFileState(cloneData);

                          onChangeData(
                            {
                              no_of_expo_halls:
                                data?.event_settings_details.no_of_expo_halls >
                                0
                                  ? data?.event_settings_details
                                      ?.no_of_expo_halls - 1
                                  : 0,
                              event_settings_expo_hall_details: cloneData,
                            },
                            "event_settings_details"
                          );
                        } else if (
                          data?.event_settings_details?.no_of_expo_halls !== 0
                        )
                          toast.error(
                            "You cannot reduce expo halls as it contains sponsors"
                          );
                      } else {
                        let cloneData = cloneDeep(expoHallFileState);
                        cloneData?.pop();
                        setExpoHallFileState(cloneData);
                        onChangeData(
                          {
                            no_of_expo_halls:
                              data?.event_settings_details.no_of_expo_halls > 0
                                ? data?.event_settings_details
                                    ?.no_of_expo_halls - 1
                                : 0,
                            event_settings_expo_hall_details: cloneData,
                          },
                          "event_settings_details"
                        );
                      }
                    }
                  }}
                  type="button"
                  className="btn  py-1 "
                >
                  -
                </button>
                <Input
                  className="text-center"
                  style={{ width: "50%" }}
                  onChange={async (e) => {
                    const { value: inputValue } = e.target;
                    const reg = /^-?\d*(\.\d*)?$/;

                    if (isNaN(parseInt(inputValue))) {
                      onChangeData(
                        {
                          no_of_expo_halls:
                            eventEligibility?.expo_hall_number
                              ?.hall_reduce_till,
                        },
                        "event_settings_details"
                      );
                    } else if (
                      reg.test(inputValue) ||
                      inputValue === "" ||
                      inputValue === "-"
                    ) {
                      // if you are adding new files to existing number of filestates
                      if (
                        data?.event_settings_details?.no_of_expo_halls <
                        parseInt(inputValue)
                      ) {
                        let cloneData = cloneDeep(expoHallFileState);

                        for (
                          var i = cloneData?.length;
                          i < parseInt(inputValue);
                          i++
                        )
                          cloneData?.push({
                            sequence: null,
                            expo_hall_url: "",
                            expo_hall_original_filename: "",
                          });
                        setExpoHallFileState(cloneData);
                        onChangeData(
                          {
                            no_of_expo_halls: parseInt(inputValue),
                            event_settings_expo_hall_details: cloneData,
                          },
                          "event_settings_details"
                        );
                      } else {
                        let cloneData = cloneDeep(expoHallFileState);
                        // if you are adding new files and if you enter a number less than eligibility
                        if (
                          parseInt(inputValue) >
                          eventEligibility?.expo_hall_number?.hall_reduce_till
                        ) {
                          const modifiedData = await popVistaFiles(
                            inputValue,
                            cloneData
                          );
                          setExpoHallFileState(modifiedData);
                          onChangeData(
                            {
                              no_of_expo_halls: parseInt(inputValue),
                              event_settings_expo_hall_details: modifiedData,
                            },
                            "event_settings_details"
                          );
                        } else {
                          toast.error(
                            "You cannot reduce expo halls as it contains sponsors"
                          );
                          // if you are reducing the input below eligibility and vista file state array
                          if (
                            cloneData.length > parseInt(inputValue) &&
                            eventEligibility?.expo_hall_number
                              ?.hall_reduce_till >= parseInt(inputValue)
                          ) {
                            const modifiedData = await popVistaFiles(
                              inputValue,
                              cloneData
                            );
                            setExpoHallFileState(modifiedData);
                            onChangeData(
                              {
                                no_of_expo_halls:
                                  eventEligibility?.expo_hall_number
                                    ?.hall_reduce_till,
                                event_settings_expo_hall_details: modifiedData,
                              },
                              "event_settings_details"
                            );
                          } else {
                            onChangeData(
                              {
                                no_of_expo_halls:
                                  eventEligibility?.expo_hall_number
                                    ?.hall_reduce_till,
                              },
                              "event_settings_details"
                            );
                          }
                        }
                      }
                    } else {
                      // toast.error("test  2");
                    }
                  }}
                  value={
                    data &&
                    data.event_settings_details &&
                    data.event_settings_details.no_of_expo_halls
                      ? data.event_settings_details.no_of_expo_halls
                      : 0
                  }
                />
                <button
                  onClick={() => {
                    if (expochecked) {
                      let cloneData = cloneDeep(expoHallFileState);

                      cloneData?.push({
                        sequence: null,
                        expo_hall_url: "",
                        expo_hall_original_filename: "",
                      });
                      setExpoHallFileState(cloneData);

                      onChangeData(
                        {
                          no_of_expo_halls:
                            data?.event_settings_details.no_of_expo_halls + 1,
                          event_settings_expo_hall_details: cloneData,
                        },
                        "event_settings_details"
                      );
                    }
                  }}
                  type="button"
                  className="btn  py-1 "
                >
                  +
                </button>
              </div>
            </div>
            {Tablet ? (
              <></>
            ) : (
              <div className={Tablet ? "col-2" : "col-1"}></div>
            )}
            <div className={Tablet ? "col-4 text-end" : "col-2"}>
              <Button
                type="primary"
                className=" button-pink rounded btn-svg border-gray"
                icon={<CalenderGrey />}
                onClick={() => window.open("/eventCalendar", "_blank")}
              >
                <span className="mx-2">Calendar</span>
              </Button>
            </div>
            <div className={Tablet ? "col-5  my-3 " : "col-2 text-end "}>
              <label className="text-secondary">Expo Halls 360 Upload</label>
            </div>
            <div
              className={Tablet ? "col-4 text-start my-3" : "col-2 text-start"}
            >
              <div className="eventsettings_fileuload_style concourse_vista_video_upload">
                <Button
                  onClick={() =>
                    setMultipleVistaModalVisible({
                      flag: true,
                      title: "expohall",
                    })
                  }
                  disabled={
                    data?.event_settings_details?.no_of_expo_halls === 0
                  }
                >
                  <CloudUploadOutlined className="fs-20" />
                  File Upload
                </Button>
                {multipleVistaModalVisible.flag &&
                  multipleVistaModalVisible.title === "expohall" &&
                  renderMultipleVistaModal(
                    "Expo Hall",
                    data?.event_settings_details?.no_of_expo_halls,
                    "expoHall"
                  )}
              </div>
            </div>
          </div>

          <div className="row my-4 row_background">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary">Navigation Bar</label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                onChange={(value) => {
                  onHandleChange(
                    "is_navigation_bar_active",
                    value,
                    "event_settings_details"
                  );
                }}
                checked={
                  data.event_settings_details &&
                  data.event_settings_details.is_navigation_bar_active
                    ? data.event_settings_details.is_navigation_bar_active
                    : false
                }
              />
            </div>
            <div className={Tablet ? "col-4" : "col-2"}>
              <Button
                onClick={() => {
                  if (data && data.event_id) onNavigateToReception();
                  else toast.error("Please complete the first step");
                }}
                className="px-3 button-pink rounded border-gray"
              >
                Settings
              </Button>
            </div>
            <div className={Tablet ? "col-2" : "col-1"}></div>
            <div className={Tablet ? "col-4 text-end my-3" : "col-2"}></div>
            <div
              className={Tablet ? "col-4 text-end my-3" : "col-2 text-end"}
            ></div>
            <div
              className={Tablet ? "col-4 text-start my-3" : "col-2 text-start"}
            ></div>
          </div>

          <div className="row my-4">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary">Main Auditorium</label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                onChange={(value) => {
                  if (
                    !value &&
                    data &&
                    data.event_id &&
                    eventEligibility &&
                    eventEligibility.can_toggle_off_main === false
                  ) {
                    toast.error(
                      "You cannot disable main auditoriums as it contains sessions and sponsors"
                    );
                  } else {
                    onHandleChange(
                      "is_main_hall_active",
                      value,
                      "event_settings_details"
                    );
                  }
                }}
                checked={
                  data.event_settings_details &&
                  data.event_settings_details.is_main_hall_active
                    ? data.event_settings_details.is_main_hall_active
                    : false
                }
              />
            </div>
            {Tablet ? <></> : <div className="col-2"></div>}
            <div className={Tablet ? "col-3" : "col-1"}></div>
            <div className={Tablet ? "col-4 text-end my-3" : "col-2"}>
              <Button
                type="primary"
                className=" button-pink rounded btn-svg border-gray"
                icon={<CalenderGrey />}
                onClick={() => window.open("/eventCalendar", "_blank")}
              >
                <span className="mx-2">Calendar</span>
              </Button>
            </div>
            <div className={Tablet ? "col-5  my-3" : "col-2 text-end"}>
              <label className="text-secondary">
                Main Auditorium 360 Upload
              </label>
            </div>
            <div
              className={Tablet ? "col-4 text-start my-3" : "col-2 text-start"}
            >
              <div className="eventsettings_fileuload_style concourse_vista_video_upload">
                <FileUpload
                  labelClassName="rounded border-pink btn-svg  px-4 py-2"
                  labelTitle="File Upload"
                  name="mainaudi-upload"
                  inputType="file"
                  inputOnChange={(e) => handle360(e, "mainAudi")}
                  inputId="mainaudi-upload"
                  inputName="mainaudi-upload"
                  onBlur={() =>
                    validator.current.showMessageFor("mainaudi-upload")
                  }
                  value=""
                />
              </div>
              {mainAudiFileState ? (
                <>
                  <span>{mainAudiFileState.name} </span>
                  <span onClick={() => handleFileStateDelete("mainAudi")}>
                    <DeleteOutlined />
                  </span>
                </>
              ) : null}
            </div>
          </div>
          <div className="row my-4 row_background">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary">Reception</label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                onChange={(value) => {
                  onHandleChange(
                    "is_reception_active",
                    value,
                    "event_settings_details"
                  );
                }}
                checked={
                  data.event_settings_details &&
                  data.event_settings_details.is_reception_active
                    ? data.event_settings_details.is_reception_active
                    : false
                }
              />
            </div>
            <div className={Tablet ? "col-4" : "col-2"}>
              <Button
                onClick={() => {
                  if (data && data.event_id) onNavigateToReception();
                  else toast.error("Please complete the first step");
                }}
                className="px-3 button-pink rounded border-gray"
              >
                Settings
              </Button>
            </div>
            <div className={Tablet ? "col-2" : "col-1"}></div>
            <div className={Tablet ? "col-4 text-end my-3" : "col-2"}></div>
            <div
              className={Tablet ? "col-4 text-end my-3" : "col-2 text-end"}
            ></div>
            <div
              className={Tablet ? "col-4 text-start my-3" : "col-2 text-start"}
            ></div>
          </div>

          <div className="row my-4">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary">Sat. Auditoriums</label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                defaultChecked={satAuditoriumChecked}
                onChange={(value) => {
                  if (
                    !value &&
                    data &&
                    data.event_id &&
                    eventEligibility &&
                    eventEligibility.satellite_auditorium_number &&
                    eventEligibility.satellite_auditorium_number
                      .auditorium_reduce_till > 0
                  ) {
                    toast.error(
                      "You cannot disable satellite auditoriums as it contains sessions and sponsors"
                    );
                  } else {
                    onChangeData(
                      {
                        is_sat_auditorium_active: value,
                        no_of_sat_auditorium: 0,
                      },
                      "event_settings_details"
                    );
                    setSatAuditoriumChecked(value);
                  }
                }}
                checked={satAuditoriumChecked}
              />
            </div>
            <div className={Tablet ? "col-3" : "col-2"}>
              <div
                className={`${
                  Tablet ? "event_settings_increment_width" : ""
                } btn-group mx-auto border-gray event-editor-increment-btn rounded`}
                role="group"
                aria-label="Second group"
              >
                <button
                  onClick={() => {
                    if (satAuditoriumChecked) {
                      if (
                        data &&
                        data.event_id &&
                        eventEligibility &&
                        eventEligibility.satellite_auditorium_number
                      ) {
                        if (
                          data?.event_settings_details?.no_of_sat_auditorium >
                          eventEligibility.satellite_auditorium_number
                            .auditorium_reduce_till
                        ) {
                          let cloneData = cloneDeep(satAudiFileState);
                          cloneData?.pop();
                          setSatAudiFileState(cloneData);

                          onChangeData(
                            {
                              no_of_sat_auditorium:
                                data?.event_settings_details
                                  ?.no_of_sat_auditorium > 0
                                  ? data?.event_settings_details
                                      ?.no_of_sat_auditorium - 1
                                  : 0,
                              event_settings_satellite_auditorium_details:
                                cloneData,
                            },
                            "event_settings_details"
                          );
                        } else if (
                          data?.event_settings_details?.no_of_sat_auditorium !==
                          0
                        )
                          toast.error(
                            "You cannot reduce satellite auditoriums as it contains sessions and sponsors"
                          );
                      } else {
                        let cloneData = cloneDeep(satAudiFileState);
                        cloneData?.pop();
                        setSatAudiFileState(cloneData);
                        onChangeData(
                          {
                            no_of_sat_auditorium:
                              data?.event_settings_details
                                ?.no_of_sat_auditorium > 0
                                ? data?.event_settings_details
                                    ?.no_of_sat_auditorium - 1
                                : 0,
                            event_settings_satellite_auditorium_details:
                              cloneData,
                          },
                          "event_settings_details"
                        );
                      }
                    }
                  }}
                  type="button"
                  className="btn  py-1 "
                >
                  -
                </button>
                <Input
                  className="text-center"
                  style={{ width: "50%" }}
                  onChange={async (e) => {
                    const { value: inputValue } = e.target;
                    const reg = /^-?\d*(\.\d*)?$/;

                    if (isNaN(parseInt(inputValue))) {
                      onChangeData(
                        {
                          no_of_sat_auditorium:
                            eventEligibility?.satellite_auditorium_number
                              ?.auditorium_reduce_till,
                        },
                        "event_settings_details"
                      );
                    } else if (
                      reg.test(inputValue) ||
                      inputValue === "" ||
                      inputValue === "-"
                    ) {
                      // if you are adding new files to existing number of filestates
                      if (
                        data?.event_settings_details?.no_of_sat_auditorium <
                        parseInt(inputValue)
                      ) {
                        let cloneData = cloneDeep(satAudiFileState);

                        for (
                          var i = cloneData?.length;
                          i < parseInt(inputValue);
                          i++
                        )
                          cloneData?.push({
                            sequence: null,
                            networking_url: "",
                            networking_original_filename: "",
                          });
                        setSatAudiFileState(cloneData);
                        onChangeData(
                          {
                            no_of_sat_auditorium: parseInt(inputValue),
                            event_settings_satellite_auditorium_details:
                              cloneData,
                          },
                          "event_settings_details"
                        );
                      } else {
                        let cloneData = cloneDeep(satAudiFileState);
                        // if you are adding new files and if you enter a number less than eligibility
                        if (
                          parseInt(inputValue) >
                          eventEligibility?.satellite_auditorium_number
                            ?.auditorium_reduce_till
                        ) {
                          const modifiedData = await popVistaFiles(
                            inputValue,
                            cloneData
                          );
                          setSatAudiFileState(modifiedData);
                          onChangeData(
                            {
                              no_of_sat_auditorium: parseInt(inputValue),
                              event_settings_satellite_auditorium_details:
                                modifiedData,
                            },
                            "event_settings_details"
                          );
                        } else {
                          toast.error(
                            "You cannot reduce satellite Audi as it contains sponsors"
                          );
                          // if you are reducing the input below eligibility and vista file state array
                          if (
                            cloneData.length > parseInt(inputValue) &&
                            eventEligibility?.satellite_auditorium_number
                              ?.auditorium_reduce_till >= parseInt(inputValue)
                          ) {
                            const modifiedData = await popVistaFiles(
                              inputValue,
                              cloneData
                            );
                            setSatAudiFileState(modifiedData);
                            onChangeData(
                              {
                                no_of_sat_auditorium:
                                  eventEligibility?.satellite_auditorium_number
                                    ?.auditorium_reduce_till,
                                event_settings_satellite_auditorium_details:
                                  modifiedData,
                              },
                              "event_settings_details"
                            );
                          } else {
                            onChangeData(
                              {
                                no_of_sat_auditorium:
                                  eventEligibility?.satellite_auditorium_number
                                    ?.auditorium_reduce_till,
                              },
                              "event_settings_details"
                            );
                          }
                        }
                      }
                    } else {
                      // toast.error("test  2");
                    }
                  }}
                  value={
                    data &&
                    data.event_settings_details &&
                    data.event_settings_details.no_of_sat_auditorium
                      ? data.event_settings_details.no_of_sat_auditorium
                      : 0
                  }
                />
                <button
                  onClick={() => {
                    if (satAuditoriumChecked) {
                      let cloneData = cloneDeep(satAudiFileState);

                      cloneData?.push({
                        sequence: null,
                        sat_audi_hall_url: "",
                        sat_audi_original_filename: "",
                      });
                      setSatAudiFileState(cloneData);
                      onChangeData(
                        {
                          no_of_sat_auditorium:
                            data?.event_settings_details?.no_of_sat_auditorium +
                            1,
                          event_settings_satellite_auditorium_details:
                            cloneData,
                        },
                        "event_settings_details"
                      );
                    }
                  }}
                  type="button"
                  className="btn  py-1 "
                >
                  +
                </button>
              </div>
            </div>
            {Tablet ? "" : <div className="col-1"></div>}
            <div className={Tablet ? "col-4 text-end " : "col-2"}>
              <Button
                type="primary"
                className=" button-pink rounded btn-svg border-gray"
                icon={<CalenderGrey />}
                onClick={() => window.open("/eventCalendar", "_blank")}
              >
                <span className="mx-2">Calendar</span>
              </Button>
            </div>
            <div className={Tablet ? "col-5  my-3" : "col-2 text-end"}>
              <label className="text-secondary">
                Sat Auditoriums 360 Upload
              </label>
            </div>
            <div
              className={Tablet ? "col-4 text-start my-3" : "col-2 text-start"}
            >
              <div className="eventsettings_fileuload_style concourse_vista_video_upload">
                <Button
                  onClick={() =>
                    setMultipleVistaModalVisible({
                      flag: true,
                      title: "satAudi",
                    })
                  }
                  disabled={
                    data?.event_settings_details?.no_of_sat_auditorium === 0
                  }
                >
                  <CloudUploadOutlined className="fs-20" />
                  File Upload
                </Button>
                {multipleVistaModalVisible.flag &&
                  multipleVistaModalVisible.title === "satAudi" &&
                  renderMultipleVistaModal(
                    "Sat Audi",
                    data?.event_settings_details?.no_of_sat_auditorium,
                    "satAudi"
                  )}
              </div>
            </div>
          </div>

          <div className="row my-4 row_background">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary">Social Wall</label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                onChange={(value) => {
                  onHandleChange(
                    "is_social_wall_active",
                    value,
                    "event_settings_details"
                  );
                }}
                checked={
                  data.event_settings_details &&
                  data.event_settings_details.is_social_wall_active
                    ? data.event_settings_details.is_social_wall_active
                    : false
                }
              />
            </div>
            <div className={Tablet ? "col-2" : "col-2"}>
              <Button
                onClick={() => {
                  if (data && data.event_id) onNavigateToSocialWall();
                  else toast.error("Please complete the first step");
                }}
                className="px-3 button-pink rounded border-gray"
              >
                Settings
              </Button>
            </div>
            <div className={Tablet ? "col-1" : "col-1"}></div>
            <div className={Tablet ? "col-4 text-end" : "col-2"}>
              <Button
                type="primary"
                className="button-pink rounded btn-svg border-gray"
                icon={<CalenderGrey />}
                onClick={() => window.open("/eventCalendar", "_blank")}
              >
                <span className="mx-2">Sponsor Calendar</span>
              </Button>
            </div>
            <div
              className={Tablet ? "col-4 text-end my-3" : "col-2 text-end"}
            ></div>
            <div
              className={Tablet ? "col-4 text-start my-3" : "col-2 text-start"}
            ></div>
          </div>

          <div className="row my-4">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary">Networking</label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                defaultChecked={networkingChecked}
                onChange={(value) => {
                  if (
                    !value &&
                    data &&
                    data.event_id &&
                    eventEligibility &&
                    eventEligibility.networking_lounge_number &&
                    eventEligibility.networking_lounge_number
                      .lounge_reduce_till > 0
                  ) {
                    toast.error(
                      "You cannot disable network lounges as it contains sessions and sponsors"
                    );
                  } else {
                    onChangeData(
                      { is_networking_active: value, no_of_networking: 0 },
                      "event_settings_details"
                    );
                    setNetworkingChecked(value);
                  }
                }}
                checked={networkingChecked}
              />
            </div>
            <div className={Tablet ? "col-3" : "col-2"}>
              <div
                className={`${
                  Tablet ? "event_settings_increment_width" : ""
                } btn-group mx-auto border-gray event-editor-increment-btn rounded`}
                role="group"
                aria-label="Second group"
              >
                <button
                  onClick={() => {
                    if (networkingChecked) {
                      if (
                        data &&
                        data.event_id &&
                        eventEligibility &&
                        eventEligibility.networking_lounge_number
                      ) {
                        if (
                          data?.event_settings_details?.no_of_networking >
                          eventEligibility.networking_lounge_number
                            .lounge_reduce_till
                        ) {
                          let cloneData = cloneDeep(netLoungeFileState);

                          cloneData?.pop();
                          setNetLoungeFileState(cloneData);
                          onChangeData(
                            {
                              no_of_networking:
                                data?.event_settings_details?.no_of_networking >
                                0
                                  ? data?.event_settings_details
                                      ?.no_of_networking - 1
                                  : 0,
                              event_settings_networking_lounge_details:
                                cloneData,
                            },
                            "event_settings_details"
                          );
                        } else if (
                          data?.event_settings_details?.no_of_networking !== 0
                        )
                          toast.error(
                            "You cannot reduce network lounges as it contains sessions and sponsors"
                          );
                      } else {
                        let cloneData = cloneDeep(netLoungeFileState);
                        cloneData?.pop();
                        setNetLoungeFileState(cloneData);

                        onChangeData(
                          {
                            no_of_networking:
                              data?.event_settings_details?.no_of_networking > 0
                                ? data?.event_settings_details
                                    ?.no_of_networking - 1
                                : 0,
                            event_settings_networking_lounge_details: cloneData,
                          },
                          "event_settings_details"
                        );
                      }
                    }
                  }}
                  type="button"
                  className="btn  py-1 "
                >
                  -
                </button>
                <Input
                  className="text-center"
                  style={{ width: "50%" }}
                  onChange={async (e) => {
                    const { value: inputValue } = e.target;
                    const reg = /^-?\d*(\.\d*)?$/;

                    if (isNaN(parseInt(inputValue))) {
                      onChangeData(
                        {
                          no_of_networking:
                            eventEligibility?.networking_lounge_number
                              ?.lounge_reduce_till,
                        },
                        "event_settings_details"
                      );
                    } else if (
                      reg.test(inputValue) ||
                      inputValue === "" ||
                      inputValue === "-"
                    ) {
                      // if you are adding new files to existing number of filestates
                      if (
                        data?.event_settings_details?.no_of_networking <
                        parseInt(inputValue)
                      ) {
                        let cloneData = cloneDeep(netLoungeFileState);

                        for (
                          var i = cloneData?.length;
                          i < parseInt(inputValue);
                          i++
                        )
                          cloneData?.push({
                            sequence: null,
                            networking_url: "",
                            networking_original_filename: "",
                          });
                        setNetLoungeFileState(cloneData);
                        onChangeData(
                          {
                            no_of_networking: parseInt(inputValue),
                            event_settings_networking_lounge_details: cloneData,
                          },
                          "event_settings_details"
                        );
                      } else {
                        let cloneData = cloneDeep(expoHallFileState);
                        // if you are adding new files and if you enter a number less than eligibility
                        if (
                          parseInt(inputValue) >
                          eventEligibility?.networking_lounge_number
                            ?.lounge_reduce_till
                        ) {
                          const modifiedData = await popVistaFiles(
                            inputValue,
                            cloneData
                          );
                          setNetLoungeFileState(modifiedData);
                          onChangeData(
                            {
                              no_of_networking: parseInt(inputValue),
                              event_settings_networking_lounge_details:
                                modifiedData,
                            },
                            "event_settings_details"
                          );
                        } else {
                          toast.error(
                            "You cannot reduce expo halls as it contains sponsors"
                          );
                          // if you are reducing the input below eligibility and vista file state array
                          if (
                            cloneData.length > parseInt(inputValue) &&
                            eventEligibility?.networking_lounge_number
                              ?.lounge_reduce_till >= parseInt(inputValue)
                          ) {
                            const modifiedData = await popVistaFiles(
                              inputValue,
                              cloneData
                            );
                            setNetLoungeFileState(modifiedData);
                            onChangeData(
                              {
                                no_of_networking:
                                  eventEligibility?.networking_lounge_number
                                    ?.lounge_reduce_till,
                                event_settings_networking_lounge_details:
                                  modifiedData,
                              },
                              "event_settings_details"
                            );
                          } else {
                            onChangeData(
                              {
                                no_of_networking:
                                  eventEligibility?.networking_lounge_number
                                    ?.lounge_reduce_till,
                              },
                              "event_settings_details"
                            );
                          }
                        }
                      }
                    } else {
                      // toast.error("test  2");
                    }
                  }}
                  value={
                    data &&
                    data.event_settings_details &&
                    data.event_settings_details.no_of_networking
                      ? data.event_settings_details.no_of_networking
                      : 0
                  }
                />
                <button
                  onClick={() => {
                    if (networkingChecked) {
                      let cloneData = cloneDeep(netLoungeFileState);
                      cloneData?.push({
                        sequence: null,
                        networking_url: "",
                        networking_original_filename: "",
                      });
                      setNetLoungeFileState(cloneData);
                      onChangeData(
                        {
                          no_of_networking:
                            data?.event_settings_details?.no_of_networking + 1,
                          event_settings_networking_lounge_details: cloneData,
                        },
                        "event_settings_details"
                      );
                    }
                  }}
                  type="button"
                  className="btn  py-1 "
                >
                  +
                </button>
              </div>
            </div>
            {Tablet ? <></> : <div className="col-1"></div>}

            <div className={Tablet ? "col-4 text-end " : "col-2"}>
              <Button
                type="primary"
                className=" button-pink rounded btn-svg border-gray"
                icon={<CalenderGrey />}
                onClick={() => window.open("/eventCalendar", "_blank")}
              >
                <span className="mx-2">Calendar</span>
              </Button>
            </div>
            <div className={Tablet ? "col-5  my-3" : "col-2 text-end"}>
              <label className="text-secondary">Networking 360 Upload</label>
            </div>
            <div
              className={Tablet ? "col-4 text-start my-3" : "col-2 text-start"}
            >
              <div className="eventsettings_fileuload_style concourse_vista_video_upload">
                <Button
                  onClick={() =>
                    setMultipleVistaModalVisible({
                      flag: true,
                      title: "netLounge",
                    })
                  }
                  disabled={
                    data?.event_settings_details?.no_of_networking === 0
                  }
                >
                  <CloudUploadOutlined className="fs-20" />
                  File Upload
                </Button>
                {multipleVistaModalVisible.flag &&
                  multipleVistaModalVisible.title === "netLounge" &&
                  renderMultipleVistaModal(
                    "Net Lounge",
                    data?.event_settings_details?.no_of_networking,
                    "netLounge"
                  )}
              </div>
            </div>
          </div>

          <div className="row my-4 row_background">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary">Concourse</label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                onChange={(value) => {
                  onHandleChange(
                    "is_concourse_active",
                    value,
                    "event_settings_details"
                  );
                }}
                checked={
                  data.event_settings_details &&
                  data.event_settings_details.is_concourse_active
                    ? data.event_settings_details.is_concourse_active
                    : false
                }
              />
            </div>
            {Tablet ? <></> : <div className="col-2"></div>}

            <div className={Tablet ? "col-3" : "col-1"}></div>
            <div className={Tablet ? "col-4 text-end " : "col-2"}>
              <Button
                type="primary"
                className="button-pink rounded btn-svg border-gray"
                icon={<CalenderGrey />}
                onClick={() => window.open("/eventCalendar", "_blank")}
              >
                <span className="mx-2">Sponsor Calendar</span>
              </Button>
            </div>
            <div
              className={Tablet ? "col-5 text-end my-3" : "col-2 text-end"}
            ></div>
            <div
              className={Tablet ? "col-4 text-start my-3" : "col-2 text-start"}
            >
              <div className="eventsettings_fileuload_style concourse_vista_video_upload">
                <FileUpload
                  labelClassName="rounded border-pink btn-svg  px-4 py-2"
                  labelTitle="File Upload"
                  name="concourse-upload"
                  inputType="file"
                  inputOnChange={(e) => handle360(e, "concourse")}
                  inputId="concourse-upload"
                  inputName="concourse-upload"
                  onBlur={() =>
                    validator.current.showMessageFor("concourse-upload")
                  }
                  value=""
                />
              </div>
              {concourseFileState ? (
                <>
                  <span className="mx-3"> {concourseFileState.name} </span>
                  <span onClick={() => handleFileStateDelete("concourse")}>
                    <DeleteOutlined />
                  </span>
                </>
              ) : null}
            </div>
          </div>

          <div className="row my-4">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary">Exterior</label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                onChange={(value) => {
                  onHandleChange(
                    "is_exterior_active",
                    value,
                    "event_settings_details"
                  );
                }}
                checked={
                  data.event_settings_details &&
                  data.event_settings_details.is_exterior_active
                    ? data.event_settings_details.is_exterior_active
                    : false
                }
              />
            </div>
            <div className={Tablet ? "col-4" : "col-2"}></div>
            <div className={Tablet ? "col-2" : "col-1"}></div>
            <div className={Tablet ? "col-1 text-end my-3" : "col-2"}></div>
            {data.event_settings_details.is_exterior_active ? (
              <div
                className={Tablet ? "col-5 text-end my-3" : "col-2 text-end"}
              ></div>
            ) : null}
            <div
              className={
                Tablet
                  ? "col-4 text-start my-3"
                  : !data.event_settings_details.is_exterior_active
                  ? "col-4 text-start"
                  : "col-2 text-start"
              }
            >
              {data.event_settings_details.is_exterior_active ? (
                <>
                  <div className="eventsettings_fileuload_style concourse_vista_video_upload">
                    <FileUpload
                      labelClassName="rounded border-pink btn-svg  px-4 py-2"
                      labelTitle="File Upload"
                      name="exterior-upload"
                      inputType="file"
                      inputOnChange={(e) => handle360(e, "exterior")}
                      inputId="exterior-upload"
                      inputName="exterior-upload"
                      onBlur={() =>
                        validator.current.showMessageFor("exterior-upload")
                      }
                      value=""
                    />
                  </div>
                  {exteriorFileState ? (
                    <>
                      <span className="mx-3">{exteriorFileState.name}</span>
                      <span onClick={() => handleFileStateDelete("exterior")}>
                        <DeleteOutlined />
                      </span>
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  <label className="text-secondary mx-1 pt-1">
                    Login background theme
                  </label>
                  <Input
                    type="color"
                    id=""
                    value={data.event_settings_details.login_background_theme}
                    addonBefore={
                      data.event_settings_details.login_background_theme
                    }
                    onChange={(e) => {
                      onHandleChange(
                        "login_background_theme",
                        e.target.value,
                        "event_settings_details"
                      );
                    }}
                    className="login_background_theme_input border-gray rounded fs-bold-14"
                  />
                </>
              )}
            </div>
          </div>

          <div className="row my-4 row_background">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary">Sponsor Banner</label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                onChange={(value) => {
                  onHandleChange(
                    "is_sponsor_banner_active",
                    value,
                    "event_settings_details"
                  );
                }}
                checked={
                  data.event_settings_details &&
                  data.event_settings_details.is_sponsor_banner_active
                    ? data.event_settings_details.is_sponsor_banner_active
                    : false
                }
              />
            </div>
            <div className={Tablet ? "col-5" : "col-2"}>
              <div className="concourse_vista_video_upload">
                <FileUpload
                  labelClassName="rounded border-pink btn-svg  px-4 py-2"
                  labelTitle="Banner Upload"
                  name="sponsor-banner"
                  inputType="file"
                  inputOnChange={handleSponsorBannerChange}
                  inputId="sponsor-banner"
                  inputName="sponsor-banner"
                  onBlur={() =>
                    validator.current.showMessageFor("sponsor-banner")
                  }
                  value=""
                />
              </div>
              {sponsorBanner ? (
                <>
                  <div className="d-flex flex-row">
                    <span className="d-flex">
                      <Tooltip
                        placement="bottomLeft"
                        title={sponsorBanner?.name}
                      >
                        <span className="uploaded_file_name_event_setting mx-3">
                          {sponsorBanner?.name}
                        </span>
                      </Tooltip>
                    </span>

                    <span
                      onClick={() => {
                        handleBoothFilesDelete("sponsorBanner");
                      }}
                    >
                      <DeleteOutlined />
                    </span>
                  </div>
                  {(sponsorBannerThumbNail ||
                    sponsorBanner.sponsor_banner_url) &&
                  (isImage(sponsorBannerThumbNail) ||
                    isImage(sponsorBanner.sponsor_banner_url)) ? (
                    <img
                      src={
                        sponsorBannerThumbNail
                          ? sponsorBannerThumbNail
                          : sponsorBanner.sponsor_banner_url
                      }
                      alt="Sponsor Banner File"
                      className="user-img-preview"
                    />
                  ) : null}
                </>
              ) : null}
            </div>
            <div className={Tablet ? "col-2" : "col-1"}>
              <Tooltip placement="right" title={"Aspect ratio should be 16:9"}>
                <InfoCircleOutlined style={{ fontSize: "20px" }} />
              </Tooltip>
            </div>
            <div className={Tablet ? "col-4 text-end my-3" : "col-2"}></div>
            <div
              className={Tablet ? "col-4 text-end my-3" : "col-2 text-end"}
            ></div>
            <div
              className={Tablet ? "col-4 text-start my-3" : "col-2 text-start"}
            ></div>
          </div>
          <div className="row my-4">
            <div className={Tablet ? "col-3 text-center" : "col-2 text-end"}>
              <label className="text-secondary">Sponsor Booth</label>
            </div>
            <div className={Tablet ? "col-2 " : "col-1"}>
              <Switch
                onChange={(value) => {
                  onHandleChange(
                    "is_sponsor_booth_active",
                    value,
                    "event_settings_details"
                  );
                }}
                checked={
                  data.event_settings_details &&
                  data.event_settings_details.is_sponsor_booth_active
                    ? data.event_settings_details.is_sponsor_booth_active
                    : false
                }
              />
            </div>
            <div className={Tablet ? "col-5" : "col-2"}>
              <div className="concourse_vista_video_upload">
                <FileUpload
                  labelClassName="rounded border-pink btn-svg  px-4 py-2"
                  labelTitle="Left Upload"
                  name="booth-left"
                  inputType="file"
                  inputOnChange={handleBoothLeftChange}
                  inputId="booth-left"
                  inputName="booth-left"
                  onBlur={() => validator.current.showMessageFor("booth-left")}
                  value=""
                />
              </div>
              {sponsorBoothLeft ? (
                <>
                  <div className="d-flex flex-row">
                    <span className="d-flex">
                      {/* {sponsorBoothLeft?.name} */}
                      <Tooltip
                        placement="bottomLeft"
                        title={sponsorBoothLeft?.name}
                      >
                        <span className="uploaded_file_name_event_setting mx-3">
                          {sponsorBoothLeft?.name}
                        </span>
                      </Tooltip>
                    </span>
                    <span
                      onClick={() => {
                        handleBoothFilesDelete("sponsorBoothLeft");
                      }}
                    >
                      <DeleteOutlined />
                    </span>
                  </div>
                  {(sponsorBoothLeftThumbNail ||
                    sponsorBoothLeft.sponsor_booth_left_url) &&
                  (isImage(sponsorBoothLeftThumbNail) ||
                    isImage(sponsorBoothLeft.sponsor_booth_left_url)) ? (
                    <img
                      src={
                        sponsorBoothLeftThumbNail
                          ? sponsorBoothLeftThumbNail
                          : sponsorBoothLeft.sponsor_booth_left_url
                      }
                      alt="Sponsor Banner File"
                      className="user-img-preview"
                    />
                  ) : null}
                </>
              ) : null}
            </div>
            <div className={Tablet ? "col-1" : "col-1"}>
              <Tooltip placement="right" title={"Aspect ratio should be 9:16"}>
                <InfoCircleOutlined style={{ fontSize: "20px" }} />
              </Tooltip>
            </div>
            <div
              style={{
                paddingLeft: Tablet ? "44%" : "0px",
              }}
              className={Tablet ? "col-10  my-3" : "col-2"}
            >
              <div className="concourse_vista_video_upload">
                <FileUpload
                  labelClassName="rounded border-pink btn-svg  px-4 py-2"
                  labelTitle="Center Upload"
                  name="booth-center"
                  inputType="file"
                  inputOnChange={handleBoothCenterChange}
                  inputId="booth-center"
                  inputName="booth-center"
                  onBlur={() =>
                    validator.current.showMessageFor("booth-center")
                  }
                  value=""
                />
              </div>
              {sponsorBoothCenter ? (
                <>
                  <div className="d-flex flex-row">
                    <span className="d-flex">
                      {/* {sponsorBoothCenter?.name} */}
                      <Tooltip
                        placement="bottomLeft"
                        title={sponsorBoothCenter?.name}
                      >
                        <span className="uploaded_file_name_event_setting mx-3">
                          {sponsorBoothCenter?.name}
                        </span>
                      </Tooltip>
                    </span>
                    <span
                      onClick={() => {
                        handleBoothFilesDelete("sponsorBoothCenter");
                      }}
                    >
                      <DeleteOutlined />
                    </span>
                  </div>

                  {(sponsorBoothCenterThumbNail ||
                    sponsorBoothCenter.sponsor_booth_center_url) &&
                  (isImage(sponsorBoothCenterThumbNail) ||
                    isImage(sponsorBoothCenter.sponsor_booth_center_url)) ? (
                    <img
                      src={
                        sponsorBoothCenterThumbNail
                          ? sponsorBoothCenterThumbNail
                          : sponsorBoothCenter.sponsor_booth_center_url
                      }
                      alt="Sponsor Booth Center File"
                      className="user-img-preview"
                    />
                  ) : null}
                </>
              ) : null}
            </div>
            <div className={Tablet ? "col-1" : "col-1"}>
              <Tooltip placement="right" title={"Aspect ratio should be 16:9"}>
                <InfoCircleOutlined style={{ fontSize: "20px" }} />
              </Tooltip>
            </div>
            <div
              style={{
                paddingLeft: Tablet ? "44%" : "0px",
              }}
              className={Tablet ? "col-10  my-3" : "col-2"}
            >
              <div className="concourse_vista_video_upload">
                <FileUpload
                  labelClassName="rounded border-pink btn-svg px-4 py-2"
                  labelTitle="Right Upload"
                  name="booth-right"
                  inputType="file"
                  inputOnChange={handleBoothRightChange}
                  inputId="booth-right"
                  inputName="booth-right"
                  onBlur={() => validator.current.showMessageFor("booth-right")}
                  value=""
                />
              </div>
              {sponsorBoothRight ? (
                <>
                  <div className="d-flex flex-row">
                    <span className="d-flex">
                      {/* {sponsorBoothRight.name} */}
                      <Tooltip
                        placement="bottomLeft"
                        title={sponsorBoothRight?.name}
                      >
                        <span className="uploaded_file_name_event_setting mx-3">
                          {sponsorBoothRight?.name}
                        </span>
                      </Tooltip>
                    </span>
                    <span
                      onClick={() => {
                        handleBoothFilesDelete("sponsorBoothRight");
                      }}
                    >
                      <DeleteOutlined />
                    </span>
                  </div>
                  {(sponsorBoothRightThumbNail ||
                    sponsorBoothRight.sponsor_booth_right_url) &&
                  (isImage(sponsorBoothRightThumbNail) ||
                    isImage(sponsorBoothRight.sponsor_booth_right_url)) ? (
                    <img
                      src={
                        sponsorBoothRightThumbNail
                          ? sponsorBoothRightThumbNail
                          : sponsorBoothRight.sponsor_booth_right_url
                      }
                      alt="Sponsor Right Booth File"
                      className="user-img-preview"
                    />
                  ) : null}
                </>
              ) : null}
            </div>
            <div
              className={Tablet ? "col-2 text-start my-3" : "col-1 text-start"}
            >
              <Tooltip placement="right" title={"Aspect ratio should be 9:16"}>
                <InfoCircleOutlined style={{ fontSize: "20px" }} />
              </Tooltip>
            </div>
          </div>

          {/* temp start */}
        </section>
      </div>
    );
  } else return null;
};

export default EventSettings;
