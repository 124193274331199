import React, { useState, useEffect } from "react";
import { Table, Button, Popover, Breadcrumb, Input, Tooltip } from "antd";
import {
  MoreOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import "./index.css";
import { logInLogo } from "../../components/Images/Images";
import { Link } from "react-router-dom";
import {
  getCustomer,
  changeStatus,
} from "../../redux/slice/customerManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  createTZString,
  isRouteExistInMenu,
} from "../../utils/commonFunctions";
import { PAGINATION_DEFAULT_SETTINGS } from "../../components/constsnts/common.constants";
import { isEmpty } from "lodash";
import CommonLoader from "../../components/Widgets/CommonLoader";
import moment from "moment";

const CustomerManagement = () => {
  const dispatch = useDispatch();
  var CryptoJS = require("crypto-js");
  const customerSelector = useSelector(
    (state) => state.customerManagement.data
  );
  let loading = useSelector((state) => state.customerManagement.loading);
  const [searchData, setsearchData] = useState("");
  const [page_no, set_page_no] = useState(PAGINATION_DEFAULT_SETTINGS.page_no);
  const [no_of_records, set_no_of_records] = useState(
    PAGINATION_DEFAULT_SETTINGS.no_of_records
  );

  useEffect(async () => {
    let is_route_present = await isRouteExistInMenu(CryptoJS);
    if (is_route_present === false) {
      window.location.href = "/pagenotfound";
    } else {
      window.scrollTo(0, 0);
      dispatch(getCustomer({ page_no, no_of_records }));
    }
  }, [CryptoJS.AES]);

  const tableData =
    customerSelector &&
    !isEmpty(customerSelector) &&
    customerSelector.rows &&
    customerSelector.rows.map((elem) => {
      const start_date_time = createTZString(elem.license_period_start_date);
      const end_date_time = createTZString(elem.license_period_end_date);
      const eleStartDate = moment(
        start_date_time.format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      );

      const eleEndDate = moment(
        end_date_time.format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      );

      const start_date_time_createdAt = createTZString(elem.created_at);

      const eleStartDateCreatedAt = moment(
        start_date_time_createdAt.format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      );

      return {
        customer_id: elem.customer_id,
        created_at: eleStartDateCreatedAt.format("DD-MM-YYYY"),
        customername: elem.customer_name,
        maincontentname: elem.main_contact_person,
        emailaddress: elem.main_contact_email,
        is_active: elem.is_active,
        can_edit_status: elem?.can_edit_status,
        start: eleStartDate.format("DD-MM-YYYY"),
        end: eleEndDate.format("DD-MM-YYYY"),
        event_count:
          elem?.customer_customer_settings_id?.total_events_count +
          "/" +
          elem?.customer_customer_settings_id?.max_no_of_events,
        brands_count:
          elem?.customer_customer_settings_id?.total_brands_count +
          "/" +
          elem?.customer_customer_settings_id?.max_no_of_exhibitors,
        users_count:
          elem?.customer_customer_settings_id?.total_users_count +
          "/" +
          elem?.customer_customer_settings_id?.max_no_of_users,
        created_by: elem?.created_user_name,
        storage:
          elem.customer_customer_settings_id.total_max_storage_size +
            "/" +
            elem.customer_customer_settings_id.max_storage_size +
            " gb" ?? "NA",
        streaming:
          elem.customer_customer_settings_id.total_max_bandwidth_per_month +
            "/" +
            elem.customer_customer_settings_id.max_bandwidth_per_month +
            " gb" ?? "NA",
        video_hours:
          elem.customer_customer_settings_id
            .total_max_video_room_hours_per_month +
            "/" +
            elem.customer_customer_settings_id.max_video_room_hours_per_month +
            " hours" ?? "NA",
      };
    });

  const handleTableChange = (pagination, filters, sorter) => {
    let sortObj = {
      page_no: pagination?.current ?? page_no,
      no_of_records,
    };
    if (
      pagination &&
      pagination.pageSize &&
      no_of_records !== pagination.pageSize
    ) {
      set_page_no(1);
      set_no_of_records(pagination.pageSize);
      sortObj = {
        page_no: 1,
        no_of_records: pagination.pageSize,
      };
    }
    sortObj = {
      ...sortObj,
      sort_type: sorter.columnKey,
      sort_by: sorter.order === "ascend" ? "ASC" : "DSC",
      search: searchData,
    };
    dispatch(getCustomer(sortObj));
  };

  const handleInputSearch = (e) => {
    setsearchData(e.target.value);
    const searchObj = {
      search: e.target.value,
      page_no,
      no_of_records,
    };
    dispatch(getCustomer(searchObj));
  };

  const handleStatus = (id, bool) => {
    dispatch(changeStatus({ customer_id: id, is_active: !bool }));
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "customer_id",
      key: "customer_id",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 70,
    },
    {
      title: "Name",
      dataIndex: "customername",
      key: "customername",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "Start",
      dataIndex: "start",
      key: "start",
      width: 100,
    },
    {
      title: "End",
      dataIndex: "end",
      key: "end",
      width: 110,
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      width: 130,
      render: (text, record) => (
        <button
          className={
            record.is_active
              ? "bg-button-green active_btns m-0 p-0"
              : "bg-button-red m-0 p-0"
          }
          shape="round"
          onClick={() => {
            if (record?.can_edit_status || record?.is_active === true)
              handleStatus(record.customer_id, record.is_active);
          }}
        >
          {record.is_active ? "Active" : "Inactive"}
        </button>
      ),
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true,
    },
    {
      title: "#Events",
      dataIndex: "event_count",
      key: "event_count",
      width: 90,
    },
    {
      title: "#Brands",
      dataIndex: "brands_count",
      key: "brands_count",
      width: 90,
    },
    {
      title: "#Users",
      dataIndex: "users_count",
      key: "users_count",
      width: 90,
    },
    {
      title: "Storage",
      dataIndex: "storage",
      key: "storage",
      width: 100,
    },
    {
      title: "Streaming",
      dataIndex: "streaming",
      key: "storage",
      width: 100,
    },
    {
      title: "Video Hours",
      dataIndex: "video_hours",
      key: "video_hours",
      width: 120,
    },
    {
      title: "Contact",
      dataIndex: "maincontentname",
      key: "maincontentname",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 130,
    },

    {
      title: "",
      dataIndex: "operation",
      fixed: "right",
      width: 40,

      render: (_, record) => (
        <Popover
          placement="bottomLeft"
          content={
            <div className="">
              <p className="options">
                <Link
                  className="options"
                  to={`viewcustomerdetails/${record.customer_id}`}
                >
                  <EyeOutlined className=" px-1" />
                  <span className="">View Details</span>
                </Link>
              </p>
              <Link to={`editcustomer/${record.customer_id}`}>
                <p className="options">
                  <EditOutlined className=" px-1" />
                  <span className="">Edit Profile</span>
                </p>
              </Link>
            </div>
          }
          trigger="click"
        >
          <div style={{ textAlign: "center" }}>
            <MoreOutlined />
          </div>
        </Popover>
      ),
    },
    {
      title: "Action",
      width: 65,
      fixed: "right",
      render: (record) => (
        <div className="px-1 mt-1">
          <Link to={`editcustomer/${record.customer_id}`}>
            <p className="options">
              <EditOutlined className=" px-1" />
            </p>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center listing_upper_div">
        <div>
          <h4 className="fs-24">Customers</h4>
          <Breadcrumb className="my-2" separator=">">
            <Breadcrumb.Item>Admin Console</Breadcrumb.Item>
            <Breadcrumb.Item>Customers</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div>
          <Link to="createnewaccount">
            <Button className="button-pink rounded px-3 py-1 mt-1 mb-3">
              <span className="fw-bold"> + Create New Account </span>
            </Button>
          </Link>
        </div>
      </div>
      <div className="bg-white px-2 w-100 listing_lower_div">
        <div className="d-flex justify-content-between mb-4">
          <div className="w-100 mt-3">
            <Input
              size="medium"
              placeholder="Search by Cust. Name, Event Name, Created By"
              value={searchData}
              onChange={handleInputSearch}
              style={{ width: "80%" }}
              prefix={<SearchOutlined />}
            />
          </div>
          <div className="d-flex"></div>
        </div>
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={{
            showSizeChanger: true,
            pageSize: no_of_records,
            total: customerSelector?.count ? customerSelector.count : 1,
          }}
          loading={{
            indicator: <CommonLoader />,
            spinning: loading,
          }}
          scroll={{
            x: 1300,
            y: "58vh",
            drag: true,
          }}
          bordered={false}
          showSizeChange={true}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
};

export default CustomerManagement;
