import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Select,
  Input,
  Button,
  Switch,
  Checkbox,
  Modal,
} from "antd";

import { useMediaQuery } from "react-responsive";
import { CalenderGrey } from "../../components/svgJS/CalenderGrey";
import { PadLock } from "../../components/svgJS/PadLock";
import { viewUserDetails } from "../../redux/slice/userManagementSlice";
import { useDispatch } from "react-redux";
import { ApiCall } from "../../utils/api";
import { Link } from "react-router-dom";
import CommonLoader from "../../components/Widgets/CommonLoader";
import RedAsterick from "../../components/Widgets/RedAsterick";
import { isRouteExistInMenu } from "../../utils/commonFunctions";
import { toast } from "react-toastify";

const AddUser = ({ match }) => {
  const { Option } = Select;
  const { TextArea } = Input;
  var CryptoJS = require("crypto-js");

  const Tablet = useMediaQuery({ maxWidth: 1200 });
  const [loadingUser, setLoadingUser] = useState(false);
  const [userData, setUserData] = useState([]);
  const [socialMediaList, setSocialMediaList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filteredZone, setFilteredZone] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [jobTitle, setJobTitle] = useState([]);
  const [accessDenied, setAccessDenied] = useState(false);

  const dispatch = useDispatch();

  useEffect(async () => {
    setLoadingUser(true);
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    let is_route_present = await isRouteExistInMenu(CryptoJS);
    if (
      (is_route_present === false && decryptedData === "AGENT") ||
      decryptedData === "DELEGATE"
    ) {
      window.location.href = "/pagenotfound";
    } else {
      getUserDetail();
      getSocialMediaList();
      getZoneList();
      getJobTitle();
      setFilteredZone(
        zoneList?.filter((data) => data?.brand_id === userData?.brand_id)
      );
      setTimeout(() => {
        setLoadingUser(false);
      }, 2000);
    }
  }, [CryptoJS.AES]);

  const getUserDetail = async () => {
    const response = await dispatch(
      viewUserDetails({ user_id: match?.params?.userid })
    );
    if (response?.payload?.code === 404) {
      toast.error(response?.payload?.message);
      setAccessDenied(true);
    } else {
      const { details } = response?.payload?.data;
      setUserData(details);
    }
  };

  const getSocialMediaList = () => {
    ApiCall("get", null, "/admin/socialmediatype/get", null)
      .then((response) => {
        setSocialMediaList(response.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getJobTitle = () => {
    ApiCall("get", null, "/admin/jobtitle/get", null)
      .then((response) => {
        setJobTitle(response.data.data);
      })
      .catch((err) => {});
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getZoneList = () => {
    ApiCall("get", null, "/admin/zones/get", null)
      .then((response) => {
        let constantData = response.data.data.rows.map((response) => {
          return {
            is_assigned: false,
            is_always_get_messages: false,
            ...response,
          };
        });
        return constantData;
      })
      .then((data) => {
        setZoneList(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const socialMediaAvailable =
    userData.users_users_social_media_handles_user_id?.some(
      (social_media) => social_media.social_media_id
    );

  if (loadingUser) {
    return <CommonLoader />;
  } else
    return (
      <div>
        {accessDenied === false && (
          <div className="view-all-common">
            <div className="position-fixed d-flex justify-content-between bg-light w-100 common-sticky-header">
              <div>
                <h4 className="fs-24">View User</h4>
                <Breadcrumb className="my-2" separator=">">
                  <Breadcrumb.Item>
                    <Link to="/usermanagement">Admin Console</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="/usermanagement">User Management</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="/viewuser">View User</Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="bg-white px-5 py-3 mt-5">
              <div className="row my-3">
                <div className="col-12 mt-4">
                  <h6 className="font-pink fw-bolder fs-20">User Editor</h6>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-3 text-end">Active</div>
                <div className="col-1">
                  <Switch checked={userData.is_active} />
                </div>
              </div>
              <div className="row my-3">
                <div className="col-3 text-end">
                  Select Event
                  <RedAsterick />
                </div>
                <div className="col-9">
                  <Input
                    name=""
                    id=""
                    className={`fs-bold-14 ${Tablet ? "w-75" : "w-25"}`}
                    placeholder="Enter First Name"
                    value={userData?.event_name}
                    disabled
                  />
                </div>
              </div>
              <div className="row my-3">
                <div className="col-3 text-end">
                  First Name
                  <RedAsterick />
                </div>
                <div className="col-9">
                  <Input
                    name=""
                    id=""
                    className={`fs-bold-14 ${Tablet ? "w-75" : "w-25"}`}
                    placeholder="Enter First Name"
                    value={userData?.first_name}
                    disabled
                  />
                </div>
              </div>
              <div className="row my-3">
                <div className="col-3 text-end">
                  Surname
                  <RedAsterick />
                </div>
                <div className="col-9">
                  <Input
                    name="second name"
                    id=""
                    className={`fs-bold-14 ${Tablet ? "w-75" : "w-25"}`}
                    placeholder="Enter Second Name"
                    value={userData?.last_name}
                    disabled
                  />
                </div>
              </div>
              <div className="row my-3">
                <div className="col-3 text-end">
                  Email Address
                  <RedAsterick />
                </div>
                <div className="col-9">
                  <Input
                    name=""
                    id=""
                    className={`fs-bold-14 ${Tablet ? "w-75" : "w-25"}`}
                    placeholder="Enter Email"
                    value={userData.email}
                    disabled
                  />
                </div>
              </div>

              <div className="row my-3">
                <div className="col-3 text-end">Profile Picture</div>
                <div className="col-9">
                  <Button className="border-pink rounded" onClick={showModal}>
                    View File
                  </Button>
                  <Modal
                    title="Profile Picture"
                    visible={isModalVisible}
                    onOk={handleOk}
                    className="d-flex justify-content-center"
                    onCancel={handleCancel}
                    footer={null}
                    width={400}
                  >
                    {userData?.image_url ? (
                      <img
                        src={userData.image_url}
                        alt="Uploaded Profile Picture"
                        className="img-fluid uploaded-image-modal-preview"
                      />
                    ) : null}
                  </Modal>
                </div>
              </div>

              {userData.user_type === "AGENT" ? (
                " "
              ) : (
                <div className="row my-3">
                  <div className="col-3 text-end">
                    Company
                    <RedAsterick />
                  </div>
                  <div className="col-9">
                    <Select
                      showSearch
                      className={`fs-bold-14 ${Tablet ? "w-75" : "w-25"}`}
                      placeholder="Select Company"
                      optionFilterProp="children"
                      value={userData.company}
                      disabled
                    >
                      <Option value={userData.company} Selected>
                        {userData.company}
                      </Option>
                    </Select>
                  </div>
                </div>
              )}
              <div className="row my-3">
                <div className="col-3 text-end">
                  Job Role
                  <RedAsterick />
                </div>
                <div className="col-9">
                  <Select
                    showSearch
                    className={`fs-bold-14 ${Tablet ? "w-75" : "w-25"}`}
                    placeholder="Select Job"
                    optionFilterProp="children"
                    value={userData.job_title_id}
                    disabled
                  >
                    {jobTitle &&
                      jobTitle?.map((data) => {
                        return (
                          <Option value={data.job_title_id}>
                            {data.job_title}
                          </Option>
                        );
                      })}
                  </Select>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-3 text-end">Country</div>
                <div className="col-9">
                  <Select
                    showSearch
                    className={`fs-bold-14 ${Tablet ? "w-75" : "w-25"}`}
                    placeholder="Select Country"
                    optionFilterProp="children"
                    value={userData?.country?.country_id}
                    disabled
                  >
                    <Option value={userData?.country?.country_id} Selected>
                      {userData?.country?.country}
                    </Option>
                  </Select>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-3 text-end">User Type</div>
                <div className="col-9">
                  <Select
                    showSearch
                    className={`fs-bold-14 ${Tablet ? "w-75" : "w-25"}`}
                    placeholder="Select User"
                    optionFilterProp="children"
                    value={userData.user_type}
                    disabled
                  >
                    <Option value={userData.user_type} Selected>
                      {userData.user_type}
                    </Option>
                  </Select>
                </div>
              </div>

              {userData.user_type === "AGENT" && (
                <>
                  <div className="row my-3">
                    <div className="col-3 text-end">Admin</div>
                    <div className="col-9">
                      <Switch checked={userData.is_admin} />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-3 text-end">
                      Brand
                      <RedAsterick />
                    </div>
                    <div className="col-9">
                      <Input
                        name=""
                        id=""
                        className={`fs-bold-14 ${Tablet ? "w-75" : "w-25"}`}
                        placeholder="Enter New Email"
                        value={userData.brand_name}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row my-3">
                    <div className="col-3 text-end">Zone Tags</div>
                    <div className="col-8 border-bottom zone-tag-scroll-div">
                      <div className="row border border-gray rounded py-1 sticky-top bg-white">
                        <div className="col-4">
                          <b>Zones</b>
                        </div>
                        <div className="col-4">
                          <b>Assign</b>
                        </div>
                        <div className="col-4">
                          <b>Always get Msgs</b>
                        </div>
                      </div>

                      {userData.user_zones.map((data) => (
                        <div className="row border-start border-end">
                          <div className="col-4">
                            <div className="my-3 fs-bold-14">
                              {data?.zone?.zone_title}
                            </div>
                          </div>

                          <div className="col-4 text-start pt-3">
                            <Checkbox
                              className="mx-3"
                              name="assign"
                              checked={data?.is_assigned}
                            />
                          </div>

                          <div className="col-4 text-start pt-3">
                            <Checkbox
                              className=" mx-3"
                              name="alwaysGetMags"
                              checked={data?.is_always_get_messages}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
              <div className="row my-3">
                <div className="col-3 text-end">
                  Tier
                  <RedAsterick />
                </div>
                <div className="col-9">
                  <Select
                    showSearch
                    className={`fs-bold-14 ${Tablet ? "w-75" : "w-25"}`}
                    placeholder="Select Tier"
                    optionFilterProp="children"
                    value={userData?.tier?.tier_id}
                    disabled
                  >
                    <Option value={userData?.tier?.tier_id} Selected>
                      {userData?.tier?.tier}
                    </Option>
                  </Select>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-3 text-end">
                  Bio
                  <RedAsterick />
                </div>
                <div className="col-9">
                  <TextArea
                    rows={6}
                    className={`fs-bold-14 ${Tablet ? "w-75" : "w-50"}`}
                    placeholder="Enter a Bio here"
                    value={userData.bio}
                    name=""
                    disabled
                  />
                </div>
              </div>
              <div className="row border-bottom-gray pb-4">
                <div className="col-3"></div>
                <div className="col-9">
                  <Button
                    className={
                      "px-3 fs-pink border-pink rounded btn-svg" +
                      (Tablet ? " mt-2" : " mx-2")
                    }
                  >
                    <CalenderGrey /> <span className="mx-2">View Calendar</span>
                  </Button>
                  <Button>
                    <PadLock /> <span className="mx-2">Reset Password</span>
                  </Button>
                  <br />
                  <br />
                  <span className="">
                    NB: This will send an email to the users current email
                    address to create a new password.
                  </span>
                </div>
              </div>
              <section>
                <div className="row">
                  <div className="col-12 mt-4">
                    <h6 className="font-pink fw-bolder fs-20">
                      External & Social Links
                    </h6>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-12 mt-1">
                    <span className="fs-16">
                      NB: If you paste your details into these boxes then anyone
                      using this site will be able to view/contact you via these
                      platforms.
                    </span>
                  </div>
                </div>

                <div className="social-media-div border-bottom-gray pb-4 mb-4">
                  {socialMediaList &&
                    socialMediaList.length > 1 &&
                    socialMediaList?.map((data, idx) => {
                      return (
                        <div className="row mt-4 ">
                          <div className="col-2 text-end">
                            {data?.social_media}
                          </div>
                          <div
                            className={
                              Tablet
                                ? "col-2 text-center"
                                : "col-1 text-center "
                            }
                          >
                            <Switch
                              checked={
                                userData?.users_users_social_media_handles_user_id?.some(
                                  (social_media) =>
                                    social_media.social_media_id ===
                                    data.social_media_id
                                )
                                  ? true
                                  : false
                              }
                            />
                          </div>
                          <div
                            className={
                              Tablet ? "col-5 text-start" : "col-4 text-start "
                            }
                          >
                            <Input
                              placeholder="https://webaddress.com"
                              className="w-100 fs-bold-14"
                              value={
                                userData.users_users_social_media_handles_user_id?.some(
                                  (social_media) =>
                                    social_media.social_media_id ===
                                    data?.social_media_id
                                )
                                  ? userData
                                      .users_users_social_media_handles_user_id[
                                      userData.users_users_social_media_handles_user_id.findIndex(
                                        (social_media) =>
                                          social_media.social_media_id ===
                                          data?.social_media_id
                                      )
                                    ]["social_media_url"]
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </section>
            </div>
            <div
              className="shadow-sm p-3 d-flex justify-content-between"
              style={{ backgroundColor: "#eeeeee" }}
            >
              <Link to="/usermanagement">
                <Button className="px-3 d-block button-pink rounded">
                  Cancel
                </Button>
              </Link>
            </div>
          </div>
        )}

        {accessDenied === true && (
          <Link to="/usermanagement">
            <Button className="px-3 d-block button-pink rounded mt-2">
              Cancel
            </Button>
          </Link>
        )}
      </div>
    );
};

export default AddUser;
