import React, { useEffect } from "react";
import "./messagesui.css";
import MessagesList from "./MessagesList";
import MessagesChat from "./MessagesChat";
import { useState } from "react";
import { ApiCall } from "../../utils/api";
import { createTZString } from "../../utils/commonFunctions";
import { toast } from "react-toastify";
import MessageThread from "./MessageThread";
import { cloneDeep } from "lodash";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { useDispatch } from "react-redux";
import { setHelpAndSupportData } from "../../redux/slice/headerData";

const MessagesUI = (props) => {
  let {
    socket,
    conversationType,
    isEventSupport,
    helpAndSupportNotificationData,
    currentEventID,
  } = props;
  console.log("currentEventID", currentEventID);

  let CryptoJS = require("crypto-js");
  const [showMessagesChat, setShowMessagesChat] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [messagesData, setMessagesData] = useState([]);
  const [userId, setUserId] = useState(null);
  const [userChatHeaderData, setUserChatHeaderData] = useState({
    userName: "",
    isOnline: true,
    link_id: null,
    receiver_id: null,
    img: "",
    userType: "",
  });
  const [showThread, setShowThread] = useState({
    flag: false,
    sender_id: null,
    receiver_id: null,
    conversation_id: null,
    thread_id: null,
    thread_messages: null,
    initialThreadMessage: "",
    chatAlign: "",
  });
  const [eventId, setEventId] = useState(null);
  const [loadingMessageUI, setLoadingMessageUI] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getMessagesUsersList();

    if (localStorage.getItem("user_id")) {
      let user_id = null;
      const ciphertext = localStorage.getItem("user_id");
      let bytes = CryptoJS.AES.decrypt(ciphertext, "user_id");
      user_id = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      setUserId(user_id);
      socket?.emit(
        "updateOnlineStatus",
        JSON.stringify({
          user_id: user_id,
          online: true,
        })
      );
    }

    if (localStorage.getItem("event_id")) {
      setEventId(JSON.parse(localStorage.getItem("event_id")));
    }
  }, []);

  useEffect(() => {
    const chatListReload = (obj) => {
      // console.log("chat_list_reload obj", obj);

      // console.log(
      //   obj?.event_id,
      //   eventId,
      //   obj?.event_id === eventId,
      //   checkIfUserExistInList(obj?.user_id),
      //   "chat_list_reload 1"
      // );

      if (obj?.event_id === eventId) {
        getMessagesUsersList();
      }
    };

    const updateOnlineStatusListener = (obj) => {
      // console.log("updateOnlineStatusListener", obj);
      setUsersList((previousData) => {
        // console.log("prevdata", previousData);
        let modifiedUsersList = previousData?.map((item) => {
          console.log(
            "updateOnlineStatusListener obj ids",
            obj?.user_id,
            item?.user_id
          );

          if (item?.user_id === obj?.user_id) {
            setUserChatHeaderData((prevData) => {
              return {
                ...prevData,
                isOnline: obj?.online,
              };
            });
            return {
              ...item,
              is_online: obj?.online,
            };
          } else {
            return item;
          }
        });

        // console.log("new users list", usersList);

        return modifiedUsersList;
      });
    };

    socket?.on("chat_list_reload", chatListReload);
    socket?.on("updateOnlineStatusListener", updateOnlineStatusListener);

    return () => {
      socket?.off("chat_list_reload");
      socket?.off("updateOnlineStatusListener", updateOnlineStatusListener);
    };
  }, [socket, eventId]);

  const getMessagesUserDetails = async (userData) => {
    setLoadingMessageUI(true);
    const response = await ApiCall(
      "GET",
      "",
      "/admin/conversation/details/get",
      {
        link_id: userData?.link_id,
        page_no: 1,
        no_of_records: 100,
      }
    );
    if (response?.data?.code === 200) {
      console.log("response", response);
      let modifiedMessagesData = response?.data?.data?.map((item) => {
        let dateTimeToModified = createTZString(item?.created_at);
        let newDateTime = dateTimeToModified.format("HH:mm A");

        return {
          ...item,
          time: newDateTime,
        };
      });

      // let getClickeUserDetails = modifiedMessagesData?.find((item) => {
      //   if (item?.link_id === userData?.link_id) {
      //     return item;
      //   }
      // });

      updateReadStatus(modifiedMessagesData, userData?.link_id);

      hideReadCount(userData?.user_id);

      setMessagesData(modifiedMessagesData.reverse());

      setUserChatHeaderData({
        userName: userData?.user_name,
        isOnline: userData?.is_online,
        link_id: userData?.link_id,
        receiver_id: userData?.user_id,
        img: userData?.image_url,
        userType: userData?.user_type,
      });
      setTimeout(() => {
        dispatch(
          setHelpAndSupportData({
            supportType: "",
            userId: null,
          })
        );
      }, 500);

      setShowMessagesChat(true);
    } else {
      toast.error(response?.data?.message);
    }
    setLoadingMessageUI(false);
  };

  const getMessagesUsersList = async () => {
    // setLoadingMessageUI(true);
    const response = await ApiCall("GET", "", "/admin/conversation/list/get", {
      conversation_type: conversationType,
      event_id: currentEventID,
    });
    if (response?.data?.code === 200) {
      // console.log('getMessagesUsersList response',response?.data?.data);
      let modifiedUsersList = response?.data?.data
        ?.filter((elem) => {
          if (elem?.last_conversation_timestamp) {
            //If last_conversation_timestamp is null not to show in user list it means it has been deleted
            return elem;
          }
        })
        .map((item) => {
          let dateTimeToModified = createTZString(
            item?.last_conversation_timestamp
          );
          let newDateTime = dateTimeToModified.format("HH:mm A");

          return {
            ...item,
            last_conversation_timestamp: newDateTime,
          };
        });
      setUsersList(modifiedUsersList);
    } else {
      toast.error(response?.data?.message);
    }
    // setLoadingMessageUI(false);
  };

  const checkIfUserExistInList = (userId) => {
    let isUserPresent = false;

    usersList?.forEach((item) => {
      if (
        item?.user_id === userId &&
        item?.last_conversation_timestamp !== null
      ) {
        isUserPresent = true;
      }
    });

    return isUserPresent;
  };

  const updateReadStatusOfMessagesApi = async (ids, linkId) => {
    // let body = {
    //   conversation_ids: ids,
    //   is_read: true,
    // };
    // console.log("test1234", body);
    // const response = await ApiCall(
    //   "PUT",
    //   body,
    //   "/admin/conversationchat/status/update"
    // );
    // console.log("updateReadStatusOfMessages resp", response);
    // if (response?.data?.code === 200) {
    // }
  };

  const updateReadStatus = (data, linkId) => {
    let allConversationIds = [];
    data?.forEach((item) => {
      if (!item?.is_read) {
        allConversationIds.push(item?.conversation_id);
      }
    });
    // console.log("allConversationIds", allConversationIds);

    allConversationIds?.forEach((item) => {
      socket?.emit(
        "updateReadStatus",
        JSON.stringify({
          conversation_id: item,
          link_id: linkId,
          is_read: true,
        })
      );
    });
  };

  const hideReadCount = (userId) => {
    setUsersList((previousData) => {
      let modifiedUsersList = previousData?.map((item) => {
        if (item?.user_id === userId) {
          return {
            ...item,
            unread_main_conversation: 0,
          };
        } else {
          return item;
        }
      });

      // console.log("new users list", usersList);

      return modifiedUsersList;
    });
  };

  // console.log("show thread data message ui", showThread);
  // console.log("users list", usersList);
  // console.log("message Chat", showMessagesChat);

  if (loadingMessageUI) {
    return <CommonLoader />;
  } else
    return (
      <>
        <div className="mess_container">
          <MessagesList
            userChatHeaderData={userChatHeaderData}
            openUserChatId={helpAndSupportNotificationData?.userId}
            usersList={usersList}
            callGetMessageListApi={() => {
              getMessagesUsersList();
            }}
            getMessagesUsersList={getMessagesUsersList}
            getMessagesUserDetails={getMessagesUserDetails}
            closeMessageChat={() => {
              setLoadingMessageUI(true);
              setShowMessagesChat(false);
              setShowThread({
                flag: false,
                sender_id: null,
                receiver_id: null,
                conversation_id: null,
                thread_id: null,
                thread_messages: null,
                chatAlign: "",
              });
              setLoadingMessageUI(false);
            }}
          />
          {showMessagesChat ? (
            <MessagesChat
              closeThreadPopup={() => {
                setShowThread({
                  flag: false,
                  sender_id: null,
                  receiver_id: null,
                  conversation_id: null,
                  thread_id: null,
                  thread_messages: null,
                  chatAlign: "",
                });
              }}
              userId={userId}
              socket={socket}
              userChatHeaderData={userChatHeaderData}
              messagesData={messagesData}
              initiliazeNewThreadValue={(item, direction) => {
                let isThreadExist = item?.users_conversation_thread
                  ? true
                  : false;
                let threadMessArr = [];
                if (isThreadExist) {
                  threadMessArr =
                    item?.users_conversation_thread?.users_conversation_thread_messages?.map(
                      (elem) => {
                        let new_date_time = createTZString(elem?.created_at);
                        return {
                          ...elem,
                          time: new_date_time.format("HH:mm A"),
                        };
                      }
                    );
                }

                setTimeout(() => {
                  setShowThread({
                    flag: true,
                    sender_id: item?.sender_id,
                    receiver_id: item?.receiver_id,
                    conversation_id: item?.conversation_id,
                    thread_id: isThreadExist
                      ? item?.users_conversation_thread?.thread_id
                      : null,
                    thread_messages: isThreadExist ? threadMessArr : null,
                    initialThreadMessage: item,
                    chatAlign: direction,
                  });
                }, 500);
              }}
              getMessagesUserDetails={getMessagesUserDetails}
              setShowThread={setShowThread}
            />
          ) : null}

          {showThread?.flag && showThread?.conversation_id ? (
            <MessageThread
              closeThreadPopup={() => {
                setShowThread({
                  flag: false,
                  sender_id: null,
                  receiver_id: null,
                  conversation_id: null,
                  thread_id: null,
                  thread_messages: null,
                  initialThreadMessage: "",
                  chatAlign: "",
                });
              }}
              setShowThread={setShowThread}
              showThread={showThread}
              userId={userId}
              socket={socket}
              userChatHeaderData={userChatHeaderData}
              messagesData={messagesData}
            />
          ) : null}
        </div>
      </>
    );
};

export default MessagesUI;
