import { ApiCall, ApiCallForm } from "./api";
import moment from "moment";
import { toast } from "react-toastify";

export const getSurveys = async (params) => {
  const response = await ApiCall("GET", "", "/admin/survey/get", params);
  if (response?.data?.code === 200) {
    if (response?.data?.data?.rows) {
      return response.data.data.rows;
    }
  } else return [];
};

export const getSocialLinks = async () => {
  const response = await ApiCall(
    "GET",
    "",
    "/admin/user/socialmediahandledetails/get",
    "",
    ""
  );
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
};

export const getSurveyList = async () => {
  const response = await ApiCall("GET", "", "/admin/survey/get", "", "");
  if (response?.data?.code === 200) {
    return response.data.data.rows;
  } else return [];
};

export const getSocialMediaLinks = async () => {
  const response = await ApiCall(
    "GET",
    "",
    "/admin/socialmediatype/get",
    "",
    ""
  );
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
};

export const getTiers = async () => {
  const response = await ApiCall("GET", "", "/admin/tiers/get", "", "");
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
};

export const getRelations = async () => {
  const response = await ApiCall("GET", "", "/admin/relations/get", "", "");
  if (response?.data?.code === 200) {
    const searchesData = await response.data.data
      .filter((item) => item.part_of === "Searches")
      .map((item) => {
        return item;
      });

    const initiateDataText = await response.data.data
      .filter((item) => item.part_of === "Initiate 1 to 1 Text Chats")
      .map((item) => {
        return item;
      });
    const initiateDataVideo = await response.data.data
      .filter((item) => item.part_of === "Initiate 1 to 1 Video Chats")
      .map((item) => {
        return item;
      });
    const calendarData = await response.data.data
      .filter((item) => item.part_of === "Calendar & Meetings")
      .map((item) => {
        return item;
      });
    const inviteData = await response.data.data
      .filter((item) => item.part_of === "Inviting Delegates")
      .map((item) => {
        return item;
      });

    return {
      searchesData,
      initiateDataText,
      calendarData,
      inviteData,
      initiateDataVideo,
      // ...relationData, tempData: tempData
    };
  } else
    return {
      searchesData: [],
      initiateDataText: [],
      calendarData: [],
      inviteData: [],
      initiateDataVideo: [],
    };
};

export const getAgents = async (params) => {
  const response = await ApiCall("GET", "", "/admin/user/get", params);
  if (response?.data?.code === 200) {
    return response.data.data.rows;
  } else return [];
};

export const getNavigationSettingsType = async () => {
  const response = await ApiCall(
    "GET",
    "",
    "/admin/navigationsettingtype/get",
    "",
    ""
  );
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
};

export const getUsers = async (params) => {
  const response = await ApiCall("GET", "", "/admin/user/get", params);
  if (response?.data?.code === 200) {
    return response.data.data.rows;
  } else return [];
};

export const getSocialWallModerators = async () => {
  const response = await ApiCall(
    "GET",
    "",
    "/admin/events/socialWall/get",
    "",
    ""
  );
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
};

export const getCountryList = async (params) => {
  const response = await ApiCall(
    "get",
    null,
    "/admin/country/get",
    params,
    true
  );
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
};

export const getJobTitles = async (params) => {
  const response = await ApiCall("get", null, "/admin/jobtitle/get", params);
  if (response?.data?.code === 200) return response.data.data;
  else return [];
};

export const getEvents = async (params = "") => {
  const response = await ApiCall(
    "get",
    null,
    "/admin/events/dropdown/get",
    params
  );
  if (response?.data?.data?.rows) {
    return response.data.data.rows;
  } else return [];
};

export const getAudiSession = async (params) => {
  let paramsToSend = {
    current_timestamp: moment().format("YYYY-MM-DD"),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  if (params) paramsToSend = { ...params, ...paramsToSend };
  const response = await ApiCall(
    "get",
    null,
    "/admin/auditoriumconfiguration/details/get",
    paramsToSend
  );

  if (response?.data?.data?.details) {
    return response.data.data.details;
  } else {
    if (response?.data?.message === "Something went wrong")
      toast.error(response.data.message);
    return {};
  }
};

export const getBrands = async (params) => {
  let paramsToSend = {
    current_timestamp: moment().format("YYYY-MM-DD"),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  if (params) paramsToSend = { ...params, ...paramsToSend };
  const response = await ApiCall("GET", "", "/admin/brand/get", paramsToSend);
  if (response?.data?.code === 200) {
    if (response?.data?.data?.rows) {
      return response?.data?.data?.rows;
    }
  } else return [];
};

export const getNetworkLoungeSession = async (params) => {
  let paramsToSend = {
    current_timestamp: moment().format("YYYY-MM-DD"),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  if (params) paramsToSend = { ...params, ...paramsToSend };
  const response = await ApiCall(
    "get",
    null,
    "/admin/networkingsession/details/get",
    paramsToSend
  );

  if (response?.data?.data) {
    return response.data.data;
  } else return {};
};

export const getTickerData = async (eventParams) => {
  const response = await ApiCall(
    "GET",
    "",
    "/admin/eventticker/details/get",
    eventParams
  );
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return null;
};

export const getTickerPickerList = async () => {
  const response = await ApiCall(
    "GET",
    "",
    "/admin/tickerconfiguration/get",
    ""
  );
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
};

// Api Calls For Role Management
export const getBrandDropdowns = async (event_id) => {
  const response = await ApiCall("get", null, "/admin/brand/dropdown/get", {
    event_id,
  });
  if (response?.data?.data) {
    return response.data.data.rows;
  } else return [];
};

export const getSpeakersForBrand = async (params) => {
  const response = await ApiCall("GET", "", "/admin/brand/speaker/get", params);

  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
};

export const getBrandDetails = async (params) => {
  const response = await ApiCall(
    "GET",
    null,
    "/admin/brand/details/get",
    params
  );

  if (response?.data?.data) {
    return response.data.data;
  } else return {};
};

export const getZoneDropdowns = async (event_id) => {
  const response = await ApiCall("GET", "", "/admin/zone/dropdown/get", {
    event_id,
  });
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
};

export const getZones = async (params) => {
  const response = await ApiCall("GET", "", "/admin/zones/get", params);
  if (response?.data?.code === 200) {
    return response.data.data.rows;
  } else return [];
};

export const getZoneDetails = async (params) => {
  let paramsToSend = {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    current_timestamp: moment().format("YYYY-MM-DD"),
  };
  if (params) paramsToSend = { ...params, ...paramsToSend };
  const response = await ApiCall(
    "get",
    null,
    "/admin/zones/details/get",
    paramsToSend
  );

  if (response?.data?.data?.details) {
    return response?.data?.data?.details;
  } else {
    if (response?.data?.code !== 200) toast.error(response?.data?.message);
    return {};
  }
};

export const getChatbotJson = async (params) => {
  const response = await ApiCall(
    "get",
    null,
    "/admin/zone/chatbot/get",
    params
  );

  if (response?.data?.data) {
    return response.data.data;
  } else return {};
};

export const getUserTypeAgents = async (params) => {
  const response = await ApiCall(
    "GET",
    "",
    "/admin/usertypeagents/get",
    params
  );
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
};

export const getZoneTaggedAgents = async (params) => {
  const response = await ApiCall(
    "GET",
    "",
    "/admin/zonetaggedagents/get",
    params
  );
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
};

export const getSocialWallModerationDetails = async (params) => {
  let paramsToSend = {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  if (params) paramsToSend = { ...params, ...paramsToSend };
  const response = await ApiCall(
    "get",
    null,
    "/admin/socialwallmoderation/details/get",
    paramsToSend
  );
  if (response?.data?.code === 200) {
    return response.data;
  } else return [];
};

export const getAudiInvitationDetails = async (params) => {
  const response = await ApiCall(
    "get",
    null,
    "/admin/auditoriumsessioninvitation/details/get",
    params
  );

  if (response?.data?.data) {
    return response.data.data;
  } else return {};
};

export const getNetworkInvitationDetails = async (params) => {
  const response = await ApiCall(
    "get",
    null,
    "/admin/networkingsessioninvitation/details/get",
    params
  );

  if (response?.data?.data) {
    return response.data.data;
  } else return {};
};

export async function sendMessageAPI(channel_id, message_text) {
  const response = await ApiCall(
    "POST",
    { channel_id, message_text },
    "/admin/channel/message/send",
    ""
  );

  return response;
}

export async function videoRequestAPI(channel_id) {
  const response = await ApiCall(
    "POST",
    { channel_id },
    "/admin/channel/videocall/request",
    ""
  );
  return response;
}

export async function oneToOneVideoRequestAPI(params) {
  const response = await ApiCall(
    "POST",
    params,
    "/admin/events/helpandsupport/videorequest/create",
    ""
  );

  return response;
}

export async function oneToOneMessageAPI(params) {
  const response = await ApiCall(
    "POST",
    params,
    "/admin/onetoone/message/send",
    ""
  );

  return response;
}

export async function oneToOneVideoAPI(params) {
  const response = await ApiCall(
    "POST",
    params,
    "/admin/onetoone/video/request",
    ""
  );

  return response;
}

export const fetchTickerList = async () => {
  const response = await ApiCall("GET", "", "/admin/event/ticker/get", "");
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
};

export const fetchEventShows = async (params) => {
  const response = await ApiCall("GET", "", "/admin/events/shows/get", params);
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
};

export const fetchChatbotHistory = async (params) => {
  const response = await ApiCall(
    "GET",
    "",
    "/admin/chatbot/conversationhistory/fetch",
    params
  );
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
};

export async function saveChatbotHistory(params) {
  const response = await ApiCall(
    "POST",
    params,
    "/admin/channel/message/send",
    ""
  );

  return response;
}

export async function getBoothLocations(params) {
  const response = await ApiCall(
    "GET",
    params,
    "/admin/boothlocations/get",
    ""
  );

  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
}

export const getEventEligibility = async (params) => {
  const response = await ApiCall(
    "get",
    null,
    "/admin/events/checkupdateeligibility/get",
    params
  );

  if (response?.data?.data) return response.data.data;
  else return {};
};

export async function addNewJobTitle(data) {
  const response = await ApiCall("POST", data, "/admin/jobtitle/create", "");
  if (response) return response;
  else return {};
}

export const getSurveyDetailsDelegateUser = async (params) => {
  const response = await ApiCall(
    "get",
    null,
    "/admin/user/survey/questionanswer/details/get",
    params
  );
  if (response?.data?.data) return response.data.data;
  else return {};
};

export async function getNetSessionJoinTables(params) {
  const response = await ApiCall(
    "GET",
    "",
    "/admin/networkingsession/jointables/get",
    params
  );
  if (response?.data?.code === 200) return response.data.data;
  else return [];
}

export async function addDelegateSurvey(data) {
  const response = await ApiCall(
    "POST",
    data,
    "/admin/user/survey/questionanswer/create",
    ""
  );

  if (response) return response.data;
  else return {};
}

export const uploadVistaFile = async (body) => {
  const response = await ApiCallForm("POST", body, "/admin/vista/upload");
  if (response?.data?.data) {
    return response.data.data;
  } else return {};
};

export const clearLocationApi = async (location, interval) => {
  clearInterval(interval);
  let body = {
    location: location,
  };
  const userLocationResp = await ApiCall(
    "PUT",
    body,
    "/admin/vista/userlocation/update",
    "",
    true
  );
  return userLocationResp;
};

// -------------------- Api Calls for Listing Pages ----------------//
export async function getCustomerListForListing(params) {
  const response = await ApiCall(
    "GET",
    "",
    "/website/customer/dropdown/get",
    ""
  );
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
}

export async function getEventCreators(params) {
  const response = await ApiCall(
    "GET",
    "",
    "/admin/events/creators/get",
    params
  );
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
}

export async function getEventsForListing(params) {
  const response = await ApiCall(
    "GET",
    "",
    "/admin/events/dropdown/get",
    params
  );
  if (response?.data?.code === 200) {
    return response.data.data.rows;
  } else return [];
}

export async function getBrandCreators(params) {
  const response = await ApiCall(
    "GET",
    "",
    "/admin/brand/creators/get",
    params
  );
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
}

export async function getZoneCreators(params) {
  const response = await ApiCall("GET", "", "/admin/zone/creators/get", params);
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
}

export async function getBrandsForListing(params) {
  const response = await ApiCall(
    "GET",
    "",
    "/admin/brand/dropdown/get",
    params
  );
  if (response?.data?.code === 200) {
    return response.data.data.rows;
  } else return [];
}
export async function getSurveyCreators(params) {
  const response = await ApiCall(
    "GET",
    "",
    "/admin/survey/creators/get",
    params
  );
  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
}

//---------- Api Calls for listing end -----------//

export const getChannels = async (params) => {
  let paramsToSend = {
    current_timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  if (params) paramsToSend = { ...params, ...paramsToSend };
  const response = await ApiCall(
    "get",
    null,
    "/admin/channel/get",
    paramsToSend
  );

  if (response?.data?.code === 200) {
    return response.data.data;
  } else return [];
};

export const updateNotificationStatus = async (params) => {
  const response = await ApiCall(
    "PUT",
    params,
    "/admin/user/notifications/update",
    null
  );

  if (response?.data?.code === 200) {
    return response.data;
  } else return {};
};

export const acceptMessageRequest = async (params) => {
  const response = await ApiCall(
    "POST",
    params,
    "/admin/channel/message/respond",
    ""
  );
  return response;
};

export const acceptVideoRequest = async (params) => {
  const response = await ApiCall(
    "POST",
    params,
    "/admin/channel/videocall/respond",
    ""
  );
  return response;
};

export const oneToOneRespond = async (params) => {
  const response = await ApiCall(
    "POST",
    params,
    "/admin/onetoone/communication/respond",
    ""
  );
  return response;
};

export async function unSubscribeChannel(params) {
  const response = await ApiCall(
    "PUT",
    params,
    "/admin/channel/subscription/update",
    ""
  );
  if (response?.data?.code === 200) return response.data;
  else return {};
}

export async function getPeopleForListing() {
  const response = await ApiCall("GET", "", "/admin/people/get", "");
  if (response?.data?.code === 200) return response.data.data;
  else return [];
}

export async function addDelegateMeeting(data) {
  const response = await ApiCall("POST", data, "/admin/meetings/create", "");
  if (response && response.data) return response.data;
  else return {};
}

export async function editDelegateMeeting(data) {
  const response = await ApiCall("PUT", data, "/admin/meetings/update", "");
  if (response && response.data) return response.data;
  else return {};
}

export async function DeletePost(data) {
  const response = await ApiCall(
    "DELETE",
    data,
    "/admin/socialwall/posts/delete",
    ""
  );
  if (response) return response;
  else return {};
}

export async function UpdatePost(data) {
  const response = await ApiCall(
    "PUT",
    data,
    "/admin/socialwall/posts/update",
    ""
  );

  if (response) return response;
  else return {};
}

export async function DeleteSponsorMedia(data) {
  const response = await ApiCall(
    "DELETE",
    data,
    "/admin/events/sponsormedia/delete",
    ""
  );
  if (response) return response;
  else return {};
}

export async function getEventDetails(params) {
  const response = await ApiCall(
    "GET",
    "",
    `/admin/events/details/get`,
    params
  );

  if (response?.data?.code === 200 && response?.data?.data)
    return response.data.data;
  else return {};
}

export const updateAssetDownloadCount = async (zone_asset_id) => {
  let body = {
    zone_asset_id: zone_asset_id,
    is_download: true,
  };
  const response = await ApiCall(
    "PUT",
    body,
    "/admin/zone/assets/viewdownload/count/update",
    null
  );
  if (response.data.code === 200) return response;
  else toast.error(response.data.message);

  return true;
};

export async function addToMyCalender(body) {
  const response = await ApiCall("POST", body, "/admin/mycalendar/create", "");
  if (response?.data?.code === 200) toast.success(response?.data?.message);
  else toast.error(response?.data?.message);
}

var xmlHttp;
export function srvTime() {
  try {
    //FF, Opera, Safari, Chrome
    xmlHttp = new XMLHttpRequest();
  } catch (err1) {
    //IE
  }
  xmlHttp.open("HEAD", window.location.href.toString(), false);
  xmlHttp.setRequestHeader("Content-Type", "text/html");
  xmlHttp.send("");
  return xmlHttp.getResponseHeader("Date");
}

export const acceptMessageHelpSupportRequest = async (params) => {
  const response = await ApiCall(
    "POST",
    params,
    "/admin/events/message/respond",
    ""
  );
  return response;
};

export const logout = async () => {
  let body = {};
  ApiCall("PUT", body, "/admin/user/logout", "").then((res) => {
    if (res?.data?.code === 200) {
    }
  });
  return null;
};

export async function getEventNavigationFlags(params) {
  const response = await ApiCall(
    "GET",
    "",
    `/admin/events/reception/details/get`,
    params
  );

  if (response?.data?.code === 200 && response?.data?.data)
    return response.data.data;
  else return [];
}

export async function getBrandNameByChatId(params) {
  const response = await ApiCall(
    "GET",
    "",
    `/admin/conversationuserdetails/get`,
    params
  );

  if (response?.data?.code === 200 && response?.data?.data)
    return response.data.data;
  else return null;
}
