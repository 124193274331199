import React, { useEffect, useState, useRef } from "react";
import "./JoinSession.css";
import { Modal, Row, Col, Input, Button, Carousel, Tooltip } from "antd";
import { useHistory } from "react-router";
import { BoothRed } from "../../components/svgJS/BoothRed";
import { Chat } from "../../components/svgJS/Chat";
import { Video } from "../../components/svgJS/Video";
import { CalenderGrey } from "../../components/svgJS/CalenderGrey";
import moment from "moment";
import { toast } from "react-toastify";
import {
  videoRequestAPI,
  sendMessageAPI,
  addToMyCalender,
} from "../../utils/commonApis";
import { User } from "../../components/svgJS/User";
import SimpleReactValidator from "simple-react-validator";
import RedAsterick from "../../components/Widgets/RedAsterick";
import {
  setExpoMapRedirectLinkName,
  setVistaEventId,
} from "../../redux/slice/vista";
import { useDispatch } from "react-redux";
import { ApiCall } from "../../utils/api";
import {
  createTZString,
  vistaRedirectToBooth,
} from "../../utils/commonFunctions";
import { cloneDeep } from "lodash";
import ICalendarLink from "react-icalendar-link";
import { setVideoConferenceData } from "../../redux/slice/videoConference";
import { TIME_FORMAT } from "../../components/constsnts/common.constants";

const JoinSession = ({
  isModalOpen,
  joinSessionPopupData,
  onClose,
  addToCalendarData,
  eventID,
}) => {
  // Start of Join Session Modal

  const [isJoinSessionModalVisible, setIsJoinSessionModalVisible] =
    useState(false);

  const [messageModal, setMessageModal] = useState({ isOpen: false, data: {} });
  const messageValidator = useRef(new SimpleReactValidator());
  const [forceUpdate, setForceUpdate] = useState(false);
  const [message_text, set_message_text] = useState("");
  const [cometChatUser, setCometChatUser] = useState({
    isValidUser: false,
    data: {},
    uid: "",
  });
  const [icalData, setIcalData] = useState(null);
  const { TextArea } = Input;
  const ciphertext = localStorage.getItem("user_type");
  let CryptoJS = require("crypto-js");
  let bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
  let userType = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (joinSessionPopupData) {
      let dataToModify = cloneDeep(joinSessionPopupData);
      const d1 = new Date(dataToModify.start_date_time);
      const st = d1.toISOString().replace("Z", "");
      const d2 = new Date(dataToModify.end_date_time);
      const et = d2.toISOString().replace("Z", "");
      dataToModify["startTime"] = st;
      dataToModify["endTime"] = et;

      if (dataToModify.session_title)
        dataToModify["title"] = dataToModify.session_title;
      if (dataToModify.session_description)
        dataToModify["description"] = dataToModify.session_description;
      setIcalData(dataToModify);
    }
  }, []);

  const handleJoinSessionOk = () => {
    setIsJoinSessionModalVisible(false);
  };

  const handleJoinSessionCancel = () => {
    setIsJoinSessionModalVisible(false);
    onClose();
  };

  const handleAddtoCalendarPopup = async (sessId) => {
    await addToMyCalender({
      event_id: eventID,
      hall_type: addToCalendarData?.audiType,
      hall_id: addToCalendarData?.audiConfigId,
      session_id: sessId,
    });
  };
  function clearMessageAndModal() {
    set_message_text("");
    setMessageModal({ isOpen: false, data: {} });
  }

  async function callSendMessageAPI(channel_id) {
    if (messageValidator.current.allValid()) {
      if (cometChatUser.uid) {
        const response = await sendMessageAPI(channel_id, message_text);
        if (response.data.code === 200) {
          toast.success(response.data.message);
          clearMessageAndModal();
        } else toast.error(response.data.message);
      } else setForceUpdate(!forceUpdate);
    } else {
      messageValidator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
  }

  const NextPopupArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="audi_next_arrow_join_sess" onClick={onClick}>
        <i class="arrow right"></i>
      </div>
    );
  };

  const PrevPopupArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className="audi_prev_arrow_joinSess" onClick={onClick}>
        <i class="arrow left"></i>
      </div>
    );
  };

  const settings = {
    nextArrow: <NextPopupArrow />,
    prevArrow: <PrevPopupArrow />,
  };

  return (
    <>
      {/* Start of Join Session Modal */}
      <Modal
        title=""
        visible={isModalOpen}
        onOk={handleJoinSessionOk}
        onCancel={handleJoinSessionCancel}
        className="joinsess_modal"
        wrapClassName="joinsess_wrap_modal"
        width={650}
        footer={null}
      >
        <div className="joinsess_container w-100">
          <h6 className="opacity-50 mb-1">Title :</h6>
          <h5 className="mb-1">{joinSessionPopupData?.session_title}</h5>
          <h6 className="opacity-50 mb-1">Time :</h6>
          <span className="fs-16 pb-1 text-dark">
            {moment(
              createTZString(joinSessionPopupData?.start_date_time).format(
                "HH:mm"
              ),
              TIME_FORMAT
            ).format("HH:mm")}
            -
            {moment(
              createTZString(joinSessionPopupData?.end_date_time).format(
                "HH:mm"
              ),
              TIME_FORMAT
            ).format("HH:mm")}
          </span>
          <h6 className="opacity-50">Description :</h6>
          <p className="text-dark">
            {joinSessionPopupData?.session_description}
          </p>
          <div className="d-flex mb-3">
            {joinSessionPopupData?.is_iCal_enabled &&
            window.navigator.platform.toLowerCase().includes("mac") ? (
              <ICalendarLink className="joinsess_downloadBtn" event={icalData}>
                <div>
                  <span>Download iCal</span>
                </div>
              </ICalendarLink>
            ) : null}
            <Button
              className="joinsess_calendarBtn mx-3"
              onClick={() =>
                handleAddtoCalendarPopup(
                  joinSessionPopupData?.auditorium_session_id
                )
              }
              disabled={
                joinSessionPopupData?.added_to_my_calendar ? false : true
              }
            >
              <CalenderGrey />
              <span className="ms-2">Add to My Calendar </span>
            </Button>
          </div>
          <h5>Speaker</h5>

          {/* Card 2 */}

          {joinSessionPopupData?.speakersArr?.length !== 0 ? (
            <Carousel
              className="audi_card_carousel w-100  bg-white mt-3"
              arrows
              {...settings}
            >
              {joinSessionPopupData?.speakersArr &&
                joinSessionPopupData?.speakersArr?.map((data, i) => {
                  return (
                    <div className="d-flex p-3 border  mb-2" key={i}>
                      <div className="join_sess_speaker_img_container">
                        <img
                          className="join_sess_speaker_img"
                          src={data?.user?.image_url}
                        />
                      </div>

                      <div className="ms-3">
                        <span className="opacity-75">Speaker</span>
                        <h6>
                          {data?.user?.first_name || data?.user?.last_name
                            ? data?.user?.first_name +
                              " " +
                              data?.user?.last_name
                            : "NA"}
                        </h6>
                        <span>Bio</span>
                        <p>{data?.user?.bio ? data?.user?.bio : "NA"}</p>
                      </div>
                    </div>
                  );
                })}
            </Carousel>
          ) : (
            <div className="audi_net_empty_data">
              <span className="fs-pink">No Speaker Data</span>
            </div>
          )}

          <h5>Brand</h5>

          <Carousel
            className="audi_card_carousel w-100  bg-white mt-3"
            arrows
            {...settings}
          >
            {joinSessionPopupData?.auditorium_hosting_brands &&
              joinSessionPopupData?.auditorium_hosting_brands?.map(
                (data, i) => {
                  return (
                    <div className="d-flex p-3  joinsess_brand  " key={i}>
                      <div className="join_sess_sponsor_img_container">
                        <img
                          className="join_sess_sponsor_img"
                          src={data?.logo_url}
                        />
                      </div>
                      <div className="ms-3">
                        <span className="joinsess_brand_title">
                          {data?.brand_title}
                        </span>
                        <span className="joinsess_brand_tier">
                          {data?.tier_name}
                        </span>

                        {data?.is_booth_link_enabled ? (
                          <div
                            onClick={() => {
                              let tier = data?.tier_id;
                              let zones = data?.brand?.zones;
                              let sequence = null;
                              let brand_id = data?.assigned_brand_id;

                              vistaRedirectToBooth(
                                tier,
                                zones,
                                sequence,
                                userType,
                                brand_id,
                                eventID,
                                history,
                                dispatch
                              );
                            }}
                            className="inner_span_svg_color cursor-pointer"
                          >
                            <BoothRed />
                            <span className="fs-pink ms-1">Visit Booth </span>
                          </div>
                        ) : null}

                        <div className="d-flex mt-2">
                          {data?.is_chat_enabled ? (
                            <div className="joinsess_icons bg-white ">
                              <Tooltip placement="right" title="Chat">
                                <Button
                                  onClick={() => {
                                    setMessageModal({
                                      isOpen: true,
                                      channel_id: data?.channel_id,
                                    });
                                  }}
                                  className="border p-1 "
                                >
                                  <Chat />
                                </Button>
                              </Tooltip>
                            </div>
                          ) : (
                            ""
                          )}

                          {data?.is_demo_room_enabled &&
                          data?.zone_demo_room_link ? (
                            <div className="joinsess_icons bg-white ms-2">
                              <Tooltip placement="right" title="Demo Room">
                                <Button
                                  className="border p-1 "
                                  onClick={() => {
                                    dispatch(
                                      setVideoConferenceData({
                                        videoConferenceFlag: true,
                                        videoConferenceUrl:
                                          data?.zone_demo_room_link,
                                        is_demo_room: true,
                                      })
                                    );
                                  }}
                                >
                                  <Video />
                                </Button>
                              </Tooltip>
                            </div>
                          ) : (
                            ""
                          )}

                          {data?.is_video_call_enabled ? (
                            <div className="joinsess_icons bg-white ms-2">
                              <Tooltip
                                placement="right"
                                title="Next Available Agent"
                              >
                                <Button
                                  onClick={async () => {
                                    let channel_id = data?.channel_id;

                                    const response = await videoRequestAPI(
                                      channel_id
                                    );
                                    if (response.data.code === 200)
                                      toast.success(response.data.message);
                                    else toast.error(response.data.message);
                                  }}
                                  className="border p-1"
                                >
                                  <User />
                                </Button>
                              </Tooltip>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
          </Carousel>

          <div className="w-100 d-flex mt-4 mb-1">
            <div className="w-75"></div>
            {joinSessionPopupData?.audiName === "Main" ? (
              <Button
                className="joinsess_audiBtn mx-4 fs-16"
                onClick={() => {
                  dispatch(
                    setExpoMapRedirectLinkName({
                      linkName: "MainAuditorium",
                      number: null,
                      notFromExpoMap: true,
                    })
                  );
                  dispatch(setVistaEventId(eventID));
                  history.push("/concourse");
                }}
              >
                <BoothRed />
                <span className="ms-2">Go to Main Auditorium</span>
              </Button>
            ) : (
              <Button
                className="joinsess_audiBtn mx-4 fs-16"
                onClick={() => {
                  dispatch(
                    setExpoMapRedirectLinkName({
                      linkName: "SATAuditorium",
                      number: joinSessionPopupData?.audiNumber,
                      notFromExpoMap: true,
                    })
                  );
                  dispatch(setVistaEventId(eventID));
                  history.push("/expomap");
                }}
              >
                <BoothRed />
                <span className="ms-2">Go to Sat Auditorium</span>
              </Button>
            )}
          </div>
        </div>
      </Modal>
      {/* End of Join Session mOdal */}
      {messageModal.isOpen ? (
        <Modal
          title={<b>Send Message</b>}
          visible={messageModal.isOpen}
          onOk={() => clearMessageAndModal()}
          onCancel={() => clearMessageAndModal()}
          footer={[
            <Button
              className="px-3 button-pink rounded"
              key="cancel"
              onClick={() => clearMessageAndModal()}
            >
              Cancel
            </Button>,
            <Button
              className="px-3 rounded"
              key="save"
              type="primary"
              onClick={() => callSendMessageAPI(messageModal.channel_id)}
            >
              Send
            </Button>,
          ]}
          className="message_modal"
        >
          <Row className="ticker_configuration_row mt-2">
            <Col span={6} className="ticker_configuration_label">
              <p>
                Message
                <RedAsterick />
              </p>
            </Col>
            <Col span={12}>
              <TextArea
                name="message_text"
                id="message_text"
                className="ticker_configuration_inputDescription"
                placeholder="Enter a message"
                rows={4}
                value={message_text}
                onChange={(e) => set_message_text(e.target.value)}
                onBlur={() =>
                  messageValidator.current.showMessageFor("message_text")
                }
              />
              {messageValidator.current.message(
                "Message",
                message_text,
                "required",
                { className: "text-danger py-1" }
              )}
            </Col>
          </Row>
        </Modal>
      ) : null}
    </>
  );
};

export default JoinSession;
