export const timeWiseEventsArray = [
  {
    startTime: "12:00 AM",
    startHours: 0,
    endTime: "1:00 AM",
  },
  {
    startTime: "1:00 AM",
    startHours: 1,
    endTime: "2:00 AM",
  },
  {
    startTime: "2:00 AM",
    startHours: 2,
    endTime: "2:00 AM",
  },
  {
    startTime: "3:00 AM",
    startHours: 3,
    endTime: "4:00 AM",
  },
  {
    startTime: "4:00 AM",
    startHours: 4,
    endTime: "5:00 AM",
  },
  {
    startTime: "5:00 AM",
    startHours: 5,
    endTime: "6:00 AM",
  },
  {
    startTime: "6:00 AM",
    startHours: 6,
    endTime: "7:00 AM",
  },
  {
    startTime: "7:00 AM",
    startHours: 7,
    endTime: "8:00 AM",
  },
  {
    startTime: "8:00 AM",
    startHours: 8,
    endTime: "9:00 AM",
  },
  {
    startTime: "9:00 AM",
    startHours: 9,
    endTime: "10:00 AM",
  },
  {
    startTime: "10:00 AM",
    startHours: 10,
    endTime: "11:00 AM",
  },
  {
    startTime: "11:00 AM",
    startHours: 11,
    endTime: "12:00 PM",
  },
  {
    startTime: "12:00 PM",
    startHours: 12,
    endTime: "1:00 PM",
  },
  {
    startTime: "1:00 PM",
    startHours: 13,
    endTime: "2:00 PM",
  },
  {
    startTime: "2:00 PM",
    startHours: 14,
    endTime: "3:00 PM",
  },
  {
    startTime: "3:00 PM",
    startHours: 15,
    endTime: "4:00 PM",
  },
  {
    startTime: "4:00 PM",
    startHours: 16,
    endTime: "5:00 PM",
  },
  {
    startTime: "5:00 PM",
    startHours: 17,
    endTime: "6:00 PM",
  },
  {
    startTime: "6:00 PM",
    startHours: 18,
    endTime: "7:00 PM",
  },
  {
    startTime: "7:00 PM",
    startHours: 19,
    endTime: "8:00 PM",
  },
  {
    startTime: "8:00 PM",
    startHours: 20,
    endTime: "9:00 PM",
  },
  {
    startTime: "9:00 PM",
    startHours: 21,
    endTime: "10:00 PM",
  },
  {
    startTime: "10:00 PM",
    startHours: 22,
    endTime: "11:00 PM",
  },
  {
    startTime: "11:00 PM",
    startHours: 23,
    endTime: "12:00 AM",
  },
];
