import React, { useEffect, useState } from "react";
import "./People.css";
import { Table, Button, Input, Select, Tooltip, Modal } from "antd";
import { SearchOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import { getPeople } from "../../redux/slice/people";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { UserDataCard } from "../common/Modal/ProfileModal";
import {
  getJobTitles,
  getCountryList,
  getZones,
  getEventsForListing,
  getBrandsForListing,
} from "../../utils/commonApis";
import { Filter } from "../../components/svgJS/Filter";
import { isRouteExistInMenu } from "../../utils/commonFunctions";
import { Link } from "react-router-dom";
import { PAGINATION_DEFAULT_SETTINGS } from "../../components/constsnts/common.constants";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { useMediaQuery } from "react-responsive";
import { PeopleDataCard } from "./PeopleDataCard";
// let placeholder_image = require("../../assets/images/profile_placeholder.png");

import placeholder_image from "../../assets/images/profile_placeholder.png";
import { setPeopleEventData } from "../../redux/slice/headerData";

const People = () => {
  const { Option } = Select;
  var CryptoJS = require("crypto-js");
  const dispatch = useDispatch();
  const peopleSelector = useSelector((state) => state.people.data);
  const peopleEventData = useSelector((state) => state.header.peopleEventData);

  let loading = useSelector((state) => state.people.loading);
  const [showFilter, setShowFilter] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [mobileUserPopupdata, setmobileUserPopupdata] = useState({
    isOpen: false,
    data: null,
  });
  const [jobTitle, setJobTitle] = useState([]);
  const [zoneList, setZoneList] = useState();
  const [eventList, setEventList] = useState([]);
  const [fields, setFields] = useState({
    event_id: null,
    brand_id: null,
    zone_id: null,
    user_type: null,
    country_id: null,
    job_title_id: null,
    brand_booth_visited: null,
  });
  const [userType, setUserType] = useState("");
  const [searchData, setSearchData] = useState();
  const [page_no, set_page_no] = useState(PAGINATION_DEFAULT_SETTINGS.page_no);
  const [no_of_records, set_no_of_records] = useState(
    PAGINATION_DEFAULT_SETTINGS.no_of_records
  );
  const isMobile = useMediaQuery({ minWidth: 480 });

  useEffect(() => {
    return () => {
      dispatch(
        setPeopleEventData({
          key: "",
          value: null,
        })
      );
    };
  }, []);

  useEffect(async () => {
    let is_route_present = await isRouteExistInMenu(CryptoJS);
    if (is_route_present === false) {
      window.location.href = "/pagenotfound";
    } else {
      const ciphertext = localStorage.getItem("user_type");
      var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUserType(decryptedData);
      window.scrollTo(0, 0);
      let eventResponse = await getEventsForListing({ is_filter: true });
      setEventList(eventResponse);
    }
  }, [CryptoJS.AES]);

  useEffect(() => {
    const searchObj = {
      search: searchData,
      event_id: fields?.event_id,
      brand_id: fields?.brand_id,
      zone_id: fields?.zone_id,
      user_type: fields?.user_type,
      country_id: fields?.country_id,
      job_title_id: fields?.job_title_id,
      page_no,
      no_of_records,
    };
    dispatch(getPeople(searchObj));
  }, [
    searchData,
    fields?.event_id,
    fields?.brand_id,
    fields?.zone_id,
    fields?.user_type,
    fields?.country_id,
    fields?.job_title_id,
  ]);

  useEffect(async () => {
    const brandParams = {
      is_filter: true,
      event_id: fields?.event_id,
    };

    let brandResponse = await getBrandsForListing(brandParams);
    setBrandList(brandResponse);
  }, [fields?.event_id]);

  useEffect(async () => {
    const zoneParams = {
      event_id: fields?.event_id,
      brand_id: fields?.brand_id,
    };
    const zonesHere = await getZones(zoneParams);
    setZoneList(zonesHere);
  }, [fields?.event_id, fields?.brand_id]);

  useEffect(async () => {
    const countryParams = {
      is_filter: true,
      event_id: fields?.event_id,
      brand_id: fields?.brand_id,
      zone_id: fields?.zone_id,
      user_type: fields?.user_type,
    };
    const countryListAPI = await getCountryList(countryParams);
    setCountryList(countryListAPI);

    const Jobparams = {
      is_filter: true,
      event_id: fields?.event_id,
      brand_id: fields?.brand_id,
      zone_id: fields?.zone_id,
      user_type: fields?.user_type,
    };
    const jobTitlesListAPI = await getJobTitles(Jobparams);
    setJobTitle(jobTitlesListAPI);
  }, [fields?.event_id, fields?.brand_id, fields?.zone_id, fields?.user_type]);

  useEffect(() => {
    if (peopleEventData?.value) {
      handleFilterChange("event_id", peopleEventData?.value);
    }
  }, [peopleEventData?.value]);

  const handleFilterChange = (name, val) => {
    if (name === "event_id") {
      setFields({
        ...fields,
        [name]: val,
        brand_id: null,
        zone_id: null,
        user_type: null,
        country_id: null,
        job_title_id: null,
      });
    } else if (name === "brand_id") {
      setFields({
        ...fields,
        [name]: val,
        zone_id: null,
        user_type: null,
        country_id: null,
        job_title_id: null,
      });
    } else if (name === "zone_id") {
      setFields({
        ...fields,
        [name]: val,
        user_type: null,
        country_id: null,
        job_title_id: null,
      });
    } else if (name === "user_type") {
      setFields({
        ...fields,
        [name]: val,
        country_id: null,
        job_title_id: null,
      });
    } else {
      setFields({ ...fields, [name]: val });
    }
  };

  const handleInputSearch = (e) => {
    setSearchData(e.target.value);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    let sortObj = {
      page_no: pagination?.current ?? page_no,
      no_of_records,
    };
    if (
      pagination &&
      pagination.pageSize &&
      no_of_records !== pagination.pageSize
    ) {
      set_page_no(1);
      set_no_of_records(pagination.pageSize);
      sortObj = {
        page_no: 1,
        no_of_records: pagination.pageSize,
      };
    }
    sortObj = {
      ...sortObj,
      sort_type: sorter.columnKey,
      sort_by: sorter.order === "ascend" ? "ASC" : "DSC",
    };
    dispatch(getPeople(sortObj));
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "user_id",
      key: "user_id",
      width: 70,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      width: 150,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Profile",
      dataIndex: "profile",
      width: 120,
      align: "center",
      render: (record) =>
        record ? (
          <img src={record} alt="pro pic" className="user-profile-picture" />
        ) : (
          "NA"
        ),
    },
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "User Type",
      dataIndex: "user_type",
      key: "user_type",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (name, record) => (
        <Tooltip
          placement="topLeft"
          title={record?.is_admin ? "BRAND ADMIN" : name}
        >
          {record?.is_admin ? "BRAND ADMIN" : name}
        </Tooltip>
      ),
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      width: 140,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "emailaddress",
      key: "emailaddress",
      width: 190,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "Event",
      dataIndex: "eventdomainname",
      key: "eventdomainname",
      width: 130,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      width: 110,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "",
      dataIndex: "mobile",
      className: isMobile && "d-none",
      key: "mobile",
      width: 110,
      ellipsis: {
        showTitle: false,
      },
      render: (_, record) => (
        <Button
          className="people_dropdown_downBtn border-0 px-2"
          onClick={async () => {
            await setmobileUserPopupdata({ isOpen: true, data: record });
          }}
        >
          <DownOutlined />
        </Button>
      ),
    },
  ];

  const tableData =
    peopleSelector?.rows &&
    peopleSelector?.rows.map((elem) => {
      return {
        ...elem,
        key: elem?.user_id,
        company: elem?.company,
        user_id: elem?.user_id,
        created_at: moment(elem.created_at).format("DD-MM-YYYY HH:mm"),
        username: elem?.user_name,
        eventdomainname: elem?.event_name,
        emailaddress: elem?.email,
        brand: elem.brand_title ?? "NA",
        profile: !elem?.image_url?.includes(null)
          ? elem?.image_url
          : placeholder_image,
        user_type: elem?.user_type,
        tier_name: elem?.tier,
        bio: elem?.bio,
        job_title: elem?.job_title,
        country: elem?.country,
        is_active: elem.is_active,
        users_users_social_media_handles_user_id:
          elem?.users_users_social_media_handles_user_id,
        is_chat_access: elem?.is_chat_access,
        is_video_call_access: elem?.is_video_call_access,
        is_calendar_access: elem?.is_calendar_access,
      };
    });

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center py-1">
        <div>
          <h4 className="fs-24 mt-1">People</h4>
        </div>
        <div>
          {peopleSelector && peopleSelector?.is_invite_access === true ? (
            <Link
              to={{
                pathname: "inviteAttend",
                state: { fromPeople: true },
              }}
            >
              <Button className="rounded px-3 mt-1 people_inviteBtn">
                <span className="fw-bold"> Invite to Attend </span>
              </Button>
            </Link>
          ) : null}
        </div>
      </div>
      <div className="people_container w-100 ">
        <div className="search_container">
          <div className="d-flex justify-content-between mb-4">
            <div className="w-75 mt-3">
              <Input
                size="medium"
                value={searchData}
                onChange={handleInputSearch}
                placeholder="Search by User Name, ID, Brand, Event Name, Customer"
                style={{ width: "70%" }}
                className="mx-3"
                prefix={<SearchOutlined />}
              />
            </div>
            <div className="d-flex mt-3">
              <Button
                className="rounded mx-3"
                onClick={() => setShowFilter(!showFilter)}
              >
                <Filter />
                <span className="ms-2"> FILTER</span> <DownOutlined />
              </Button>
            </div>
          </div>
          {showFilter ? (
            <div className="mb-3 mt-1">
              <div className="w-100">
                <div className="d-flex people_filter_container">
                  <Select
                    allowClear
                    name="brand_id"
                    placeholder="Select Event"
                    className="people_filter_select mx-1"
                    onChange={(val) => handleFilterChange("event_id", val)}
                    listHeight={120}
                    listItemHeight={4}
                    showSearch
                    optionFilterProp="children"
                    value={fields?.event_id}
                  >
                    {eventList &&
                      eventList?.map((data) => {
                        return (
                          <Option value={data.event_id}>
                            {data.event_name}
                          </Option>
                        );
                      })}
                  </Select>
                  <Select
                    allowClear
                    name="brand_id"
                    placeholder="Select Brand"
                    className="people_filter_select mx-1"
                    onChange={(val) => handleFilterChange("brand_id", val)}
                    listHeight={120}
                    listItemHeight={4}
                    showSearch
                    optionFilterProp="children"
                    value={fields?.brand_id}
                  >
                    {brandList &&
                      brandList?.map((data) => {
                        return (
                          <Option value={data.brand_id}>
                            {data.brand_title}
                          </Option>
                        );
                      })}
                  </Select>

                  <Select
                    placeholder="Select Zone"
                    className="people_filter_select mx-1"
                    onChange={(val) => handleFilterChange("zone_id", val)}
                    listHeight={120}
                    listItemHeight={4}
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    value={fields?.zone_id}
                  >
                    {zoneList &&
                      zoneList.map((data) => (
                        <Option value={data.zone_id}>{data.zone_title}</Option>
                      ))}
                  </Select>
                  <Select
                    placeholder="Select User Type"
                    className="people_filter_select mx-1"
                    onChange={(val) => handleFilterChange("user_type", val)}
                    allowClear
                    listHeight={120}
                    listItemHeight={4}
                    showSearch
                    optionFilterProp="children"
                    value={fields?.user_type}
                  >
                    <Option value="AGENT">AGENT</Option>
                    <Option value="DELEGATE">DELEGATE</Option>
                    <Option value="SPEAKER">SPEAKER</Option>
                  </Select>

                  <Select
                    placeholder="Select Country"
                    className="people_filter_select mx-1"
                    onChange={(val) => handleFilterChange("country_id", val)}
                    allowClear
                    listHeight={120}
                    listItemHeight={4}
                    showSearch
                    optionFilterProp="children"
                    value={fields?.country_id}
                  >
                    {countryList &&
                      countryList?.map((data) => {
                        return (
                          <Option value={data.country_id}>
                            {data.country}
                          </Option>
                        );
                      })}
                  </Select>
                  <Select
                    placeholder="Select Job"
                    className="people_filter_select mx-1"
                    onChange={(val) => handleFilterChange("job_title_id", val)}
                    allowClear
                    listHeight={120}
                    listItemHeight={4}
                    showSearch
                    optionFilterProp="children"
                    value={fields?.job_title_id}
                  >
                    {jobTitle &&
                      jobTitle?.map((data) => {
                        return (
                          <Option value={data.job_title_id}>
                            {data.job_title}
                          </Option>
                        );
                      })}
                  </Select>
                </div>
              </div>
            </div>
          ) : null}
          <div className="bg-white">
            <Table
              id="people_table_id"
              className="people_table px-4 mt-4"
              columns={columns}
              dataSource={tableData}
              rowkey={(record) => record.key}
              pagination={{
                showSizeChanger: true,
                pageSize: no_of_records,
                total: peopleSelector?.count ? peopleSelector.count : 1,
              }}
              loading={{
                indicator: <CommonLoader />,
                spinning: loading,
              }}
              scroll={{ x: 1300, y: 441 }}
              bordered={false}
              expandIconColumnIndex={isMobile ? 11 : null}
              expandable={
                isMobile
                  ? {
                      expandedRowRender: (record, index) => (
                        <PeopleDataCard
                          isFromPeople={true}
                          userData={{
                            image_url: record?.profile,
                            ...record,
                            user_type: record?.is_admin
                              ? "BRAND ADMIN"
                              : record.user_type,
                          }}
                        />
                      ),
                    }
                  : false
              }
              expandRowByClick
              expandIcon={({ expanded, onExpand, record }) =>
                expanded ? (
                  <Button
                    className="people_dropdown_upBtn border-0 px-2"
                    onClick={(e) => onExpand(record, e)}
                  >
                    <UpOutlined />
                  </Button>
                ) : (
                  <Button
                    className="people_dropdown_downBtn border-0 px-2"
                    onClick={(e) => onExpand(record, e)}
                  >
                    <DownOutlined />
                  </Button>
                )
              }
              onChange={handleTableChange}
            />
          </div>
        </div>
      </div>
      {mobileUserPopupdata?.isOpen && (
        <Modal
          visible={mobileUserPopupdata?.isOpen}
          onCancel={() => setmobileUserPopupdata({ isOpen: false, data: null })}
          onOk={() => setmobileUserPopupdata({ isOpen: false, data: null })}
        >
          <UserDataCard
            isFromPeople={true}
            isMobile={true}
            userData={{
              image_url: mobileUserPopupdata?.data?.profile,
              ...mobileUserPopupdata?.data,
            }}
          />
        </Modal>
      )}
      ;
    </div>
  );
};

export default People;
