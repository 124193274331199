import React, { Fragment, useState, useEffect, useRef } from "react";
import { Paper } from "@material-ui/core";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  WeekView,
  Toolbar,
  DateNavigator,
  Appointments,
  TodayButton,
  AppointmentTooltip,
} from "@devexpress/dx-react-scheduler-material-ui";
import { Button, Modal, Tooltip } from "antd";
import { Chat } from "../../components/svgJS/Chat";
import {
  VideoCameraOutlined,
  MenuUnfoldOutlined,
  SearchOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { wallClock, booth } from "../../components/Images/Images";
import "./MyCalendar.css";
import { ApiCall } from "../../utils/api";
import { toast } from "react-toastify";
import SearchBar from "../common/SearchBar";
import SimpleReactValidator from "simple-react-validator";
import RedAsterick from "../../components/Widgets/RedAsterick";
import { sendMessageAPI } from "../../utils/commonApis";
import {
  getComentChatUser,
  isRouteExistInMenu,
  isPastDate,
  createTZString,
} from "../../utils/commonFunctions";

import { useSelector, useDispatch } from "react-redux";

import { useMediaQuery } from "react-responsive";
import NetViewDelegates from "../commonPopups/NetViewDelegates";
import AudiViewDelegates from "../commonPopups/AudiViewDelegates";
import ZoneSessionView from "../commonPopups/ZoneSessionView";
import UpdateMeeting from "../commonPopups/UpdateMeeting";
import ViewMeetingDelegate from "../commonPopups/ViewMeetingDelegate";
import SessionRejected from "../commonPopups/SessionRejected";
import SessionConfirmation from "../commonPopups/SessionConfirmation";
import PastDateDelegate from "../commonPopups/PastDateDelegate";
import { setCalendarNotificationData } from "../../redux/slice/headerData";
import { isEmpty } from "lodash";

const MyCalendar = () => {
  var CryptoJS = require("crypto-js");
  let attendeesToSend = [];
  const [currentDate, setCurrentDate] = useState(moment().format("yyyy-MM-DD"));
  const [appointmentDetails, setAppointmentDetails] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [userResponse, setUserResponse] = useState({});
  const [messageModal, setMessageModal] = useState({ isOpen: false, data: {} });
  const [appoinmentModalVisible, setAppoinmentModalVisible] = useState(true);
  const isMobile = useMediaQuery({ minWidth: 400 });
  const [showtooltip, setshowtooltip] = useState(false);
  const [color, setColor] = useState(); //kept for R&D bugs
  const [minStartTime, setMinStartTime] = useState(); //kept for R&D bugs
  const [message_text, set_message_text] = useState("");
  const [userType, setUserType] = useState("");
  const [cometChatUser, setCometChatUser] = useState({
    isValidUser: false,
    data: {},
    uid: "",
  });
  const [toggle, setToggle] = useState(false);
  const messageValidator = useRef(new SimpleReactValidator());
  const [forceUpdate, setForceUpdate] = useState(false);

  // update meet initial state
  const [date, setDate] = useState(null); //kept for r&d

  const [notificationPopupData, setNotificationPopupData] = useState(null);

  const myCalendarAppoinmentVisible = useSelector(
    (state) => state?.videoConference?.myCalendarAppoinmentVisible
  );

  var startOfWeek = moment(currentDate).startOf("week").toDate();
  var endOfWeek = moment(currentDate).endOf("week").toDate();
  const notificationCalendarData = useSelector(
    (state) => state?.header?.notificationForCalendar
  );
  const dispatch = useDispatch();

  useEffect(async () => {
    let is_route_present = await isRouteExistInMenu(CryptoJS);

    if (is_route_present === false) {
      window.location.href = "/pagenotfound";
    } else {
      const ciphertext = localStorage.getItem("user_type");
      var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUserType(decryptedData);

      const cometRes = await getComentChatUser();
      setCometChatUser(cometRes);

      getMyCalendarAppointmentDetails();

      setUserResponse({
        event_id: selectedAppointment && selectedAppointment?.event_id,
        hall_type: selectedAppointment && selectedAppointment?.hall_type,
        hall_id: selectedAppointment && selectedAppointment?.hall_id,
        session_id: selectedAppointment && selectedAppointment?.session_id,
        invitation_setup_id:
          selectedAppointment && selectedAppointment?.invitation_setup_id,
        rsvp_type: "YES",
        hosting_speaker_id:
          selectedAppointment && selectedAppointment?.hosting_speaker_id,
        rsvp_detail_id:
          selectedAppointment && selectedAppointment?.rsvp_detail_id,
      });

      attendeesToSend = selectedAppointment?.meeting_attendees_details?.map(
        (data) => data.user_id
      );

      setDate(selectedAppointment?.start_date_time);
    }
  }, [selectedAppointment, userResponse?.rsvp_type, currentDate, CryptoJS.AES]);

  useEffect(() => {
    if (myCalendarAppoinmentVisible) {
      setAppoinmentModalVisible(myCalendarAppoinmentVisible);
    }
  }, [myCalendarAppoinmentVisible]);

  useEffect(async () => {
    if (
      notificationCalendarData?.hallType &&
      notificationCalendarData?.sessionId
    ) {
      var startOfYear = moment(currentDate).startOf("year").toDate();
      var endOfYear = moment(currentDate).endOf("year").toDate();

      let calendarDetails = await ApiCall(
        "get",
        null,
        "/admin/mycalendar/details/get",
        {
          start_date: moment(startOfYear).format("YYYY-MM-DD"),
          end_date: moment(endOfYear).format("YYYY-MM-DD"),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
      );

      let data = calendarDetails?.data?.data?.find((item) => {
        if (
          item?.hall_type === notificationCalendarData?.hallType &&
          item?.session_id === notificationCalendarData?.sessionId
        ) {
          return item;
        }
      });

      if (!isEmpty(data)) {
        const d1 = new Date(data?.start_date_time);
        const st = d1.toISOString().replace("Z", "");
        const d2 = new Date(data?.end_date_time);
        const et = d2.toISOString().replace("Z", "");

        let start_date_time = createTZString(data?.start_date_time);
        let newStartTime = start_date_time.format("YYYY-MM-DD HH:mm");

        let end_date_time = createTZString(data?.end_date_time);
        let newEndTime = end_date_time.format("YYYY-MM-DD HH:mm");

        data = {
          ...data,
          startDate: newStartTime,
          endDate: newEndTime,
          startTime: st,
          endTime: et,
          title: data?.session_title,
          description: data?.session_description,
        };

        setNotificationPopupData(data);
        setIsNotificationModalOpen(true);
        dispatch(
          setCalendarNotificationData({
            hallType: "",
            sessionId: null,
          })
        );
      }
    }
  }, [notificationCalendarData]);

  function clearMessageAndModal() {
    set_message_text("");
    setMessageModal({ isOpen: false, data: {} });
  }

  async function callSendMessageAPI(channel_id) {
    if (messageValidator.current.allValid()) {
      if (cometChatUser.uid) {
        const response = await sendMessageAPI(channel_id, message_text);
        if (response.data.code === 200) {
          toast.success(response.data.message);
          clearMessageAndModal();
        } else {
          toast.error(response.data.message);
        }
      } else setForceUpdate(!forceUpdate);
    } else {
      messageValidator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
  }

  const getMyCalendarAppointmentDetails = async () => {
    await ApiCall("get", null, "/admin/mycalendar/details/get", {
      start_date: moment(startOfWeek).format("YYYY-MM-DD"),
      end_date: moment(endOfWeek).format("YYYY-MM-DD"),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
      .then((response) => {
        setAppointmentDetails(response?.data?.data ? response?.data?.data : []);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const Appointment = ({ children, style, ...restProps }) => {
    return (
      <Appointments.Appointment
        {...restProps}
        style={{
          ...style,
          backgroundColor: restProps?.data?.brand_color,
          borderRadius: "8px",
        }}
      >
        {children}
      </Appointments.Appointment>
    );
  };

  const toggleEditingTooltipVisibility = () => {
    setshowtooltip(!showtooltip);
  };

  const formattedData =
    appointmentDetails && appointmentDetails
      ? appointmentDetails.map((data) => {
          const d1 = new Date(data.start_date_time);
          const st = d1.toISOString().replace("Z", "");
          const d2 = new Date(data.end_date_time);
          const et = d2.toISOString().replace("Z", "");

          let start_date_time = createTZString(data.start_date_time);
          let newStartTime = start_date_time.format("YYYY-MM-DD HH:mm");

          let end_date_time = createTZString(data.end_date_time);
          let newEndTime = end_date_time.format("YYYY-MM-DD HH:mm");

          return {
            ...data,
            startDate:
              data?.hall_type === "MEETING"
                ? moment(st).format("YYYY-MM-DD HH:mm")
                : newStartTime,
            endDate:
              data?.hall_type === "MEETING"
                ? moment(et).format("YYYY-MM-DD HH:mm")
                : newEndTime,

            title: data.session_title,

            startTime: st,
            endTime: et,
            title: data?.session_title,
            description: data?.session_description,
          };
        })
      : "";
  const Header = ({ children, appointmentData, ...restProps }) => {
    return (
      <div className="d-flex justify-content-between edit_popup_header ant-modal-header">
        <div className="ant-modal-title">
          <p>Session Details</p>
        </div>
        <div
          className="my-auto"
          style={{ cursor: "pointer" }}
          onClick={() => toggleEditingTooltipVisibility()}
        >
          <CloseOutlined />
        </div>
      </div>
    );
  };

  const Content = ({ children, appointmentData, ...restProps }) => {
    setSelectedAppointment(appointmentData);
    setColor(appointmentData.brand_color);

    let sessionDateIsPassed = isPastDate(appointmentData?.start_date);

    return ((userType && userType === "DELEGATE") ||
      userType === "AGENT" ||
      userType === "SPEAKER") &&
      sessionDateIsPassed === "yes" ? (
      <div>
        <PastDateDelegate isFromCalender="zone" />
      </div>
    ) : appointmentData.hall_type === "SATELLITE" ||
      appointmentData.hall_type === "MAIN" ? (
      <div>
        <AudiViewDelegates
          data={appointmentData}
          visibleChange={toggleEditingTooltipVisibility}
          closeTooltip={() => {
            toggleEditingTooltipVisibility();
          }}
          isFromCalender="zone"
        />
      </div>
    ) : ((userType && userType === "DELEGATE") ||
        userType === "AGENT" ||
        userType === "SPEAKER") &&
      appointmentData.hall_type === "NETWORKING" ? (
      <div className="row my-0 p-0 m-0">
        <NetViewDelegates data={appointmentData} isFromCalender="zone" />
      </div>
    ) : appointmentData.hall_type === "MEETING" &&
      appointmentData.can_edit === false ? (
      <div>
        <ViewMeetingDelegate data={appointmentData} isFromCalender="zone" />
      </div>
    ) : appointmentData.rsvp_type === "YES" &&
      appointmentData.hall_type === "MEETING" &&
      appointmentData.can_edit === true ? (
      <UpdateMeeting data={appointmentData} isFromCalender="zone" />
    ) : appointmentData.hall_type === "PRODUCT ZONE" ? (
      <ZoneSessionView data={appointmentData} isFromCalender="zone" />
    ) : appointmentData.rsvp_type === "YES" ? (
      <div>
        <div className="row">
          <div className="col-1"></div>
          <div className="col-1">
            <img src={wallClock} />
          </div>
          <div className="col-5 fs-14">
            <b>
              {appointmentData.start_time} - {appointmentData.end_time}
            </b>
          </div>
        </div>
        {appointmentData.channel_id !== 0 ? (
          <div className="row mt-3">
            <div className="col-2"></div>
            <div className="col-1">
              <Tooltip
                color="#ef0855"
                placement="right"
                title={"Chat"}
                className=""
              >
                <Button
                  className="border px-2"
                  onClick={() => {
                    setMessageModal({
                      isOpen: true,
                      channel_id: appointmentData.channel_id,
                    });
                  }}
                >
                  <Chat />
                </Button>
              </Tooltip>
            </div>

            <div className="col-8">
              <span className="fs-16 popup_text_main">
                Go to Moderation or Chat
              </span>

              <br />
              <span className="fs-13">
                At vero eos et accusamus et iusto odio dignissimos ducimus
              </span>
            </div>
            <div className="col-1"></div>
          </div>
        ) : null}
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8">
            {messageModal.isOpen ? (
              <div className="w-100">
                <div className="w-100 row mt-3">
                  <div className="d-block col-2">
                    <p>
                      Message
                      <RedAsterick />
                    </p>
                  </div>
                  <div className="w-75 col-8">
                    <input
                      name="message_text"
                      id="message_text"
                      className=" w-100 mb-2 p-2 border border-secondary rounded"
                      placeholder="Enter a message"
                      value={message_text}
                      onChange={(e) => set_message_text(e.target.value)}
                      onBlur={() =>
                        messageValidator.current.showMessageFor("message_text")
                      }
                      autoFocus={true}
                    />
                    <Button
                      className="px-3 my-2 rounded"
                      key="save"
                      type="primary"
                      onClick={() =>
                        callSendMessageAPI(messageModal?.channel_id)
                      }
                    >
                      Send
                    </Button>
                    <Button
                      className="px-3 my-2 rounded mx-2"
                      key="save"
                      type="primary"
                      onClick={() => {
                        setMessageModal({
                          ...messageModal,
                          isOpen: false,
                        });
                      }}
                    >
                      Close
                    </Button>
                    {messageValidator.current.message(
                      "Message",
                      message_text,
                      "required",
                      { className: "text-danger py-1" }
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-2"></div>
          <div className="col-1">
            <img src={booth} className="admin_popup_icons px-2" />
          </div>
          <div className="col-8">
            <span className="fs-16 popup_text_main">
              Go to Booth or Session
            </span>
            <br />
            <span className="fs-13">
              Lorem Ipsum is simply dummy text of the printing and typesetting
            </span>
          </div>
          <div className="col-1"></div>
        </div>
        <div className="row mt-3 mb-2">
          <div className="col-2"></div>
          <div className="col-1">
            <VideoCameraOutlined
              className="admin_popup_icons"
              onClick={() =>
                window.open(appointmentData.join_session_url, "_blank").focus()
              }
            />
          </div>
          <div className="col-8">
            <span
              className="fs-16 popup_text_main"
              onClick={() =>
                window.open(appointmentData.join_session_url, "_blank").focus()
              }
            >
              Go to Speakers Room
            </span>
            <br />
            <span className="fs-13">
              The point of using Lorem Ipsum is that it has a more-or-less
              normal
            </span>
          </div>
          <div className="col-1"></div>
        </div>
      </div>
    ) : appointmentData.rsvp_type === "NO" ? (
      <div>
        <SessionRejected data={appointmentData} isFromCalender="zone" />
      </div>
    ) : (
      <div>
        <SessionConfirmation data={appointmentData} isFromCalender="zone" />
      </div>
    );
  };

  const minTime = Math.min(
    ...formattedData?.map((element) => {
      return element.startHour;
    })
  );

  setTimeout(() => {
    setMinStartTime(minTime);
  }, 5000);

  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);

  return (
    <Fragment>
      <h4 className="fs-24 my-2">My Calendar</h4>

      {isNotificationModalOpen ? (
        <Modal
          width={950}
          title="Session Details"
          open={isNotificationModalOpen}
          visible={isNotificationModalOpen}
          onCancel={() => setIsNotificationModalOpen(false)}
          footer={null}
        >
          {notificationPopupData.hall_type === "SATELLITE" ||
          notificationPopupData.hall_type === "MAIN" ? (
            <AudiViewDelegates
              data={notificationPopupData}
              // visibleChange={toggleEditingTooltipVisibility}
              closeTooltip={() => {
                // seIsFromNotification(false);
              }}
              isFromCalender="zone"
            />
          ) : notificationPopupData.hall_type === "NETWORKING" ? (
            <div className="row my-0 p-0 m-0">
              <NetViewDelegates
                data={notificationPopupData}
                isFromCalender="zone"
              />
            </div>
          ) : notificationPopupData.hall_type === "PRODUCT ZONE" ? (
            <ZoneSessionView
              data={notificationPopupData}
              isFromCalender="zone"
            />
          ) : notificationPopupData.hall_type === "MEETING" &&
            notificationPopupData.can_edit === false ? (
            <div>
              <ViewMeetingDelegate
                data={notificationPopupData}
                isFromCalender="zone"
              />
            </div>
          ) : notificationPopupData.rsvp_type === "YES" &&
            notificationPopupData.hall_type === "MEETING" &&
            notificationPopupData.can_edit === true ? (
            <UpdateMeeting data={notificationPopupData} isFromCalender="zone" />
          ) : notificationPopupData.rsvp_type === "NO" ? (
            <div>
              <SessionRejected
                data={notificationPopupData}
                isFromCalender="zone"
              />
            </div>
          ) : (
            <div>
              <SessionConfirmation
                data={notificationPopupData}
                isFromCalender="zone"
              />
            </div>
          )}
        </Modal>
      ) : null}

      <Paper className="d-flex">
        {toggle === true ? (
          <Paper
            className={`w-25 p-3 border border-end ${
              isMobile ? "d-flex" : "d-none"
            }`}
          >
            <span className="w-100">
              <SearchBar
                placeholder="Search Sponsor"
                className="rounded w-100"
                hideShowData={toggle}
                details={appointmentDetails}
              />
            </span>
            <span className="mx-2">
              <MenuUnfoldOutlined
                style={{ fontSize: "18px" }}
                onClick={() => setToggle(false)}
              />
            </span>
          </Paper>
        ) : (
          <Paper
            className={`p-3 border border-end d-flex  ${
              isMobile ? "d-flex" : "d-none"
            }`}
          >
            <span className="w-100"></span>
            <span className="mx-2">
              <SearchOutlined
                style={{ fontSize: "18px" }}
                onClick={() => setToggle(true)}
              />
            </span>
          </Paper>
        )}

        {/* {isFromNotification && notificationData.aud ? (
          <AudiViewDelegates
            data={appointmentData}
            // visibleChange={toggleEditingTooltipVisibility}
            closeTooltip={() => {
             seIsFromNotification(false)
            }}
            isFromCalender="zone"
          />
        ) : null} */}

        <Scheduler
          data={formattedData}
          // height={660}
          // width={50}
          style={{ border: "1px solid red" }}
          disableEnforceFocus
        >
          <ViewState
            currentDate={currentDate}
            onCurrentDateChange={(e) => setCurrentDate(e)}
          />
          <Toolbar />
          <DateNavigator />
          <TodayButton />
          <WeekView
            startDayHour={0}
            endDayHour={24}
            cellDuration={60}
            // timeTableCellComponent={TimeTableCell}
          />

          <Appointments appointmentComponent={Appointment} disableEnforceFocus>
            <Appointments.Appointment
              disableEnforceFocus
              style={{ backgroundColor: "green" }}
            ></Appointments.Appointment>
          </Appointments>
          {/* ---------------This Code is Kept for Current Time R&D ------------- */}
          {/* <CurrentTimeIndicator
            indicatorComponent={() => (
              <div ref={scollToRef} className="current_time_indicator"></div>
            )}
          /> */}
          {appoinmentModalVisible ? (
            <AppointmentTooltip
              headerComponent={Header}
              contentComponent={Content}
              showCloseButton
              visible={showtooltip}
              onVisibilityChange={() => setshowtooltip(!showtooltip)}
            />
          ) : null}
        </Scheduler>
      </Paper>
    </Fragment>
  );
};

export default MyCalendar;
