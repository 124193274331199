import React, { useEffect, useState, useRef } from "react";
import "./Auditorium.css";
import {
  Row,
  Col,
  Carousel,
  Button,
  Select,
  DatePicker,
  Divider,
  Empty,
  Input,
  Tooltip,
  Avatar,
  Modal,
  Result,
} from "antd";
import moment from "moment";
import { isEmpty } from "lodash";
import { SearchOutlined, SmileOutlined } from "@ant-design/icons";
import { Booth } from "../../components/svgJS/Booth";
import { Chat } from "../../components/svgJS/Chat";
import { Video } from "../../components/svgJS/Video";
import { Download } from "../../components/svgJS/Download";
import { Calendar6 } from "../../components/svgJS/Calendar6";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { User } from "../../components/svgJS/User";
import {
  addToMyCalender,
  getEvents,
  updateAssetDownloadCount,
} from "../../utils/commonApis";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  getEventAudiDetails,
  getAudiSessionDetails,
} from "../../redux/slice/auditorium";
import SimpleReactValidator from "simple-react-validator";
import RedAsterick from "../../components/Widgets/RedAsterick";
import {
  sendMessageAPI,
  videoRequestAPI,
  fetchChatbotHistory,
  getChatbotJson,
  saveChatbotHistory,
} from "../../utils/commonApis";
import {
  DEFAULT_CHATBOT_HISTORY,
  TIME_FORMAT,
} from "../../components/constsnts/common.constants";
import { ApiCall } from "../../utils/api";
import {
  checkIfChatbotTimeSlot,
  createISOTimeString,
  createTZString,
  getComentChatUser,
  isRouteExistInMenu,
  vistaRedirectToBooth,
} from "../../utils/commonFunctions";
import CustomChatbot from "../../components/Widgets/CustomChatbot";
import ProfileModal from "../common/Modal/ProfileModal";
import JoinSession from "../Popups/JoinSession";
import {
  INPUT_TEXT_LIMITS,
  TEXTAREA_TEXT_LIMITS,
} from "../../components/constsnts/common.constants";
import ICalendarLink from "react-icalendar-link";
import { useMediaQuery } from "react-responsive";
import { setVideoConferenceData } from "../../redux/slice/videoConference";
import placeholder_image from "../../../src/assets/images/profile_placeholder.png";

const Auditorium = (props) => {
  const messageValidator = useRef(new SimpleReactValidator());
  const { Option } = Select;
  let CryptoJS = require("crypto-js");
  const dateFormat = "LL";
  const isMobile = useMediaQuery({ minWidth: 400 });
  const { TextArea } = Input;
  const [disableEvent, setDisableEvent] = useState(false);
  const [userType, setUserType] = useState("");
  const vistaEventId = useSelector((state) => state?.vistaBrand?.eventId);
  const dispatch = useDispatch();
  const eventAudiDetails = useSelector(
    (state) => state?.auditorium?.eventAudiData
  );
  const audiSessionDetails = useSelector(
    (state) => state.auditorium.audiSessionData
  );
  const [eventList, setEventList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState(moment());
  const [sponsorData, setSponsorData] = useState();
  const [filteredSessions, setFilteredSessions] = useState([]);
  const localData_event_id = parseInt(localStorage.getItem("event_id"));
  const [eventid, setEventID] = useState();
  const [eventAudiData, setEventAudiData] = useState([]);
  const [messageModal, setMessageModal] = useState({ isOpen: false, data: {} });
  const [message_text, set_message_text] = useState("");
  const [cometChatUser, setCometChatUser] = useState({
    isValidUser: false,
    data: {},
    uid: "",
  });
  const [audiSessAvailable, setAudiSessAvailable] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [isChatBot, setIsChatBot] = useState(false);
  const [chatbot_json, set_chatbot_json] = useState([]);
  const [chatbot_history, set_chatbot_history] = useState(
    DEFAULT_CHATBOT_HISTORY
  );
  let [userNearbyArr, setUserNearbyArr] = useState([]);
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);
  const [userData, setUserData] = useState(null);
  const [openJoinSessionModal, setOpenJoinSessionModal] = useState(false);
  const [joinSessionPopupData, setJoinSessionPopupData] = useState(null);
  const [audiConfigId, setAuiConfigId] = useState(null);
  const [audiType, setAudiType] = useState("");
  const [audiTitle, setAudiTitle] = useState(""); //To check for if its  Main Audi, Sat 1, Sat 2
  const [userId, setUserId] = useState(null);
  const socket = props?.socket;

  useEffect(() => {
    const fetchLocation = (nearyByData) => {
      console.log("nearby data audi", nearyByData);
      console.log(
        parseInt(nearyByData?.data?.event_id),
        eventid,
        nearyByData?.data?.event_id === eventid,
        "nearby data compare event id"
      );
      if (parseInt(nearyByData?.data?.event_id) === eventid) {
        let userDetails = nearyByData?.data?.details?.filter((item) => {
          if (
            item?.user_id !== parseInt(userId) &&
            item?.location === "auditoriumPublished"
          ) {
            return item;
          }
        });
        console.log("nearby userDetails", userDetails);
        getUserLocationVista(userDetails);
      }
    };

    socket?.on("fetchLocation", fetchLocation);

    return () => {
      socket?.off("fetchLocation", fetchLocation);
    };
  }, [socket, eventid]);

  useEffect(async () => {
    setLoading(true);

    const ciphertext = localStorage.getItem("user_type");
    let bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    let user_type = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(user_type);

    if (localStorage.getItem("user_id")) {
      let user_id = null;
      const ciphertext = localStorage.getItem("user_id");
      let bytes = CryptoJS.AES.decrypt(ciphertext, "user_id");
      user_id = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUserId(user_id);
    }

    let is_route_present = await isRouteExistInMenu(CryptoJS);
    if (is_route_present === false) {
      window.location.href = "/pagenotfound";
    } else {
      const eventsData = await getEvents();
      let eventIdHere = "";
      if (
        user_type === "DELEGATE" ||
        user_type === "SPEAKER" ||
        user_type === "BRAND ADMIN" ||
        user_type === "AGENT"
      ) {
        const events = eventsData.filter((item) => {
          return item.event_id === localData_event_id;
        });
        setEventList(events);
        let id = events[0]?.event_id;
        eventIdHere = id;
        setEventID(id);

        setDisableEvent(true);
      } else {
        if (eventsData.length === 1) {
          setEventID(eventsData[0].event_id);
          eventIdHere = eventsData[0].event_id;
        }
        setEventList(eventsData);
      }

      if (eventIdHere) {
        const chatHistory = await fetchChatbotHistory({
          event_id: eventIdHere,
        });
        if (chatHistory && !isEmpty(chatHistory))
          set_chatbot_history(chatHistory);
      }
      const cometRes = await getComentChatUser();
      setCometChatUser(cometRes);
    }
    setLoading(false);
  }, [CryptoJS.AES]);

  useEffect(() => {
    if (currentDate && eventid)
      dispatch(
        getEventAudiDetails({
          event_id: eventid,
          date: currentDate.format("YYYY-MM-DD"),
        })
      );
  }, [currentDate, eventid]);

  useEffect(() => {
    if (eventAudiDetails && eventid) {
      initializeEventAudiData();
      // getUserLocationVista();
    }
  }, [eventAudiDetails]);

  useEffect(() => {
    if (eventAudiData && eventid) handleFilterChange(0);
  }, [eventAudiData]);

  useEffect(() => {
    if (audiSessionDetails && eventid) {
      checkSponsorAndSessionDateTime();
      checkAudiSessionAvailable();
    }
  }, [audiSessionDetails]);

  useEffect(() => {
    if (
      (window.location.pathname === "/expomap" ||
        window.location.pathname === "/concourse") &&
      vistaEventId
    )
      setEventID(vistaEventId);
  }, [vistaEventId]);

  const checkAudiSessionAvailable = () => {
    const arr = audiSessionDetails?.session_details?.filter((ele) => {
      const selectedDate = moment(currentDate).format("YYYY-MM-DD");
      console.log(ele.start_date_time, "ele.start_date_time");
      const start_date_time = createTZString(ele.start_date_time);
      const end_date_time = createTZString(ele.end_date_time);
      const eleStartDate = moment(
        start_date_time.format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      );
      console.log(eleStartDate.format("HH:mm"), "eleStartDate");
      const eleEndTime = moment(end_date_time.format("HH:mm"), TIME_FORMAT);

      const currentTime = moment(moment().format("HH:mm"), TIME_FORMAT);
      if (selectedDate === eleStartDate.format("YYYY-MM-DD")) {
        if (currentTime > eleEndTime) return;
        else return ele;
      }
    });
    setAudiSessAvailable(arr);
  };

  function clearMessageAndModal() {
    set_message_text("");
    setMessageModal({ isOpen: false, data: {} });
  }

  async function callSendMessageAPI(channel_id) {
    if (messageValidator.current.allValid()) {
      if (cometChatUser.uid) {
        const response = await sendMessageAPI(channel_id, message_text);
        if (response.data.code === 200) {
          toast.success(response.data.message);
          clearMessageAndModal();
        } else toast.error(response.data.message);
      } else setForceUpdate(!forceUpdate);
    } else {
      messageValidator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
  }

  const checkSponsorAndSessionDateTime = () => {
    let arr = [];
    audiSessionDetails?.sponsor_details?.forEach((ele) => {
      const selectedDate = moment(currentDate).format("YYYY-MM-DD");
      const start_date_time = createTZString(ele.start_date_time);
      const end_date_time = createTZString(ele.end_date_time);
      const eleStartDate = moment(
        start_date_time.format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      );
      const eleStartTime = moment(start_date_time.format("HH:mm"), TIME_FORMAT);
      const eleEndTime = moment(end_date_time.format("HH:mm"), TIME_FORMAT);
      const currentTime = moment().format("HH:mm");

      if (
        selectedDate === eleStartDate.format("YYYY-MM-DD") &&
        currentTime >= eleStartTime.format("HH:mm") &&
        currentTime <= eleEndTime.format("HH:mm")
      )
        arr.push(ele);
    });

    if (arr.length !== 0) {
      setSponsorData(arr[0]); // If sponsor is present.
    } else {
      //Default Sponsor
      let modifiedSponsorData = {
        ...audiSessionDetails?.default_sponsor_details,
        is_booth_link_enable:
          audiSessionDetails?.default_sponsor_details?.is_on_booth_leads_enable,
        is_demo_room_enable:
          audiSessionDetails?.default_sponsor_details
            ?.is_video_demo_room_enable,
        is_video_call_enable:
          audiSessionDetails?.default_sponsor_details
            ?.is_one_to_one_video_calls_enable,
        is_asset_enable:
          audiSessionDetails?.default_sponsor_details?.asset_file_url,
        logo_url: audiSessionDetails?.default_sponsor_details?.brand_logo,
      };
      setSponsorData(modifiedSponsorData);
    }
  };

  const initializeEventAudiData = () => {
    let selectedDate = moment(currentDate).format("YYYY-MM-DD");
    const arr = eventAudiDetails?.filter((ele) => {
      const start_date_time = createTZString(ele.start_date_time);

      const eleStartDate = moment.tz(start_date_time, "YYYY-MM-DD");

      if (!props?.isFromSatelliteAudi) {
        if (selectedDate === eleStartDate.format("YYYY-MM-DD")) return ele;
      } else {
        if (
          selectedDate === eleStartDate.format("YYYY-MM-DD") &&
          ele?.auditorium_type !== "MAIN"
        )
          return ele;
      }
    });

    setEventAudiData(arr);
  };

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="audi_next_arrow" onClick={onClick}>
        <i className="arrow right"></i>
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="audi_prev_arrow" onClick={onClick}>
        <i className="arrow left"></i>
      </div>
    );
  };

  const settings = {
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const handleFilterChange = (index) => {
    if (eventAudiData) {
      const item = eventAudiData.find((elem, i) => {
        return i === index;
      });
      if (item) {
        setLoading(true);
        setAuiConfigId(item.auditorium_configuration_id);
        setAudiType(item.auditorium_type);
        setAudiTitle(item?.auditorium_title);

        dispatch(
          getAudiSessionDetails({
            auditorium_configuration_id: item?.auditorium_configuration_id,
            auditorium_type: item?.auditorium_type,
            event_id: eventid,
          })
        );
      }
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const searchSessions = (e) => {
    let searchTerm = e.target.value.toLowerCase().trim();
    let allSearchedData = [];
    if (searchTerm !== "" || !isEmpty(searchTerm)) {
      audiSessionDetails &&
        audiSessionDetails?.session_details?.forEach((item) => {
          if (item?.session_title?.toLowerCase().includes(searchTerm)) {
            allSearchedData.push(item);
          }
        });
    }
    setFilteredSessions(allSearchedData);
  };

  const getUserLocationVista = async (userDetails) => {
    setUserNearbyArr([]);

    userDetails?.forEach((user) => {
      setUserNearbyArr((prev) => [
        ...prev,
        {
          user_name: user?.first_name + " " + user?.last_name,
          ...user,
        },
      ]);
    });

    // setUserNearbyArr([]);
    // const response = await ApiCall("GET", "", "/admin/vista/userlocation/get", {
    //   location: "auditoriumPublished",
    // });
    // if (
    //   response &&
    //   response.data.code === 200 &&
    //   response.data.data &&
    //   !isEmpty(response?.data?.data)
    // ) {
    //   response.data.data.forEach((user) => {
    //     setUserNearbyArr((prev) => [
    //       ...prev,
    //       {
    //         userName: user.first_name + " " + user.last_name,
    //         ...user,
    //       },
    //     ]);
    //   });
    // }
  };

  let chatbotRes = checkIfChatbotTimeSlot(sponsorData);

  if (loading) return <CommonLoader />;
  else
    return (
      <div className={props.isFromVista ? "bg-80vh bg-white p-2" : "bg-80vh"}>
        <div className="d-flex justify-content-between flex-wrap">
          <div className="d-flex align-items-center py-2">
            {!props.isFromVista ? (
              <div className="">
                <h4 className="fs-24 mt-1">Auditorium</h4>
                {/* <span className="opacity-50 ">Auditorium</span> */}
              </div>
            ) : null}
            {eventAudiData && eventAudiData.length !== 0 ? (
              <Carousel
                afterChange={handleFilterChange}
                className="audi_carousel "
                arrows
                {...settings}
              >
                {eventAudiData &&
                  eventAudiData?.map((data, i) => {
                    return (
                      <div
                        key={"eventAudi" + i}
                        className="d-flex justify-content-center align-items-center mt-2"
                      >
                        <h6>{data?.auditorium_title}</h6>
                      </div>
                    );
                  })}
              </Carousel>
            ) : null}
          </div>
          {userType === "SUPER ADMIN" || userType === "CUSTOMER" ? (
            <div className={props && props.isFromVista ? "mr-3 mb-3" : "my-2"}>
              <span className="mx-2">Event Name</span>
              <Select
                showSearch
                className="audi_event_search"
                placeholder="Select Events"
                optionFilterProp="children"
                disabled={disableEvent}
                value={eventid}
                bordered={false}
                listHeight={120}
                listItemHeight={4}
                onChange={(val) => {
                  setEventID(val);
                }}
              >
                {eventList &&
                  eventList.map((data, index) => {
                    return (
                      <Option key={"event_" + index} value={data.event_id}>
                        {data.event_name}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          ) : null}
        </div>
        {eventid ? (
          loading ? (
            <CommonLoader />
          ) : (
            <div className="audi_container w-100 d-flex">
              {isChatBot && chatbot_json ? (
                <CustomChatbot
                  onClose={async (conversation_details) => {
                    await saveChatbotHistory({
                      event_id: eventid,
                      conversation_details,
                    });
                    setIsChatBot(false);
                  }}
                  responseJson={chatbot_json}
                  chatbot_history={chatbot_history}
                  onSetHistory={(newHistory) => set_chatbot_history(newHistory)}
                  extraProps={{ event_id: eventid }}
                />
              ) : null}
              <div className="audi_left_container d-flex flex-column  p-3">
                <h5 className="mb-3 mx-auto">Sponsored by</h5>
                <div className="audit_hosting_sponsor_img_container">
                  {sponsorData && sponsorData?.logo_url ? (
                    <img
                      className="auditorium_img mb-3 mx-auto"
                      src={sponsorData?.logo_url}
                    />
                  ) : (
                    <div className="w-100 d-flex justify-content-center">
                      <h5 className="">No Sponsor Added</h5>
                    </div>
                  )}
                </div>

                <div className="d-flex justify-content-center w-100 mb-3 mx-auto">
                  {sponsorData?.is_asset_enable &&
                  sponsorData?.is_asset_enable !== "NA" &&
                  sponsorData?.asset_file_url ? (
                    <Tooltip placement="right" title="Download">
                      <Button
                        onClick={async () => {
                          if (sponsorData?.can_download_asset) {
                            window.location.href = sponsorData?.asset_file_url;

                            updateAssetDownloadCount(
                              sponsorData?.zone_asset_id
                            );
                            dispatch(
                              getEventAudiDetails({
                                event_id: eventid,
                                date: currentDate.format("YYYY-MM-DD"),
                              })
                            );
                          } else {
                            toast.error("Max Number of Downloads Reached");
                          }
                        }}
                        className="ms-2 border "
                      >
                        <Download />
                      </Button>
                    </Tooltip>
                  ) : null}
                  {sponsorData?.is_booth_link_enable &&
                  sponsorData?.is_booth_link_enable !== "NA" ? (
                    <Tooltip placement="right" title="Visit Booth">
                      <Button
                        className="border ms-2"
                        onClick={() => {
                          let tier = sponsorData?.brand?.brand_tier;
                          let zones = sponsorData?.brand?.zones;
                          let sequence = null;
                          let brand_id = sponsorData?.brand_id;
                          let eventID = eventid;
                          let final_booth_url =
                            sponsorData?.brand?.final_booth_url;

                          vistaRedirectToBooth(
                            tier,
                            zones,
                            sequence,
                            userType,
                            brand_id,
                            eventID,
                            props.history,
                            dispatch,
                            final_booth_url
                          );
                        }}
                      >
                        <Booth />
                      </Button>
                    </Tooltip>
                  ) : null}
                  {(sponsorData?.is_chat_enabled &&
                    sponsorData?.is_chat_enabled !== "NA" &&
                    sponsorData.channel_id) ||
                  (chatbotRes && sponsorData.chat_bot_start_date_time) ? (
                    <Tooltip placement="right" title={"Chat"}>
                      <Button
                        className="border ms-2 "
                        onClick={async () => {
                          if (
                            chatbotRes &&
                            sponsorData.chat_bot_start_date_time
                          ) {
                            let chat_bot_res = await getChatbotJson({
                              zone_id: sponsorData.zone_id,
                            });
                            if (chat_bot_res && !isEmpty(chat_bot_res)) {
                              set_chatbot_json(chat_bot_res);
                              setIsChatBot(true);
                            } else toast.error("Chatbot details doesn't exist");
                          } else {
                            setMessageModal({
                              isOpen: true,
                              channel_id: sponsorData?.channel_id,
                            });
                          }
                        }}
                      >
                        <Chat />
                      </Button>
                    </Tooltip>
                  ) : null}
                  {messageModal.isOpen ? (
                    <Modal
                      title={<b>Send Message</b>}
                      visible={messageModal.isOpen}
                      onOk={() => clearMessageAndModal()}
                      onCancel={() => clearMessageAndModal()}
                      footer={[
                        <Button
                          className="px-3 button-pink rounded"
                          key="cancel"
                          onClick={() => clearMessageAndModal()}
                        >
                          Cancel
                        </Button>,
                        <Button
                          className="px-3 rounded"
                          key="save"
                          type="primary"
                          onClick={() =>
                            callSendMessageAPI(messageModal.channel_id)
                          }
                        >
                          Send
                        </Button>,
                      ]}
                      className="message_modal"
                    >
                      <Row className="ticker_configuration_row mt-2">
                        <Col span={6} className="ticker_configuration_label">
                          <p>
                            Message
                            <RedAsterick />
                          </p>
                        </Col>
                        <Col span={12}>
                          <TextArea
                            name="message_text"
                            id="message_text"
                            className="ticker_configuration_inputDescription"
                            placeholder="Enter a message"
                            rows={4}
                            value={message_text}
                            onChange={(e) => set_message_text(e.target.value)}
                            maxLength={TEXTAREA_TEXT_LIMITS.MAX}
                            onBlur={() =>
                              messageValidator.current.showMessageFor(
                                "message_text"
                              )
                            }
                          />
                          {messageValidator.current.message(
                            "Message",
                            message_text,
                            "required",
                            { className: "text-danger py-1" }
                          )}
                        </Col>
                      </Row>
                    </Modal>
                  ) : null}
                  {sponsorData?.is_demo_room_enable &&
                  sponsorData?.is_demo_room_enable !== "NA" &&
                  sponsorData?.zone_demo_room_link ? (
                    <Tooltip placement="right" title="Demo Room">
                      <Button
                        onClick={() => {
                          dispatch(
                            setVideoConferenceData({
                              videoConferenceFlag: true,
                              videoConferenceUrl:
                                sponsorData?.zone_demo_room_link,
                              is_demo_room: true,
                            })
                          );
                        }}
                        className="ms-2 border "
                      >
                        <Video />
                      </Button>
                    </Tooltip>
                  ) : null}
                  {sponsorData?.is_video_call_enable &&
                  sponsorData?.is_video_call_enable !== "NA" ? (
                    <Tooltip placement="right" title={"Next Available Agent"}>
                      <Button
                        onClick={async () => {
                          let channel_id = sponsorData?.channel_id;

                          const response = await videoRequestAPI(channel_id);
                          if (response.data.code === 200)
                            toast.success(response.data.message);
                          else toast.error(response.data.message);
                        }}
                        className="border ms-2"
                      >
                        <User />
                      </Button>
                    </Tooltip>
                  ) : null}
                </div>
                <h6 className="mb-3 mx-auto">Agenda</h6>
                <DatePicker
                  className="audi_date mb-3"
                  defaultValue={currentDate}
                  onChange={(val) => {
                    setCurrentDate(val);
                  }}
                  format={dateFormat}
                />
                <Input
                  placeholder="Search by Session"
                  className="audi_search_input"
                  onChange={(e) => searchSessions(e)}
                  prefix={<SearchOutlined />}
                  maxLength={INPUT_TEXT_LIMITS.MAX}
                />
                <div className="audi_search_container mt-2 w-100">
                  {filteredSessions?.map((item, i) => {
                    return (
                      <div
                        key={"audi" + i}
                        className="audi_options mt-2 custom_border"
                      >
                        <span>{item?.session_title}</span>
                      </div>
                    );
                  })}
                </div>
                <Divider />
                <div className="audi_calendar_container">
                  {audiSessAvailable &&
                    audiSessAvailable.map((ele, i) => {
                      const start_date_time = createTZString(
                        ele.start_date_time
                      );
                      const end_date_time = createTZString(ele.end_date_time);
                      const eleStartTime = moment(
                        start_date_time.format("HH:mm"),
                        TIME_FORMAT
                      );
                      const eleEndTime = moment(
                        end_date_time.format("HH:mm"),
                        TIME_FORMAT
                      );

                      let color =
                        ele?.auditorium_hosting_brands?.[0]?.brand_color;
                      return (
                        <>
                          <div
                            key={"audi" + i}
                            className="d-flex position-relative  overflow-auto"
                          >
                            <div className="w-25 h-25">
                              <p className="position-absolute top-0 start-0 m-0">
                                {eleStartTime.format("HH:mm")}
                              </p>
                              <p className="position-absolute bottom-0 start-0 m-0">
                                {eleEndTime.format("HH:mm")}
                              </p>
                            </div>
                            <div
                              style={{
                                background: color,
                              }}
                              className="audi_session d-flex flex-column p-2 ms-2"
                            >
                              <span className="text-white">
                                {ele?.session_title ? ele?.session_title : "NA"}
                              </span>
                              <span className="text-white audi_calendar_text">
                                {ele?.session_description}
                              </span>
                              <span className="text-white">
                                {eleStartTime.format("HH:mm") +
                                  " - " +
                                  eleEndTime.format("HH:mm")}
                              </span>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
              <div
                className={`audi_right_container w-100  d-flex  ${
                  isMobile ? "ms-4" : "mt-3"
                }`}
              >
                {audiSessAvailable && audiSessAvailable.length === 0 ? (
                  <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                    <Empty
                      description={
                        <span className="fs-pink">No Upcoming Sessions</span>
                      }
                    />
                  </div>
                ) : (
                  <>
                    {audiSessAvailable &&
                      audiSessAvailable.map((ele, i) => {
                        let data = ele;
                        let currentTime = moment(
                          moment().format("HH:mm"),
                          TIME_FORMAT
                        );

                        const start_date_time = createTZString(
                          ele.start_date_time
                        );
                        const end_date_time = createTZString(ele.end_date_time);
                        const eleStartTime = moment(
                          start_date_time.format("HH:mm"),
                          TIME_FORMAT
                        );
                        const eleEndTime = moment(
                          end_date_time.format("HH:mm"),
                          TIME_FORMAT
                        );

                        const start_utc = createISOTimeString(
                          ele.start_date_time
                        ).replace("Z", "");
                        const end_utc = createISOTimeString(
                          ele.end_date_time
                        ).replace("Z", "");
                        let audiTitleArr = audiTitle.split(" ");

                        let speakersArr = [];

                        data?.auditorium_hosting_brands?.forEach((elem) => {
                          elem?.auditorium_hosting_speakers?.forEach(
                            (subElem) => speakersArr.push(subElem)
                          );
                        });

                        data = {
                          ...data,
                          startTime: start_utc,
                          endTime: end_utc,
                          title: data?.session_title,
                          description: data?.session_description,
                          audiName: audiTitleArr[0],
                          audiNumber: parseInt(audiTitleArr?.[2] ?? 0),
                          speakersArr: speakersArr,
                        };

                        return (
                          <div className="audi_col" key={"audi" + i}>
                            <div>
                              <h5>
                                {currentTime >= eleStartTime &&
                                currentTime <= eleEndTime
                                  ? "Up Now"
                                  : "Up Next"}
                              </h5>
                              <div className="bg-white p-3">
                                <span>Title :</span>
                                <h5>
                                  {data?.session_title
                                    ? data?.session_title
                                    : "NA"}
                                </h5>
                                <span>Time :</span>
                                <h6>
                                  {eleStartTime.format("HH:mm") +
                                    " - " +
                                    eleEndTime.format("HH:mm")}
                                </h6>
                                <span>Description :</span>
                                <h6>{data?.session_description}</h6>
                                {currentTime >= eleStartTime &&
                                currentTime <= eleEndTime ? (
                                  <Button
                                    className="audi_join_sessionBtn"
                                    onClick={() => {
                                      setJoinSessionPopupData(data);
                                      setOpenJoinSessionModal(true);
                                    }}
                                  >
                                    <span className="ms-3">
                                      <Booth />
                                      <span className="ms-2">
                                        Join Session Now
                                      </span>
                                    </span>
                                  </Button>
                                ) : (
                                  <>
                                    <div className="d-flex">
                                      {data?.is_iCal_enabled &&
                                      window.navigator.platform
                                        .toLowerCase()
                                        .includes("mac") ? (
                                        <ICalendarLink
                                          className="audi_downloadBtn mx-1"
                                          event={data}
                                        >
                                          <div>
                                            <span>Download iCal</span>
                                          </div>
                                        </ICalendarLink>
                                      ) : null}
                                      {data?.added_to_my_calendar ? (
                                        <Button
                                          onClick={async () => {
                                            await addToMyCalender({
                                              event_id: eventid,
                                              hall_type: audiType,
                                              hall_id: audiConfigId,
                                              session_id:
                                                data?.auditorium_session_id,
                                            });
                                          }}
                                          className="audi_calendarBtn "
                                        >
                                          <Calendar6 />
                                          <span className="ms-4">
                                            Add to My Calendar
                                          </span>
                                        </Button>
                                      ) : null}
                                    </div>
                                  </>
                                )}
                              </div>

                              {speakersArr?.length !== 0 ? (
                                <Carousel
                                  className="audi_card_carousel w-100  bg-white mt-3"
                                  arrows
                                  {...settings}
                                >
                                  {speakersArr &&
                                    speakersArr?.map((data, i) => {
                                      return (
                                        <div
                                          className="d-flex  align-items-center mt-2"
                                          key={"audi_speaker_" + i}
                                        >
                                          <div className="mx-2 auditorium_speaker_container">
                                            <img
                                              className="img-responsive auditorium_speaker ms-1"
                                              src={data?.user?.image_url}
                                            />
                                          </div>
                                          <div className="ms-4">
                                            <span>Speaker :</span>
                                            <h5>
                                              {data?.user?.first_name ||
                                              data?.user?.last_name
                                                ? data?.user?.first_name +
                                                  " " +
                                                  data?.user?.last_name
                                                : "NA"}
                                            </h5>
                                            <span>Bio :</span>
                                            <h6>
                                              {data?.user?.bio
                                                ? data?.user?.bio
                                                : "NA"}
                                            </h6>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </Carousel>
                              ) : (
                                <div className="audi_net_empty_data">
                                  <span className="fs-pink">
                                    No Speaker Data
                                  </span>
                                </div>
                              )}
                              <Carousel
                                className="audi_card_carousel w-100  bg-white mt-3"
                                arrows
                                {...settings}
                              >
                                {data?.auditorium_hosting_brands &&
                                  data?.auditorium_hosting_brands?.map(
                                    (data, i) => {
                                      return (
                                        <div
                                          className="w-100 d-flex  align-items-center mt-2"
                                          key={"audi" + i}
                                        >
                                          <div className="hosting_sponsor_audi_img_container">
                                            <img
                                              className="img-responsive hosting_sponsor_audi_img"
                                              src={data?.logo_url}
                                            />
                                          </div>
                                          <div>
                                            {data?.is_assets_enabled &&
                                            data?.zone_assets?.[0]
                                              ?.asset_file_url ? (
                                              <Tooltip
                                                placement="right"
                                                title="Download"
                                              >
                                                <Button
                                                  className="audi_pdfBtn mb-3"
                                                  onClick={() => {
                                                    if (
                                                      data?.zone_assets?.[
                                                        data?.zone_assets
                                                          ?.length - 1
                                                      ]?.can_download_asset
                                                    ) {
                                                      let assetId =
                                                        data?.zone_assets?.[
                                                          data?.zone_assets
                                                            ?.length - 1
                                                        ]?.zone_asset_id;
                                                      updateAssetDownloadCount(
                                                        assetId
                                                      );
                                                      dispatch(
                                                        getEventAudiDetails({
                                                          event_id: eventid,
                                                          date: currentDate.format(
                                                            "YYYY-MM-DD"
                                                          ),
                                                        })
                                                      );
                                                    } else {
                                                      toast.error(
                                                        "Max Number of Downloads Reached"
                                                      );
                                                    }

                                                    window
                                                      .open(
                                                        data?.zone_assets?.[
                                                          data?.zone_assets
                                                            ?.length - 1
                                                        ]?.asset_file_url,
                                                        "_blank"
                                                      )
                                                      .focus();
                                                  }}
                                                >
                                                  <Download />
                                                  <span className="ms-2 fs-16 audi_download_brands_button_text">
                                                    {data?.zone_assets?.[
                                                      data?.zone_assets
                                                        ?.length - 1
                                                    ]?.asset_name +
                                                      data?.zone_assets?.[
                                                        data?.zone_assets
                                                          ?.length - 1
                                                      ]?.original_file_name.substring(
                                                        data?.zone_assets?.[
                                                          data?.zone_assets
                                                            ?.length - 1
                                                        ]?.original_file_name.indexOf(
                                                          "."
                                                        )
                                                      )}
                                                  </span>
                                                </Button>
                                              </Tooltip>
                                            ) : null}

                                            <div className="d-flex  w-100 mb-3">
                                              {data?.is_booth_link_enabled ? (
                                                <Tooltip
                                                  placement="right"
                                                  title="Visit Booth"
                                                >
                                                  <Button
                                                    className="border p-1"
                                                    onClick={() => {
                                                      let tier =
                                                        data?.brand?.brand_tier;
                                                      let zones =
                                                        data?.brand?.zones;
                                                      let sequence = null;
                                                      let brand_id =
                                                        data?.assigned_brand_id;
                                                      let eventID = eventid;
                                                      let final_booth_url =
                                                        data?.final_booth_url;

                                                      vistaRedirectToBooth(
                                                        tier,
                                                        zones,
                                                        sequence,
                                                        userType,
                                                        brand_id,
                                                        eventID,
                                                        props.history,
                                                        dispatch,
                                                        final_booth_url
                                                      );
                                                    }}
                                                  >
                                                    <Booth />
                                                  </Button>
                                                </Tooltip>
                                              ) : null}

                                              {data?.is_chat_enabled ? (
                                                <Tooltip
                                                  placement="right"
                                                  title="Chat"
                                                >
                                                  <Button
                                                    onClick={() => {
                                                      setMessageModal({
                                                        isOpen: true,
                                                        channel_id:
                                                          data?.channel_id,
                                                      });
                                                    }}
                                                    className="border p-1 ms-2"
                                                  >
                                                    <Chat />
                                                  </Button>
                                                </Tooltip>
                                              ) : null}
                                              {data?.is_demo_room_enabled &&
                                              data?.zone_demo_room_link ? (
                                                <Tooltip
                                                  placement="right"
                                                  title="Demo Room"
                                                >
                                                  <Button
                                                    className="border p-1 ms-2"
                                                    onClick={() => {
                                                      dispatch(
                                                        setVideoConferenceData({
                                                          videoConferenceFlag: true,
                                                          videoConferenceUrl:
                                                            data?.zone_demo_room_link,
                                                          is_demo_room: true,
                                                        })
                                                      );
                                                    }}
                                                  >
                                                    <Video />
                                                  </Button>
                                                </Tooltip>
                                              ) : null}
                                              {data?.is_video_call_enabled ? (
                                                <Tooltip
                                                  placement="right"
                                                  title="Next Available Agent"
                                                >
                                                  <Button
                                                    onClick={async () => {
                                                      let channel_id =
                                                        data?.channel_id;

                                                      const response =
                                                        await videoRequestAPI(
                                                          channel_id
                                                        );
                                                      if (
                                                        response.data.code ===
                                                        200
                                                      )
                                                        toast.success(
                                                          response.data.message
                                                        );
                                                      else
                                                        toast.error(
                                                          response.data.message
                                                        );
                                                    }}
                                                    className="border p-1 ms-2"
                                                  >
                                                    <User />
                                                  </Button>
                                                </Tooltip>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                              </Carousel>
                            </div>
                          </div>
                        );
                      })}
                  </>
                )}
              </div>
              <div
                style={{
                  height: "auto",
                  maxHeight: "430px",
                  width: "50px",
                  overflow: "auto",
                  position: "fixed",
                  right: 0,
                }}
                className="bg-white   ms-2 mt-5 "
                id="net_tooltip"
              >
                {userNearbyArr &&
                  userNearbyArr.map((user) => {
                    return (
                      <div
                        key={"usernearby_" + user.userId}
                        className="networking_tooltip   p-1"
                        onClick={() => {
                          setUserData(user);
                          setIsProfileModalVisible(true);
                        }}
                      >
                        <Tooltip
                          placement="left"
                          title={() => (
                            <div className="d-flex p-2 networking_tooltipContent">
                              <div>
                                <span className="fs-18 text-nowrap">
                                  {user.userName}
                                </span>
                                <br />
                                <span>{user.company}</span>
                              </div>
                              <Avatar
                                shape="square"
                                size={64}
                                className="ms-2"
                                src={user?.image_url ?? placeholder_image}
                              />
                            </div>
                          )}
                        >
                          <Avatar
                            shape="square"
                            src={user?.image_url ?? placeholder_image}
                          />
                        </Tooltip>
                      </div>
                    );
                  })}
              </div>
            </div>
          )
        ) : userType &&
          (userType === "SUPER ADMIN" || userType === "CUSTOMER") ? (
          <div className="empty_event">
            <Result
              icon={<SmileOutlined />}
              title={
                <h4 className="fs-pink">
                  Please choose your event in the top right corner
                </h4>
              }
            />
          </div>
        ) : null}
        <ProfileModal
          isProfileModalVisible={isProfileModalVisible}
          userData={userData}
          changeModalFlag={() => setIsProfileModalVisible(false)}
        />
        {openJoinSessionModal ? (
          <JoinSession
            joinSessionPopupData={joinSessionPopupData}
            isModalOpen={openJoinSessionModal}
            addToCalendarData={{
              audiType,
              audiConfigId,
            }}
            eventID={eventid}
            onClose={() => setOpenJoinSessionModal(false)}
          />
        ) : null}
      </div>
    );
};

export default Auditorium;
