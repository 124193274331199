import React, { useState } from "react";
import BrandZoneDetails from "../ExpoMap/BrandZoneDetails";
import Brands from "../ExpoMap/Brands";

const Brand = (props) => {
  const [brandObj, setBrandObj] = useState({
    event_id: null,
    brand_id: null,
    show: false,
  });

  if (brandObj.show) {
    return <BrandZoneDetails socket={props.socket} brandObj={brandObj} />;
  } else
    return (
      <div>
        <div className="d-flex justify-content-between align-items-center py-1">
          <div>
            <h4 className="fs-24 mt-1">Brands</h4>
          </div>
        </div>
        <div className="p-2 bg-white">
          <Brands socket={props.socket} setBrandObj={setBrandObj} />
        </div>
      </div>
    );
};

export default Brand;
