import React, { useContext } from "react";
import { binaryImage } from "./VistaMapDataImage";
import AppContext, { ACTIONS } from "../../views/Vista/src/contexts/AppContext";

export const VistaMap = (props) => {
  const { dispatch } = useContext(AppContext);

  const sectionClick = (src, currentPosition) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_WINDOW,
      value: "",
    });

    dispatch({
      type: ACTIONS.SET_VISTA_SRC,
      value: {
        src: src,
        currentPosition: currentPosition,
      },
    });
  };

  return (
    <svg
      id="Map_top_view"
      data-name="Map top view"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox="0 0 1920 1080"
    >
      <image
        id="Rectangle_20694"
        data-name="Rectangle 20694"
        width="100%"
        height="100%"
        className="opacity-75 "
        xlinkHref={binaryImage}
      />
      <path
        id="Center_part"
        data-name="Center part"
        d="M856.941,210.353s-.47-21.647,18.588-26.588,60-3.53,102.353,4,89.412,16.941,98.589,29.647,11.058,21.412,8,36.941-2.589,76.235,4,109.176S1113.412,468,1146.353,477.882s165.882,25.647,171.059,26.353,14.941,2.236,21.176,7.765.941,78.118-3.059,107.176S1325.941,733.882,1291,759.647s-80.471,43.412-120.706,43.941-55.412-24.882-62.118-38.117-23.941-25.942-68.882-12.059-139.765,38.353-205.176,16.47-113.824-48.706-126.177-83.294-12.412-68.941,19.824-108.235,50.823-57.882,51.059-73.412-.942-16.941-24.236-14.823-82.47,11.176-96.235,9.411-24.706,1.412-32.471-39.176-28-138.235-12.47-145.765,45.647,6.941,66.823,26.471,84.706,24.117,124.471-3.647S864.588,248.353,856.941,210.353Z"
        fill="rgba(255,255,255,0)"
      />
      <path
        id="Entry"
        d="M604.417,506.367c-3.856-7.335-11.711-24.575-25.829-64.014-16.215-45.3-26.979-76.124-31.623-89.507-8.713-1.614-23.22-5.238-42.024-13.317-22.9-9.838-58.841-6.408-69.479-58.079-4.5,12.377-11.267,32.927-20.874,66.55-20.706,72.471-2.117,109.882,13.647,155.059s65.118,91.412,81,102.882c5.077,3.666,8.416,5.9,10.619,7.258a121.862,121.862,0,0,1,1.558-14.846C528,558.824,537.176,532,583.176,511.529c0,0,8.124-1.981,21.422-5.206Z"
        fill="rgba(255,255,255,0)"
      />

      <path
        id="Auditorium"
        cursor={"pointer"}
        onClick={() => {
          sectionClick(
            "/concourse-publish/index.html?media-name=3",
            "concoursePublished"
          );
        }}
        d="M860.687,195.266l-.009.136a22.762,22.762,0,0,1,14.851-11.637c19.059-4.941,60-3.53,102.353,4s89.412,16.941,98.589,29.647a59.444,59.444,0,0,1,6.426,10.831l.822.082c3.667-37.532,8.987-66.678,8.987-66.678-41.883-3.294-230.471-34.353-230.471-34.353L859.771,127C859.88,130,861.348,171.364,860.687,195.266Z"
        fill="rgba(255,255,255,0)"
      />

      <path
        id="Sponsor_Area"
        cursor={"pointer"}
        onClick={() => {
          sectionClick(
            "/concourse-publish/index.html?media-name=5",
            "concoursePublished"
          );
        }}
        data-name="Sponsor Area"
        d="M1324.235,192.706c-56.47-18.588-128.706-23.353-133.735-23.794s-97.794-7.265-97.794-7.265-5.469,29.964-9.138,68.25c2.776,7.249,2.877,14.431.9,24.456-3.059,15.529-2.589,76.235,4,109.176S1113.412,468,1146.353,477.882s165.882,25.647,171.059,26.353a57.342,57.342,0,0,1,13.8,3.349c44.652,6.026,136.086,18.3,136.086,18.3s7.529-53.176,15.529-176.47S1380.706,211.294,1324.235,192.706Z"
        fill="rgba(255,255,255,0)"
      />

      <path
        id="Social_Wall"
        cursor={"pointer"}
        onClick={() => {
          sectionClick(
            "/concourse-publish/index.html?media-name=7",
            "concoursePublished"
          );
        }}
        data-name="Social Wall"
        d="M1331.208,507.584a25.735,25.735,0,0,1,7.38,4.416c6.235,5.529.941,78.118-3.059,107.176S1325.941,733.882,1291,759.647c-.7.516-1.409,1.022-2.117,1.531,1.581,3.508,6.493,14.367,11.668,25.39,13.319,23.857,33.679,53.489,46.027,70.881,5.3,6.246,9.839,10.948,12.363,12.316,8.471,4.588,27.53,0,41.294-6.706s20.118-40.235,25.765-60.706,12.706-65.647,15.529-84,25.765-192.471,25.765-192.471S1375.852,513.609,1331.208,507.584Z"
        fill="rgba(255,255,255,0)"
      />

      <path
        id="ExpoHall"
        cursor={"pointer"}
        onClick={() => {
          sectionClick(
            "/concourse-publish/index.html?media-name=6",
            "concoursePublished"
          );
        }}
        d="M1356.941,871.765c-4.405-5.8-59.022-80.77-68.209-110.478-34.629,24.868-79.065,41.783-118.438,42.3q-3.792.051-7.291-.2c3.7,17.448,12.023,60.662,10.644,90.728-1.765,38.47-6.353,73.941-18,103.941,0,0,107.882,39.47,154.471,8.059,61.647-27.647,80.941-98.353,80.941-98.353S1361.412,877.647,1356.941,871.765Z"
        fill="rgba(255,255,255,0)"
      />

      <path
        id="Path_49565"
        data-name="Path 49565"
        d="M707.941,686.588C695.588,652,695.529,617.647,727.765,578.353s50.823-57.882,51.059-73.412-.942-16.941-24.236-14.823-82.47,11.176-96.235,9.411c-5.326-.682-10.229-.627-14.689-2.652-34.822,8.393-60.488,14.652-60.488,14.652-46,20.471-55.176,47.295-61.764,86.824s7.059,72,28.235,99.529,113.177,56.706,140,66.353,102.118,33.53,102.118,33.53L804.824,760l-.883-1.082C754.063,739.162,718.306,715.609,707.941,686.588Z"
        fill="rgba(255,255,255,0)"
      />

      <path
        id="Networking"
        cursor={"pointer"}
        onClick={() => {
          sectionClick(
            "/concourse-publish/index.html?media-name=4",
            "concoursePublished"
          );
        }}
        d="M834.118,769.882q-10.981-3.674-21.283-7.54c-5.091-1.416-8.01-2.342-8.011-2.342h0l-.883-1.082c-49.878-19.756-85.635-43.309-96-72.33-.494-1.383-.961-2.767-1.414-4.15l-60.58,66.354c18.464,6.8,34.729,12.217,43.7,15.443,26.822,9.647,102.113,33.528,102.118,33.53h0C815.1,805.452,851.6,823.981,892.079,846.4c2.24-21.881,5.958-51.559,10.039-56.514,2.319-2.816,6.1-7.206,10.017-11.714C884.785,779.613,857.562,777.725,834.118,769.882Z"
        fill="rgba(255,255,255,0)"
      />

      <path
        id="Opening_Pano"
        data-name="Opening Pano"
        d="M625.882,460.353c-7.226-37.773-25.246-124.948-15.2-142.932-15.174,7.786-54.451,36.7-54.451,36.7a77.634,77.634,0,0,1-9.271-1.272c4.645,13.382,15.409,44.208,31.624,89.507,14.118,39.439,21.973,56.679,25.829,64.014,10.37-2.516,23.921-5.8,39.247-9.49C636.6,493.669,630.643,485.237,625.882,460.353Z"
        fill="rgba(255,255,255,0)"
      />

      <path
        id="Open_Reception"
        data-name="Open Reception"
        d="M613.412,314.588c15.529-7.529,45.647,6.941,66.823,26.471,12.458,11.488,39.571,17.8,67.882,15.4,16.581-2.319,32.693-7.355,46.835-13.113a93.247,93.247,0,0,0,9.754-5.939c26.712-18.652,44.54-52.43,51.06-84.117,1.193-8.151,2.079-16.09,2.746-23.348a90.058,90.058,0,0,0-1.571-19.594,33.7,33.7,0,0,1,3.742-14.961c.7-24.788-.918-68.568-.918-68.568s-20-4-73.177-13.883S645.176,82.824,585.176,71.765s-84.411-5.471-84.411-5.471c-11.338,1.99-46.291.007-64.059,126.412-20.391,145.066,36.47,133.176,68.235,146.823s51.294,14.589,51.294,14.589,39.277-28.911,54.451-36.7A6.534,6.534,0,0,1,613.412,314.588Z"
        fill="rgba(255,255,255,0)"
      />
      <svg>
        <g>
          <rect
            rx="10"
            ry="10"
            x="660"
            y="218"
            width="170"
            height="50"
            fill="blue"
          ></rect>
          <text
            x="686"
            y="249"
            font-family="Verdana"
            font-size="25"
            fill="white"
          >
            Reception
          </text>
        </g>
      </svg>

      <svg>
        <g>
          <rect
            rx="10"
            ry="10"
            x="900"
            y="75"
            width="170"
            height="50"
            fill="blue"
          ></rect>
          <text
            x="914"
            y="108"
            font-family="Verdana"
            font-size="25"
            fill="white"
          >
            Auditorium
          </text>
        </g>
      </svg>

      <svg>
        <g>
          <rect
            rx="10"
            ry="10"
            x="1140"
            y="319"
            width="201"
            height="50"
            fill="blue"
          ></rect>
          <text
            x="1152"
            y="351"
            font-family="Verdana"
            font-size="25"
            fill="white"
          >
            Sponsor Area
          </text>
        </g>
      </svg>

      <svg>
        <g>
          <rect
            rx="10"
            ry="10"
            x="1466"
            y="604"
            width="182"
            height="50"
            fill="blue"
          ></rect>
          <text
            x="1482"
            y="636"
            font-family="Verdana"
            font-size="25"
            fill="white"
          >
            Social Wall
          </text>
        </g>
      </svg>

      <svg>
        <g>
          <rect
            rx="10"
            ry="10"
            x="1177"
            y="919"
            width="182"
            height="50"
            fill="blue"
          ></rect>
          <text
            x="1204"
            y="953"
            font-family="Verdana"
            font-size="25"
            fill="white"
          >
            Expo Hall
          </text>
        </g>
      </svg>
      <svg>
        <g>
          <rect
            rx="10"
            ry="10"
            x="751"
            y="715"
            width="182"
            height="50"
            fill="blue"
          ></rect>
          <text
            x="767"
            y="747"
            font-family="Verdana"
            font-size="25"
            fill="white"
          >
            Networking
          </text>
        </g>
      </svg>
    </svg>
  );
};
