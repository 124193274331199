import React, { useState, useEffect, useRef, useContext } from "react";
import "./index.css";
import FileUpload from "../common/FileUpload";
import {
  Button,
  Input,
  Switch,
  Select,
  Modal,
  Row,
  Col,
  Tooltip,
  Result,
} from "antd";
import {
  LinkedinOutlined,
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  HeartFilled,
  HeartOutlined,
  DeleteOutlined,
  EditOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { Download } from "../../components/svgJS/Download";
import { Chat } from "../../components/svgJS/Chat";
import { Video } from "../../components/svgJS/Video";
import { Sort } from "../../components/svgJS/Sort";
import {
  getSocialWallModerationDetails,
  updateAssetDownloadCount,
} from "../../utils/commonApis";
import { ApiCall } from "../../utils/api";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import {
  DEFAULT_CHATBOT_HISTORY,
  TIME_FORMAT,
} from "../../components/constsnts/common.constants";
import {
  sendMessageAPI,
  videoRequestAPI,
  fetchChatbotHistory,
  getChatbotJson,
  saveChatbotHistory,
  DeletePost,
  UpdatePost,
} from "../../utils/commonApis";
import RedAsterick from "../../components/Widgets/RedAsterick";
import CommonLoader from "../../components/Widgets/CommonLoader";
import {
  checkIfChatbotTimeSlot,
  createTZString,
  getComentChatUser,
  isImage,
  isRouteExistInMenu,
  isSuperAdminOrCustomer,
  vistaRedirectToBooth,
} from "../../utils/commonFunctions";
import { isEmpty } from "lodash";
import CustomChatbot from "../../components/Widgets/CustomChatbot";
import { BoothRed } from "../../components/svgJS/BoothRed";
import { useDispatch, useSelector } from "react-redux";
import { setVistaBrandData } from "../../redux/slice/vista";
import AppContext, { ACTIONS } from "../Vista/src/contexts/AppContext";
import { hallData, standsData } from "../Vista/src/apiData";
import { useMediaQuery } from "react-responsive";
import { setVideoConferenceData } from "../../redux/slice/videoConference";

const SocialWall = (props) => {
  let CryptoJS = require("crypto-js");
  let localData_event_id = parseInt(localStorage.getItem("event_id"));
  const { TextArea } = Input;
  const { Option } = Select;
  const validator = useRef(new SimpleReactValidator());
  const messageValidator = useRef(new SimpleReactValidator());
  const [imgPreview, setImgPreview] = useState();
  const [logoImg, setLogoImg] = useState();
  const [updatedLogoImg, setUpdatedLogoImg] = useState();
  const [socialWallData, setSocialWallData] = useState();
  const [socialMediaPost, setSocialMediaPost] = useState([]);
  const [socialMediaId, setSocialMediaId] = useState();
  const [eventList, setEventList] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState();
  const [messageModal, setMessageModal] = useState({ isOpen: false, data: {} });
  const [message_text, set_message_text] = useState("");
  const [updatedLogoPreview, setUpdatedLogoPreview] = useState();
  const [cometChatUser, setCometChatUser] = useState({
    isValidUser: false,
    data: {},
    uid: "",
  });
  const [fields, setFields] = useState({
    social_settings_id: socialMediaId,
    post_content: "",
    is_delete: false,
    event_id: selectedEventId ?? null,
  });

  const [updateFields, setUpdatefields] = useState({
    post_id: null,
    post_content: "",
    logo_image_url: "",
  });

  const [deletePost, setDeletePost] = useState({
    post_id: null,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [disableEvent, setDisableEvent] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState("");
  const [isChatBot, setIsChatBot] = useState(false);
  const [chatbot_json, set_chatbot_json] = useState([]);
  const [chatbot_history, set_chatbot_history] = useState(
    DEFAULT_CHATBOT_HISTORY
  );
  const [sortType, setSortType] = useState("recent");
  const [sortBy, setSortBy] = useState(false);
  const [dataToDisplay, setDataToDisplay] = useState();
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const vistaEventId = useSelector((state) => state?.vistaBrand?.eventId);
  const menuItemFlag = useSelector((state) => state?.vistaBrand?.menuItemFlag);
  const isMobile = useMediaQuery({ minWidth: 480 });
  const isMobile2 = useMediaQuery({ maxWidth: 500 });

  const dispatch = useDispatch();
  const { dispatch: AppDispatch } = useContext(AppContext);
  const concourseData = useSelector(
    (state) => state?.vistaBrand?.concourseData
  );

  useEffect(async () => {
    setLoading(true);

    let is_route_present = await isRouteExistInMenu(CryptoJS);

    if (is_route_present === false) {
      window.location.href = "/pagenotfound";
    } else {
      const ciphertext = localStorage.getItem("user_type");
      var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUserType(decryptedData);
      const cometRes = await getComentChatUser();
      setCometChatUser(cometRes);

      getEventList();
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [CryptoJS.AES]);

  useEffect(async () => {
    if (selectedEventId) {
      const chatHistory = await fetchChatbotHistory({
        event_id: selectedEventId,
      });
      setFields({
        ...fields,
        event_id: selectedEventId,
      });
      if (chatHistory && !isEmpty(chatHistory))
        set_chatbot_history(chatHistory);
    }
    getAllDetails();
  }, [selectedEventId]);

  useEffect(() => {
    getAllDetails();
  }, [sortBy, sortType]);

  useEffect(() => {
    if (vistaEventId) {
      setSelectedEventId(vistaEventId);
    }
  }, [vistaEventId]);

  const getAllDetails = async () => {
    let social_wall_params = {
      event_id: selectedEventId ?? "",
      sort_type: sortType ?? "",
      sort_by: sortBy === true ? "ASC" : "DSC",
      today_date: moment().format("YYYY-MM-DD"),
    };
    let response = await getSocialWallModerationDetails(social_wall_params);

    if (response !== null || response !== undefined) {
      if (response?.data?.event_social_wall_settings) {
        const { social_media_posts } =
          response?.data?.event_social_wall_settings;
        const { social_settings_id } =
          response?.data?.social_wall_moderators_details;

        await setSocialMediaPost(social_media_posts);
        await setSocialMediaId(social_settings_id);

        setFields({
          ...fields,
          event_id: selectedEventId,
          social_settings_id: social_settings_id,
        });
      }
      await setSocialWallData(response.data);

      const currentTime = moment(moment().format("HH:mm"), TIME_FORMAT);
      let myLocalData = response?.data?.brand_sponsorship_details.filter(
        (ele) => {
          const start_date_time = createTZString(ele.start_date_time);
          const end_date_time = createTZString(ele.end_date_time);
          const eleStartTime = moment(
            start_date_time.format("HH:mm"),
            TIME_FORMAT
          );
          const eleEndTime = moment(end_date_time.format("HH:mm"), TIME_FORMAT);

          if (eleStartTime < currentTime && eleEndTime > currentTime)
            return ele;
          else return;
        }
      );
      if (myLocalData?.length === 0) {
        myLocalData = response?.data?.social_wall_moderators_details;
      }
      await setDataToDisplay(myLocalData);
    }
  };

  const getEventList = () => {
    ApiCall("get", null, "/admin/events/dropdown/get", "")
      .then((response) => {
        const { rows } = response?.data?.data;

        if (rows.length === 1) {
          setSelectedEventId(rows[0]?.event_id);
          // setFields({
          //   ...fields,
          //   event_id: rows[0]?.event_id,
          // });
        }
        const ciphertext = localStorage.getItem("user_type");
        let bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
        let user_type = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setUserType(user_type);
        if (
          user_type === "DELEGATE" ||
          user_type === "SPEAKER" ||
          user_type === "BRAND ADMIN"
        ) {
          const events = rows.filter((item) => {
            return item.event_id === localData_event_id;
          });

          setEventList(events);
          let id = events[0].event_id;
          setSelectedEventId(id);
          setDisableEvent(true);
        } else {
          if (props.event_id) {
            setEventList(rows);
            setSelectedEventId(props.event_id);
            setDisableEvent(true);
          } else {
            setEventList(rows);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleImgChange1 = (event) => {
    let img = event.target.files[0];

    if (isImage(img.name)) {
      setLogoImg(img);
      showModal();
      setImgPreview(URL.createObjectURL(img));
    } else {
      toast.error("Please Upload Valid Image");
    }
  };

  const handleUpdateImageChange = (event) => {
    setUpdatedLogoImg(event.target.files[0]);
    setUpdatedLogoPreview(URL.createObjectURL(event.target.files[0]));
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleEditOk = () => {
    setEditModalVisible(false);
  };

  const handleEditCancel = () => {
    setEditModalVisible(false);
  };

  const handleDeleteOk = () => {
    setDeleteModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
  };

  const handleEditFieldChanges = (data) => {
    setEditModalVisible(true);
    setUpdatefields({
      ...updateFields,
      post_id: data.post_id ?? null,
      post_content: data.post_content ?? "",
      logo_image_url: data.logo_image_url ?? "",
    });
  };
  const handleDeletePost = (data) => {
    setDeleteModalVisible(true);
    setDeletePost({
      ...deletePost,
      post_id: data.post_id,
    });
  };

  const handleFieldsChange = (val, name) => {
    setFields({ ...fields, [name]: val });
  };

  const addPost = async () => {
    let formData = new FormData();
    formData.append("data", JSON.stringify(fields));
    formData.append("logo_image_url", logoImg);

    if (fields?.post_content.trim().length === 0) {
      toast.error("Please enter the description");
    } else {
      await ApiCall(
        "POST",
        formData,
        "/admin/socialwallmoderation/posts/create",
        null
      )
        .then((response) => {
          if (response?.data?.code === 200) {
            toast.success(response.data.message);
            fields.post_content = "";
            getAllDetails();
            setLogoImg(null);
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const DeletePostSubmit = async () => {
    setDeleteModalVisible(false);
    let deleteResponse = await DeletePost(deletePost);

    if (deleteResponse?.data?.code === 200) {
      toast.success(deleteResponse.data.message);
      getAllDetails();
    } else {
      toast.error(deleteResponse.data.message);
    }
  };

  const UpdatePostSubmit = async () => {
    setEditModalVisible(false);
    let formData = new FormData();
    formData.append("data", JSON.stringify(updateFields));
    formData.append("logo_image_url", updatedLogoImg);
    let updateResponse = await UpdatePost(formData);

    if (updateResponse.data.code === 200) {
      toast.success(updateResponse.data.message);
      getAllDetails();
    } else {
      toast.error(updateResponse.data.message);
    }
  };

  const socialWallLike = async (post_id, like) => {
    await ApiCall(
      "POST",
      { post_id: post_id, is_like: like },
      "/admin/socialwallmoderation/postlike/addandremove",
      { event_id: selectedEventId }
    )
      .then((response) => {
        if (response?.data?.code === 200) {
          getAllDetails();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const socialWallPostVisibility = async (post_id, visible) => {
    await ApiCall(
      "PUT",
      { post_id: post_id, is_visible: visible },
      "/admin/socialwallmoderation/postvisiblity/update",
      null
    )
      .then((response) => {
        if (response?.data?.code === 200) {
          toast.success(response.data.message);
          getAllDetails();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onRemoveClick = () => {
    setImgPreview(null);
    setLogoImg(null);
    setIsModalVisible(false);
    setUpdatedLogoPreview(null);
    setUpdatedLogoImg(null);
  };

  function clearMessageAndModal() {
    set_message_text("");
    setMessageModal({ isOpen: false, data: {} });
  }

  async function callSendMessageAPI(channel_id) {
    if (messageValidator.current.allValid()) {
      if (cometChatUser.uid) {
        const response = await sendMessageAPI(channel_id, message_text);
        if (response.data.code === 200) {
          toast.success(response.data.message);
          clearMessageAndModal();
        } else {
          toast.error(response.data.message);
        }
      } else setForceUpdate(!forceUpdate);
    } else {
      messageValidator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
  }

  const sortByLikeFunction = () => {
    setSortType("likes");
    setSortBy(!sortBy);
  };
  const SortByRecentFunction = () => {
    setSortBy(!sortBy);
    setSortType("recent");
  };

  const getExpoHallDetails = async (event_id) => {
    const response = await ApiCall(
      "GET",
      "",
      "/admin/expohallsmap/details/get",
      event_id
    );
    if (response?.data?.code === 200) {
      return response.data.data;
    } else {
      return [];
    }
  };

  const initializeHallDataValues = async (event_id, brand_id, tier) => {
    let responseData = await getExpoHallDetails({
      event_id: event_id,
    });

    let singleExpoHall = null;

    singleExpoHall = responseData?.find((expohall) => {
      return expohall?.expo_hall_mappings?.find((obj) => {
        if (obj?.brand_id === brand_id) {
          return expohall;
        }
      });
    });

    let getExpoHallNumber = singleExpoHall?.hall_title.split(" ")[2];
    let expoMappingArr = [];
    if (getExpoHallNumber % 2 === 0) {
      expoMappingArr = singleExpoHall?.expo_hall_mappings;
    } else {
      expoMappingArr = singleExpoHall?.expo_hall_mappings.reverse();
    }

    expoMappingArr = expoMappingArr?.map((elem, i) => {
      return {
        ...elem,
        isElementPresent: true,
      };
    });

    hallData[0].url = concourseData?.event_settings?.expo_hall_url;

    expoMappingArr?.forEach((val) => {
      hallData[0].stands[val.sequence - 1].status = val?.isElementPresent;
      hallData[0].stands[val.sequence - 1].standID = val?.brand_tier;
      hallData[0].stands[val.sequence - 1].standUrl = val?.final_booth_url;

      hallData[0].stands[val.sequence - 1].brand_id = val?.brand_id;
      hallData[0].stands[val.sequence - 1].zones = val?.zones;
      hallData[0].stands[val.sequence - 1].brand_tier = val?.brand_tier;
    });
  };

  console.log("isModalVisible", isModalVisible);

  if (loading) {
    return <CommonLoader />;
  } else {
    let chatbotRes = false;
    if (dataToDisplay && dataToDisplay?.[0]?.channel_id)
      chatbotRes = checkIfChatbotTimeSlot(dataToDisplay?.[0]);
    return (
      <div>
        <div
          className={
            props && props.isFromVista ? "social_wall_image_modal" : ""
          }
        >
          <Modal
            title=""
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            className="pro_pic_review"
          >
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img className="social_wall_preview_img" src={imgPreview} />
              <div className="d- flex justify-content-center mt-2">
                <Button className="px-3" onClick={onRemoveClick}>
                  Delete
                </Button>
                <Button
                  className="ms-2 px-3"
                  onClick={() => setIsModalVisible(false)}
                >
                  Yes
                </Button>
              </div>
            </div>
          </Modal>

          <Modal
            title=""
            visible={editModalVisible}
            onOk={handleEditOk}
            onCancel={handleEditCancel}
            footer={null}
            className="pro_pic_review"
          >
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div className="w-100">
                <div className="row">
                  <div className="col-2 text-end">
                    <span>Logo :</span>
                  </div>
                  <div className="col-5">
                    <FileUpload
                      labelClassName="rounded border-pink btn-svg px-3 py-2"
                      labelTitle="Image Upload"
                      inputType="file"
                      inputOnChange={handleUpdateImageChange}
                      inputId="social_wall_logo_update"
                      inputName="social_wall_logo_update"
                      name="logo_update"
                    />
                  </div>
                  <div
                    className={`${isMobile2 ? "ms-4" : ""} col-2 text-start`}
                  >
                    {updatedLogoPreview ? (
                      <img
                        src={updatedLogoPreview}
                        height="50px"
                        width="50px"
                      />
                    ) : updateFields?.logo_image_url ? (
                      <img
                        src={updateFields?.logo_image_url ?? ""}
                        height="50px"
                        width="50px"
                      />
                    ) : null}
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-2"></div>
                  <div className="col-6 d-flex">
                    {updatedLogoImg ? (
                      <>
                        <span className="mx-3"> {updatedLogoImg.name} </span>
                        <span onClick={onRemoveClick}>
                          <DeleteOutlined />
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-2 text-end">
                    <span>Post : </span>
                  </div>
                  <div className="col-9">
                    <TextArea
                      name="update_post_content"
                      rows={4}
                      className="fs-bold-14"
                      placeholder="Enter A Description Here"
                      value={updateFields?.post_content}
                      onChange={(e) =>
                        setUpdatefields({
                          ...updateFields,
                          post_content: e.target.value,
                        })
                      }
                    />
                    {validator.current.message(
                      "update_post_content",
                      updateFields.post_content,
                      "required|text",
                      { className: "text-danger py-2" }
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-start mt-3">
              <Button
                className="mx-1"
                onClick={() => setEditModalVisible(false)}
              >
                Cancel
              </Button>
              <Button className="ms-2 mx-3" onClick={() => UpdatePostSubmit()}>
                Update
              </Button>
            </div>
          </Modal>

          <Modal
            title="Delete Post"
            visible={deleteModalVisible}
            onOk={handleDeleteOk}
            onCancel={handleDeleteCancel}
            footer={null}
            className="pro_pic_review"
          >
            <div className="d-flex flex-column justify-content-center align-items-center">
              <p>Are you sure to delete the post ?</p>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-2">
              <Button className="px-3" onClick={handleDeleteCancel}>
                Remove
              </Button>
              <Button
                className="ms-2 mx-3 px-3"
                onClick={() => DeletePostSubmit()}
              >
                Yes
              </Button>
            </div>
          </Modal>
        </div>
        <div
          className={
            props.isFromVista
              ? "d-flex justify-content-end bg-white p-2"
              : "d-flex justify-content-between py-2 align-items-center"
          }
        >
          {props.isFromVista ? (
            ""
          ) : (
            <div>
              <h4 className="fs-24 mt-1">Social Wall</h4>
            </div>
          )}
          {props.isFromVista && userType !== "SUPER ADMIN" ? (
            ""
          ) : userType === "SUPER ADMIN" || userType === "CUSTOMER" ? (
            <div className={props && props.isFromVista ? "mr-3" : ""}>
              <span>Event Name</span>
              <Select
                showSearch
                placeholder="Select Event"
                name="event_id"
                disabled={disableEvent}
                allowClear
                className="expomap_expohall_search"
                value={selectedEventId}
                onChange={(val) => {
                  setSelectedEventId(val);
                  setFields({ ...fields, event_id: val });
                }}
                optionFilterProp="children"
                listHeight={120}
                listItemHeight={4}
                bordered={false}
              >
                {eventList &&
                  eventList?.map((data) => {
                    return (
                      <Option value={data.event_id}>{data.event_name}</Option>
                    );
                  })}
              </Select>
            </div>
          ) : null}
        </div>

        {messageModal.isOpen ? (
          <Modal
            title={<b>Send Message</b>}
            visible={messageModal.isOpen}
            onOk={() => clearMessageAndModal()}
            onCancel={() => clearMessageAndModal()}
            footer={[
              <Button
                className="px-3 button-pink rounded"
                key="cancel"
                onClick={() => clearMessageAndModal()}
              >
                Cancel
              </Button>,
              <Button
                className="px-3 rounded"
                key="save"
                type="primary"
                onClick={() => callSendMessageAPI(messageModal.channel_id)}
              >
                Send
              </Button>,
            ]}
            className="message_modal"
          >
            <Row className="ticker_configuration_row mt-2">
              <Col span={6} className="ticker_configuration_label">
                <p>
                  Message
                  <RedAsterick />
                </p>
              </Col>
              <Col span={12}>
                <TextArea
                  name="message_text"
                  id="message_text"
                  className="ticker_configuration_inputDescription"
                  placeholder="Enter a message"
                  rows={4}
                  value={message_text}
                  onChange={(e) => set_message_text(e.target.value)}
                  onBlur={() =>
                    messageValidator.current.showMessageFor("message_text")
                  }
                />
                {messageValidator.current.message(
                  "Message",
                  message_text,
                  "required",
                  { className: "text-danger py-1" }
                )}
              </Col>
            </Row>
          </Modal>
        ) : null}
        {selectedEventId ? (
          <div className={props.isFromVista ? "vista_social_container" : ""}>
            {isChatBot && chatbot_json ? (
              <CustomChatbot
                onClose={async (conversation_details) => {
                  await saveChatbotHistory({
                    event_id: selectedEventId,
                    conversation_details,
                  });
                  setIsChatBot(false);
                }}
                responseJson={chatbot_json}
                chatbot_history={chatbot_history}
                onSetHistory={(newHistory) => set_chatbot_history(newHistory)}
                extraProps={{ event_id: selectedEventId }}
              />
            ) : null}
            <div className="social_container w-100 bg-white p-3">
              <div className="d-flex pb-2 border-bottom socialwall_header_container_mobile">
                <div className="d-block social_wall_event_img_container">
                  <img
                    className="social_wall_event_img"
                    src={
                      dataToDisplay && dataToDisplay?.event_logo_url
                        ? dataToDisplay?.event_logo_url
                        : dataToDisplay?.[0]?.brand?.logo_url
                    }
                  />
                </div>
                <div className="ms-3 d-flex flex-column justify-content-between">
                  <div>
                    <h6 className="fs-13">Sponsored by</h6>
                    <h5 className="fs-pink vista_social_title">
                      {dataToDisplay && dataToDisplay?.social_wall_title
                        ? dataToDisplay?.social_wall_title
                        : dataToDisplay?.[0]?.brand?.brand_title}
                    </h5>
                    <p className="social_wall_decription_mobile">
                      {dataToDisplay && dataToDisplay?.social_wall_description
                        ? dataToDisplay?.social_wall_description
                        : dataToDisplay?.[0]?.brand?.brand_description}
                    </p>
                  </div>
                  <div>
                    {dataToDisplay?.[0] && (
                      <h6 className="display-inline mb-2">
                        From our sponsor :{" "}
                      </h6>
                    )}
                    {dataToDisplay &&
                    dataToDisplay?.[0]?.is_asset_enable === true &&
                    dataToDisplay?.[0]?.zone?.zones_assets_id?.[0]
                      ?.is_active === true &&
                    dataToDisplay?.[0]?.zone?.zones_assets_id?.[0]
                      ?.is_downloadable === true ? (
                      <Button
                        onClick={() => {
                          if (
                            dataToDisplay?.[0]?.zone?.zones_assets_id[0]
                              ?.can_download_asset
                          ) {
                            window.location.href =
                              dataToDisplay &&
                              dataToDisplay?.[0]?.zone?.zones_assets_id[0]
                                .asset_file_url;
                            updateAssetDownloadCount(
                              dataToDisplay?.[0]?.zone?.zones_assets_id[0]
                                ?.zone_asset_id
                            );
                          } else {
                            toast.error("Maximum Number of Downloads Reached");
                          }
                        }}
                        className=" px-3 rounded mb-2"
                      >
                        <span className="d-flex justify-content-between">
                          <span className="ms-2 mx-2">
                            {dataToDisplay &&
                              dataToDisplay?.[0]?.zone?.zones_assets_id[0]
                                ?.asset_name}
                          </span>
                          <span className="text-end">
                            <Download />
                          </span>
                        </span>
                      </Button>
                    ) : (
                      ""
                    )}

                    <div className="w-100 d-flex justify-content-start flex-wrap">
                      {(dataToDisplay && dataToDisplay?.[0]?.channel_id) ||
                      (chatbotRes &&
                        dataToDisplay &&
                        dataToDisplay?.[0]?.chat_bot_start_date_time) ? (
                        <Button
                          className="p-1 px-3 rounded mb-2"
                          onClick={async () => {
                            if (
                              chatbotRes &&
                              dataToDisplay?.[0]?.chat_bot_start_date_time
                            ) {
                              let chat_bot_res = await getChatbotJson({
                                zone_id: dataToDisplay?.[0]?.zone_id,
                              });
                              if (chat_bot_res && !isEmpty(chat_bot_res)) {
                                set_chatbot_json(chat_bot_res);
                                setIsChatBot(true);
                              } else
                                toast.error("Chatbot details doesn't exist");
                            } else {
                              setMessageModal({
                                isOpen: true,
                                channel_id: dataToDisplay?.[0]?.channel_id,
                              });
                            }
                          }}
                        >
                          <Chat />
                          <span className="ms-2">Chat</span>
                        </Button>
                      ) : null}

                      {dataToDisplay &&
                      dataToDisplay?.[0]?.is_video_call_enable === true ? (
                        <Button
                          onClick={async () => {
                            let channel_id = dataToDisplay?.[0]?.channel_id;
                            const response = await videoRequestAPI(channel_id);
                            if (response.data.code === 200)
                              toast.success(response.data.message);
                            else toast.error(response.data.message);
                          }}
                          className="ms-2 px-3 rounded mb-2"
                        >
                          <Video />
                          <span className="ms-2">Video Call</span>
                        </Button>
                      ) : null}

                      {dataToDisplay &&
                      dataToDisplay?.[0]?.is_demo_room_enable === true ? (
                        <Button className="ms-2 px-3 rounded mb-2">
                          <Video />
                          <span
                            className="ms-2"
                            onClick={() => {
                              dispatch(
                                setVideoConferenceData({
                                  videoConferenceFlag: true,
                                  videoConferenceUrl:
                                    dataToDisplay?.[0]?.zone_demo_room_link,
                                  is_demo_room: true,
                                })
                              );
                            }}
                          >
                            Join the Demo Room
                          </span>
                        </Button>
                      ) : (
                        ""
                      )}

                      {dataToDisplay &&
                      dataToDisplay?.[0]?.is_booth_link_enable === true ? (
                        <Button
                          className="mx-2 px-3 rounded py-1"
                          onClick={() => {
                            let tier = dataToDisplay?.[0]?.brand?.brand_tier;
                            let zones = dataToDisplay?.[0]?.brand?.zones;
                            let sequence = null;
                            let brand_id = dataToDisplay?.[0]?.brand_id;
                            let eventID = selectedEventId;
                            let final_booth_url =
                              dataToDisplay?.[0]?.brand?.final_booth_url;
                            let history = props.history;

                            if (props?.isFromVista) {
                              dispatch(
                                setVistaBrandData({
                                  brand_id: brand_id,
                                })
                              );
                              let selectedStand = standsData[tier - 1];

                              selectedStand.url = final_booth_url;
                              if (zones?.length !== 0) {
                                zones?.forEach((item) => {
                                  if (item?.booth_location_id) {
                                    let obj = standsData[
                                      tier - 1
                                    ].hotspots.find(
                                      (val) =>
                                        val.index === item.booth_location_id
                                    );
                                    if (obj) {
                                      obj.status = item?.is_active;
                                    }
                                  }
                                });
                              }
                              selectedStand.lookAtBooth = 1;
                              selectedStand.exit = 1;

                              initializeHallDataValues(eventID, brand_id, tier);

                              AppDispatch({
                                type: ACTIONS.SET_STAND,
                                value: selectedStand,
                              });

                              AppDispatch({
                                type: ACTIONS.SET_ACTIVE_WINDOW,
                                value: "",
                              });
                            } else {
                              vistaRedirectToBooth(
                                tier,
                                zones,
                                sequence,
                                userType,
                                brand_id,
                                eventID,
                                history,
                                dispatch,
                                final_booth_url
                              );
                            }
                          }}
                        >
                          <BoothRed />
                          <span className=" mx-1">Visit Booth</span>
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/*----------------  Bottom Section --------------------*/}
              <div className="social_bottom d-flex justify-content-between">
                <div className="social_input_post">
                  <h5 className="mt-3 mb-4">Post</h5>
                  <TextArea
                    name="post_content"
                    rows={4}
                    className="fs-bold-14"
                    placeholder="Enter A Description Here"
                    value={fields?.post_content}
                    onChange={(e) =>
                      handleFieldsChange(e.target.value, "post_content")
                    }
                  />
                  {validator.current.message(
                    "post_content",
                    fields.post_content,
                    "required|text",
                    { className: "text-danger py-2" }
                  )}
                  <div className="d-flex justify-content-start mt-3">
                    <FileUpload
                      labelClassName="rounded border-pink btn-svg px-3 py-2"
                      labelTitle="Image Upload"
                      inputType="file"
                      inputOnChange={handleImgChange1}
                      inputId="social_wall_logo"
                      inputName="social_wall_logo"
                      name="logo"
                    />

                    {socialWallData?.social_wall_moderators_details
                      ?.social_wall_title ? (
                      <Button
                        className="social_postBtn mx-3 py-1 fs-14"
                        onClick={addPost}
                        size="middle"
                      >
                        Submit Post
                      </Button>
                    ) : (
                      <Button
                        className="social_postBtn ms-3 fs-16 py-1"
                        onClick={addPost}
                        disabled
                        size="middle"
                      >
                        Submit Post
                      </Button>
                    )}
                  </div>
                  {logoImg ? (
                    <>
                      <span className="mx-3"> {logoImg.name} </span>
                      <span onClick={onRemoveClick}>
                        <DeleteOutlined />
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </div>

                <div className={`social_postlist mt-3 ${isMobile && "ms-4"}`}>
                  <div className="d-flex justify-content-between w-100 mb-2">
                    <div className="d-flex justify-content-start">
                      <h5 className="">Post List</h5>
                    </div>

                    <div className="d-flex justify-content-end">
                      <Button
                        className="ms-2 px-3 rounded mb-2"
                        onClick={SortByRecentFunction}
                      >
                        <Sort /> <span className="ms-2">Recent</span>
                      </Button>
                      <Button
                        onClick={sortByLikeFunction}
                        className="ms-2 px-3 rounded mb-2"
                      >
                        <Sort /> <span className="ms-2">Likes</span>
                      </Button>

                      {socialWallData?.social_wall_moderators_details
                        ?.is_moderators === true ? (
                        <span className="ms-4 mt-1 fs-16">Visible</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="social_post_container">
                    {socialMediaPost?.map((data) => {
                      return (
                        <div
                          className={`border w-100  ${
                            isMobile ? "d-flex" : "d-block"
                          } mb-3`}
                        >
                          {data.logo_image_url && (
                            <div className="border d-flex m-3 justify-content-center">
                              <img
                                className="rounded m-1 mx-2 social_wall_post_preview"
                                src={data.logo_image_url}
                              />
                            </div>
                          )}
                          <div className="row w-100 h-100 px-3">
                            <div className="col-12 social_wall_post_content">
                              <Tooltip
                                placement="bottom"
                                title={data?.post_content}
                              >
                                <p className="mb-4 mt-2 fs-16">
                                  {data.post_content}
                                </p>
                              </Tooltip>
                            </div>

                            <div className={`col-12  ${isMobile && "d-flex"} `}>
                              <div className="">
                                <span className="fs-14 text-bold mt-2 mb-0 w-100 social_post_user_name">
                                  <b className="">{data.created_user_name}</b>
                                </span>
                                <p className="fs-14 mt-1 text-muted">
                                  <span className="">
                                    {moment(data.created_at).format("DD-MM-YY")}
                                  </span>
                                  <span className="mx-2">
                                    {moment(data.created_at).format("HH:mm")}
                                  </span>
                                </p>
                              </div>
                              <div className="d-flex align-self-end mb-2 w-100">
                                <div
                                  className={`d-flex align-self-end    w-50 ${
                                    isMobile && "border-start mx-3"
                                  }`}
                                >
                                  {data?.socail_media_post_created_by?.users_users_social_media_handles_user_id?.map(
                                    (item) => {
                                      let { social_media } = item;
                                      let url = "";
                                      if (
                                        item?.social_media_url.startsWith(
                                          "http://"
                                        ) ||
                                        item?.social_media_url.startsWith(
                                          "https://"
                                        )
                                      ) {
                                        url = item?.social_media_url;
                                      } else {
                                        url =
                                          "https://" + item.social_media_url;
                                      }

                                      if (social_media === "Instagram") {
                                        return (
                                          <div
                                            className=""
                                            onClick={(e) => {
                                              window.open(url, "_blank");
                                            }}
                                          >
                                            <InstagramOutlined
                                              style={{
                                                fontSize: "25px",
                                              }}
                                              className="ms-2"
                                            />
                                          </div>
                                        );
                                      } else if (social_media === "LinkedIn") {
                                        return (
                                          <div
                                            onClick={() =>
                                              window.open(url, "_blank")
                                            }
                                          >
                                            <LinkedinOutlined
                                              style={{
                                                fontSize: "25px",
                                              }}
                                              className="ms-2"
                                            />
                                          </div>
                                        );
                                      } else if (social_media === "Twitter") {
                                        return (
                                          <div
                                            onClick={() =>
                                              window.open(url, "_blank")
                                            }
                                          >
                                            <TwitterOutlined
                                              style={{
                                                fontSize: "25px",
                                              }}
                                              className="ms-2 "
                                            />
                                          </div>
                                        );
                                      } else if (social_media === "Facebook") {
                                        return (
                                          <div
                                            onClick={() =>
                                              window.open(url, "_blank")
                                            }
                                          >
                                            <FacebookOutlined
                                              style={{
                                                fontSize: "25px",
                                              }}
                                              className="ms-2"
                                            />
                                          </div>
                                        );
                                      }
                                    }
                                  )}

                                  <div
                                    className="ms-3"
                                    onClick={() =>
                                      socialWallLike(
                                        data.post_id,
                                        data.is_like === true ? false : true
                                      )
                                    }
                                  >
                                    {data.is_like === true ? (
                                      <HeartFilled
                                        style={{
                                          color: "#ef0855",
                                          fontSize: "27px",
                                        }}
                                      />
                                    ) : (
                                      <HeartOutlined
                                        style={{
                                          color: "#ef0855",
                                          fontSize: "27px",
                                        }}
                                      />
                                    )}
                                  </div>
                                  <span className="fs-pink my-1 mx-2">
                                    {data.post_likes_count}
                                  </span>
                                </div>
                                {data?.can_edit_delete === true && (
                                  <span
                                    className={`d-flex  edit_delete_section`}
                                  >
                                    <EditOutlined
                                      className="fs-16 mt-2 edit_delete_icon"
                                      style={{
                                        fontSize: "22px",
                                      }}
                                      onClick={() =>
                                        handleEditFieldChanges(data)
                                      }
                                    />
                                    <DeleteOutlined
                                      className="fs-16 mx-3 mt-2 edit_delete_icon"
                                      style={{
                                        fontSize: "22px",
                                      }}
                                      onClick={() => handleDeletePost(data)}
                                    />
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          {socialWallData?.social_wall_moderators_details
                            ?.is_moderators === true ? (
                            <div className="text-end">
                              <Switch
                                className=" ms-2"
                                checked={data.is_visible ? true : false}
                                onChange={() =>
                                  socialWallPostVisibility(
                                    data.post_id,
                                    data.is_visible === true ? false : true
                                  )
                                }
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : isSuperAdminOrCustomer(userType) ? (
          <div className="d-flex justify-content-center align-items-center height_80vh">
            <div className="empty_event">
              <Result
                icon={<SmileOutlined />}
                title={
                  <h4 className="fs-pink">
                    Please choose your event in the top right corner
                  </h4>
                }
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
};

export default SocialWall;
