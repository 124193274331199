import { cloneDeep, isEmpty, isEqual, isNumber, uniqWith } from "lodash";
import moment from "moment";
import { TIME_FORMAT } from "../../components/constsnts/common.constants";
import { getDatesBetweenDates } from "../../utils/commonFunctions";
const fullHours = [
  "12:00 AM",
  "1:00 AM",
  "2:00 AM",
  "3:00 AM",
  "4:00 AM",
  "5:00 AM",
  "6:00 AM",
  "7:00 AM",
  "8:00 AM",
  "9:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "1:00 PM",
  "2:00 PM",
  "3:00 PM",
  "4:00 PM",
  "5:00 PM",
  "6:00 PM",
  "7:00 PM",
  "8:00 PM",
  "9:00 PM",
  "10:00 PM",
  "11:00 PM",
  "12:00 AM",
];
export const getHourIntervals = () => {
  let hours = [];
  fullHours.forEach((ele, index) => {
    if (fullHours[index] && fullHours[index + 1])
      hours.push({
        start_time: fullHours[index],
        end_time: fullHours[index + 1],
      });
  });
  return hours;
};

export function ifTimeSlotIncluded(
  hrStart,
  hrEnd,
  currentBlockStartTime,
  currentBlockEndTime,
  columnName = ""
) {
  let lastMoment = moment("11:59:00 PM", TIME_FORMAT);
  if (
    (hrStart.format("HH:mm A") === currentBlockStartTime.format("HH:mm A") &&
      currentBlockEndTime.isBefore(hrEnd)) ||
    (currentBlockStartTime.isAfter(hrStart) &&
      currentBlockEndTime.isBefore(hrEnd)) ||
    (currentBlockStartTime.isAfter(hrStart) &&
      currentBlockStartTime.isBefore(hrEnd) &&
      currentBlockEndTime.isAfter(hrEnd)) ||
    (currentBlockStartTime.isAfter(hrStart) &&
      hrEnd.format("HH:mm A") === currentBlockEndTime.format("HH:mm A")) ||
    (currentBlockEndTime.isAfter(hrStart) &&
      currentBlockEndTime.isBefore(hrEnd)) ||
    (currentBlockStartTime.format("HH:mm A") === hrStart.format("HH:mm A") &&
      currentBlockStartTime.isBefore(hrEnd)) ||
    (currentBlockEndTime.format("HH:mm A") === hrEnd.format("HH:mm A") &&
      currentBlockStartTime.isBefore(hrStart)) ||
    (hrStart.isAfter(currentBlockStartTime) &&
      hrStart.isBefore(currentBlockEndTime))
  )
    return true;
  //if data is in between 11pm 12 AM
  else if (
    hrEnd.format("hh:mm A") === "12:00 AM" &&
    ((currentBlockStartTime.isBefore(lastMoment) &&
      currentBlockEndTime.isBefore(lastMoment) &&
      (currentBlockStartTime.isAfter(hrStart) ||
        currentBlockStartTime.format("HH:mm A") ===
          hrStart.format("HH:mm A"))) ||
      (currentBlockStartTime.isAfter(hrStart) &&
        currentBlockEndTime.format("HH:mm A") === hrEnd.format("HH:mm A")) ||
      (currentBlockStartTime.isAfter(hrStart) &&
        currentBlockStartTime.isBefore(lastMoment) &&
        currentBlockEndTime.isAfter(hrStart) &&
        currentBlockEndTime.format("HH:mm A") === lastMoment.format("HH:mm A")))
  )
    return true;
  else return false;
}

export function ifTimeSlotAvailable(startTime, endTime, existingTimings) {
  let isTimeSlotAvailble = true;

  if (existingTimings) {
    existingTimings.some(function (ele, index) {
      let presentStart = moment(ele.start_time, TIME_FORMAT);
      let presentEnd = moment(ele.end_time, TIME_FORMAT);

      if (
        startTime.format("HH:mm A") === presentStart.format("HH:mm A") ||
        endTime.format("HH:mm A") === presentEnd.format("HH:mm A") ||
        (startTime.isBefore(presentStart) &&
          startTime.isBefore(presentEnd) &&
          endTime.isAfter(presentStart) &&
          endTime.isBefore(presentEnd)) ||
        (startTime.isBefore(presentStart) &&
          startTime.isBefore(presentEnd) &&
          endTime.isAfter(presentStart) &&
          endTime.isAfter(presentEnd)) ||
        (startTime.isAfter(presentStart) &&
          startTime.isBefore(presentEnd) &&
          endTime.isAfter(presentStart) &&
          endTime.isAfter(presentEnd))
      )
        isTimeSlotAvailble = false;
    });

    return isTimeSlotAvailble;
  } else return isTimeSlotAvailble;
}

export function isPastDate(dateStringToCheck) {
  const dateToCheck = new Date(dateStringToCheck);
  let todayDateString = moment().format("YYYY-MM-DD");
  const now = new Date(todayDateString);

  if (dateToCheck < now) return "yes";
  else return "no";
}

export function getCurrentTimeAndPercentage() {
  let heightMargin = 0,
    minuteDifference = 0;
  let timeSlot = moment().format("hh:mm A");
  let fullHours = getHourIntervals();
  fullHours.push({ start_time: "11:00 PM", end_time: "11:59 PM" });
  let timeSlotOfCurrentTime = { start_time: "", end_time: "" };
  fullHours.some((ele, index) => {
    let hrStart = moment(ele.start_time, "hh:mm A");
    let hrEnd = moment(ele.end_time, "hh:mm A");
    let currentMoment = moment(moment().format("hh:mm A"), "hh:mm A");
    if (
      currentMoment === hrStart ||
      currentMoment === hrEnd ||
      (currentMoment.isAfter(hrStart) && currentMoment.isBefore(hrEnd))
    ) {
      const a = hrStart;
      let currentTime = moment().format("hh:mm A");
      const b = moment(currentTime, "hh:mm A");
      minuteDifference = getMinutesDiff(a, b);
      let currentIndex = index;
      heightMargin = currentIndex * 60 + minuteDifference;

      if (index === fullHours.length - 1) {
        timeSlotOfCurrentTime = {
          start_time: "11:00 PM",
          end_time: "12:00 AM",
        };
        timeSlot = moment("11:00 PM", "hh:mm A").format("hh:mm A");
      } else {
        timeSlotOfCurrentTime = {
          start_time: ele.start_time,
          end_time: ele.end_time,
        };
        timeSlot = moment(ele.start_time, "hh:mm A").format("hh:mm A");
      }
      return true;
    }
  });
  return {
    timeSlot,
    currentTime: moment(),
    heightMargin,
    minuteDifference,
    timeSlotOfCurrentTime,
  };
}

export function searchSessionsFromData(allSessions, searchTerm) {
  let clonedAllSessions = cloneDeep(allSessions);
  let allSearchedData = [];
  clonedAllSessions &&
    clonedAllSessions.forEach((element) => {
      if (element) {
        if (element && element.auditorium_hosting_brands) {
          let isItemFound = false;
          element.auditorium_hosting_brands.forEach((item) => {
            if (item?.brand_title?.toLowerCase().includes(searchTerm))
              isItemFound = true;
          });
          if (isItemFound) allSearchedData.push(element);
          else if (element.session_title.toLowerCase().includes(searchTerm)) {
            allSearchedData.push(element);
          }
        } else if (element && element.networking_lounge_sponsoring_brands) {
          let isItemFound = false;
          element.networking_lounge_sponsoring_brands.forEach((item) => {
            if (item?.brand_title?.toLowerCase().includes(searchTerm))
              isItemFound = true;
          });
          if (isItemFound) allSearchedData.push(element);
          else if (element.session_title.toLowerCase().includes(searchTerm)) {
            allSearchedData.push(element);
          }
        }
      }
    });
  return allSearchedData;
}

export function getSponserTimingsAndHeader(ele, key, liveTime) {
  let timings = [];
  let newHeader = {};
  ele[key].forEach((item) => {
    if (item && item.start_time && item.end_time) {
      let start_time = moment(item.start_time, TIME_FORMAT);
      let end_time = moment(item.end_time, TIME_FORMAT);
      timings.push({
        start_time: item.start_time,
        end_time: item.end_time,
        data: item,
      });
      if (
        liveTime &&
        liveTime.currentTime.isAfter(start_time) &&
        liveTime.currentTime.isBefore(end_time)
      )
        newHeader = item;
    }
  });
  timings = uniqWith(timings, isEqual);
  return { timings, newHeader };
}

export function getAllSessions(result) {
  let allSessionsToSearch = [];
  if (
    result.main_auditorium_configurations &&
    result.main_auditorium_configurations.auditorium_sessions
  ) {
    result.main_auditorium_configurations.auditorium_sessions.forEach(
      (element) => {
        allSessionsToSearch.push(element);
      }
    );
  }

  if (result.satellite_auditorium_configurations) {
    result.satellite_auditorium_configurations.forEach((ele) => {
      if (ele && ele.auditorium_sessions) {
        ele.auditorium_sessions.forEach((element) => {
          allSessionsToSearch.push(element);
        });
      }
    });
  }

  if (result.networking_lounge_configurations) {
    result.networking_lounge_configurations.forEach((ele) => {
      if (ele && ele.networking_lounge_sessions) {
        ele.networking_lounge_sessions.forEach((element) => {
          allSessionsToSearch.push(element);
        });
      }
    });
  }
  return allSessionsToSearch;
}

export function getMinutesDiff(start, end) {
  const diff = end.diff(start, "minutes");
  return parseInt(diff);
}

export function getSessionlabels(cellName) {
  let leftModalTitle, rightModalTitle;
  if (cellName === "mainAudi") {
    leftModalTitle = "Main Auditorium Session Editor";
    rightModalTitle = "Main Auditorium Sponsorship";
  } else if (cellName === "satAudi") {
    leftModalTitle = "Satellite Auditorium Session Editor";
    rightModalTitle = "Satellite Auditorium Sponsorship";
  } else if (cellName === "netLounge") {
    leftModalTitle = "Admin Networking Session Editor";
    rightModalTitle = "Network Lounge Sponsorship";
  }
  return { leftModalTitle, rightModalTitle };
}

export function getNewDateIndexToNavigate(props) {
  const { leftOrRight, current_date_to_navigate, event_dates_to_navigate } =
    props;
  let newDate;
  let newIndexForNavigate;
  if (
    leftOrRight === "right" &&
    current_date_to_navigate.currentIndex < current_date_to_navigate.length - 1
  ) {
    let indexNew = event_dates_to_navigate.findIndex(
      (ele) => ele.currentIndex === current_date_to_navigate.currentIndex
    );
    newIndexForNavigate = indexNew + 1;
    newDate = moment(event_dates_to_navigate[indexNew + 1].dateString);
  } else if (
    leftOrRight === "left" &&
    current_date_to_navigate.currentIndex !== 0
  ) {
    let indexNew = event_dates_to_navigate.findIndex(
      (ele) => ele.currentIndex === current_date_to_navigate.currentIndex
    );
    newIndexForNavigate = indexNew - 1;
    newDate = moment(event_dates_to_navigate[indexNew - 1].dateString);
  }
  return { newIndexForNavigate, newDate };
}

export function isBoothLink(headerData, default_sponsor_details) {
  if (
    (headerData &&
      !isEmpty(headerData) &&
      headerData.is_booth_link_enable &&
      headerData.is_booth_link_enable !== 0) ||
    (default_sponsor_details &&
      default_sponsor_details.is_on_booth_leads_enable)
  )
    return true;
  else return false;
}

export function isVideoRoomLink(headerData, default_sponsor_details) {
  if (
    (headerData &&
      !isEmpty(headerData) &&
      headerData.is_demo_room_enable &&
      headerData.is_demo_room_enable !== 0 &&
      headerData.zone_demo_room_link) ||
    (default_sponsor_details &&
      default_sponsor_details.is_video_demo_room_enable &&
      default_sponsor_details.zone_demo_room_link)
  )
    return true;
  else return false;
}

export function getAllSubDivisionsData(params) {
  // hrStart; //12 AM
  // hrEnd; //1 AM

  const { hrStart, hrEnd, data, columnName } = params;

  let eleStartTime, eleEndTime;
  let filledDivs = [];

  if (data && data.boxData) {
    const checkData = data.boxData;
    //for expo data will be direct in the boxdata
    if (columnName === "expo" && params?.isSession) {
      if (checkData && checkData.start_time && checkData.end_time) {
        eleStartTime = moment(checkData.start_time, TIME_FORMAT);
        eleEndTime = moment(checkData.end_time, TIME_FORMAT);
        let eleEndTimeNew = eleEndTime;
        if (
          ifTimeSlotIncluded(
            hrStart,
            hrEnd,
            eleStartTime,
            eleEndTimeNew,
            columnName
          )
        ) {
          // get the minutes difference between start and end time and set height accordingly
          const diffHere = getMinutesDiff(eleStartTime, eleEndTime);

          filledDivs.push({
            ...checkData,
            isFilled: true,
            height: diffHere > 60 ? 60 : diffHere,
          });
        }
      }
    }
    //for others data will be boxdata array objects //
    else {
      checkData &&
        checkData?.forEach(function (ele) {
          if (ele && ele.start_time && ele.end_time) {
            eleStartTime = moment(ele.start_time, TIME_FORMAT);
            eleEndTime = moment(ele.end_time, TIME_FORMAT);
            if (
              ifTimeSlotIncluded(
                hrStart,
                hrEnd,
                eleStartTime,
                eleEndTime,
                columnName
              )
            ) {
              const diffHere = getMinutesDiff(eleStartTime, eleEndTime);

              filledDivs.push({
                ...ele,
                isFilled: true,
                height: diffHere > 60 ? 60 : diffHere,
              });
            }
          }
        });
    }

    let finalTimingsArray = [];
    if (filledDivs) {
      /* ---------------------------------------------
        found array where data is present for the start end time block
        modify the data according to multiple conditions
      --------------------------------------------- */
      filledDivs.forEach((ele, index) => {
        const eleStartTime = moment(ele.start_time, TIME_FORMAT);
        const eleEndTime = moment(ele.end_time, TIME_FORMAT);
        if (
          index === 0 &&
          hrStart.format("HH:mm A") !== eleStartTime.format("HH:mm A")
        ) {
          /* ---------------------------------------------
           first element from the array ->
            && it is not started at hourly start time, random start time conditions
            eg 02.10 to 02.20, 2.50 to 03.10
          --------------------------------------------- */

          let firstEleDifferenceWithHrStart;
          /* ---------------------------------------------
            eleStartTime.isBefore(hrStart)
            eg. session 2.50 3.10, so 2.50 to 3.00 is already up
            we need to show 3.00 to 3.10 
            it wont have corner radius(isAlreadyUp flag will render a simple colored div)
            --------------------------------------------- */

          if (eleStartTime.isBefore(hrStart)) {
            const diffHere = getMinutesDiff(hrStart, eleEndTime);
            // console.log(hrStart, eleEndTime, diffHere);

            firstEleDifferenceWithHrStart = diffHere > 60 ? 60 : diffHere;

            finalTimingsArray.push({
              ...ele,
              isFilled: true,
              height: firstEleDifferenceWithHrStart ?? 0,
              isAlreadyUp: true,
            });
            /* ---------------------------------------------
              last block of the session
              eg it is ending as 5.10 in the 5 to 5.30 block
              height will be 10px
            --------------------------------------------- */
            if (
              isNumber(firstEleDifferenceWithHrStart) &&
              firstEleDifferenceWithHrStart > 0 &&
              firstEleDifferenceWithHrStart < 60
            ) {
              if (filledDivs.length <= 1) {
                finalTimingsArray.push({
                  ...ele,
                  isFilled: false,
                  height: 60 - firstEleDifferenceWithHrStart,
                });
              }
            }
          } else {
            /* ---------------------------------------------
             start time of session/sponser is equal or greater than hour item.
             eg 02.05 is not before 02.00(hour item start time). it is after 02.00
            --------------------------------------------- */

            const diffHere = getMinutesDiff(hrStart, eleStartTime);

            firstEleDifferenceWithHrStart = diffHere > 60 ? 60 : diffHere;
            if (firstEleDifferenceWithHrStart !== 60) {
              // console.log(
              //   "firstEleDifferenceWithHrStart",
              //   firstEleDifferenceWithHrStart
              // );

              finalTimingsArray.push({
                start_time: hrStart.format("HH:mm A"),
                end_time: eleStartTime.format("HH:mm A"),
                isFilled: false,
                height: firstEleDifferenceWithHrStart ?? 0,
              });
            }

            let objToPush = ele;
            if (eleStartTime.format("HH:mm A") === hrStart.format("hh:mm A"))
              objToPush.isFirst = true;

            finalTimingsArray.push(ele);

            const diffHereWithEndTime = getMinutesDiff(eleEndTime, hrEnd);

            if (diffHereWithEndTime > 0) {
              finalTimingsArray.push({
                start_time: eleEndTime.format("HH:mm A"),
                end_time: hrEnd.format("HH:mm A"),
                isFilled: false,
                height: diffHereWithEndTime ?? 0,
              });
            }
          }

          /* ---------------------------------------------
             when filledDivs length is greater than 0 below code will work
             it will add additional transparent division.
             eg
             lets say session ends at 0.50-1.10
             so above code will generate and add 1 more division
             0.50 to 1.00
             1.00 to 1.10
            --------------------------------------------- */
          if (index !== filledDivs.length - 1) {
            if (filledDivs[index + 1] && filledDivs[index + 1].start_time) {
              const diffHere = getMinutesDiff(
                eleEndTime,
                moment(filledDivs[index + 1].start_time, TIME_FORMAT)
              );

              finalTimingsArray.push({
                start_time: ele.end_time,
                end_time: filledDivs[index + 1].start_time,
                isFilled: false,
                height: diffHere > 60 ? 60 : diffHere ?? 0,
              });
            }
          }
        } else {
          /* ---------------------------------------------
             not the first element from array
             eg session start time is equal to hourly start time
            --------------------------------------------- */

          finalTimingsArray.push(ele);

          if (index !== filledDivs.length - 1) {
            if (filledDivs[index + 1] && filledDivs[index + 1].start_time) {
              const diffHere = getMinutesDiff(
                eleEndTime,
                moment(filledDivs[index + 1].start_time, TIME_FORMAT)
              );
              finalTimingsArray.push({
                start_time: ele.end_time,
                end_time: filledDivs[index + 1].start_time,
                isFilled: false,
                height: diffHere > 60 ? 60 : diffHere ?? 0,
              });
            }
          } else {
            /* ---------------------------------------------
            session time - 12.00 to 1.10
             eleEndTime say 1.10 is after 1.00 hourly end
            --------------------------------------------- */

            // amandeep start
            const diffHereWithEndTime = getMinutesDiff(eleEndTime, hrEnd);

            // console.log("filledDivs", filledDivs);
            // console.log("finalTimingsArray", finalTimingsArray);

            // if (columnName === "mainAudi")
            //   console.log("diffHereWithEndTime", diffHereWithEndTime);

            if (diffHereWithEndTime > 0) {
              //if time is from 5 to 5.30 then fill the rest 30 mins

              finalTimingsArray.push({
                start_time: eleEndTime.format("HH:mm A"),
                end_time: hrEnd.format("HH:mm A"),
                isFilled: false,
                height: diffHereWithEndTime ?? 0,
              });
            }

            //amandeep end

            if (eleEndTime.isAfter(hrEnd)) {
              let diffHere = getMinutesDiff(eleStartTime, hrEnd, true);
              if (hrStart.format("hh:mm A") === "11:00 PM") {
                diffHere = getMinutesDiff(eleStartTime, eleEndTime, true);
              }

              finalTimingsArray[finalTimingsArray.length - 1] = {
                ...ele,
                isFilled: true,
                height: diffHere > 60 ? 60 : diffHere ?? 0,
                isNextAvailable: true,
              };
            }
          }
        }
      });

      return finalTimingsArray;
    } else return [];
  }
  return [];
}

export function getAVLTimes(
  isForBlank,
  elementToCheck,
  dataToCheck,
  key,
  subKey
) {
  let clonedAvlTimings = cloneDeep(dataToCheck[key]);
  let finalAvlTimings = [];
  if (!isForBlank)
    finalAvlTimings =
      clonedAvlTimings &&
      clonedAvlTimings.filter(
        (item) =>
          item.data &&
          elementToCheck &&
          elementToCheck[subKey] &&
          item.data[subKey] !== elementToCheck[subKey]
      );
  else {
    finalAvlTimings =
      clonedAvlTimings &&
      clonedAvlTimings.filter((item) => item.data && item.data[subKey] !== "");
  }
  return finalAvlTimings;
}

export function getLeftTitles(columnName, ele) {
  if (columnName === "expo") return "Expo Hall Open";
  else if (
    columnName === "mainAudi" ||
    columnName === "satAudi" ||
    columnName === "netLounge"
  )
    return "Session";
}

export function getRightTitles(columnName, ele) {
  if (columnName === "ticker") return ele.ticker_name;
  else return "Sponsor";
}

export async function getAllEventDates(show_details) {
  let event_dates_here = [];
  await show_details.forEach((ele) => {
    if (ele.start_date) {
      if (!event_dates_here.includes(ele.start_date)) {
        if (ele.start_date === ele.end_date)
          event_dates_here.push(ele.start_date);
        else {
          const datesOfShow = getDatesBetweenDates(
            ele.start_date,
            ele.end_date
          );
          event_dates_here = [...event_dates_here, ...datesOfShow];
        }
      }
    }
  });
  return event_dates_here;
}
