import React, { useState, useRef, useEffect } from "react";
import { Modal, Row, Col, TimePicker, Input, Button, Typography } from "antd";
import moment from "moment";
import "../eventCalender.css";
import SimpleReactValidator from "simple-react-validator";
import { TIME_FORMAT } from "../../../components/constsnts/common.constants";
import { toast } from "react-toastify";
import {
  createExpoHall,
  updateExpoHall,
} from "../../../redux/slice/eventCalender";
import { cloneDeep } from "lodash";
import { useDispatch } from "react-redux";
import { dialogs } from "../../../components/constsnts/string.constants";
import {
  createISOTimeString,
  createTZString,
  getDisabledTime,
} from "../../../utils/commonFunctions";
import { getMinutesDiff } from "../eventCalender.utils";
import CommonLoader from "../../../components/Widgets/CommonLoader";

const ExpoHallOpen = (props) => {
  const dispatch = useDispatch();
  const { expoHallData } = props;
  const { TextArea } = Input;
  const { Text } = Typography;
  const format = "HH:mm";
  const validator = useRef(new SimpleReactValidator());
  const [expHallForceUpdate, setExpoHallForceUpdate] = useState(false);
  const [expoHallLocalData, setExpoHallLocalData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const start_date_time_new = createTZString(expoHallData?.start_date_time);
    const end_date_time_new = createTZString(expoHallData?.end_date_time);
    setExpoHallLocalData({
      expo_hall_configuration_id: expoHallData.expo_hall_configuration_id ?? "",
      expo_hall_description: expoHallData.expo_hall_description ?? "",
      hall_title: expoHallData.hall_title ?? "",
      start_date_time: expoHallData?.start_date_time
        ? moment(start_date_time_new.format("HH:mm"), TIME_FORMAT)
        : moment(moment().format("HH:mm"), TIME_FORMAT),
      end_date_time: expoHallData?.end_date_time
        ? moment(end_date_time_new.format("HH:mm"), TIME_FORMAT)
        : moment(moment().format("HH:mm"), TIME_FORMAT),
      event_id: expoHallData.event_id,
    });
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const createUpdateAPI = async (dataToUpdate) => {
    if (dataToUpdate.expo_hall_configuration_id) {
      const updateRes = await dispatch(updateExpoHall(dataToUpdate));

      if (updateRes?.payload?.data?.code === 200) {
        toast.success(updateRes.payload.data.message);
        props.onSuccess(true);
      } else {
        if (updateRes.error && updateRes.error === "Network Error")
          toast.error("Please check the internet connection");
        if (updateRes.payload?.data?.message)
          toast.error(updateRes.payload.data.message);
      }
    } else {
      const createRes = await dispatch(createExpoHall(dataToUpdate));

      if (createRes?.payload?.data?.code === 200) {
        toast.success(createRes.payload.data.message);
        props.onSuccess(true);
      } else {
        if (createRes?.payload?.data?.message)
          toast.error(createRes.payload.data.message);
      }
    }
  };

  const handleSubmit = () => {
    if (validator.current.allValid()) {
      const { expoHallData } = props;

      let clonedData = cloneDeep(expoHallLocalData);

      if (moment(clonedData.start_date_time) > moment(clonedData.end_date_time))
        toast.error("Please select correct time");
      else if (
        getMinutesDiff(
          moment(clonedData.start_date_time),
          moment(clonedData.end_date_time)
        ) < 10
      )
        toast.error("Please select atleast 10 mins duration");
      else {
        const dateHere = expoHallData.currentDateForCalender;
        const start_date_str =
          dateHere +
          " " +
          moment(clonedData.start_date_time).format("HH:mm:ss");
        const start_utc = createISOTimeString(start_date_str);
        const end_date_str =
          dateHere + " " + moment(clonedData.end_date_time).format("HH:mm:ss");
        const end_utc = createISOTimeString(end_date_str);
        clonedData.start_date_time = start_utc;
        clonedData.end_date_time = end_utc;

        createUpdateAPI(clonedData);
      }
    } else {
      validator.current.showMessages();
      toast.error(dialogs.missingFields);
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      setExpoHallForceUpdate(!expHallForceUpdate);
    }
  };

  const onChangeFields = (val, name) => {
    setExpoHallLocalData({ ...expoHallLocalData, [name]: val });
  };

  return (
    <Modal
      title={<b>Expo Hall Open</b>}
      visible={true}
      width={750}
      // bodyStyle={{ height: 450 }}
      onOk={() => props.onCloseModal()}
      onCancel={() => props.onCloseModal()}
      footer={[
        <Button
          className="px-3 button-pink rounded"
          key="cancel"
          style={{ width: "11%" }}
          onClick={() => props.onCloseModal()}
        >
          Cancel
        </Button>,
        <Button
          className="px-3 rounded"
          key="save"
          type="primary"
          style={{ width: "11%" }}
          onClick={handleSubmit}
        >
          Save
        </Button>,
      ]}
    >
      {loading ? (
        <CommonLoader />
      ) : (
        <div className="expohall_form">
          <Row
            className="expohall_row"
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          >
            <Col span={6} className="expohall_label gutter-row">
              <Text style={{ color: "#666666", fontWeight: "500" }}>
                Start & End Time
              </Text>
            </Col>
            <Col className="gutter-row" span={8}>
              <TimePicker
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                disabled={
                  expoHallData &&
                  expoHallData.expo_hall_configuration_id &&
                  expoHallData.userType === "BRAND ADMIN"
                    ? true
                    : false
                }
                name="start_date_time"
                id="start_date_time"
                format={format}
                value={expoHallLocalData.start_date_time}
                className="expohall_timepicker"
                style={{ width: "100%" }}
                onSelect={(val) => {
                  onChangeFields(
                    moment(moment(val).format("HH:mm"), TIME_FORMAT),
                    "start_date_time"
                  );
                }}
                onChange={(val) => {
                  onChangeFields(
                    moment(moment(val).format("HH:mm"), TIME_FORMAT),
                    "start_date_time"
                  );
                }}
                onBlur={() =>
                  validator.current.showMessageFor("start_date_time")
                }
              />

              {validator.current.message(
                "Start Time",
                expoHallLocalData.start_date_time,
                "required",
                { className: "text-danger py-1" }
              )}
            </Col>
            <Col className="gutter-row" span={8}>
              <TimePicker
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                disabled={
                  expoHallData &&
                  expoHallData.expo_hall_configuration_id &&
                  expoHallData.userType === "BRAND ADMIN"
                    ? true
                    : false
                }
                disabledTime={() =>
                  getDisabledTime(expoHallLocalData.start_date_time)
                }
                name="end_date_time"
                id="end_date_time"
                value={expoHallLocalData.end_date_time}
                format={format}
                className="expohall_timepicker"
                style={{ width: "100%" }}
                onSelect={(val) => {
                  if (
                    moment(moment(val).format("HH:mm"), TIME_FORMAT) <
                    expoHallLocalData.start_date_time
                  ) {
                    onChangeFields(
                      expoHallLocalData?.end_date_time,
                      "end_date_time"
                    );
                    toast.error("Please select correct time");
                  } else
                    onChangeFields(
                      moment(moment(val).format("HH:mm"), TIME_FORMAT),
                      "end_date_time"
                    );
                }}
                onChange={(val) => {
                  if (
                    moment(moment(val).format("HH:mm"), TIME_FORMAT) <
                    expoHallLocalData.start_date_time
                  ) {
                    onChangeFields(
                      expoHallLocalData?.end_date_time,
                      "end_date_time"
                    );
                    toast.error("Please select correct time");
                  } else
                    onChangeFields(
                      moment(moment(val).format("HH:mm"), TIME_FORMAT),
                      "end_date_time"
                    );
                }}
                onBlur={() => validator.current.showMessageFor("end_date_time")}
              />
              {validator.current.message(
                "End Time",
                expoHallLocalData.end_date_time,
                "required",
                { className: "text-danger py-1" }
              )}
            </Col>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={6} className="expohall_label gutter-row">
              <Text style={{ color: "#666666", fontWeight: "500" }}>
                Expo Hall Description
              </Text>
            </Col>
            <Col className="gutter-row" span={16}>
              <TextArea
                name="expo_hall_description"
                id="expo_hall_description"
                className="expohall_inputDescription"
                placeholder="Enter a description here"
                rows={4}
                value={expoHallLocalData.expo_hall_description}
                onChange={(e) =>
                  onChangeFields(e.target.value, "expo_hall_description")
                }
                onBlur={() =>
                  validator.current.showMessageFor("expo_hall_description")
                }
                minLength={1}
                maxLength={200}
              />
              {validator.current.message(
                "Description",
                expoHallLocalData.expo_hall_description,
                "required",
                { className: "text-danger py-1" }
              )}
            </Col>
          </Row>
        </div>
      )}
    </Modal>
  );
};

export default ExpoHallOpen;
