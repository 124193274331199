import React, { useState, useEffect, useRef } from "react";
import "./Brands.css";
import { Row, Col, Select, Button, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { chat, calendarBlack } from "../../components/Images/Images";
import {
  LinkedinOutlined,
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
} from "@ant-design/icons";
import { ApiCall } from "../../utils/api";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { Menu, Dropdown, Empty, Input, Modal } from "antd";
import { Sort } from "../../components/svgJS/Sort";
import SimpleReactValidator from "simple-react-validator";
import RedAsterick from "../../components/Widgets/RedAsterick";
import { sendMessageAPI } from "../../utils/commonApis";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { BrandBooth } from "../../components/svgJS/BrandBooth";
import {
  getComentChatUser,
  isRouteExistInMenu,
  vistaRedirectToBooth,
} from "../../utils/commonFunctions";
import { PAGINATION_DEFAULT_SETTINGS } from "../../components/constsnts/common.constants";
import { cloneDeep } from "lodash";
import { useMediaQuery } from "react-responsive";

const Brands = (props) => {
  const { setBrandObj, isFromVista, setSelectedMenuItem, historyFromVista } =
    props;
  const messageValidator = useRef(new SimpleReactValidator());

  const history = useHistory();
  const dispatch = useDispatch();
  const vistaEventId = useSelector((state) => state?.vistaBrand?.eventId);
  let CryptoJS = require("crypto-js");
  const { Option } = Select;
  const { TextArea } = Input;
  const [userType, setUserType] = useState();
  const [brandList, setBrandList] = useState([]);
  const [tierList, setTierList] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    event_id: null,
    brand_id: null,
    brand_tier: null,
    search: null,
  });
  const [showA_Z, setShowA_Z] = useState(false);
  const [messageModal, setMessageModal] = useState({ isOpen: false, data: {} });
  const [message_text, set_message_text] = useState("");
  const [cometChatUser, setCometChatUser] = useState({
    isValidUser: false,
    data: {},
    uid: "",
  });
  const [forceUpdate, setForceUpdate] = useState(false);
  const [page_no, set_page_no] = useState(PAGINATION_DEFAULT_SETTINGS.page_no);
  const [no_of_records] = useState(
    PAGINATION_DEFAULT_SETTINGS.no_of_records_brands
  );
  const [brands_count, set_brands_count] = useState(0);
  const [enableNext, setEnableNext] = useState(false);
  const isMobile = useMediaQuery({ minWidth: 480 });
  const Tablet = useMediaQuery({ maxWidth: 1200 });
  const [loginUserId, setLoginUserId] = useState(null);
  const [userName, setUserName] = useState("");

  useEffect(async () => {
    if (localStorage.getItem("user_id")) {
      let cipher_login_user_id = localStorage.getItem("user_id");
      let bytes_login_user_id = CryptoJS.AES.decrypt(
        cipher_login_user_id,
        "user_id"
      );
      let jsonparse_user_id = JSON.parse(
        bytes_login_user_id.toString(CryptoJS.enc.Utf8)
      );

      setLoginUserId(jsonparse_user_id);
    }

    if (localStorage.getItem("user_name")) {
      let cipher_login_user_name = localStorage.getItem("user_name");
      let bytes_login_user_name = CryptoJS.AES.decrypt(
        cipher_login_user_name,
        "user_name"
      );
      let jsonparse_user_name = JSON.parse(
        bytes_login_user_name.toString(CryptoJS.enc.Utf8)
      );

      setUserName(jsonparse_user_name);
    }

    let is_route_present = await isRouteExistInMenu(CryptoJS);
    if (is_route_present === false) {
      window.location.href = "/pagenotfound";
    } else {
      getEventList();
      getTierList();

      const cometRes = await getComentChatUser();
      setCometChatUser(cometRes);
    }
  }, [CryptoJS.AES]);

  useEffect(() => {
    if (fields?.event_id || fields?.brand_tier) {
      getAllBrands(
        null,
        PAGINATION_DEFAULT_SETTINGS.page_no,
        PAGINATION_DEFAULT_SETTINGS.no_of_records_brands,
        "reset"
      );
    }
  }, [fields?.event_id, fields?.brand_tier]);

  useEffect(() => {
    setFields({ ...fields, event_id: vistaEventId });
    getBrandList(vistaEventId);
  }, [vistaEventId]);

  const getEventList = () => {
    ApiCall("get", null, "/admin/events/dropdown/get", "")
      .then((response) => {
        const { rows } = response?.data?.data;
        const ciphertext = localStorage.getItem("user_type");
        let bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
        let user_type = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setUserType(user_type);

        if (window.location.pathname === "/brand") {
          if (user_type === "SUPER ADMIN" || user_type === "CUSTOMER") {
            getAllBrands(
              null,
              PAGINATION_DEFAULT_SETTINGS.page_no,
              PAGINATION_DEFAULT_SETTINGS.no_of_records_brands,
              "reset"
            );
          }
        }

        if (
          user_type === "DELEGATE" ||
          user_type === "SPEAKER" ||
          user_type === "BRAND ADMIN"
        ) {
          const events = rows.filter((item) => {
            return item.event_id === parseInt(localStorage.getItem("event_id"));
          });
          let id = events[0].event_id;
          setFields({ ...fields, event_id: id });
          getBrandList(id);
        } else {
          if (rows.length === 1) {
            setFields({ ...fields, event_id: rows[0].event_id });
            getBrandList(rows[0].event_id);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getAllBrands = async (
    params,
    pageNo = "",
    no_of_records_here = "",
    reset = "",
    sortVal = false,
    search = ""
  ) => {
    setLoading(true);
    let paramsToPass = { page_no, no_of_records };
    if (pageNo) paramsToPass.page_no = pageNo;

    if (search) paramsToPass.search = search;

    if (params) paramsToPass = { ...params, ...paramsToPass };

    if (!params) {
      paramsToPass = {
        ...paramsToPass,
        event_id: fields?.event_id,
        brand_id: fields?.brand_id,
        brand_tier: fields?.brand_tier,
        sort_type: "brand_title",
        sort_by: sortVal ? "DSC" : "ASC",
      };
    }

    if (no_of_records_here) paramsToPass.no_of_records = no_of_records_here;

    await ApiCall("get", null, "/admin/brandview/details/get", {
      ...paramsToPass,
    })
      .then((response) => {
        const { rows } = response?.data?.data;
        let clonedBrandData;

        if (reset && reset !== "") clonedBrandData = [];
        else clonedBrandData = cloneDeep(brandData);

        clonedBrandData = [...clonedBrandData, ...rows];
        setBrandData(clonedBrandData);

        if (response?.data?.data.count)
          set_brands_count(response?.data?.data.count);
        setEnableNext(response?.data?.data.enableNext);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const getBrandList = (event_id) => {
    ApiCall("get", null, "/admin/brand/dropdown/get", {
      event_id: event_id,
    })
      .then((response) => {
        const { rows } = response?.data?.data;
        if (rows.length === 1)
          setFields({ ...fields, brand_id: rows[0].brand_id });
        setBrandList(rows);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getTierList = () => {
    ApiCall("get", null, "/admin/tiers/get", null)
      .then((response) => {
        const data = response?.data?.data;
        setTierList(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleFieldsChange = (val, name) => {
    if (name === "event_id")
      setFields({ ...fields, [name]: val, brand_id: null, brand_tier: null });
    else setFields({ ...fields, [name]: val });
    setBrandData([]);

    if (name === "search") {
      getAllBrands(
        null,
        PAGINATION_DEFAULT_SETTINGS.page_no,
        PAGINATION_DEFAULT_SETTINGS.no_of_records_brands,
        "reset",
        null,
        val
      );
    } else {
      getAllBrands(
        null,
        PAGINATION_DEFAULT_SETTINGS.page_no,
        PAGINATION_DEFAULT_SETTINGS.no_of_records_brands,
        "reset"
      );
    }
  };

  function clearMessageAndModal() {
    set_message_text("");
    setMessageModal({ isOpen: false, data: {} });
  }

  async function callSendMessageAPI(channel_id) {
    if (messageValidator.current.allValid()) {
      const response = await sendMessageAPI(channel_id, message_text);
      if (response.data.code === 200) {
        toast.success(response.data.message);
        clearMessageAndModal();
      } else toast.error(response.data.message);
    } else {
      messageValidator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
  }

  const menu = (
    <Menu>
      {showA_Z === true ? (
        <Menu.Item
          className="text-center fs-pink"
          onClick={() => sortBrands(true)}
        >
          <span>Z-A</span>
        </Menu.Item>
      ) : (
        <Menu.Item
          className="text-center fs-pink"
          onClick={() => sortBrands(false)}
        >
          <span>A-Z</span>
        </Menu.Item>
      )}
    </Menu>
  );

  function sortBrands(sortVal) {
    setBrandData([]);
    setShowA_Z(sortVal);
    setLoading(true);

    set_page_no(1);

    getAllBrands(
      null,
      PAGINATION_DEFAULT_SETTINGS.page_no,
      null,
      "reset",
      sortVal
    );
  }

  const renderBrandCard = (data) => {
    return (
      <>
        <div className="brands_card_header">
          <div className="brands_card_img_container">
            <img src={data.logo_url} className="brands_card_img" />
          </div>
          <div className="brands_card_header_content">
            <Tooltip placement="right" title={data?.brand_title}>
              <h5 className="brands_card_header_title">{data.brand_title}</h5>
            </Tooltip>
            <span className="brands_card_header_sub_title ">
              {data.brand_tier_name}
            </span>
            <div
              className="brands_card_header_booth"
              onClick={(e) => {
                if (e.currentTarget !== e.target) {
                  e.stopPropagation();

                  e.preventDefault();
                  let tier = data?.brand_tier;
                  let zones = data?.zones;
                  let sequence = null;
                  let brand_id = data?.brand_id;
                  let eventID = data?.event_id;
                  let final_booth_url = data?.final_booth_url;
                  vistaRedirectToBooth(
                    tier,
                    zones,
                    sequence,
                    userType,
                    brand_id,
                    eventID,
                    history,
                    dispatch,
                    final_booth_url
                  );
                  if (isFromVista) setSelectedMenuItem();
                }
              }}
            >
              <BrandBooth className="" />
              <span className="fs-pink">Visit Booth</span>
            </div>
            <div className="brands_card_header_icon_container">
              {data?.brands_default_zone_id &&
              data?.brands_default_zone_id?.is_text_chat_enable ? (
                <div
                  onClick={(e) => {
                    if (e.currentTarget !== e.target) {
                      e.stopPropagation();
                      e.preventDefault();

                      setMessageModal({
                        isOpen: true,
                        channel_id: data?.brands_default_zone_id?.channel_id,
                      });
                    }
                  }}
                  className="brands_card_header_icons_bg"
                >
                  <img className="brands_card_header_icon" src={chat} />
                </div>
              ) : null}

              <div
                onClick={(e) => {
                  if (e.currentTarget !== e.target) {
                    e.stopPropagation();
                    e.preventDefault();
                    if (data?.zones !== null) {
                      if (data?.zones?.length !== 0) {
                        if (!isFromVista) {
                          history.push(
                            `/brandCalendar/${data.event_id}/${data.brand_id}`
                          );
                        } else {
                          historyFromVista.push(
                            `/brandCalendar/${data.event_id}/${data.brand_id}`
                          );
                        }
                      }
                    } else {
                      toast.error(
                        "No zone is created against this brand, create a zone first to visit brand calendar"
                      );
                    }
                  }
                }}
              >
                <div className="brands_card_header_icons_bg">
                  <img
                    className="brands_card_header_icon"
                    src={calendarBlack}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="brands_card_body">
          <p className="mb-0  text-dark">{data.brand_description}</p>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="brands_header">
        <div
          className={`h-100 d-flex ${
            !isFromVista ? "brands_header_fields_container" : ""
          }`}
        >
          {window.location.pathname === "/brand" ? (
            <div className="d-flex mt-2 flex-column px-3 brands_search_container">
              <Input
                className="w-100 d-flex py-1 rounded mt-4 brands_search_bar"
                allowClear
                onChange={(val) =>
                  handleFieldsChange(val.target.value, "search")
                }
                placeholder="Search By Brand Name, Zone Name, Agent Name"
              />
            </div>
          ) : (
            <>
              <div className="d-flex flex-column align-items-center px-3 brands_select_brand_container">
                <span className="pb-1 align-self-start mt-2">Select Brand</span>
                <div className="d-flex position-relative w-100">
                  <SearchOutlined className="brands_searchicon" />
                  <Select
                    showSearch
                    allowClear
                    className="brands_input_search"
                    placeholder="Search Exibitors"
                    optionFilterProp="children"
                    value={fields?.brand_id}
                    onChange={(val) => {
                      setFields({ ...fields, brand_id: val });
                      getAllBrands(
                        {
                          event_id: fields?.event_id,
                          brand_id: val,
                          brand_tier: fields?.brand_tier,
                        },
                        PAGINATION_DEFAULT_SETTINGS.page_no,
                        null,
                        "reset"
                      );
                    }}
                    bordered={false}
                    disabled={brandList && brandList.length < 2 ? true : false}
                    listHeight={120}
                    listItemHeight={4}
                  >
                    {brandList &&
                      brandList?.map((data, i) => {
                        return (
                          <Option value={data.brand_id} key={i}>
                            {data.brand_title}
                          </Option>
                        );
                      })}
                  </Select>
                </div>
              </div>
            </>
          )}
          <div
            className={`d-flex brands_tier_sort align-items-center justify-content-end`}
            style={{ marginLeft: !isMobile && "32px" }}
          >
            <Select
              showSearch
              allowClear
              className="brands_tier_search"
              value={fields?.brand_tier}
              placeholder="Filter By Tier"
              optionFilterProp="children"
              onChange={(val) => {
                handleFieldsChange(val, "brand_tier");
              }}
              bordered={false}
              listHeight={120}
              listItemHeight={4}
            >
              {tierList &&
                tierList?.map((data, i) => {
                  return (
                    <Option value={data.tier_id} key={i}>
                      {data.tier}
                    </Option>
                  );
                })}
            </Select>

            <Dropdown overlay={menu} placement="bottom" arrow>
              {showA_Z ? (
                <Button
                  className="brands_header_btns me-3"
                  onClick={() => sortBrands(false)}
                >
                  <Sort />
                  <span>A-Z</span>
                </Button>
              ) : (
                <Button
                  className="brands_header_btns me-3"
                  onClick={() => sortBrands(true)}
                >
                  <Sort />
                  <span>Z-A</span>
                </Button>
              )}
            </Dropdown>
          </div>
        </div>
      </div>
      <div>
        <div
          className={`brands_cards_container pr-3 ${
            (!isMobile || Tablet) && "justify-content-center"
          }`}
          style={{ paddingLeft: isMobile && "20px" }}
        >
          {loading ? (
            <CommonLoader />
          ) : brandData && brandData?.length === 0 ? (
            <Empty className="mx-auto mt-5 align-self-center" />
          ) : (
            brandData &&
            brandData?.map((data, i) => {
              return (
                <div
                  key={i}
                  className="brands_card"
                  onClick={() => {
                    if (data.default_zone_id) {
                      if (setBrandObj)
                        setBrandObj({
                          event_id: data.event_id ?? null,
                          brand_id: data.brand_id ?? null,
                          show: data.event_id && data?.brand_id ? true : false,
                        });
                    } else
                      toast.error(
                        // "No zone is created against this brand, create a zone first to visit Brand Calendar"
                        "Default zone not assigned against this brand"
                      );
                  }}
                >
                  {data.default_zone_id ? (
                    <div
                      onClick={(e) => {
                        if (e.currentTarget !== e.target) {
                          e.stopPropagation();
                          e.preventDefault();
                          if (data?.brands_default_zone_id?.is_active) {
                            //check  If zone is active

                            if (!isFromVista) {
                              history.push(
                                `/expomap/brandzonedetails/${data.brand_id}/${data.default_zone_id}`
                              );
                            } else {
                              historyFromVista.push(
                                `/expomap/brandzonedetails/${data.brand_id}/${data.default_zone_id}`
                              );
                            }
                          } else {
                            toast.error(
                              // "No zone is created against this brand, create a zone first to visit Brand Calendar"
                              "Default Zone is inactive against this brand"
                            );
                          }
                        }
                      }}
                    >
                      {renderBrandCard(data)}
                    </div>
                  ) : (
                    <div>{renderBrandCard(data)}</div>
                  )}

                  <div
                    onClick={(e) => {
                      if (e.currentTarget !== e.target) {
                        e.stopPropagation();
                        e.preventDefault();
                      }
                    }}
                    className="brands_card_footer_links w-100 text-dark"
                  >
                    {data?.brands_social_media_handles_id?.map((item, i) => {
                      let { brandsSocialMediaHandles_social_media_id } = item;
                      let { social_media } =
                        brandsSocialMediaHandles_social_media_id;
                      let url = "";
                      if (
                        item?.social_media_url.startsWith("http://") ||
                        item?.social_media_url.startsWith("https://")
                      )
                        url = item?.social_media_url;
                      else url = "https://" + item.social_media_url;

                      if (social_media === "Instagram") {
                        return (
                          <div
                            key={i}
                            className="brand_social_icons"
                            onClick={(e) => {
                              window.open(url, "_blank");
                            }}
                          >
                            <InstagramOutlined />
                          </div>
                        );
                      } else if (social_media === "LinkedIn") {
                        return (
                          <div
                            onClick={() => window.open(url, "_blank")}
                            key={i}
                          >
                            <LinkedinOutlined />
                          </div>
                        );
                      } else if (social_media === "Twitter") {
                        return (
                          <div
                            onClick={() => window.open(url, "_blank")}
                            key={i}
                          >
                            <TwitterOutlined />
                          </div>
                        );
                      } else if (
                        social_media === "Facebook" ||
                        social_media === "facebook"
                      ) {
                        return (
                          <div
                            onClick={() => window.open(url, "_blank")}
                            key={i}
                          >
                            <FacebookOutlined />
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              );
            })
          )}
          {messageModal.isOpen ? (
            <Modal
              title={<b>Send Message</b>}
              visible={messageModal.isOpen}
              onOk={() => clearMessageAndModal()}
              onCancel={(e) => {
                if (e.currentTarget !== e.target) {
                  e.stopPropagation();
                  e.preventDefault();
                  clearMessageAndModal();
                }
              }}
              footer={[
                <Button
                  className="px-3 button-pink rounded"
                  key="cancel"
                  onClick={() => clearMessageAndModal()}
                >
                  Cancel
                </Button>,
                <Button
                  className="px-3 rounded"
                  key="save"
                  type="primary"
                  onClick={() => {
                    callSendMessageAPI(messageModal.channel_id);
                  }}
                >
                  Send
                </Button>,
              ]}
              className="message_modal"
            >
              <Row className="ticker_configuration_row mt-2">
                <Col span={6} className="ticker_configuration_label">
                  <p>
                    Message
                    <RedAsterick />
                  </p>
                </Col>
                <Col span={12}>
                  <TextArea
                    name="message_text"
                    id="message_text"
                    className="ticker_configuration_inputDescription"
                    placeholder="Enter a message"
                    rows={4}
                    value={message_text}
                    onChange={(e) => set_message_text(e.target.value)}
                    onBlur={() =>
                      messageValidator.current.showMessageFor("message_text")
                    }
                  />
                  {messageValidator.current.message(
                    "Message",
                    message_text,
                    "required",
                    { className: "text-danger py-1" }
                  )}
                </Col>
              </Row>
            </Modal>
          ) : null}
        </div>
        {!loading ? (
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: "5px" }}
          >
            {enableNext ? (
              <Button
                className="fs-pink custom_border"
                size="medium"
                onClick={() => {
                  set_page_no(page_no + 1);

                  getAllBrands(null, page_no + 1);
                }}
              >
                Load More
              </Button>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Brands;
