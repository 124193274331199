import React, { useState, useEffect } from "react";
import "./surveyscreen.css";
import { Button, Checkbox, Input, Switch, Steps } from "antd";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import {
  getSurveyDetailsDelegateUser,
  addDelegateSurvey,
} from "../../../utils/commonApis";
import { toast } from "react-toastify";
import { cloneDeep } from "lodash-es";
import { useHistory } from "react-router-dom";
import CommonLoader from "../../../components/Widgets/CommonLoader";
import { isRouteExistInMenu } from "../../../utils/commonFunctions";
import { ApiCall } from "../../../utils/api";
import { Bar, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

const { TextArea } = Input;

charts(FusionCharts);

const Survey = (surveyProps) => {
  var CryptoJS = require("crypto-js");
  const [details, setDetails] = useState({});
  const [stepData, setStepData] = useState(null);
  const [current, setCurrent] = useState(0);
  const [loadingSurvey, setLoadingSurvey] = useState(false);
  const [dataToSend, setDataToSend] = useState([]);
  const history = useHistory();

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  );

  let registration_survey_id = null;
  let first_time_login_based_on_event_survey = false;

  useEffect(async () => {
    if (localStorage.getItem("registration_survey_id"))
      registration_survey_id = JSON.parse(
        localStorage.getItem("registration_survey_id")
      );

    if (localStorage.getItem("first_time_login_based_on_event_survey"))
      first_time_login_based_on_event_survey = JSON.parse(
        localStorage.getItem("first_time_login_based_on_event_survey")
      );

    let is_route_present = await isRouteExistInMenu(CryptoJS);

    if (is_route_present === false && !surveyProps?.isFromSurveyModal) {
      //IsfromSurveyModal represents registration survey
      window.location.href = "/pagenotfound";
    } else {
      setLoadingSurvey(true);
      let paramsForSurveyDelegate = null;

      if (
        registration_survey_id !== null &&
        first_time_login_based_on_event_survey !== false
      ) {
        //User login for first time show registration survey for the  event
        paramsForSurveyDelegate = {
          survey_type: "REGISTRATION",
          event_id: localStorage.getItem("event_id"),
          is_survey_type: true,
        };
      } else if (surveyProps.isFromBrandZone) {
        paramsForSurveyDelegate = {
          is_brandzone_survey: true,
          survey_id: surveyProps?.survey_id,
          event_id: surveyProps?.event_id,
        };
      } else if (surveyProps?.fromFeedback === true) {
        paramsForSurveyDelegate = {
          survey_type: "FEEDBACK",
        };
      } else {
        paramsForSurveyDelegate = {
          survey_type: "EVENT",
          is_event_survey: true,
        };
      }

      const surveyResponse = await getSurveyDetailsDelegateUser(
        paramsForSurveyDelegate
      );
      if (surveyResponse) {
        setDetails(surveyResponse);
        setLoadingSurvey(false);
        if (surveyResponse.question_details) setAllQuestions(surveyResponse);
      }

      setTimeout(() => {
        setLoadingSurvey(false);
      }, 2000);
    }
  }, []);

  function setAllQuestions(surveyResponse) {
    setStepData(
      surveyResponse.question_details.map((data, seqIndex) => {
        const dataToPass = {
          ...data,
          parentData: surveyResponse,
        };
        if (data.question_type === "TOGGLE")
          return <ToggleForm data={dataToPass} seqIndex={seqIndex} />;
        else if (data.question_type === "SINGLE SELECT")
          return <SingleTickBox data={dataToPass} seqIndex={seqIndex} />;
        else if (data.question_type === "MULTIPLE SELECT")
          return <MultipleTickBoxes data={dataToPass} seqIndex={seqIndex} />;
        else if (data.question_type === "MULTIPLE CHOICE")
          return <MultipleChoice data={dataToPass} seqIndex={seqIndex} />;
        else if (data.question_type === "FREEFORM TEXT")
          return <FreeFormText data={dataToPass} seqIndex={seqIndex} />;
      })
    );
  }

  const handleSubmit = async (finalData) => {
    const addDelegateSurveyResponse = await addDelegateSurvey(
      finalData.parentData
    );
    if (addDelegateSurveyResponse.code === 200) {
      toast.success(addDelegateSurveyResponse?.message);
      let preEventUrl = null;
      let welcomeUrl = null;
      if (
        localStorage.getItem("pre_event_msg") ||
        localStorage.getItem("welcome_msg")
      ) {
        preEventUrl = localStorage.getItem("pre_event_msg");
        welcomeUrl = localStorage.getItem("welcome_msg");
      }

      if (
        registration_survey_id !== null &&
        (welcomeUrl.trim().length !== 0 || preEventUrl.trim().length !== 0)
      ) {
        history?.push("/welcomepage"); //Will Trigger when user logins for first time.
        surveyProps?.handleCloseUserSurveyModal();
        localStorage.setItem("registration_survey_id", null);
      } else history?.push("/concourse");
    } else toast.error(addDelegateSurveyResponse.message);
  };

  const next = () => {
    setCurrent((prev) => prev + 1);
  };

  const prev = () => {
    setCurrent((prev) => prev - 1);
  };

  const handleFieldsChange = (data, text, choice_id) => {
    if (data.question_type === "FREEFORM TEXT") {
      if (data && data.parentData && data.parentData.question_details) {
        let cloneDetails = cloneDeep(data.parentData);
        cloneDetails &&
          cloneDetails.question_details &&
          cloneDetails.question_details.forEach((ele, index) => {
            if (ele && ele.survey_qa_id === data.survey_qa_id) {
              cloneDetails.question_details[
                index
              ].answer_details[0].user_survey_answer_details.free_form_text_answer =
                text;
            }
          });
        setDataToSend(cloneDetails);
        setAllQuestions(cloneDetails);
      }
    } else if (data.question_type === "MULTIPLE CHOICE") {
      if (data && data.parentData && data.parentData.question_details) {
        let cloneDetails = cloneDeep(data.parentData);
        cloneDetails &&
          cloneDetails.question_details &&
          cloneDetails.question_details.forEach((ele, index) => {
            if (ele && ele.question_type === data.question_type) {
              cloneDetails.question_details[index].answer_details.forEach(
                (ele, i) => {
                  if (ele.choice_id === choice_id) {
                    cloneDetails.question_details[index].answer_details[
                      i
                    ].is_default_selected = text;
                  } else {
                    cloneDetails.question_details[index].answer_details[
                      i
                    ].is_default_selected = !text;
                  }
                }
              );
            }
          });
        setDataToSend(cloneDetails);
        setAllQuestions(cloneDetails);
      }
    } else if (data.question_type === "TOGGLE") {
      if (data && data.parentData && data.parentData.question_details) {
        let cloneDetails = cloneDeep(data.parentData);

        cloneDetails &&
          cloneDetails.question_details &&
          cloneDetails.question_details.forEach((ele, index) => {
            if (ele && ele.question_type === data.question_type) {
              cloneDetails.question_details[index].answer_details.forEach(
                (ele, i) => {
                  if (text === true) {
                    cloneDetails.question_details[
                      index
                    ].answer_details[1].is_default_selected = false;
                    cloneDetails.question_details[
                      index
                    ].answer_details[0].is_default_selected = true;
                  } else {
                    cloneDetails.question_details[
                      index
                    ].answer_details[1].is_default_selected = true;
                    cloneDetails.question_details[
                      index
                    ].answer_details[0].is_default_selected = false;
                  }
                }
              );
            }
          });
        setDataToSend(cloneDetails);
        setAllQuestions(cloneDetails);
      }
    } else if (data.question_type === "MULTIPLE SELECT") {
      if (data && data.parentData && data.parentData.question_details) {
        let cloneDetails = cloneDeep(data.parentData);
        cloneDetails &&
          cloneDetails.question_details &&
          cloneDetails.question_details.forEach((ele, index) => {
            if (ele && ele.question_type === data.question_type) {
              cloneDetails.question_details[index].answer_details.forEach(
                (ele, i) => {
                  if (ele.choice_id === choice_id) {
                    cloneDetails.question_details[index].answer_details[
                      i
                    ].is_default_selected = text;
                    cloneDetails.question_details[index].answer_details[
                      i
                    ].user_survey_answer_details.is_default_selected = text;
                  }
                }
              );
            }
          });
        setDataToSend(cloneDetails);
        setAllQuestions(cloneDetails);
      }
    } else if (data.question_type === "SINGLE SELECT") {
      if (data && data.parentData && data.parentData.question_details) {
        let cloneDetails = cloneDeep(data.parentData);

        cloneDetails &&
          cloneDetails.question_details &&
          cloneDetails.question_details.forEach((ele, index) => {
            if (ele && ele.question_type === data.question_type) {
              cloneDetails.question_details[index].answer_details.forEach(
                (ele, i) => {
                  if (ele.choice_id === choice_id) {
                    cloneDetails.question_details[index].answer_details[
                      i
                    ].is_default_selected = text;
                  }
                }
              );
            }
          });
        setDataToSend(cloneDetails);
        setAllQuestions(cloneDetails);
      }
    }
  };

  const ToggleForm = (props) => {
    console.log("Toggle Props", props);
    return (
      <>
        <div
          className={`${
            !surveyProps?.isFromSurveyModal
              ? "survey_container"
              : "userSurveyModal_container"
          }  w-100 bg-white`}
          style={{ position: "relative" }}
        >
          <div className="row">
            <div className="col-12 text-start mt-4">
              <h5 className="fs-pink">
                {props?.data?.parentData?.is_submitted === true
                  ? "Edit the Survey"
                  : "Complete the Survey"}
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="mt-3">
                <h4 className="fs-18 fs-bold">
                  <span className="mx-1"> Question : </span>
                  {props?.data?.question}
                </h4>
              </div>
              <div className="mt-5">
                <span className="fs-18 fs-bold mx-1">Answer :</span>
                <span className="fs-16 mx-3">
                  {props?.data?.answer_details[1].offLable}
                </span>
                <Switch
                  defaultChecked={
                    !props?.data?.parentData?.is_submitted
                      ? props?.data?.answer_details[0]?.is_default_selected
                      : props?.data?.answer_details[0]
                          ?.user_survey_answer_details?.is_default_selected
                  }
                  // checked={props?.data?.answer_details[0]?.is_default_selected}
                  onChange={(checked) =>
                    handleFieldsChange(
                      props?.data,
                      checked,
                      props?.data.choice_id
                    )
                  }
                />
                <span className="mx-3 fs-16">
                  {props?.data?.answer_details[0].onLable}
                </span>
              </div>
            </div>
          </div>
          <div
            className={`${
              !surveyProps?.isFromSurveyModal ? "" : "userSurveyModal_buttons"
            } d-flex w-75 mb-4 justify-content-between mt-4`}
          >
            {props.seqIndex >= 1 && (
              <Button className="mt-2" onClick={() => prev()}>
                Previous
              </Button>
            )}

            {props.seqIndex <
            props?.data.parentData.question_details.length - 1 ? (
              <Button
                className="mt-2"
                onClick={() => {
                  props?.data?.is_required === true &&
                  props?.data?.answer_details[0]?.is_default_selected === false
                    ? toast.error(`${"Toggle should be on to proceed further"}`)
                    : next();
                }}
              >
                Next
              </Button>
            ) : (
              <Button
                type="primary"
                className="mt-2"
                onClick={() => {
                  props?.data?.is_required === true &&
                  props?.data?.answer_details[0]?.is_default_selected === false
                    ? toast.error(`${"Toggle should be on to proceed further"}`)
                    : handleSubmit(props?.data);
                }}
              >
                Submit
              </Button>
            )}
          </div>
        </div>
      </>
    );
  };

  const SingleTickBox = (props) => {
    return (
      <>
        <div
          className={`${
            !surveyProps?.isFromSurveyModal
              ? "survey_container"
              : "userSurveyModal_container"
          }  w-100 bg-white"`}
        >
          <div className="row">
            <div className="col-12 text-start mt-4">
              <h5 className="fs-pink">
                {props?.data?.parentData?.is_submitted === true
                  ? "Edit the Survey"
                  : "Complete the Survey"}
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="mt-4 d-flex survey-checkbox">
                <h4 className="fs-18 fs-bold">
                  <span className="mx-1"> Question : </span>
                  {props?.data?.question}
                </h4>
                {props?.data?.answer_details.map((data, idx) => (
                  <Checkbox
                    className="mx-4"
                    style={{ height: "30px" }}
                    defaultChecked={
                      !props?.data?.parentData?.is_submitted
                        ? data?.is_default_selected
                        : data?.user_survey_answer_details?.is_default_selected
                    }
                    onChange={(e) =>
                      handleFieldsChange(
                        props?.data,
                        e.target.checked,
                        data.choice_id
                      )
                    }
                  />
                ))}
              </div>
              <div className="mt-4"></div>
            </div>
          </div>
          <div
            className={`${
              !surveyProps?.isFromSurveyModal ? "" : "userSurveyModal_buttons"
            } d-flex w-75 mb-4 justify-content-between`}
          >
            {props.seqIndex >= 1 && (
              <Button onClick={() => prev()}>Previous</Button>
            )}

            {props.seqIndex <
            props?.data.parentData.question_details.length - 1 ? (
              <Button
                onClick={() => {
                  props?.data?.is_required === true &&
                  props?.data?.answer_details[0]?.is_default_selected === false
                    ? toast.error(
                        `${"Please select checkbox to proceed further"}`
                      )
                    : next();
                }}
              >
                Next
              </Button>
            ) : (
              <Button
                type="primary"
                className="mt-2"
                onClick={() => {
                  props?.data?.is_required === true &&
                  props?.data?.answer_details[0]?.is_default_selected === false
                    ? toast.error(
                        `${"Please select checkbox to proceed further"}`
                      )
                    : handleSubmit(props?.data);
                }}
              >
                Submit
              </Button>
            )}
          </div>
        </div>
      </>
    );
  };

  const MultipleTickBoxes = (props) => {
    console.log("Multiple Tick Box props", props);
    const [resultArr, setResultArr] = useState({
      labels: [],
      datasets: [
        {
          label: "number of votes",
          data: [],
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    });

    let chartType = props?.data?.chart_type;
    const [showChart, setShowChart] = useState(false);
    const [saveButton, setSaveButton] = useState(true);
    const [loaderMultiTick, setLoaderMultiTick] = useState(false);

    const optionsPie = {
      responsive: true,
      plugins: {
        title: {
          display: false,
          text: "",
        },
        legend: {
          display: true,
          position: "right",
        },
      },
    };

    const optionsBar = {
      responsive: true,
      plugins: {
        title: {
          display: false,
          text: "",
        },
        legend: {
          display: false,
          position: "right",
        },
      },
    };

    // useEffect(() => {
    //   if (props?.data?.parentData?.is_submitted === true) {
    //     getSurveyGraphData({ survey_qa_id: props?.data?.survey_qa_id });
    //   }
    // }, []);

    const getSurveyGraphData = async (params) => {
      setLoaderMultiTick(true);
      const response = await ApiCall(
        "GET",
        "",
        "/admin/survey/result/get",
        params
      );
      if (response?.data?.code === 200) {
        let choiceIds = [];

        props?.data?.answer_details?.forEach((elem) => {
          if (elem?.is_default_selected === true) {
            choiceIds.push(elem?.choice_id);
          }
        });

        setResultArr({
          labels: response?.data?.data?.labels.map((item, i) => `${item}`),
          datasets: [
            {
              label:
                chartType?.toLowerCase() === "bar" ? "Number of Votes" : "",
              data: response?.data?.data?.datasets?.map((item) => {
                if (choiceIds.includes(item?.choice_id)) return item?.data + 1;
                else return item?.data;
              }),
              backgroundColor: [
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(82, 215, 38, 0.2)",
                "rgba(153, 102, 255, 0.2)",
                "rgba(255, 159, 64, 0.2)",
              ],
              borderColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(82, 215, 38, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
              ],
              borderWidth: 1,
            },
          ],
        });
        setShowChart(true);
        setSaveButton(false);
      } else {
        toast.error(response?.data?.message);
      }
      setLoaderMultiTick(false);
    };

    let trueCount = props?.data?.answer_details?.filter(
      (value) => value?.user_survey_answer_details?.is_default_selected === true
    ).length;

    if (loaderMultiTick) return <CommonLoader />;
    else
      return (
        <>
          <div
            className={`${
              !surveyProps?.isFromSurveyModal
                ? "survey_container"
                : "userSurveyModal_container"
            }  w-100 bg-white"`}
          >
            <div className="row">
              <div className="col-12 text-start mt-4">
                <h5 className="fs-pink">
                  {props?.data?.parentData?.is_submitted === true
                    ? "Edit the Survey"
                    : "Complete the Survey"}
                </h5>
              </div>
            </div>

            <div className="row">
              <div className="col-12 my-2">
                <span className="fs-18 fs-bold">
                  <span className="mx-3"> Question : </span>
                  {props?.data?.question}
                </span>
              </div>
              <div className="d-flex survey_mobile_multiple_ans_container justify-content-around mt-3">
                <span className="fs-18 fs-bold ">Answer's :</span>
                {props?.data?.answer_details.map((data) => (
                  <div className=" d-flex border p-2 option_block">
                    <Checkbox
                      checked={
                        data?.user_survey_answer_details?.is_default_selected
                      }
                      disabled={!saveButton ? true : false}
                      onChange={(e) => {
                        handleFieldsChange(
                          props?.data,
                          e.target.checked,
                          data.choice_id
                        );
                      }}
                    />
                    <span className="ms-2">{data.option}</span>
                  </div>
                ))}
              </div>

              {props?.data?.display_chart === true && (
                <div
                  className="d-flex mt-5 my-4 justify-content-center"
                  // style={{ height: "250px" }}
                >
                  {/* <ReactFusioncharts
                  type="pie2d"
                  dataFormat="JSON"
                  dataSource={dataSource}
                /> */}

                  {showChart ? (
                    chartType === "PIE" ? (
                      <div
                        className="video-conference-result-chart-type"
                        style={{
                          width: "258px",
                          height: "186px",
                        }}
                      >
                        <Pie data={resultArr} options={optionsPie} />
                      </div>
                    ) : (
                      <div
                        className="video-conference-result-chart-type"
                        style={{
                          width: "433px",
                          height: "200px",
                        }}
                      >
                        <Bar options={optionsBar} data={resultArr} />
                      </div>
                    )
                  ) : null}
                </div>
              )}
            </div>
            <div
              className={`${
                !surveyProps?.isFromSurveyModal ? "" : "userSurveyModal_buttons"
              } d-flex w-75 mb-4 justify-content-between`}
            >
              {props.seqIndex >= 1 && (
                <Button onClick={() => prev()}>Previous</Button>
              )}

              {props.seqIndex <
              props?.data.parentData.question_details.length - 1 ? (
                <>
                  {saveButton ? (
                    <Button
                      className="ms-2"
                      onClick={() => {
                        props?.data?.min_obligatory <= trueCount
                          ? getSurveyGraphData({
                              survey_qa_id: props?.data?.survey_qa_id,
                            })
                          : toast.error(
                              `${
                                "Please select minimum " +
                                props?.data?.min_obligatory +
                                " answer"
                              }`
                            );
                      }}
                    >
                      Save and Show results
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        if (!props?.data?.parentData?.is_submitted) {
                          props?.data?.min_obligatory <= trueCount
                            ? next()
                            : toast.error(
                                `${
                                  "Please select minimum " +
                                  props?.data?.min_obligatory +
                                  " answer"
                                }`
                              );
                        } else {
                          next();
                        }
                      }}
                    >
                      Next
                    </Button>
                  )}
                </>
              ) : saveButton ? (
                <Button
                  onClick={() => {
                    props?.data?.min_obligatory <= trueCount
                      ? getSurveyGraphData({
                          survey_qa_id: props?.data?.survey_qa_id,
                        })
                      : toast.error(
                          `${
                            "Please select minimum " +
                            props?.data?.min_obligatory +
                            " answer"
                          }`
                        );
                  }}
                >
                  Save and Show results
                </Button>
              ) : (
                <Button
                  type="primary"
                  className="mt-2"
                  onClick={() => {
                    props?.data?.min_obligatory <= trueCount
                      ? handleSubmit(props?.data)
                      : toast.error(
                          `${
                            "Please select minimum " +
                            props?.data?.min_obligatory +
                            " answer"
                          }`
                        );
                  }}
                >
                  Submit
                </Button>
              )}
            </div>
          </div>
        </>
      );
  };

  const MultipleChoice = (props) => {
    const [resultArr, setResultArr] = useState({
      labels: [],
      datasets: [
        {
          label: "number of votes",
          data: [],
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    });

    let chartType = props?.data?.chart_type;
    const [showChart, setShowChart] = useState(false);
    const [saveButton, setSaveButton] = useState(true);
    const [loaderMultiChoice, setLoaderMultiChoice] = useState(false);

    const optionsPie = {
      responsive: true,
      plugins: {
        title: {
          display: false,
          text: "",
        },
        legend: {
          display: true,
          position: "right",
        },
      },
    };

    const optionsBar = {
      responsive: true,
      plugins: {
        title: {
          display: false,
          text: "",
        },
        legend: {
          display: false,
          position: "right",
        },
      },
    };

    // useEffect(() => {
    //   if (props?.data?.parentData?.is_submitted === true) {
    //     getSurveyGraphData({ survey_qa_id: props?.data?.survey_qa_id });
    //   }
    // }, []);

    const getSurveyGraphData = async (params) => {
      setLoaderMultiChoice(true);
      const response = await ApiCall(
        "GET",
        "",
        "/admin/survey/result/get",
        params
      );
      if (response?.data?.code === 200) {
        let choiceIds = [];
        console.log(props?.data?.answer_details);
        props?.data?.answer_details?.forEach((elem) => {
          if (elem?.is_default_selected === true) {
            choiceIds.push(elem?.choice_id);
          }
        });

        // console.log("response multi choice", response);
        // console.log("choice ids", response);

        setResultArr({
          labels: response?.data?.data?.labels.map((item, i) => `${item}`),
          datasets: [
            {
              label:
                chartType?.toLowerCase() === "bar" ? "Number of Votes" : "",
              data: response?.data?.data?.datasets?.map((item) => {
                if (choiceIds.includes(item?.choice_id)) return item?.data + 1;
                else return item?.data;
              }),
              backgroundColor: [
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(82, 215, 38, 0.2)",
                "rgba(153, 102, 255, 0.2)",
                "rgba(255, 159, 64, 0.2)",
              ],
              borderColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(82, 215, 38, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
              ],
              borderWidth: 1,
            },
          ],
        });
        setShowChart(true);
        setSaveButton(false);
      } else {
        toast.error(response?.data?.message);
      }
      setLoaderMultiChoice(false);
    };

    if (loaderMultiChoice) return <CommonLoader />;
    else
      return (
        <>
          <div
            className={`${
              !surveyProps?.isFromSurveyModal
                ? "survey_container"
                : "userSurveyModal_container"
            }  w-100 bg-white"`}
          >
            <div className="row">
              <div className="col-12 text-start mt-4">
                <h5 className="fs-pink">
                  {props?.data?.parentData?.is_submitted === true
                    ? "Edit the Survey"
                    : "Complete the Survey"}
                </h5>
              </div>
            </div>

            <div className="row">
              <div className="col-12 my-1">
                <span className="fs-18 fs-bold">
                  <span className="mx-1"> Question : </span>
                  {props?.data?.question}
                </span>
              </div>
              {/* <div className="col-1 mt-3">
              <span className="fs-18 fs-bold">Answer's : </span>
            </div> */}
              <div className=" d-flex justify-content-around survey_mobile_multiple_ans_container mt-3">
                <span className="fs-18 fs-bold">Answer's : </span>
                {props?.data?.answer_details.map((data, index) => (
                  <div className=" d-flex border p-2 option_block">
                    <input
                      type="radio"
                      defaultChecked={data.is_default_selected}
                      value={data?.is_default_selected}
                      disabled={!saveButton ? true : false}
                      name="radio"
                      className="mx-1"
                      onChange={(e) => {
                        handleFieldsChange(
                          props?.data,
                          e.target.checked,
                          data.choice_id
                        );
                      }}
                    />
                    <span className="ms-2">{data.option}</span>
                  </div>
                ))}
              </div>

              {props?.data?.display_chart === true && (
                <div className="d-flex mt-5 my-4 justify-content-center">
                  {/* <ReactFusioncharts
                  type="pie2d"
                  dataFormat="JSON"
                  dataSource={dataSource}
                /> */}

                  {showChart ? (
                    chartType === "PIE" ? (
                      <div
                        className="video-conference-result-chart-type"
                        style={{
                          width: "258px",
                          height: "186px",
                        }}
                      >
                        <Pie data={resultArr} options={optionsPie} />
                      </div>
                    ) : (
                      <div
                        className="video-conference-result-chart-type"
                        style={{
                          width: "433px",
                          height: "200px",
                        }}
                      >
                        <Bar options={optionsBar} data={resultArr} />
                      </div>
                    )
                  ) : null}
                </div>
              )}
            </div>
            <div
              className={`${
                !surveyProps?.isFromSurveyModal ? "" : "userSurveyModal_buttons"
              } d-flex w-75 mb-4 justify-content-between`}
            >
              {props.seqIndex >= 1 && (
                <Button onClick={() => prev()}>Previous</Button>
              )}

              {props.seqIndex <
              props?.data.parentData.question_details.length - 1 ? (
                saveButton ? (
                  <Button
                    className="ms-2"
                    onClick={() =>
                      getSurveyGraphData({
                        survey_qa_id: props?.data?.survey_qa_id,
                      })
                    }
                  >
                    Save and Show results
                  </Button>
                ) : (
                  <Button onClick={() => next()}>Next</Button>
                )
              ) : saveButton ? (
                <Button
                  onClick={() =>
                    getSurveyGraphData({
                      survey_qa_id: props?.data?.survey_qa_id,
                    })
                  }
                >
                  Save and Show results
                </Button>
              ) : (
                <Button
                  type="primary"
                  className="mt-2"
                  onClick={() => handleSubmit(props?.data)}
                >
                  Submit
                </Button>
              )}
            </div>
          </div>
        </>
      );
  };

  const FreeFormText = (props) => {
    return (
      <>
        <div
          className={`${
            !surveyProps?.isFromSurveyModal
              ? "survey_container"
              : "userSurveyModal_container"
          }  w-100 bg-white"`}
        >
          <div className="row">
            <div className="col-12 text-start mt-4">
              <h5 className="fs-pink">
                {props?.data?.parentData?.is_submitted === true
                  ? "Edit the Survey"
                  : "Complete the Survey"}
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="mt-4">
                <h4 className="fs-18 d-flex">
                  <span className="mx-1"> Question: </span>
                  <span>{props?.data?.question}</span>
                </h4>
              </div>

              {props?.data?.format === "MULTI LINE" ? (
                <div className="mt-4 d-flex">
                  <span className="fs-18 fs-bold m-1">Answer: </span>
                  <TextArea
                    placeholder="Type here"
                    className=" m-1"
                    rows={4}
                    value={
                      props?.data?.answer_details[0].user_survey_answer_details
                        .free_form_text_answer
                    }
                    onChange={(e) => {
                      handleFieldsChange(props?.data, e.target.value);
                    }}
                  />
                </div>
              ) : (
                <div className="mt-4 d-flex">
                  <span className="fs-18 fs-bold m-1">Answer : </span>
                  <Input
                    placeholder="Type here"
                    className="m-1"
                    rows={4}
                    value={
                      props?.data?.answer_details[0].user_survey_answer_details
                        .free_form_text_answer
                    }
                    onChange={(e) => {
                      handleFieldsChange(props?.data, e.target.value);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div
            className={`${
              !surveyProps?.isFromSurveyModal ? "" : "userSurveyModal_buttons"
            } d-flex w-75 mb-4 justify-content-between mt-2`}
          >
            {props.seqIndex >= 1 && (
              <Button onClick={() => prev()}>Previous</Button>
            )}

            {props.seqIndex <
            props?.data.parentData.question_details.length - 1 ? (
              <Button
                onClick={() => {
                  props?.data?.answer_details[0].user_survey_answer_details
                    .free_form_text_answer === "" &&
                  props?.data?.is_required === true
                    ? toast.error("Please Fill The Answer")
                    : next();
                }}
              >
                Next
              </Button>
            ) : (
              <Button
                type="primary"
                className=""
                onClick={() => handleSubmit(props?.data)}
              >
                Submit
              </Button>
            )}
          </div>
        </div>
      </>
    );
  };

  const { Step } = Steps;

  if (loadingSurvey) return <CommonLoader />;
  else
    return (
      <div className="h-100">
        <h4 className="my-2 fs-24">
          {surveyProps?.fromFeedback ? "Feedback Survey" : "Survey"}
        </h4>
        <br />
        {Object.keys(details).length === 0 ? (
          <p className="text-center fs-pink fs-16">No Survey Available</p>
        ) : (
          <div className="" style={{ position: "relative", maxHeight: "100%" }}>
            <Steps direction="horizontal" responsive={false} current={current}>
              {stepData &&
                stepData.map((item) => (
                  <Step key={item?.title} title={item?.title} />
                ))}
            </Steps>
            <div className="steps-content">
              {stepData && stepData?.[current]}
            </div>
          </div>
        )}
      </div>
    );
};

export default Survey;
