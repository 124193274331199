/**
 * Popup Styles
 */

import styled from "styled-components";

export const PopupContainer = styled.div`
  width: 95%;
  height: 95%;
  position: relative;
  background-color: ${(props) =>
    props.name !== "eventmap" && props.name !== "login" ? "#ffffff" : ""};
  top: 8px;
`;

export const PopupContent = styled.div`
  // flex-grow: 1;
  //   height: 100%;
  //   width: 100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  overflow: auto;
  height: 100%;

  h6 {
    font-size: 20px;
  }

  h5 {
    font-size: 20px;
  }
  ::-webkit-scrollbar {
    display: none;
  }

  .vista_social_title {
    padding-top: 10px;
    font-size: 20px;
  }
`;

export const ShadowBox = styled.div`
  height: 100%;
  width: 100%;
  z-index: 20;
  display: flex;
  //   align-items: center;
  padding-top: 13px;
  justify-content: center;
  background-color: rgba(1, 1, 1, 0.5);
`;

export const PopupHeader = styled.div(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  position: "relative",
}));

export const CloseButton = styled.button(() => ({
  width: "30px",
  padding: "2px",
  margin: "2px",
  position: "relative",
}));

export const VistaPopupFrame = styled.iframe(() => ({
  width: "100%",
  height: "100%",
  border: "none",
}));
