import React, { useState, useEffect, useRef } from "react";
import { Input } from "antd";
import { cloneDeep, isEmpty } from "lodash";
import "./chatbot.less";
import { CloseOutlined } from "@ant-design/icons";

const CustomChatbot = (props) => {
  const sendBtnRef = useRef(null);
  const [responseJson] = useState(props.responseJson);
  const [chatbot_history, set_chatbot_history] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        sendBtnRef.current.click();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  useEffect(() => {
    set_chatbot_history(props.chatbot_history);
  }, [props.chatbot_history]);

  return (
    <div className="hJzUFe" width="350px" height="520px">
      <div className="jqSBBM">
        <h2 className="kbDkhC">Chat</h2>
        <CloseOutlined
          className="float-right text-danger mr-0 close-icon"
          onClick={() => props.onClose(chatbot_history)}
        />
      </div>
      <div className="cWBQNw" height="520px">
        {chatbot_history &&
          chatbot_history.map((ele) => {
            if (ele.position === "left")
              return (
                <div className="left_sided_message_div" key={ele.key}>
                  <div className="sc-ftvSup hAEwKD rsc-ts-image-container">
                    <img
                      className="sc-iBkjds cftzIa rsc-ts-image"
                      src="data:image/svg+xml,%3csvg version='1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpath d='M303 70a47 47 0 1 0-70 40v84h46v-84c14-8 24-23 24-40z' fill='%2393c7ef'/%3e%3cpath d='M256 23v171h23v-84a47 47 0 0 0-23-87z' fill='%235a8bb0'/%3e%3cpath fill='%2393c7ef' d='M0 240h248v124H0z'/%3e%3cpath fill='%235a8bb0' d='M264 240h248v124H264z'/%3e%3cpath fill='%2393c7ef' d='M186 365h140v124H186z'/%3e%3cpath fill='%235a8bb0' d='M256 365h70v124h-70z'/%3e%3cpath fill='%23cce9f9' d='M47 163h419v279H47z'/%3e%3cpath fill='%2393c7ef' d='M256 163h209v279H256z'/%3e%3cpath d='M194 272a31 31 0 0 1-62 0c0-18 14-32 31-32s31 14 31 32z' fill='%233c5d76'/%3e%3cpath d='M380 272a31 31 0 0 1-62 0c0-18 14-32 31-32s31 14 31 32z' fill='%231e2e3b'/%3e%3cpath d='M186 349a70 70 0 1 0 140 0H186z' fill='%233c5d76'/%3e%3cpath d='M256 349v70c39 0 70-31 70-70h-70z' fill='%231e2e3b'/%3e%3c/svg%3e"
                      alt="avatar"
                    />
                  </div>
                  <div className="sc-gKXOVf jzwenc rsc-ts-bubble">
                    {ele.message}
                  </div>
                </div>
              );
            else
              return (
                <div className="right_sided_message_div" key={ele.key}>
                  <div className="sc-ftvSup hnGEZW rsc-ts-image-container">
                    <img
                      className="sc-iBkjds cXLLpN rsc-ts-image"
                      src="data:image/svg+xml,%3csvg viewBox='-208.5 21 100 100' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ccircle cx='-158.5' cy='71' fill='%23F5EEE5' r='50'/%3e%3cdefs%3e%3ccircle cx='-158.5' cy='71' id='a' r='50'/%3e%3c/defs%3e%3cclipPath id='b'%3e%3cuse overflow='visible' xlink:href='%23a'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23b)' d='M-108.5 121v-14s-21.2-4.9-28-6.7c-2.5-.7-7-3.3-7-12V82h-30v6.3c0 8.7-4.5 11.3-7 12-6.8 1.9-28.1 7.3-28.1 6.7v14h100.1z' fill='%23E6C19C'/%3e%3cg clip-path='url(%23b)'%3e%3cdefs%3e%3cpath d='M-108.5 121v-14s-21.2-4.9-28-6.7c-2.5-.7-7-3.3-7-12V82h-30v6.3c0 8.7-4.5 11.3-7 12-6.8 1.9-28.1 7.3-28.1 6.7v14h100.1z' id='c'/%3e%3c/defs%3e%3cclipPath id='d'%3e%3cuse overflow='visible' xlink:href='%23c'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23d)' d='M-158.5 100.1c12.7 0 23-18.6 23-34.4 0-16.2-10.3-24.7-23-24.7s-23 8.5-23 24.7c0 15.8 10.3 34.4 23 34.4z' fill='%23D4B08C'/%3e%3c/g%3e%3cpath d='M-158.5 96c12.7 0 23-16.3 23-31 0-15.1-10.3-23-23-23s-23 7.9-23 23c0 14.7 10.3 31 23 31z' fill='%23F2CEA5'/%3e%3c/svg%3e"
                      alt="avatar"
                    />
                  </div>
                  <div className="sc-gKXOVf iXUvls rsc-ts-bubble">
                    {ele.message}
                  </div>
                </div>
              );
          })}
      </div>
      <div className="cyCRYR">
        <Input
          placeholder="Type the message ..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <button
          ref={sendBtnRef}
          className="biRHyV"
          disabled={!isEmpty(newMessage) ? false : true}
          onClick={() => {
            let clonedHistory = cloneDeep(chatbot_history);
            let hlength = clonedHistory.length;
            clonedHistory.push({
              key: "right_message_" + hlength,
              message: newMessage,
              position: "right",
            });

            if (responseJson) {
              const newL = clonedHistory.length;
              let defRes = responseJson.filter(
                (o) => o.keyterm && o.keyterm === "Default Response"
              );
              let newLeftMessage =
                defRes && defRes[0] ? defRes[0].response : "";

              let res = responseJson.filter(
                (o) =>
                  o.keyterm &&
                  o.keyterm.toLowerCase().includes(newMessage.toLowerCase())
              );

              if (res && res[0] && res[0].response) {
                newLeftMessage = res[0].response;
              }

              clonedHistory.push({
                key: "left_message_" + newL,
                message: newLeftMessage,
                position: "left",
              });
            }
            setNewMessage("");
            set_chatbot_history(clonedHistory);
            props.onSetHistory(clonedHistory);
          }}
        >
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 500 500"
          >
            <g>
              <g>
                <polygon points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75" />
              </g>
            </g>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default CustomChatbot;
