import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Card, Checkbox, Input, Select, Button } from "antd";
import SimpleReactValidator from "simple-react-validator";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import "./index.css";
import { logo } from "../../components/Images/Images";
import { useSelector, useDispatch } from "react-redux";
import { getRegisterUser } from "../../redux/slice/register";
import { addUser } from "../../redux/slice/register";
import { toast } from "react-toastify";
import CommonLoader from "../../components/Widgets/CommonLoader";
import RedAsterick from "../../components/Widgets/RedAsterick";
import { handleLoginAction } from "../login/LoginBox";
import { INPUT_TEXT_LIMITS } from "../../components/constsnts/common.constants";
import CryptoAES from "crypto-js/aes";

const Registration = (props) => {
  const isMobile = useMediaQuery({ minWidth: 480 });
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state?.register?.registerUser);
  const loading = useSelector((state) => state?.register?.registerLoading);
  const validator = useRef(
    new SimpleReactValidator({
      messages: {
        regex:
          "Password must contain at least 1 letter, 1 number, 1 special character",
      },
    })
  );

  const Tablet = useMediaQuery({ maxWidth: 1200 });
  const { Option } = Select;
  const [forceUpdate, setForceUpdate] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userType, setUserType] = useState("");
  const [initialLoading, setInitialLoading] = useState(false);
  const [fields, setFields] = useState({});
  const [termsAndConditionCheck, setTermsAndConditionCheck] = useState(false);
  const [showRegisErrorPopup, setShowRegisErrorPopup] = useState({
    flag: false,
    message: "",
  });

  useEffect(async () => {
    const query = new URLSearchParams(location.search);
    const user_id = query.get("user_id");
    setUserId(user_id);
    dispatch(getRegisterUser({ user_id }));
  }, []);

  useEffect(() => {
    if (user) {
      setInitialLoading(true);
      initializeUserData();

      setTimeout(() => {
        setInitialLoading(false);
      }, 500);
    }
  }, [user]);

  const checkCountryAndJobTitle = () => {
    //if country and jobtitle is not in array then adding it manually
    const newCountryArr = user?.country_details?.slice();
    const newJobTitleArr = user?.job_title_details?.slice();

    if (
      !newCountryArr?.some(
        (item) => item?.country_id === user?.user_details?.country_id
      )
    ) {
      newCountryArr?.push({
        country_id: user?.user_details?.country_id,
        country: user?.user_details?.country,
      });
    }

    if (
      !newJobTitleArr?.some(
        (item) => item?.job_title_id === user?.user_details?.job_title_id
      )
    ) {
      newJobTitleArr?.push({
        job_title_id: user?.user_details?.job_title_id,
        job_title: user?.user_details?.job_title,
      });
    }

    return {
      newCountryArr,
      newJobTitleArr,
    };
  };

  const getLogoUrl = (userType, eventLogo, brandLogo) => {
    if (userType === "AGENT") {
      return brandLogo;
    } else if (eventLogo) {
      return eventLogo;
    } else {
      return logo;
    }
  };

  const initializeUserData = () => {
    const query = new URLSearchParams(location.search);
    const user_id = parseInt(query.get("user_id"));

    if (user?.is_registration_closed) {
      setShowRegisErrorPopup({
        flag: user?.is_registration_closed,
        message: user?.error,
      });
    } else {
      // const { newCountryArr, newJobTitleArr } = checkCountryAndJobTitle();
      setUserType(user?.user_details?.user_type);

      if (user) {
        if (
          user?.user_details?.user_type === "DELEGATE" ||
          user?.user_details?.user_type === "SPEAKER"
        ) {
          let eventColor = user?.user_details?.event_color;
          window.less
            .modifyVars({
              "@primary-color": eventColor?.event_color_theme,
              "@default_button_background_color":
                eventColor?.default_button_background_color,
              "@default_button_background_second_color":
                eventColor?.default_button_background_second_color
                  ? eventColor?.default_button_background_second_color
                  : eventColor?.default_button_background_color,

              "@default_button_text_color":
                eventColor?.default_button_text_color,
              "@highlight_button_background_color":
                eventColor?.highlight_button_background_color,
              "@highlight_button_background_second_color":
                eventColor?.highlight_button_background_second_color
                  ? eventColor?.highlight_button_background_second_color
                  : eventColor?.highlight_button_background_color,
              "@highlight_button_text_color":
                eventColor?.highlight_button_text_color,
              "@default_button_gradient_angle":
                eventColor?.default_button_gradient_angle
                  ? eventColor?.default_button_gradient_angle + "deg"
                  : "0deg",
              "@highlight_button_gradient_angle":
                eventColor?.highlight_button_gradient_angle
                  ? eventColor?.highlight_button_gradient_angle + "deg"
                  : "0deg",
            })
            .then(() => {});
        }

        if (
          user?.user_details?.user_type === "AGENT" ||
          user?.user_details?.user_type === "BRAND ADMIN"
        ) {
          let color = user?.user_details?.brand_color;
          window.less
            .modifyVars({
              "@primary-color": color?.brand_color,
              "@default_button_background_color":
                color?.default_button_background_color,
              "@default_button_background_second_color":
                color?.default_button_background_second_color
                  ? color?.default_button_background_second_color
                  : color?.default_button_background_color,

              "@default_button_text_color": color?.default_button_text_color,
              "@highlight_button_background_color":
                color?.highlight_button_background_color,
              "@highlight_button_background_second_color":
                color?.highlight_button_background_second_color
                  ? color?.highlight_button_background_second_color
                  : color?.highlight_button_background_color,
              "@highlight_button_text_color":
                color?.highlight_button_text_color,
              "@default_button_gradient_angle":
                color?.default_button_gradient_angle
                  ? color?.default_button_gradient_angle + "deg"
                  : "0deg",
              "@highlight_button_gradient_angle":
                color?.highlight_button_gradient_angle
                  ? color?.highlight_button_gradient_angle + "deg"
                  : "0deg",
            })
            .then(() => {});
        }

        let company = "";
        let userType = user?.user_details?.user_type;

        if (userType === "AGENT" || userType === "BRAND ADMIN") {
          company = "dummy";
        } else {
          company = user?.user_details?.company ?? "";
        }

        setFields({
          user_id: user_id,
          first_name: user?.user_details?.first_name ?? "",
          last_name: user?.user_details?.last_name ?? "",
          email: user?.user_details?.email ?? "",
          company: company,
          country: user?.user_details?.country ?? "",
          job_title: user?.user_details?.job_title ?? "",
          job_title_id: user?.user_details?.job_title_id ?? null,
          country_id: user?.user_details?.country_id ?? null,
          user_zones: user?.user_details?.user_zones,
          event_id: user?.user_details?.event_id ?? null,
          password: "",
          confirmPassword: "",
          allow_other_country: user?.allow_other_country ?? false,
          block_other_country_text:
            user?.block_other_country_text ?? "Other Option not available",
          allow_other_job_title: user?.allow_other_job_title ?? false,
          block_other_job_title_text:
            user?.block_other_job_title_text ?? "Other Option not available",
          selectedCountries: user?.country_details ?? [],
          selectedJobTitles: user?.job_title_details ?? [],
          logo_url: getLogoUrl(
            user?.user_details?.user_type,
            user?.user_details?.event_logo_url,
            user?.user_details?.brand_logo_url
          ),
        });
      }
    }
  };

  const handleFieldsChange = (val, name) => {
    setFields({ ...fields, [name]: val });
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();

    const finalUserZones = fields?.user_zones?.map((item) => {
      return {
        zone_id: item?.zone_id,
        is_assigned: item?.is_assigned,
        is_always_get_messages: item?.is_always_get_messages,
      };
    });

    let obj = {
      password: fields.password,
    };
    let ciphertext2 = CryptoAES.encrypt(
      JSON.stringify(obj),
      "$2a$10$e.oPc.dyrwRoQCpDvO9Rhe"
    ).toString();

    const newUser = {
      first_name: fields?.first_name,
      last_name: fields?.last_name,
      email: fields?.email,
      user_id: fields?.user_id,
      company: fields?.company,
      job_title_id:
        fields?.job_title_id === "others" ? null : fields?.job_title_id,
      job_title: fields?.job_title,
      country_id: fields?.country_id === "others" ? null : fields?.country_id,
      country: fields?.country,
      password: ciphertext2,
      zone_tag_details: finalUserZones,
    };

    if (userType === "AGENT" || userType === "BRAND ADMIN") {
      validator.current.fields.Company = true;
    }

    if (validator.current.allValid()) {
      if (fields.password !== fields.confirmPassword) {
        toast.error("Confirm Passord does not match");
      } else if (termsAndConditionCheck === false) {
        toast.error("Please indicate that you accept the Terms & Conditions");
      } else {
        const addUserResponse = await dispatch(addUser(newUser));

        if (addUserResponse.payload.code === 200) {
          let email = newUser.email;
          let password = fields.password;
          let eventId = fields.event_id;
          handleLoginAction(email, password, props, dispatch, eventId);
          toast.success(addUserResponse.payload.message);
        } else {
          toast.error(addUserResponse.payload.message);
        }
      }
    } else {
      validator.current.showMessages();

      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      setForceUpdate(!forceUpdate);
    }
  };

  if (showRegisErrorPopup?.flag) {
    return (
      <>
        <div className="registration-bg-img-div flex_center">
          <div className="registartion-error-message-popup flex_center">
            <h4 className="fs-pink">{showRegisErrorPopup?.message}</h4>
          </div>
        </div>
      </>
    );
  } else if (loading || initialLoading) return <CommonLoader />;
  else
    return (
      <div className="registration-bg-img-div">
        <div className="site-card-border-less-wrapper py-5 text-center">
          <img className="regis_event_logo" src={fields?.logo_url} alt="logo" />

          <Card
            bordered={true}
            // style={{ width: 500 }}
            // headStyle={{ width: 500 }}
            className={
              !isMobile
                ? "m-auto  mt-4 rounded registration-div"
                : `m-auto ${Tablet ? "w-75" : "w-50"} mt-4 rounded`
            }
          >
            <h5 className="text-center mt-3 fs-26 fs-pink"> Sign up </h5>

            <form autoComplete="off">
              <div className={`container ${isMobile ? "px-5" : ""}`}>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group text-start">
                      <label className="fs-16">
                        Forename
                        <RedAsterick />
                      </label>
                      <Input
                        name="forename"
                        type="text"
                        className="form-control py-2"
                        placeholder="Enter Forename"
                        autoComplete="forename"
                        maxLength={INPUT_TEXT_LIMITS.MAX}
                        value={fields.first_name}
                        onChange={(e) =>
                          handleFieldsChange(e.target.value, "first_name")
                        }
                        onBlur={() =>
                          validator.current.showMessageFor("forename")
                        }
                      />
                      {validator.current.message(
                        "ForeName",
                        fields.first_name,
                        "required",
                        {
                          className: "text-danger py-1",
                        }
                      )}
                    </div>
                  </div>
                  <div
                    className={`col-lg-6 col-md-6 col-sm-12 ${
                      !isMobile && "mt-4"
                    }`}
                  >
                    <div className="form-group text-start">
                      <label className="fs-16">
                        Surname
                        <RedAsterick />
                      </label>
                      <Input
                        name="surname"
                        type="text"
                        className="form-control py-2"
                        placeholder="Enter Surname"
                        autoComplete="surname"
                        maxLength={INPUT_TEXT_LIMITS.MAX}
                        value={fields.last_name}
                        onChange={(e) =>
                          handleFieldsChange(e.target.value, "last_name")
                        }
                        onBlur={() =>
                          validator.current.showMessageFor("surname")
                        }
                      />
                      {validator.current.message(
                        "Surname",
                        fields.last_name,
                        "required",
                        {
                          className: "text-danger py-1",
                        }
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <div className="form-group text-start">
                      <label className="fs-16">
                        Email Address
                        <RedAsterick />
                      </label>
                      <Input
                        name="email"
                        type="email"
                        disabled
                        className="form-control py-2"
                        maxLength={INPUT_TEXT_LIMITS.MAX}
                        placeholder="Enter Email Address"
                        autoComplete="username"
                        value={fields.email}
                        onChange={(e) =>
                          handleFieldsChange(e.target.value, "email")
                        }
                        onBlur={() => validator.current.showMessageFor("email")}
                      />
                      {validator.current.message(
                        "Email",
                        fields.email,
                        "required",
                        {
                          className: "text-danger py-1",
                        }
                      )}
                    </div>
                  </div>

                  {userType === "AGENT" || userType === "BRAND ADMIN" ? (
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-4"></div>
                  ) : (
                    <>
                      <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                        <div className="form-group text-start">
                          <label className="fs-16">
                            Company
                            <RedAsterick />
                          </label>
                          <Input
                            name="company"
                            type="text"
                            className="form-control py-2"
                            placeholder="Enter Company"
                            maxLength={INPUT_TEXT_LIMITS.MAX}
                            autoComplete="company"
                            value={fields?.company}
                            onChange={(e) =>
                              handleFieldsChange(e.target.value, "company")
                            }
                            onBlur={() =>
                              validator.current.showMessageFor("company")
                            }
                          />
                          {validator.current.message(
                            "Company",
                            fields.company,
                            "required",
                            {
                              className: "text-danger py-1",
                            }
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <div className="form-group text-start">
                      <label className="fs-16">Country</label>
                      <Select
                        showSearch
                        name="country"
                        listHeight={120}
                        listItemHeight={4}
                        className="w-100 mt-1"
                        placeholder="Select Country"
                        onChange={(val) => {
                          if (val === "others") {
                            setFields({
                              ...fields,
                              country: "",
                              country_id: val,
                            });
                          } else {
                            const countryObj = fields?.selectedCountries.find(
                              (item) => {
                                if (item.country_id === val) {
                                  return item?.country;
                                }
                              }
                            );
                            setFields({
                              ...fields,
                              country_id: val,
                              country: countryObj?.country,
                            });
                          }
                        }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={
                          fields?.selectedCountries?.length > 0 &&
                          fields.country_id
                            ? fields.country_id
                            : "Select Country"
                        }
                        onBlur={() =>
                          validator.current.showMessageFor("country")
                        }
                      >
                        {fields?.selectedCountries?.length > 0 &&
                          fields?.selectedCountries?.map((item) => {
                            return (
                              <Option
                                key={item.country_id}
                                value={item.country_id}
                              >
                                {item.country}
                              </Option>
                            );
                          })}
                        {fields?.selectedCountries?.length > 0 ? (
                          <Option key="others" value="others">
                            Others
                          </Option>
                        ) : null}
                      </Select>

                      {fields?.country_id === "others" &&
                        (!fields?.allow_other_country ? (
                          <>
                            <p className="text-danger py-1">
                              {fields?.block_other_country_text}
                            </p>
                          </>
                        ) : (
                          <Input
                            name="otherCountry"
                            type="text"
                            className="form-control py-2 mt-2"
                            maxLength={INPUT_TEXT_LIMITS.MAX}
                            placeholder="Enter Other Country Name"
                            autoComplete="off"
                            onChange={(e) =>
                              handleFieldsChange(e.target.value, "country")
                            }
                            onBlur={() =>
                              validator.current.showMessageFor("otherCountry")
                            }
                          />
                        ))}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <div className="form-group text-start">
                      <label className="fs-16">Job Title</label>

                      <Select
                        showSearch
                        name="job_title"
                        className="w-100 mt-1"
                        placeholder="Select Job Title"
                        autoComplete="jobtitle"
                        listHeight={120}
                        listItemHeight={4}
                        onChange={(val) => {
                          if (val === "others") {
                            setFields({
                              ...fields,
                              job_title: "",
                              job_title_id: val,
                            });
                          } else {
                            const jobTitleObj = fields?.selectedJobTitles.find(
                              (item) => {
                                if (item.job_title_id === val) {
                                  return item?.job_title;
                                }
                              }
                            );
                            setFields({
                              ...fields,
                              job_title_id: val,
                              job_title: jobTitleObj?.job_title,
                            });
                          }
                        }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={
                          fields?.selectedJobTitles?.length > 0 &&
                          fields.job_title_id
                            ? fields.job_title_id
                            : "Select Job Title"
                        }
                        onBlur={() =>
                          validator.current.showMessageFor("job_title")
                        }
                      >
                        {fields?.selectedJobTitles?.length > 0 &&
                          fields?.selectedJobTitles?.map((item) => {
                            return (
                              <Option
                                key={item.job_title_id}
                                value={item.job_title_id}
                              >
                                {item.job_title}
                              </Option>
                            );
                          })}
                        {fields?.selectedJobTitles?.length > 0 ? (
                          <Option key="others" value="others">
                            Others
                          </Option>
                        ) : null}
                      </Select>
                      {fields?.job_title_id === "others" &&
                        (!fields?.allow_other_job_title ? (
                          <p className="text-danger py-1">
                            {fields?.block_other_job_title_text}
                          </p>
                        ) : (
                          <Input
                            name="otherJobTitle"
                            maxLength={INPUT_TEXT_LIMITS.MAX}
                            type="text"
                            className="form-control py-2 mt-2"
                            placeholder="Enter Other Job Title"
                            autoComplete="off"
                            value={fields.jobInput}
                            onChange={(e) =>
                              handleFieldsChange(e.target.value, "job_title")
                            }
                          />
                        ))}
                    </div>
                  </div>

                  {fields?.user_zones?.length !== 0 ? (
                    <div className="col-12 text-start mt-4">
                      <label className="fs-16 d-block">Zone Tags</label>

                      <div className="w-100 border p-2 d-flex justify-content-between">
                        <span className="w-25">Zones</span>
                        <span className="w-25">Assign</span>
                        <span className="w-25">Always get Messages</span>
                      </div>

                      <div className="w-100 border p-2">
                        {fields?.user_zones &&
                          fields?.user_zones?.map((data) => {
                            return (
                              <>
                                <div className="mb-2 d-flex justify-content-between">
                                  <span className="w-25">
                                    {data?.zone_title}
                                  </span>
                                  <span className="w-25">
                                    <Checkbox
                                      checked={data?.is_assigned}
                                      disabled
                                      className=""
                                      onChange={() =>
                                        setFields({
                                          ...fields,
                                          user_zones: fields?.user_zones?.map(
                                            (elem) => {
                                              if (
                                                data?.zone_id === elem?.zone_id
                                              ) {
                                                return {
                                                  ...elem,
                                                  is_assigned:
                                                    !elem?.is_assigned,
                                                };
                                              }
                                              return elem;
                                            }
                                          ),
                                        })
                                      }
                                    />
                                  </span>
                                  <span className="w-25">
                                    <Checkbox
                                      checked={data?.is_always_get_messages}
                                      // disabled={!data?.is_assigned}
                                      disabled
                                      onChange={() =>
                                        setFields({
                                          ...fields,
                                          user_zones: fields?.user_zones?.map(
                                            (elem) => {
                                              if (
                                                data?.zone_id === elem?.zone_id
                                              ) {
                                                return {
                                                  ...elem,
                                                  is_always_get_messages:
                                                    !elem?.is_always_get_messages,
                                                };
                                              }
                                              return elem;
                                            }
                                          ),
                                        })
                                      }
                                      className=""
                                    />
                                  </span>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* </>
                  ) : (
                    ""
                  )} */}

                  <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                    <div className="form-group text-start">
                      <label className="fs-16">Password</label>
                      <Input.Password
                        name="password"
                        type="password"
                        maxLength={INPUT_TEXT_LIMITS.MAX}
                        className="form-control py-2"
                        placeholder="Enter password"
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        onBlur={() =>
                          validator.current.showMessageFor("password")
                        }
                        onChange={(e) =>
                          handleFieldsChange(e.target.value, "password")
                        }
                        autoComplete="new-password"
                      />
                      {validator.current.message(
                        "Password",
                        fields.password,
                        "required|min:5|regex:(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\"/@#$%^&`+=._!-*(){};'<>?/~`\\[\\]])",
                        // : |
                        {
                          className: "text-danger py-1",
                        }
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                    <div className="form-group text-start">
                      <label className="fs-16">Confirm Password</label>
                      <Input.Password
                        name="confirmPassword"
                        maxLength={INPUT_TEXT_LIMITS.MAX}
                        type="password"
                        className="form-control py-2"
                        placeholder="Enter password"
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        onBlur={() =>
                          validator.current.showMessageFor("password")
                        }
                        onChange={(e) =>
                          handleFieldsChange(e.target.value, "confirmPassword")
                        }
                        autoComplete="off"
                      />
                      {validator.current.message(
                        "Confirm Password",
                        fields.confirmPassword,
                        "required|min:5|regex:(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\"/@#$%^&`+=._!-*(){};'<>?/~`\\[\\]])",
                        {
                          className: "text-danger py-1",
                        }
                      )}
                    </div>
                  </div>
                  <div className="col-12 mt-2">
                    <Checkbox
                      className="mx-1"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setTermsAndConditionCheck(true);
                        } else {
                          setTermsAndConditionCheck(false);
                        }
                      }}
                    />
                    <span>
                      Please check the box to show that you have read and
                      understood our
                      <span className=" ms-1 fs-pink">
                        <a
                          target={"_blank"}
                          href="https://www.gravit8.co/terms-conditions/"
                        >
                          Terms & Conditions
                        </a>
                      </span>
                    </span>
                  </div>
                  <div className="col-12">
                    <Button
                      type="submit"
                      className="w-100 mt-2 register_submit_btn"
                      onClick={handleRegisterSubmit}
                      loading={loading}
                    >
                      Register
                    </Button>
                  </div>
                </div>
              </div>
            </form>
            <div className="mt-2 text-danger"></div>
            <p className="fs-14">
              {"Already Registered? "}
              <Link
                to={{
                  pathname: `/login`,
                  state: { user_id: userId },
                }}
              >
                <span className="fs-pink">
                  <u>Sign In</u>
                </span>
              </Link>
            </p>
          </Card>
        </div>
      </div>
    );
};

export default Registration;
